import { Box, Button, Container, Img, Text, VStack } from '@chakra-ui/react';

import { useAtomValue } from 'jotai';

import { MONTH_PRICE } from '@/common/constants/brand';
import { userAtom } from '@/shared/store';
import { CongratulationsModal } from '@/entities/modals';
import { ActionSubType, useCreateSub } from '@/features/subscription/create-sub';

export default function DashboardBannerStandard() {
    const { isSubLoading, isSubError, isSubCreate, createSub, sendError } = useCreateSub(
        ActionSubType.DASHBOARD_BANNER_STANDARD_PRICE,
    );
    const user = useAtomValue(userAtom);

    if (!user || user?.is_subscribe) {
        return null;
    }

    return (
        <Box
            pt={{ base: 8, md: '80px' }}
            pb={{ md: '80px' }}
            color="white"
            backgroundImage={{
                base: '/img/bg/pink-blue-dark-vertical.png',
                md: '/img/bg/pink-blue-dark-horizontal.png',
            }}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            overflow="hidden"
        >
            <Container
                pos="relative"
                maxW={{ lg: 'container.md', xl: 'container.xl' }}
            >
                <Box
                    pos="relative"
                    zIndex={30}
                >
                    <Box
                        as="p"
                        maxW={{ base: '360px', md: '610px' }}
                        mx="auto"
                        textStyle="xl"
                        textAlign="center"
                        lineHeight="1.4"
                    >
                        <Text as="span">Want to access daily live events with </Text>

                        <Box
                            as="span"
                            pos="relative"
                            mb={0}
                        >
                            <Text as="span">top authors?</Text>

                            <Img
                                src="/img/elements/ranbow-line.svg"
                                alt="ranbow-line"
                                pos="absolute"
                                bottom={{ base: '-80%', md: '-70%' }}
                                left={0}
                                w="100%"
                                h="100%"
                            />
                        </Box>
                    </Box>

                    <Text
                        textStyle="md6"
                        textAlign="center"
                    >
                        Start with our 7-Day Free Trial.
                    </Text>

                    <Text
                        textStyle="sm4"
                        display="flex"
                        w={{ base: '200px', md: '226px' }}
                        mx="auto"
                        mb={{ base: 7.5, md: 5 }}
                        textAlign="center"
                    >
                        Continue for just ${MONTH_PRICE}
                        /month
                    </Text>

                    <VStack>
                        <Button
                            variant="white"
                            display="flex"
                            mx="auto"
                            isLoading={isSubLoading}
                            loadingText={`Submitting`}
                            onClick={createSub}
                        >
                            Sign up now!
                        </Button>

                        {isSubError && (
                            <Text
                                mb={0}
                                color="red.600"
                            >
                                {sendError}
                            </Text>
                        )}
                    </VStack>
                </Box>

                <Box
                    pos={{ base: 'relative', md: 'static' }}
                    h={{ base: '420px', md: 0 }}
                    maxW={{ base: '320px', md: 'none' }}
                    mx="auto"
                    mt={{ base: 7.5, md: 0 }}
                >
                    <Img
                        src="/img/girls/sea-girl-1.gif"
                        alt="sea-girl"
                        pos="absolute"
                        zIndex={20}
                        top={{ base: 0, md: 'auto' }}
                        bottom={{ md: '-5%' }}
                        right={{
                            base: '45px',
                            md: 0,
                            xl: '14%',
                        }}
                        w={{ base: '135px', md: '180px' }}
                        h={{ base: '126px', md: '168px' }}
                        borderRadius="50%"
                        objectFit="contain"
                    />

                    <Img
                        src="/img/girls/breathe-girl-1.gif"
                        alt="breathe-girl"
                        pos="absolute"
                        zIndex={10}
                        top={{ base: '60px', md: 'auto' }}
                        bottom={{ md: '115px' }}
                        left={{
                            base: '0',
                            md: '-20%',
                            xl: '20px',
                        }}
                        transform={{ md: 'rotate(-5deg)' }}
                        w={{ base: '158px', md: '223px' }}
                        h={{ base: '148px', md: '210px' }}
                        borderRadius="24px"
                        objectFit="contain"
                    />

                    <Img
                        src="/img/girls/yoga-girl-2.gif"
                        alt="yoga-girl"
                        pos="absolute"
                        zIndex={10}
                        bottom={{ base: '105px', md: '115px' }}
                        right={{
                            base: '0',
                            md: '-20%',
                            xl: '20px',
                        }}
                        transform={{ md: 'rotate(5deg)' }}
                        w={{ base: '158px', md: '220px' }}
                        h={{ base: '148px', md: '205px' }}
                        borderRadius="24px"
                        objectFit="contain"
                    />

                    <Img
                        src="/img/girls/yoga-girl-1.gif"
                        alt="yoga-girl"
                        pos="absolute"
                        zIndex={20}
                        bottom={{
                            base: '40px',
                            md: '-5%',
                        }}
                        left={{
                            base: '50px',
                            md: 0,
                            xl: '14%',
                        }}
                        w={{ base: '137px', md: '175px' }}
                        h={{ base: '129px', md: '164px' }}
                        borderRadius="50%"
                        objectFit="contain"
                    />
                </Box>
            </Container>

            <CongratulationsModal
                isOpen={isSubCreate}
                onClose={() => location.reload()}
            />
        </Box>
    );
}
