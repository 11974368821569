export const stuffData: { icon: string; desc: string }[] = [
    {
        icon: 'yoga-mat',
        desc: 'Yoga mat',
    },
    {
        icon: 'blanket',
        desc: 'Blanket',
    },
];
