import { useState, useEffect } from 'react';

import { METHODS, fetchDataCb } from '@/shared/lib';
import { SelectOption, TimeZoneData } from '@/shared/models';
import { getTimeZoneOptionList } from '@/shared/models/time-zone/getTimeZoneOptionList';
import { useBoolean } from '@chakra-ui/react';

const TIME_ZONES_PATH = '/api/timezones';
const TIME_ZONE_USER_PATH = '/api/users/me?expand=timezone';
const getPutTimeZonePath = (id: string) => `/api/users/timezone/${id}`;

export default function useTimeZone({ onChange }: { onChange: () => void }) {
    const [optionList, setOptionList] = useState<SelectOption[] | null>(null);
    const [defaultSelect, setDefaultSelect] = useState<SelectOption | null>(null);
    const [currentSelectOption, setCurrentSelectOption] = useState<SelectOption | null>(null);
    const [currentTimeZone, setCurrentTimeZone] = useState<TimeZoneData | null>(null);

    const [isSendLoading, setIsSendLoading] = useBoolean(false);
    const [isSendError, setIsSendError] = useBoolean(false);

    useEffect(() => {
        if (!optionList) {
            fetchDataCb<{ data: TimeZoneData[] }>(METHODS.GET, TIME_ZONES_PATH, (data) =>
                setOptionList(getTimeZoneOptionList(data.data)),
            );
        }
        if (!currentTimeZone) {
            fetchDataCb(METHODS.GET, TIME_ZONE_USER_PATH, (data) => setCurrentTimeZone(data.data.timezone));
        }
    }, []);

    useEffect(() => {
        if (optionList && currentTimeZone && !defaultSelect) {
            const findedDefaultSelect = optionList.find((i) => i.value === `${currentTimeZone.id}`);

            setDefaultSelect(findedDefaultSelect!);
        }
    }, [currentTimeZone, defaultSelect, optionList]);

    async function onChangeTimeZone() {
        try {
            setIsSendLoading.on();
            setIsSendError.off();

            await fetchDataCb(METHODS.POST, getPutTimeZonePath(currentSelectOption!.value), (data) =>
                setCurrentTimeZone(data.data),
            );

            onChange();
        } catch (error) {
            setIsSendError.on();
        } finally {
            setIsSendLoading.off();
        }
    }

    return {
        optionList,
        defaultSelect,
        setCurrentSelectOption,
        isSendError,
        isSendLoading,
        currentTimeZone,
        onChangeTimeZone,
    };
}
