import { extendTheme } from '@chakra-ui/react';

import { breakpoints, colors, fontSizes, sizes, space } from './constant';

import { textStyles } from './textStyles';
import { layerStyles } from './layerStyles';

import { avatarTheme } from './components/avatar';
import { inputTheme } from './components/input';
import { buttonTheme } from './components/button';
import { checkboxTheme } from './components/checkbox';
import { containerTheme } from './components/container';
import { stepperTheme } from './components/stepper';
import { accordionTheme } from './components/accordion';
import { modalTheme } from './components/modal';
import { progressTheme } from './components/progress';
import { textareaTheme } from './components/textarea';
import { headingTheme } from './components/heading';
import { dividerTheme } from './components/divider';
import { linkTheme } from './components/link';
import { menuTheme } from './components/menu';
import { tabsTheme } from './components/tabs';
import { cardTheme } from './components/card';
import { listTheme } from './components/list';
import { tagTheme } from './components/tag';
import { selectTheme } from './components/select';

const theme = extendTheme({
    fonts: {
        body: `'Rubik', sans-serif`,
    },
    breakpoints,
    fontSizes,
    sizes,
    space,
    colors,
    textStyles,
    layerStyles,
    components: {
        Button: buttonTheme,
        Container: containerTheme,
        Avatar: avatarTheme,
        Input: inputTheme,
        Select: selectTheme,
        Checkbox: checkboxTheme,
        Stepper: stepperTheme,
        Accordion: accordionTheme,
        Modal: modalTheme,
        Progress: progressTheme,
        Textarea: textareaTheme,
        Heading: headingTheme,
        Divider: dividerTheme,
        Link: linkTheme,
        Menu: menuTheme,
        Tabs: tabsTheme,
        Card: cardTheme,
        List: listTheme,
        Tag: tagTheme,
    },
});

export default theme;
