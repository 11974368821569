import { Accordion, AccordionProps, Box, BoxProps } from '@chakra-ui/react';

import TildaFaqItem from './components/TildaFaqItem';

export interface FaqAccordionProps {
    data: any;
    accordionVariant?: string;
    accordionProps?: AccordionProps;
    styles?: BoxProps;
}

export default function TildaFaqAccordion({
    data,
    accordionVariant = 'solid-tilda',
    accordionProps = { allowToggle: true },
    styles,
}: FaqAccordionProps) {
    if (!data) {
        return null;
    }

    return (
        <Box {...styles}>
            <Accordion
                variant={accordionVariant}
                {...accordionProps}
            >
                {data.map((item: any, index: number) => {
                    let lessons = '';

                    if (item.lessons) {
                        lessons = item.lessons.reduce((acc: string, lessonsItem: any) => {
                            acc += `<li>${lessonsItem.title}</li>`;
                            return acc;
                        }, '');

                        lessons = `<ul class="list-none">${lessons}</ul>`;
                    }

                    return (
                        <TildaFaqItem
                            key={index}
                            title={item.title}
                            description={lessons ? lessons : item.description}
                        />
                    );
                })}
            </Accordion>
        </Box>
    );
}
