import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

const darkVariant = definePartsStyle({
    list: {
        py: '12px',
        bg: 'rgba(51, 51, 51, 0.99)',
        border: 'none',
        fontSize: '16px',
    },
    item: {
        bg: 'gray,675',

        _hover: {
            color: 'green.400',
        },
        _focus: {
            color: 'green.400',
        },
    },
});

const playerDarkVariant = definePartsStyle({
    list: {
        maxW: { base: '118px', lg: '154px' },
        py: { base: '8px', md: '20px' },
        px: { base: '10px', md: '20px' },
        bg: 'gray.675',
        border: 'none',
        borderRadius: '8px',
        fontSize: { base: '12px', lg: '14px' },
    },
    item: {
        bg: 'gray,675',

        _hover: {
            color: 'green.400',
        },
        _focus: {
            color: 'green.400',
        },
    },
});

const menuTheme = defineMultiStyleConfig({
    variants: {
        dark: darkVariant,
        'player-dark': playerDarkVariant,
    },
});

export { menuTheme };
