import { useAtom } from 'jotai';
import { useState } from 'react';

import { unsubFlowOpenStateAtom } from './unsub-flow-store';

export default function useUnsubFlow() {
    const [currentStep, setCurrentStep] = useState(1);

    const goToNextStep = () => setCurrentStep((prev) => prev + 1);
    const goToPreviousStep = () => setCurrentStep((prev) => prev - 1);

    const [isUnsubFlowOpen, setIsUnsubFlowOpen] = useAtom(unsubFlowOpenStateAtom);

    const onClosePopup = () => {
        setIsUnsubFlowOpen(false);
        setCurrentStep(1);
    };

    const goToDashboard = () => {
        setIsUnsubFlowOpen(false);
        window.location.pathname = '/client';
    };

    return {
        currentStep,
        goToNextStep,
        goToPreviousStep,
        isUnsubFlowOpen,
        onClosePopup,
        goToDashboard,
        setCurrentStep,
    };
}
