import { Box, Button, HStack, Text } from '@chakra-ui/react';

import { CustomRadio, RadioVariants } from '@/shared/ui';
import { RiShoppingCart2Fill } from 'react-icons/ri';
import { CourseSubscriptionPriceData } from '@/features/checkout/model/domestika.types';

export default function OneClickCheckoutItemRegular({
    isChecked,
    coursePrices,
    onClick,
    onBtnClick,
}: {
    isChecked: boolean;
    coursePrices?: CourseSubscriptionPriceData;
    onClick: () => void;
    onBtnClick: () => void;
}) {
    return (
        <Box
            onClick={onClick}
            p={4}
            borderRadius="xl"
            border="1px solid"
            borderColor={isChecked ? 'green.400' : 'gray.350'}
            bgColor={isChecked ? 'green.50' : 'white'}
        >
            <Box mb={4}>
                <HStack
                    justify="space-between"
                    mb={3}
                >
                    <Text as="span">Regular price</Text>
                </HStack>

                <Box>
                    <CustomRadio
                        label={`$${coursePrices ? coursePrices?.price_base_course : '...'} USD`}
                        radioData={{ value: 'regular', label: 'regular', icon: 'regular' }}
                        variant={RadioVariants.RadioInline}
                        isChecked={isChecked}
                        styles={{
                            color: 'gray.675',
                            fontSize: '26px',
                            fontWeight: 500,
                            lineHeight: '1',
                        }}
                    />

                    <Text mb={0}>Pay the full price</Text>
                </Box>
            </Box>

            <Box
                maxH={isChecked ? '1000px' : '0'}
                opacity={isChecked ? 1 : 0}
                overflow="hidden"
                transition="all .2s ease-in-out"
            >
                <Button
                    onClick={onBtnClick}
                    display="flex"
                    gap={2}
                    w="100%"
                    mb={2}
                >
                    <Box
                        as={RiShoppingCart2Fill}
                        w="15px"
                        h="15px"
                    />
                    Buy
                </Button>
            </Box>
        </Box>
    );
}
