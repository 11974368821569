export const testimonials = [
    {
        authorName: 'Miss Gemma',
        imgSrc: '/img/promo/people/testimonials/Miss Gemma.png',
        text: "Absolutely wonderful, it's a beautiful experience being led through this meditation of somatic dance. So incredible for the body and mind. Thank you. 🙏",
    },
    {
        authorName: 'Melitta Minescu',
        imgSrc: '/img/promo/people/testimonials/Melitta Minescu.png',
        text: 'I loved the course! For me the first parts were too easy, I really started having fun in the last part. So I would really love a more advanced sequel. Thank you!',
    },
    {
        authorName: 'Tatiana Pfalzgraf',
        imgSrc: '/img/promo/people/testimonials/Tatiana Pfalzgraf.jpg',
        text: 'I enjoyed watching and learning the course. She invites to awake the feminine energy with powerful movements and patterns. Highly recommended!',
    },
    {
        authorName: 'Negoita Alexandra',
        imgSrc: '/img/promo/people/testimonials/Negoita Alexandra.jpg',
        text: 'This was a very special course - it would be so nice for everyone to find and build such a connection with a partner! Thank you very much for the course!',
    },
    {
        authorName: 'Ron Skene',
        imgSrc: '/img/promo/people/testimonials/Ron Skene.png',
        text: "I'm 75 and was looking for something I could do from home that would maintain my balance, flexibility and strength... but above all to be aware of my body and movement. The coordination exercises were particularly challenging for this aging brain.",
    },
    {
        authorName: 'Loveday',
        imgSrc: '/img/promo/people/testimonials/Loveday.jpg',
        text: "I have been nervous about the level of grip I need to use. I will try some of these amazing massage moves, as soon as I get my hands on my beautiful man. Can't wait.",
    },
    {
        authorName: 'LISA M ARCHIBALD',
        imgSrc: '/img/promo/people/testimonials/LISA M ARCHIBALD.jpg',
        text: "Thank you, Natalia) I got a lot out of your course and look forward to practicing with my friends and family. I've studied Thai massage before but enjoyed seeing you use different techniques in a shorter 40 min format. Well done)",
    },
    {
        authorName: 'Maria Fatima',
        imgSrc: '/img/promo/people/testimonials/Maria Fatima.jpg',
        text: "This course is great ! I've improved my knowledge of Thai massage with wonderful tips like the anatomical match . The teacher explains very clearly and with good images. I recommend it to all.",
    },
    {
        authorName: 'D T wijeyesekera',
        imgSrc: '/img/promo/people/testimonials/D T wijeyesekera.jpg',
        text: 'Beautiful setting and instructor. So peaceful. A lovely way to start learning qi gong. I wish there were more energizing exercises to start the day with.',
    },
    {
        authorName: 'Erica McCulloch',
        imgSrc: '/img/promo/people/testimonials/Erica McCulloch.jpg',
        text: "I'm so grateful for this lesson , I'm only just starting but hope you will do another course after this one to build on what we learn . This is a great addition to my weekly djembe drumming circle I go to . Thank you 🤩",
    },
    {
        authorName: 'ODED SAGIR',
        imgSrc: '/img/promo/people/testimonials/ODED SAGIR.png',
        text: 'The course was a meditation all by itself, bringing calmness and relaxation. My own conclusion is that I need to act on creating the setup for a more calm life 🙏',
    },
    {
        authorName: 'Shirine Gill',
        imgSrc: '/img/promo/people/testimonials/Shirine Gill.jpg',
        text: 'Excellent instruction- fantastic feeling after exercises',
    },
    {
        authorName: 'Nerise Howes',
        imgSrc: '/img/promo/people/testimonials/Nerise Howes.jpg',
        text: 'Namaste... I love listening to you....you are a natural teacher...thank you…',
    },
    {
        authorName: 'Miss Sarah brown',
        imgSrc: '/img/promo/people/testimonials/Miss Sarah brown.jpg',
        text: 'Wonderful experience. I will be repeating it a few times. Helping me learn to sing. thank you',
    },
    {
        authorName: 'Phyness George-Wilson',
        imgSrc: '/img/promo/people/testimonials/Phyness George-Wilson.jpeg',
        text: 'I am so grateful for this course. I was intrigued from the first time I came across the ad and I finally decided to listen to spirit and get into it. Excited to go within .',
    },
    {
        authorName: 'Kathleen Gorman',
        imgSrc: '/img/promo/people/testimonials/Kathleen Gorman.jpg',
        text: 'Excellent , concise course which clearly presents techniques through vocal exercises and visualization to enhance healing and wellness in the body. I loved this!',
    },
    {
        authorName: 'Susie C',
        imgSrc: '/img/promo/people/testimonials/Susie C.jpg',
        text: 'I love this! After the first exercise everything felt and literally looked lighter and brighter! Thank you for this practice!',
    },
    {
        authorName: 'Cathy Snelling Kerry',
        imgSrc: '/img/promo/people/testimonials/Cathy Snelling Kerry.jpg',
        text: 'Yes, Amazing course! Thank you Peruquois for this incredible gift. It’s such a beautiful way into working with the voice, and makes vocal practice so beautiful and such a joy! 🙏❤️',
    },
];
