import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Text,
    Box,
    HStack,
    ModalCloseButton,
    Link,
    Fade,
} from '@chakra-ui/react';

import { HELLO_EMAIL } from '@/common/constants/brand';
import { TildaCourseBuyFormDark } from '@/entities/landing/course-buy-block/course-buy-form';

interface CartLandingModalProps {
    courseList: {
        title: string;
        price: number;
        id: number;
    }[];
    isOpen: boolean;
    onClose: () => void;
}

export default function CartTildaLandingModalDark({ courseList, isOpen, onClose }: CartLandingModalProps) {
    const totalPrice = courseList.reduce((acc, item) => acc + Number(item.price), 0);

    return (
        <Modal
            scrollBehavior="outside"
            isOpen={isOpen}
            onClose={onClose}
            size={'xl'}
            variant="glassmorphism"
        >
            <ModalOverlay />

            <ModalContent>
                <ModalBody>
                    <ModalCloseButton />

                    <Text
                        mb={7.5}
                        fontSize={{ base: '24px', md: '26px' }}
                        lineHeight={1.4}
                        fontWeight={600}
                    >
                        Save your seat right now with a special price
                    </Text>

                    <Box
                        as="hr"
                        borderWidth="1px !important"
                        borderStyle="solid !important"
                        borderColor="#454545 !important"
                        opacity={1}
                    />

                    {courseList &&
                        courseList.map((item, index) => {
                            return (
                                <Fade
                                    key={index}
                                    in={true}
                                >
                                    <HStack
                                        my={8}
                                        gap={6}
                                        justifyContent="space-between"
                                    >
                                        <Text
                                            as="span"
                                            fontWeight={600}
                                        >
                                            {item.title}
                                        </Text>
                                        <Text
                                            as="span"
                                            flexShrink={0}
                                            ml="auto"
                                        >
                                            ${item.price}
                                        </Text>
                                    </HStack>
                                </Fade>
                            );
                        })}

                    <Box
                        as="hr"
                        borderWidth="1px !important"
                        borderStyle="solid !important"
                        borderColor="#454545 !important"
                        opacity={1}
                    />

                    <Box
                        mt={8}
                        mb={4}
                        fontWeight={600}
                        textAlign="right"
                    >
                        <Text as="span">Total:</Text>{' '}
                        <Text as="span">{courseList && <>$ {Number(totalPrice).toFixed(2)}</>}</Text>
                    </Box>

                    <TildaCourseBuyFormDark coursesId={[]} />

                    <Box
                        mt={10}
                        fontSize="14px"
                    >
                        <Text>
                            <Text
                                as="span"
                                fontWeight={500}
                            >
                                Start practicing immediately after filling out the form and paying.
                            </Text>{' '}
                            Hurry up to buy a course at a low price while the special offer is still valid.
                        </Text>

                        <Text mb={0}>
                            <Text
                                as="span"
                                fontWeight={500}
                            >
                                14 days money back guarantee:
                            </Text>{' '}
                            if the course does not meet your expectations within the first fourteen days after purchase,
                            we will refund the full price no questions asked. Simply send an email to support at{' '}
                            <Link
                                href={`mailto:${HELLO_EMAIL}`}
                                color="blue.500"
                            >
                                {HELLO_EMAIL}
                            </Link>
                        </Text>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
