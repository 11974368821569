import { ALL_COURSES_LIST_BY_ID } from '@/entities/courses/lists/ids';
import { CourseCategories } from './course-categories-list';
import { QuizList } from '../types/quiz-list-types';
import { Gender } from './radio-list';

export const FIRST_COURSE = {
    label: 'Vocal yoga',
    value: ALL_COURSES_LIST_BY_ID.VocalYogaPeruquois,
};

export const VOICE_MASTERY_QUIZ_LIST_FEMALE: QuizList[] = [
    {
        label: 'Merge breath with voice',
        value: ALL_COURSES_LIST_BY_ID.VocalYogaPeruquois,
    },
    {
        label: 'Enhancing vocal skills',
        value: ALL_COURSES_LIST_BY_ID.OpenVoiceLearnSingBeautifully,
    },
    {
        label: 'Heal through voice',
        value: ALL_COURSES_LIST_BY_ID.VoiceAlchemyHealingVoice,
    },
    {
        label: 'Express emotions',
        value: ALL_COURSES_LIST_BY_ID.MedicineSongsGeetaFeelHeal,
    },
    {
        label: 'Accessing the power within',
        value: ALL_COURSES_LIST_BY_ID.MagicVoice,
    },
    {
        label: 'Unlock the natural potential of your voice',
        value: ALL_COURSES_LIST_BY_ID.SingBetterIn21Days,
    },
    {
        label: 'Discover the shamanic power of your voice',
        value: ALL_COURSES_LIST_BY_ID.LuminaMultidimensionalSoundPractice,
    },
    {
        label: 'Open new opportunities for your voice',
        value: ALL_COURSES_LIST_BY_ID.SingWithAgaBestSingingCourse,
    },
    {
        label: 'Open your creative channel of expression through self sound healing',
        value: ALL_COURSES_LIST_BY_ID.NadaYogaSoundToHealMindBodySoul,
    },
];

export const VOICE_MASTERY_QUIZ_LIST_MALE: QuizList[] = [
    {
        label: 'Unlock the natural potential of your voice',
        value: ALL_COURSES_LIST_BY_ID.SingBetterIn21Days,
    },
    {
        label: 'Discover the shamanic power of your voice',
        value: ALL_COURSES_LIST_BY_ID.LuminaMultidimensionalSoundPractice,
    },
    {
        label: 'Open new opportunities for your voice',
        value: ALL_COURSES_LIST_BY_ID.SingWithAgaBestSingingCourse,
    },
    {
        label: 'Open your creative channel of expression through self sound healing',
        value: ALL_COURSES_LIST_BY_ID.NadaYogaSoundToHealMindBodySoul,
    },
    {
        label: 'Express emotions',
        value: ALL_COURSES_LIST_BY_ID.MedicineSongsGeetaFeelHeal,
    },
];

export const BODY_DEVELOPMENT_QUIZ_LIST_FEMALE: QuizList[] = [
    {
        label: 'Brain & Body Coordination',
        value: ALL_COURSES_LIST_BY_ID.OuroborosBrainBody,
    },
    {
        label: 'Femininity Through Movement',
        value: ALL_COURSES_LIST_BY_ID.SensualGoddessWorkout,
    },
    {
        label: 'Sexuality & Self-Love',
        value: ALL_COURSES_LIST_BY_ID.BreathingOfSexuality,
    },
    {
        label: 'Mobility & Spine Health',
        value: ALL_COURSES_LIST_BY_ID.HealthySpine16Exercises,
    },
    {
        label: 'Increase your natural energy sources',
        value: ALL_COURSES_LIST_BY_ID.ReclaimVitalityEnergySavingQigong,
    },
    {
        label: 'Achieve a more balanced and restful state of being',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Balance your life and energy on all the levels',
        value: ALL_COURSES_LIST_BY_ID.BodyAndSoulConnectionChakras,
    },
    {
        label: 'Ignite your energy, transform your mindset, sculpt your body',
        value: ALL_COURSES_LIST_BY_ID.ExtremeTransformationChallenge,
    },
    {
        label: 'Charge yourself with positive vibes',
        value: ALL_COURSES_LIST_BY_ID.DanceMovementTherapyWithAnnaTrish,
    },
    {
        label: 'Quick route to a more supple, energetic you',
        value: ALL_COURSES_LIST_BY_ID.WarmupMobilitySpineHipShoulder,
    },
];

export const BODY_DEVELOPMENT_QUIZ_LIST_MALE: QuizList[] = [
    {
        label: 'Brain & Body Coordination',
        value: ALL_COURSES_LIST_BY_ID.OuroborosBrainBody,
    },
    {
        label: 'Mobility & Spine Health',
        value: ALL_COURSES_LIST_BY_ID.HealthySpine16Exercises,
    },
    {
        label: 'Achieve a more balanced and restful state of being',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Increase your natural energy sources',
        value: ALL_COURSES_LIST_BY_ID.ReclaimVitalityEnergySavingQigong,
    },
    {
        label: 'Ignite your energy, transform your mindset, sculpt your body',
        value: ALL_COURSES_LIST_BY_ID.ExtremeTransformationChallenge,
    },
    {
        label: 'Quick route to a more supple, energetic you',
        value: ALL_COURSES_LIST_BY_ID.WarmupMobilitySpineHipShoulder,
    },
];

export const RELATIONSHIP_QUIZ_LIST_FEMALE: QuizList[] = [
    {
        label: 'Self-Awareness & Intimacy',
        value: ALL_COURSES_LIST_BY_ID.BreathingOfSexuality,
    },
    {
        label: 'Intimacy through Massage',
        value: ALL_COURSES_LIST_BY_ID.MassageAsDanceLove,
    },
    {
        label: 'Tantric Connections',
        value: ALL_COURSES_LIST_BY_ID.BreathingLoveTantricMagic,
    },
    {
        label: 'Dance & Self-Expression',
        value: ALL_COURSES_LIST_BY_ID.TwerkingBasicsLoveBody,
    },
    {
        label: 'Romantic Growth',
        value: ALL_COURSES_LIST_BY_ID.RisingLoveHealingJourney,
    },
    {
        label: 'Deepen the connection in your couple',
        value: ALL_COURSES_LIST_BY_ID.DiyUnforgettableNights,
    },
    {
        label: 'Revive forgotten feelings and interest',
        value: ALL_COURSES_LIST_BY_ID.WomensLingamMassageWorkshop,
    },
    {
        label: 'Elevate self-esteem and enhance intimacy with your partner',
        value: ALL_COURSES_LIST_BY_ID.PracticalOnlineCourseSacredIntimacy,
    },
    {
        label: 'Learn new things about touching a woman’s breasts',
        value: ALL_COURSES_LIST_BY_ID.BreastMassageAwakenSensitivity,
    },
    {
        label: 'Be heard by the Universe!',
        value: ALL_COURSES_LIST_BY_ID.SacredTantricManifestationRitual,
    },
];

export const RELATIONSHIP_QUIZ_LIST_MALE: QuizList[] = [
    {
        label: 'Romantic Growth',
        value: ALL_COURSES_LIST_BY_ID.RisingLoveHealingJourney,
    },
    {
        label: 'Deepen the connection in your couple',
        value: ALL_COURSES_LIST_BY_ID.DiyUnforgettableNights,
    },
    {
        label: 'Learn new things about touching a woman’s breasts',
        value: ALL_COURSES_LIST_BY_ID.BreastMassageAwakenSensitivity,
    },
    {
        label: 'Intimacy through Massage',
        value: ALL_COURSES_LIST_BY_ID.MassageAsDanceLove,
    },
];

export const DAILY_ENERGY_QUIZ_LIST_FEMALE: QuizList[] = [
    {
        label: 'Sexual Energy for Vitality',
        value: ALL_COURSES_LIST_BY_ID.SexualAlchemyVitalityPractices,
    },
    {
        label: 'Better Sleep for More Energy',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Rejuvenating Energy Centers',
        value: ALL_COURSES_LIST_BY_ID.HealingChakraChargeEnergyCenters,
    },
    {
        label: 'Kundalini Energy',
        value: ALL_COURSES_LIST_BY_ID.SensualAbundantUnlockKundalini,
    },
    {
        label: 'Dynamic Dance',
        value: ALL_COURSES_LIST_BY_ID.AfroJazzModernDanceSoulBody,
    },
    {
        label: 'Sharpen your mind and support cognitive growth',
        value: ALL_COURSES_LIST_BY_ID.NeuroDanceGrooveToGenius,
    },
    {
        label: 'Awaken your inner child',
        value: ALL_COURSES_LIST_BY_ID.RhythmicMoodFeelBetterInstant,
    },
    {
        label: 'Develop your creativity and unconventional thinking',
        value: ALL_COURSES_LIST_BY_ID.BeatYourBrainRhythmicNeurogymnastics,
    },
    {
        label: 'Improve focus, memory, and information assimilation',
        value: ALL_COURSES_LIST_BY_ID.NeurogeniusElevateCognitivePerformance,
    },
    {
        label: 'Foster mental clarity, emotional tranquility, and physical relaxation',
        value: ALL_COURSES_LIST_BY_ID.QigongForStressAndAnxiety,
    },
];

export const DAILY_ENERGY_QUIZ_LIST_MALE: QuizList[] = [
    {
        label: 'Foster mental clarity, emotional tranquility, and physical relaxation',
        value: ALL_COURSES_LIST_BY_ID.QigongForStressAndAnxiety,
    },
    {
        label: 'Develop your creativity and unconventional thinking',
        value: ALL_COURSES_LIST_BY_ID.BeatYourBrainRhythmicNeurogymnastics,
    },
    {
        label: 'Better Sleep for More Energy',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Awaken your inner child',
        value: ALL_COURSES_LIST_BY_ID.RhythmicMoodFeelBetterInstant,
    },
    {
        label: 'Dynamic Dance',
        value: ALL_COURSES_LIST_BY_ID.AfroJazzModernDanceSoulBody,
    },
    {
        label: 'Improve focus, memory, and information assimilation',
        value: ALL_COURSES_LIST_BY_ID.NeurogeniusElevateCognitivePerformance,
    },
    {
        label: 'Sharpen your mind and support cognitive growth',
        value: ALL_COURSES_LIST_BY_ID.NeuroDanceGrooveToGenius,
    },
];

export const COGNITIVE_SKILLS_QUIZ_LIST_FEMALE: QuizList[] = [
    {
        label: 'Brain & Body Coordination',
        value: ALL_COURSES_LIST_BY_ID.OuroborosBrainBody,
    },
    {
        label: 'Reduce stress and elevate your mood with rhythmic games',
        value: ALL_COURSES_LIST_BY_ID.HappyRhythmsPlayYourWayToStressFreeLiving,
    },
    {
        label: 'Sharpen your mind and support cognitive growth',
        value: ALL_COURSES_LIST_BY_ID.NeuroDanceGrooveToGenius,
    },
    {
        label: 'Improve your memory, concentration & problem-solving',
        value: ALL_COURSES_LIST_BY_ID.BrainBiohackingHowToBecomeSmarterAndImproveYourMemory,
    },
    {
        label: 'Achieve a more balanced and restful state of being',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Awaken your inner child',
        value: ALL_COURSES_LIST_BY_ID.RhythmicMoodFeelBetterInstant,
    },
    {
        label: 'Develop your creativity and unconventional thinking',
        value: ALL_COURSES_LIST_BY_ID.BeatYourBrainRhythmicNeurogymnastics,
    },
    {
        label: 'Improve focus, memory, and information assimilation',
        value: ALL_COURSES_LIST_BY_ID.NeurogeniusElevateCognitivePerformance,
    },
    {
        label: 'Foster mental clarity, emotional tranquility, and physical relaxation',
        value: ALL_COURSES_LIST_BY_ID.QigongForStressAndAnxiety,
    },
    {
        label: 'Сonnect Powerfully And Persuade Effectively',
        value: ALL_COURSES_LIST_BY_ID.TheArtOfSpeakingSonnectPowerfullyAndPersuadeEffectively,
    },
    {
        label: 'Mindfulness & Stress',
        value: ALL_COURSES_LIST_BY_ID.BreatheBeyondStressMindfulness,
    },
];

export const COGNITIVE_SKILLS_QUIZ_LIST_MALE: QuizList[] = [
    {
        label: 'Brain & Body Coordination',
        value: ALL_COURSES_LIST_BY_ID.OuroborosBrainBody,
    },
    {
        label: 'Reduce stress and elevate your mood with rhythmic games',
        value: ALL_COURSES_LIST_BY_ID.HappyRhythmsPlayYourWayToStressFreeLiving,
    },
    {
        label: 'Sharpen your mind and support cognitive growth',
        value: ALL_COURSES_LIST_BY_ID.NeuroDanceGrooveToGenius,
    },
    {
        label: 'Foster mental clarity, emotional tranquility, and physical relaxation',
        value: ALL_COURSES_LIST_BY_ID.QigongForStressAndAnxiety,
    },
    {
        label: 'Develop your creativity and unconventional thinking',
        value: ALL_COURSES_LIST_BY_ID.BeatYourBrainRhythmicNeurogymnastics,
    },
    {
        label: 'Improve your memory, concentration & problem-solving',
        value: ALL_COURSES_LIST_BY_ID.BrainBiohackingHowToBecomeSmarterAndImproveYourMemory,
    },
    {
        label: 'Achieve a more balanced and restful state of being',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Awaken your inner child',
        value: ALL_COURSES_LIST_BY_ID.RhythmicMoodFeelBetterInstant,
    },
    {
        label: 'Improve focus, memory, and information assimilation',
        value: ALL_COURSES_LIST_BY_ID.NeurogeniusElevateCognitivePerformance,
    },
    {
        label: 'Сonnect Powerfully And Persuade Effectively',
        value: ALL_COURSES_LIST_BY_ID.TheArtOfSpeakingSonnectPowerfullyAndPersuadeEffectively,
    },
    {
        label: 'Mindfulness & Stress',
        value: ALL_COURSES_LIST_BY_ID.BreatheBeyondStressMindfulness,
    },
];

export const OTHER_QUIZ_LIST_FEMALE: QuizList[] = [
    {
        label: 'Brain & Body Coordination',
        value: ALL_COURSES_LIST_BY_ID.OuroborosBrainBody,
    },
    {
        label: 'Therapeutic Massage',
        value: ALL_COURSES_LIST_BY_ID.FeelBodyThaiMassageTechniques,
    },
    {
        label: 'Mindfulness & Stress',
        value: ALL_COURSES_LIST_BY_ID.BreatheBeyondStressMindfulness,
    },
    {
        label: 'Vocal Power',
        value: ALL_COURSES_LIST_BY_ID.VocalYogaPeruquois,
    },
    {
        label: 'Intimacy through Massage',
        value: ALL_COURSES_LIST_BY_ID.MassageAsDanceLove,
    },
    {
        label: 'Open your true feminine energy, using the sacred practices of four elements',
        value: ALL_COURSES_LIST_BY_ID.FeminineAlchemySacredPractices4Elements,
    },
    {
        label: 'Harness the potent energies of the moon',
        value: ALL_COURSES_LIST_BY_ID.MoonManifestingLunarEnergiesEft,
    },
    {
        label: 'Open true wild and divine nature',
        value: ALL_COURSES_LIST_BY_ID.DivineYouChakra,
    },
    {
        label: 'Ignite your energy, transform your mindset, sculpt your body',
        value: ALL_COURSES_LIST_BY_ID.ExtremeTransformationChallenge,
    },
];

export const OTHER_QUIZ_LIST_MALE: QuizList[] = [
    {
        label: 'Brain & Body Coordination',
        value: ALL_COURSES_LIST_BY_ID.OuroborosBrainBody,
    },
    {
        label: 'Therapeutic Massage',
        value: ALL_COURSES_LIST_BY_ID.FeelBodyThaiMassageTechniques,
    },
    {
        label: 'Vocal Power',
        value: ALL_COURSES_LIST_BY_ID.VocalYogaPeruquois,
    },
    {
        label: 'Ignite your energy, transform your mindset, sculpt your body',
        value: ALL_COURSES_LIST_BY_ID.ExtremeTransformationChallenge,
    },
];

export const ALL_COURSES_LIST_GENDERED = [
    {
        categoryName: CourseCategories.VoiceMastery,
        coursesList: {
            [Gender.Female]: VOICE_MASTERY_QUIZ_LIST_FEMALE,
            [Gender.Male]: VOICE_MASTERY_QUIZ_LIST_MALE,
            [Gender.Other]: VOICE_MASTERY_QUIZ_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.LoveDevelopBody,
        coursesList: {
            [Gender.Female]: BODY_DEVELOPMENT_QUIZ_LIST_FEMALE,
            [Gender.Male]: BODY_DEVELOPMENT_QUIZ_LIST_MALE,
            [Gender.Other]: BODY_DEVELOPMENT_QUIZ_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.StrengthenRelationships,
        coursesList: {
            [Gender.Female]: RELATIONSHIP_QUIZ_LIST_FEMALE,
            [Gender.Male]: RELATIONSHIP_QUIZ_LIST_MALE,
            [Gender.Other]: RELATIONSHIP_QUIZ_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.BoostDailyEnergy,
        coursesList: {
            [Gender.Female]: DAILY_ENERGY_QUIZ_LIST_FEMALE,
            [Gender.Male]: DAILY_ENERGY_QUIZ_LIST_MALE,
            [Gender.Other]: DAILY_ENERGY_QUIZ_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.CognitiveSkills,
        coursesList: {
            [Gender.Female]: COGNITIVE_SKILLS_QUIZ_LIST_FEMALE,
            [Gender.Male]: COGNITIVE_SKILLS_QUIZ_LIST_MALE,
            [Gender.Other]: COGNITIVE_SKILLS_QUIZ_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.SomethingElse,
        coursesList: {
            [Gender.Female]: OTHER_QUIZ_LIST_FEMALE,
            [Gender.Male]: OTHER_QUIZ_LIST_MALE,
            [Gender.Other]: OTHER_QUIZ_LIST_FEMALE,
        },
    },
];
