import { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';

import { userAtom } from '@/shared/store';
import getCountdownReminingTime from './getCountdownReminingTime';

export default function useCountdownReminigTime(coundownTime: number) {
    const user = useAtomValue(userAtom);
    const [reminingTime, setReminingTime] = useState(0);

    useEffect(() => {
        if (user && !user.quiz_info.date_timer_start) {
            const reminingTime = getCountdownReminingTime(user.quiz_info.date_timer_start, coundownTime);
            setReminingTime(reminingTime);
        }
    }, [user]);

    return { reminingTime };
}
