'use client';

import { useEffect } from 'react';
import { Box, Container, Heading, Text } from '@chakra-ui/react';

import { initializeFacebookPixels } from '@/shared/lib';
import { useLandingProvider } from '../model/LandingProvider';
import TopBanner from './components/TopBanner';
import Cart from './components/Cart';
import WhyChoose from './components/WhyChoose';
import CourseForYouIf from './components/CourseForYouIf';
import WhatWillYouLearn from './components/WhatWillYouLearn';
import WhyChoose2 from './components/WhyChoose2';
import { TILDA_FAQ_DATA, TildaFaqAccordion } from '@/entities/support/faq-accordion';
import CourseAuthor from './components/CourseAuthor';
import AfterPaymentSteps from './components/AfterPaymentSteps';
import { ReviewSliderBlack } from '@/entities/lists/sliders/reviews';

const H4_STYLE = {
    mb: 5,
    fontSize: { base: '18px', lg: '32px' },
    fontWeight: 700,
    color: '#ffffff',
};

export default function ExpensiveLandingPageComponent() {
    const { courseData, courseInfoData, courseReviews } = useLandingProvider();

    useEffect(() => {
        if (courseData) {
            initializeFacebookPixels(courseData.fb_pixels!);
        }
    }, [courseData]);

    if (!courseData) {
        return null;
    }

    return (
        <Box
            bgColor="#111111"
            overflow="hidden"
            minH="100vh"
        >
            <Container>
                <TopBanner styles={{ mb: { base: '60px', lg: '80px' } }} />
            </Container>

            <Cart
                styles={{
                    maxW: '1640px',
                    mx: 'auto',
                    mb: { base: '60px', lg: '100px' },
                }}
            />

            {courseData.info && courseData.info.for && (
                <Container>
                    <WhyChoose
                        styles={{
                            mb: { base: '60px', lg: '160px' },
                            sx: {
                                h4: H4_STYLE,
                            },
                        }}
                    />

                    {courseData.info.for.list_title && (
                        <Box mb={{ base: '60px', lg: '160px' }}>
                            <Heading
                                as="h4"
                                {...H4_STYLE}
                                mb={{ base: 5, lg: 10 }}
                            >
                                {courseData.info.for.list_title}
                            </Heading>

                            <CourseForYouIf />
                        </Box>
                    )}
                </Container>
            )}

            <WhatWillYouLearn
                styles={{
                    mb: { base: '60px', lg: '160px' },
                    maxW: '1640px',
                    mx: 'auto',
                    sx: {
                        h4: H4_STYLE,
                    },
                }}
            />

            <Container>
                {courseData.info && courseData.info.descr && (
                    <WhyChoose2
                        styles={{
                            mb: { base: '60px', lg: '160px' },
                            sx: {
                                h4: H4_STYLE,
                            },
                        }}
                    />
                )}

                <Box mb={{ base: '60px', lg: '160px' }}>
                    <Heading
                        as="h4"
                        {...H4_STYLE}
                        mb={{ base: 2, lg: 8 }}
                    >
                        Academic plan
                    </Heading>

                    <Text
                        mb={{ lg: 8 }}
                        color="white"
                    >
                        {courseInfoData.videoCount} video lessons
                    </Text>

                    <TildaFaqAccordion
                        data={courseData.sections}
                        accordionVariant="skeuomorph"
                        accordionProps={{
                            allowMultiple: true,
                        }}
                        styles={{
                            mb: { base: '60px', lg: '160px' },
                        }}
                    />
                </Box>

                {courseData.authors && (
                    <CourseAuthor
                        authorList={courseData.authors}
                        styles={{
                            mb: { base: '60px', lg: '160px' },
                            sx: {
                                h4: {
                                    ...H4_STYLE,
                                    mb: { base: 5, lg: 10 },
                                },
                            },
                        }}
                    />
                )}
            </Container>

            <AfterPaymentSteps
                styles={{
                    mb: { base: '60px', lg: '160px' },
                    maxW: '1640px',
                    mx: 'auto',
                    sx: {
                        h4: {
                            ...H4_STYLE,
                            mb: { base: 5, lg: 10 },
                        },
                    },
                }}
            />

            <Container>
                {courseReviews && courseReviews?.length > 0 && (
                    <Box mb={{ base: '60px', lg: '160px' }}>
                        <Heading
                            as="h4"
                            {...H4_STYLE}
                        >
                            Testimonials
                        </Heading>

                        <ReviewSliderBlack reviewList={courseReviews} />
                    </Box>
                )}

                <Box mb={{ base: '60px', lg: '160px' }}>
                    <Heading
                        as="h4"
                        {...H4_STYLE}
                        mb={{ base: 5, lg: 10 }}
                    >
                        FAQ
                    </Heading>

                    <TildaFaqAccordion
                        data={TILDA_FAQ_DATA}
                        accordionVariant="skeuomorph"
                        accordionProps={{
                            allowMultiple: true,
                        }}
                        styles={{
                            mb: { base: '60px', lg: '160px' },
                        }}
                    />
                </Box>
            </Container>
        </Box>
    );
}
