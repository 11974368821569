import { useEffect, useState } from 'react';
import { Button, Text, useToast, VStack } from '@chakra-ui/react';

import { useOrderSub, ActionSubType } from '@/features/subscription/create-sub';
import { CUSTOM_CHECKBOX_ID, useLoadingTextWithFetch } from '@/shared/ui';
import { fetchData, METHODS } from '@/shared/lib';
import { URLS } from '@/common/constants/global';

export default function ThanksCheckboxedBtn() {
    const toast = useToast();
    const [checkboxChecked, setCheckboxChecked] = useState(false);

    const { orderSub } = useOrderSub();
    const { loadingText, textedFetch, sendError, isSubmitted } = useLoadingTextWithFetch(async () => {
        if (checkboxChecked) {
            await orderSub(ActionSubType.CustomThankuAles);

            toast({
                duration: 5000,
                status: 'success',
                title: 'You have successfully subscribed to the Rejuvenation Club!',
                position: 'top-right',
            });

            setTimeout(() => {
                window.location.href = '/payment-success';
            }, 3000);
        } else {
            const skipOrderSubResponse = await fetchData(URLS.SKIP_ORDER_SUB, {
                method: METHODS.POST,
                headers: {
                    'X-CSRF-TOKEN': (document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement)!.content,
                },
            });

            if (skipOrderSubResponse.redirect_url !== '') {
                window.location.href = skipOrderSubResponse.redirect_url;
            } else {
                window.location.reload();
            }
        }
    });

    const handleCheckboxChange = (event: Event) => {
        const target = event.target as HTMLInputElement;
        setCheckboxChecked(target.checked);
    };

    useEffect(() => {
        const checkboxEl = document.getElementById(CUSTOM_CHECKBOX_ID) as HTMLInputElement;

        if (checkboxEl) {
            setCheckboxChecked(checkboxEl.checked);
            checkboxEl.addEventListener('change', handleCheckboxChange);

            return () => {
                checkboxEl.removeEventListener('change', handleCheckboxChange);
            };
        }
    }, []);

    return (
        <VStack>
            <Button
                onClick={textedFetch}
                isLoading={isSubmitted}
                loadingText={loadingText}
                w="100%"
                fontSize="13px"
            >
                CONTINUE TO THE COURSE
            </Button>
            {sendError && (
                <Text
                    mb={0}
                    color="red.500"
                >
                    An error occurred while subscribing
                </Text>
            )}
        </VStack>
    );
}
