import { Flex, Text, Box, BoxProps } from '@chakra-ui/react';

import { Picture, PictureType } from '@/shared/ui';
import { ReactNode } from 'react';

interface PictureQuizSlideProps {
    slideData: {
        title: string;
        imgSrc: string;
        imgDesc?: string;
    };
    NextBtnEl: () => ReactNode;
    PrevBtnEl?: () => ReactNode;
    BottomEl?: () => ReactNode;
    styles?: BoxProps;
}

export default function PictureQuizSlide({ slideData, NextBtnEl, PrevBtnEl, BottomEl, styles }: PictureQuizSlideProps) {
    return (
        <Box {...styles}>
            <Text
                textStyle="lg4"
                textAlign="center"
            >
                {slideData.title}
            </Text>

            <Picture
                src={slideData.imgSrc}
                alt="promo-img"
                type={PictureType.Simple}
                pictureStyle={{
                    overflow: 'hidden',
                    minH: {
                        base: '100px',
                    },
                }}
                imgStyle={{
                    w: '100%',
                    borderRadius: 'xl',
                }}
            />

            {slideData.imgDesc && (
                <Text
                    textAlign="center"
                    mt={4}
                >
                    {slideData.imgDesc}
                </Text>
            )}

            {BottomEl ? (
                <BottomEl />
            ) : (
                <Flex
                    mt={7.5}
                    gap={2.5}
                    justify="center"
                >
                    {PrevBtnEl && <PrevBtnEl />}
                    {NextBtnEl && <NextBtnEl />}
                </Flex>
            )}
        </Box>
    );
}
