import { createRoot } from 'react-dom/client';

import ProvidersCsr from '../../providers/providers-csr';

import { ThanksCheckboxAles } from '@/entities/thanks/checkbox';
import { ThanksCheckboxedBtn } from '@/entities/btns/thanks/checkboxed';

// Чекбокс для страницы спасибо
const thanksCheckboxAlesNode = document.getElementById('thanksCheckboxAles');
if (thanksCheckboxAlesNode) {
    const root = createRoot(thanksCheckboxAlesNode);

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <ThanksCheckboxAles />
        </ProvidersCsr>,
    );
}

// Кнопка для страницы спасибо
const btnCheckboxedAlesNode = document.querySelectorAll('.btnCheckboxedAles');
if (btnCheckboxedAlesNode.length > 0) {
    btnCheckboxedAlesNode.forEach((node) => {
        const root = createRoot(node);

        root.render(
            <ProvidersCsr isAlwaysRender={true}>
                <ThanksCheckboxedBtn />
            </ProvidersCsr>,
        );
    });
}
