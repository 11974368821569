import { Text } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';

import {
    BottomTextMap,
    PromoAlertTextMap,
    QuizGroupType,
    QuizPageHeaderProgress,
    quizSettingAtom,
    QuizType,
    useCourseQuiz,
} from '@/entities/quizes/quiz';
import { PromoAlert } from '@/entities/promo/alert';
import { userDataFromBladeAtom } from '@/entities/user/user-data-from-blade/user-data-from-blade-store';

export default function OnboardingQuiz({ quizList, prevLink }: any) {
    const { currentQuiz, activeStep, onNextBtnClick, onPrevBtnClick, currentQuizStepNumber, quizLastStepNumber } =
        useCourseQuiz(quizList, prevLink);
    const quizSetting = useAtomValue(quizSettingAtom);
    const userDataFromBlade = useAtomValue(userDataFromBladeAtom); // store

    if (!quizSetting) {
        return null;
    }

    const isShowAlert = !quizSetting.options?.redirect;
    const isOnboardingCheckEmail = quizSetting.type === QuizType.DefaultCheckEmail;

    const isLastStep = currentQuizStepNumber === quizLastStepNumber;
    const isNextStepLast = quizLastStepNumber === currentQuizStepNumber + 1;

    return (
        <>
            <QuizPageHeaderProgress
                activeStep={activeStep}
                styles={{
                    maxW: 'container.sm3',
                }}
            />

            {(isShowAlert || isOnboardingCheckEmail) && PromoAlertTextMap[quizSetting.type] && (
                <PromoAlert
                    text={PromoAlertTextMap[quizSetting.type]}
                    styles={{
                        maxW: isLastStep ? '' : 'container.sm3',
                        mx: { base: -5, md: 'auto' },
                    }}
                />
            )}

            {currentQuiz.Component({
                currentQuizStepNumber,
                currentQuiz,
                onNextBtnClick,
                onPrevBtnClick,
                isNextStepLast,
                userDataFromBlade,
            })}

            {currentQuiz && currentQuiz.type !== QuizGroupType.Picture && !isNextStepLast && (
                <Text
                    mt={7.5}
                    textAlign="center"
                    color="gray.500"
                >
                    {BottomTextMap[quizSetting.type]}
                </Text>
            )}
        </>
    );
}
