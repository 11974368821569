import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Portal,
    Text,
    useDisclosure,
    useMediaQuery,
} from '@chakra-ui/react';

import { RiSettings3Fill } from 'react-icons/ri';

import { SelectOption } from '@/shared/models';
import { CustomSelect } from '@/shared/ui';
import useTimeZone from './model/useTimeZone';

export default function TimeZone({ onChangeZone }: { onChangeZone: () => void }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        optionList,
        defaultSelect,
        setCurrentSelectOption,
        isSendError,
        isSendLoading,
        currentTimeZone,
        onChangeTimeZone,
    } = useTimeZone({
        onChange: () => {
            onChangeZone();
            onClose();
        },
    });

    const [isLargerThanMd] = useMediaQuery('(min-width: 768px)', {
        ssr: true,
        fallback: false,
    });

    const [isLessThan375] = useMediaQuery('(max-width: 375px)', {
        ssr: true,
        fallback: false,
    });

    return (
        <Flex
            align="center"
            gap={2.5}
            justify="space-between"
            w="fit-content"
            fontSize={{ base: 'xs', md: 'md' }}
            id="events-timezone-tooltip-anchor"
        >
            <Flex
                display={isLessThan375 ? 'none' : 'flex'}
                gap={{ base: 0, md: 2.5 }}
                flexWrap={{
                    base: 'wrap',
                    md: 'nowrap',
                }}
            >
                <Text
                    mb={0}
                    h="1em"
                    w={{ base: '100%', md: 'auto' }}
                >
                    Time zone:
                </Text>

                <Text
                    as="span"
                    textStyle="truncate-line"
                >
                    {currentTimeZone?.name ?? ''}
                </Text>
            </Flex>

            <Box
                as={RiSettings3Fill}
                display={optionList ? 'block' : 'none'}
                flexShrink={0}
                w={6}
                h={6}
                cursor="pointer"
                _hover={{ opacity: 0.7 }}
                onClick={onOpen}
            />

            <Portal>
                <Modal
                    variant="primary"
                    isOpen={isOpen}
                    onClose={onClose}
                    isCentered={isLargerThanMd}
                >
                    <ModalOverlay />

                    <ModalContent>
                        <ModalBody>
                            <Text
                                textStyle="md3"
                                mb={5}
                            >
                                Select your time zone:
                            </Text>

                            <CustomSelect
                                instanceId="time-zone"
                                options={optionList ?? []}
                                defaultValue={defaultSelect}
                                onChange={(e) => setCurrentSelectOption(e as SelectOption)}
                            />

                            {isSendError && (
                                <Text
                                    mt={5}
                                    mb={0}
                                    color="red"
                                >
                                    Something wrong. Try again
                                </Text>
                            )}
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                w="auto !important"
                                px={7.5}
                                isLoading={isSendLoading}
                                loadingText={`Submitting`}
                                onClick={onChangeTimeZone}
                            >
                                Apply
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Portal>
        </Flex>
    );
}
