import { ActivityType } from '@/entities/events/event-data/event-data-types';
import { fetchData, METHODS } from '@/shared/lib';

export async function postJournalEventActivity(isUpcoming: boolean, eventId: number) {
    const activityBody = {
        activity_type: isUpcoming ? ActivityType.Participation : ActivityType.ViewRecord,
    };

    try {
        await fetchData(`/api/events/${eventId}/journal-activity`, {
            method: METHODS.POST,
            body: JSON.stringify(activityBody),
        });
    } catch (error) {
        throw new Error(`Not journal-activity post ${error}`);
    }
}
