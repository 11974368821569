import { BoxProps, Flex, Text } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';

import { CustomCheckbox } from '@/shared/ui';
import { ThanksCheckboxedBtn } from '@/entities/btns/thanks/checkboxed';
import { MONTH_PRICE } from '@/common/constants/brand';

interface Inputs {
    sub: boolean;
}

export default function ThanksCheckboxAles({ styles }: { styles?: BoxProps }) {
    const { control } = useForm<Inputs>({ defaultValues: { sub: true } });

    return (
        <Flex
            flexDir="column"
            gap={4}
            as="form"
            {...styles}
        >
            <Controller
                name="sub"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }: TObj<any>) => {
                    return (
                        <CustomCheckbox
                            checkboxProps={{
                                isChecked: Boolean(value),
                                onChange: onChange,
                            }}
                            LabelEl={() => (
                                <Text
                                    display="inline"
                                    mb={0}
                                    fontSize={'18px'}
                                    fontWeight={700}
                                >
                                    Get Exclusive Welcome Bonus Access to the Rejuvenation Club for Just $1!
                                </Text>
                            )}
                        />
                    );
                }}
            />

            <Text
                mb={0}
                fontSize={'14px'}
            >
                After your first week, continue enjoying access to the club for only ${MONTH_PRICE}/month. You can
                cancel your subscription at any time.
            </Text>

            <ThanksCheckboxedBtn />
        </Flex>
    );
}
