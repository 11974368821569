import React from 'react';
import { Box, Divider, Flex, HStack, Text } from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';

import CheckoutCourseRegular from './CheckoutCourseRegular';
import CheckoutAddTrial from './CheckoutAddTrial';
import { TypeOfCourse } from '../../model/useCheckoutPage';
import { useAtomValue } from 'jotai';
import { typeCheckoutCheckedItemsAtom } from '../../model/checkout-store';
import { CardCartCourse } from '@/entities/cards/card-cart-course';

export default function CheckoutOptionCourses({
    onAddTrial,
    totalCost,
    checkedItems,
    optionalCourse,
    onAddOptionalCourse,
    onDeleteItem,
}: any) {
    const typeCheckoutCheckedItems = useAtomValue(typeCheckoutCheckedItemsAtom);

    return (
        <>
            <Box>
                {!checkedItems.some((item: any) => item.type === TypeOfCourse.Trial) && (
                    <CheckoutAddTrial onAddTrial={onAddTrial} />
                )}

                {checkedItems.map((item: any, index: number) => {
                    return (
                        <React.Fragment key={index}>
                            {index > 0 && <Divider />}

                            <CheckoutCourseRegular
                                course={item}
                                onDelete={(courseItem: any) => onDeleteItem(courseItem)}
                                isNotDeleted={index === 0}
                            />
                        </React.Fragment>
                    );
                })}
            </Box>

            <Box mt={4}>
                <OverlayScrollbarsComponent>
                    <Flex
                        gap={2.5}
                        pb={2.5}
                    >
                        {optionalCourse.map((item: any) => {
                            return (
                                <CardCartCourse
                                    key={item.title}
                                    onCourseBtnClick={() => onAddOptionalCourse(item)}
                                    courseData={item}
                                    styles={{ w: '155px' }}
                                />
                            );
                        })}
                    </Flex>
                </OverlayScrollbarsComponent>
            </Box>

            <HStack
                justify="space-between"
                p={2}
                mt={4}
                bgColor="gray.300"
                borderRadius="lg"
                fontSize="18px"
            >
                <Text as="span">Total:</Text>

                <Text as="span">${totalCost}</Text>
            </HStack>

            <HStack
                justify="space-between"
                mt={1}
                fontSize="12px"
            >
                <Text
                    as="span"
                    color={'gray.500'}
                >
                    We&#39;ll charge ${totalCost} to your card
                </Text>
                {typeCheckoutCheckedItems === TypeOfCourse.Trial && <Text as="span">Cancel any time</Text>}
            </HStack>
        </>
    );
}
