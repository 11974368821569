import { Controls, PlayButton, useMediaState } from '@vidstack/react';
import { RiPauseMiniFill, RiPlayMiniFill } from 'react-icons/ri';
import { AbsoluteCenter, Box, Center } from '@chakra-ui/react';

interface PlayControlProps {
    player: any;
    onClick?: () => void;
}

export default function PlayControlSimple({ player, onClick }: PlayControlProps) {
    const isPaused = useMediaState('paused', player);

    return (
        <Box
            as={Controls.Group}
            flexGrow={1}
        >
            <Box
                as={PlayButton}
                w="100%"
                h="100%"
                _hover={{
                    '& > *': {
                        opacity: 1,
                    },
                }}
                onClick={onClick}
            >
                <AbsoluteCenter
                    as={Center}
                    w="50px"
                    h="50px"
                    backgroundColor="green.400"
                    color="white"
                    borderRadius="full"
                    fontSize="30px"
                    opacity={0.7}
                    transition="all 0.2s ease-in-out"
                >
                    {isPaused ? <RiPlayMiniFill /> : <RiPauseMiniFill />}
                </AbsoluteCenter>
            </Box>
        </Box>
    );
}
