import { Flex, Center, Box, BoxProps, useMediaQuery, Text, useDisclosure, Portal, HStack } from '@chakra-ui/react';
import {
    TimeSlider,
    PlayButton,
    SeekButton,
    MuteButton,
    FullscreenButton,
    Controls,
    useMediaState,
    Time,
} from '@vidstack/react';
import {
    RiPlayMiniFill,
    RiPauseMiniFill,
    RiReplay10Line,
    RiForward10Line,
    RiVolumeMuteLine,
    RiVolumeDownLine,
    RiVolumeUpLine,
    RiFullscreenExitFill,
    RiFullscreenFill,
    RiInformationLine,
} from 'react-icons/ri';

import { VideoTroubleModal } from '@/entities/modals';
import PlaybackRateSubmenu from '../menues/PlaybackRateSubmenu';
import VideoQualitySubmenu from '../menues/VideoQualitySubmenu';

export enum PlayerType {
    Small = 'small',
}

interface PlayerControlsProps {
    player: any;
    courseId: number;
    isSeekinkgDesible?: boolean;
    isShowTroubleBtn?: boolean;
    type?: PlayerType | null;
    styles?: BoxProps;
}

const PlayerControlsSimple: React.FC<PlayerControlsProps> = ({
    player,
    courseId,
    isSeekinkgDesible = false,
    isShowTroubleBtn = false,
    type,
    styles,
}) => {
    const isPaused = useMediaState('paused', player);
    const volume = useMediaState('volume', player);
    const isMuted = useMediaState('muted', player);
    const isFullscreen = useMediaState('fullscreen', player);

    const [isLargerThanMd] = useMediaQuery('(min-width: 768px)', {
        ssr: true,
        fallback: false, // return false on the server, and re-evaluate on the client side
    });

    const {
        isOpen: isVideoTroubleModalOpen,
        onOpen: onVideoTroubleModalOpen,
        onClose: onVideoTroubleModalClose,
    } = useDisclosure();

    return (
        <Box
            as={Controls.Group}
            pt="4px"
            pb="10px"
            px="10px"
            {...styles}
        >
            <HStack>
                <TimeSlider.Root
                    className="vds-time-slider vds-slider"
                    disabled={isSeekinkgDesible}
                >
                    <TimeSlider.Track className="vds-slider-track" />
                    <TimeSlider.TrackFill className="vds-slider-track-fill vds-slider-track" />
                    <TimeSlider.Progress className="vds-slider-progress vds-slider-track" />
                    <TimeSlider.Thumb className="vds-slider-thumb" />
                </TimeSlider.Root>

                {type !== PlayerType.Small && (
                    <Box className="vds-time-group">
                        <Box
                            as={Time}
                            type="current"
                            className="vds-time"
                            fontSize="sm"
                            fontFamily="body"
                        />
                        <Box
                            className="vds-time-divider"
                            fontSize="sm"
                        >
                            /
                        </Box>
                        <Box
                            as={Time}
                            type="duration"
                            className="vds-time"
                            fontSize="sm"
                            fontFamily="body"
                        />
                    </Box>
                )}

                {type === PlayerType.Small && (
                    <Box
                        as={MuteButton}
                        ml="auto"
                        color="white"
                    >
                        {isMuted || volume === 0 ? (
                            <RiVolumeMuteLine />
                        ) : volume < 0.5 ? (
                            <RiVolumeDownLine />
                        ) : (
                            <RiVolumeUpLine />
                        )}
                    </Box>
                )}
            </HStack>

            {type !== PlayerType.Small && (
                <Flex
                    layerStyle="player-control"
                    gap={2}
                >
                    <PlayButton>{isPaused ? <RiPlayMiniFill /> : <RiPauseMiniFill />}</PlayButton>

                    <SeekButton seconds={-10}>
                        <RiReplay10Line />
                    </SeekButton>

                    <SeekButton seconds={10}>
                        <RiForward10Line />
                    </SeekButton>

                    <PlaybackRateSubmenu />

                    <Box
                        as={MuteButton}
                        ml="auto"
                    >
                        {isMuted || volume === 0 ? (
                            <RiVolumeMuteLine />
                        ) : volume < 0.5 ? (
                            <RiVolumeDownLine />
                        ) : (
                            <RiVolumeUpLine />
                        )}
                    </Box>

                    <VideoQualitySubmenu />

                    <Box
                        as={FullscreenButton}
                        transition="all ease-in-out 0.2s"
                        _hover={{
                            transform: 'scale(1.15)',
                        }}
                    >
                        {isFullscreen ? <RiFullscreenExitFill /> : <RiFullscreenFill />}
                    </Box>

                    {isShowTroubleBtn ? (
                        isLargerThanMd ? (
                            <Text
                                cursor="pointer"
                                mb={0}
                                px="5px"
                                color="gray.500"
                                fontSize="md"
                                lineHeight={2}
                                _hover={{
                                    color: '#fff',
                                }}
                                transition="all ease-in-out .2s"
                                onClick={onVideoTroubleModalOpen}
                            >
                                Something wrong?
                            </Text>
                        ) : (
                            <Center
                                cursor="pointer"
                                p="5px"
                                color="gray.500"
                                _hover={{
                                    color: 'gray.450',
                                }}
                                onClick={onVideoTroubleModalOpen}
                            >
                                <RiInformationLine />
                            </Center>
                        )
                    ) : null}
                </Flex>
            )}

            {isShowTroubleBtn && (
                <Portal>
                    <VideoTroubleModal
                        courseId={courseId}
                        isOpen={isVideoTroubleModalOpen}
                        onClose={onVideoTroubleModalClose}
                    />
                </Portal>
            )}
        </Box>
    );
};

export default PlayerControlsSimple;
