import { useEffect } from 'react';
import { useAtom, useAtomValue } from 'jotai';

import { fetchData, getStripe, METHODS } from '@/shared/lib';
import { URLS } from '@/common/constants/global';
import { clientSecretAtom, stripeAtom, submitCheckoutDataAtom } from './checkout-store';

export default function useStripeCheckout() {
    const submitData = useAtomValue(submitCheckoutDataAtom);
    const [clientSecret, setClientSecret] = useAtom(clientSecretAtom);
    const [stripeInstanse, setStripeInstanse] = useAtom(stripeAtom);

    const fetchClientSecret = async () => {
        const { data } = await fetchData(URLS.CHECKOUT_CREATE_PAYMENT, {
            method: METHODS.POST,
            body: JSON.stringify(submitData),
        });

        setClientSecret(data.client_secret);
    };

    useEffect(() => {
        const fetchStripe = async () => {
            const secret = await getStripe();
            setStripeInstanse(secret);
        };

        fetchStripe();
    }, []);

    useEffect(() => {
        if (submitData) {
            setClientSecret(null);
            fetchClientSecret();
        }
    }, [submitData]);

    const options = {
        fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }],
        appearance: {
            variables: {
                fontSizeBase: '16px',
                colorBackground: '#fff',
                colorPrimary: '#333',
            },
            rules: {
                '.Input': {
                    padding: '12px 20px',
                    border: '1px solid #e5e5e5',
                    boxShadow: 'none',
                    borderRadius: '0',
                },
            },
        },
    };

    return { stripeInstanse, clientSecret, options, fetchClientSecret };
}
