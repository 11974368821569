import { createRoot } from 'react-dom/client';
import { useEffect, useState } from 'react';
import { Center, Spinner } from '@chakra-ui/react';

import ProvidersCsr from '../../providers/providers-csr';

import { ExpensiveLandingPageComponent, LandingProvider } from '@/widgets/landing/expensive-page';
import { fetchData, getSearchParam } from '@/shared/lib';
import {
    planOfCourseOfQuizAtom,
    QUIZ_DEFAULT_PUBLIC,
    QuizPageHeaderProgress,
    quizSettingAtom,
    QuizType,
} from '@/entities/quizes/quiz';
import { OnboardingQuiz } from '@/widgets/quizes/courses-choose-quiz';
import { useAtom } from 'jotai';
import { ClassesForYouOnboardingPaywall, useClassesForYouLanding } from '@/entities/promo/classes-for-you';
import { ChangeLife60days } from '@/widgets/products/change-life-60-days';
import { TrackerCongratPage } from '@/widgets/tracker/tracker-page';
import { URLS } from '@/common/constants/global';
import { ChallengesPageComponent } from '@/widgets/socials/challenges/index';
import ChallengesProvider from '@/entities/socials/challenges/model/ChallengesProvider';
import { CheckoutPageComponent } from '@/features/checkout/checkout-page';

const expensiveCoursePageNode = document.getElementById('expensiveCoursePage');
if (expensiveCoursePageNode) {
    const root = createRoot(expensiveCoursePageNode);

    const searchParam = getSearchParam('utm_source');
    if (searchParam) {
        localStorage.setItem('utmUser', `utm_source=${searchParam}`);
    } else {
        localStorage.removeItem('utmUser');
    }

    const Page = () => {
        const [courseData, setCourseData] = useState();

        useEffect(() => {
            async function getCourseData() {
                const utmUser = localStorage.getItem('utmUser');

                const { data } = await fetchData(
                    `/api/courses/by-slug/${window.location.pathname.split('/').pop()}?${utmUser ? `${utmUser}&` : ''}expand=authors,video_intro,thumb_big,price,testimonials,buyers_preview,payment_gateway,edc_discount_code,public_key,doc_links,info,playlist_sections,sections,fb_pixels,optional_courses`,
                );
                setCourseData(data);
            }

            getCourseData();
        }, []);

        if (!courseData) {
            return (
                <Center
                    bgColor="#111111"
                    overflow="hidden"
                    minH="100vh"
                >
                    <Spinner />
                </Center>
            );
        }

        return (
            <LandingProvider landingData={courseData}>
                <ExpensiveLandingPageComponent />
            </LandingProvider>
        );
    };

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <Page />
        </ProvidersCsr>,
    );
}

const quizPageNode = document.getElementById('quizPagePublic');
if (quizPageNode) {
    const root = createRoot(quizPageNode);

    const QuizComponent = () => {
        const [quizList, setQuizList] = useState<any>(null);
        const [quizSetting, setQuizSetting] = useAtom(quizSettingAtom);

        useEffect(() => {
            async function getDataFromTypeQuiz() {
                const { data } = await fetchData(URLS.getQuizData(parseInt(QuizType.PaywallQuizMainPage)));

                let eventType;
                let activitiesData;

                if (data) {
                    activitiesData = data;
                }

                if (data.amplitude_event_name) {
                    eventType = data.amplitude_event_name;
                }

                if (!quizSetting) {
                    setQuizSetting({
                        type: QuizType.PaywallQuizMainPage,
                        options: {
                            actionSubTypeMnlt: activitiesData.activity,
                            eventType: eventType,
                        },
                    });
                }
            }

            getDataFromTypeQuiz();

            if (!quizList) {
                setQuizList(QUIZ_DEFAULT_PUBLIC);
            }
        }, []);

        if (!quizList) {
            return (
                <Center minH="500px">
                    <Spinner />
                </Center>
            );
        }

        return <OnboardingQuiz quizList={quizList} />;
    };

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <QuizComponent />
        </ProvidersCsr>,
    );
}

const classesForYouPageNode = document.getElementById('classesForYouPage');
if (classesForYouPageNode) {
    const root = createRoot(classesForYouPageNode);

    const ClassesForYouComponent = () => {
        const { classesQuantity, isSelectedCoursesLoading, selectedCoursesList, promoText, BtnEl } =
            useClassesForYouLanding();
        const nameOfCourseChecked: string = '';

        const [quizSetting, setQuizSetting] = useAtom(quizSettingAtom);
        const [planOfCourseOfQuiz, setPlanOfCourseOfQuizAtom] = useAtom(planOfCourseOfQuizAtom);

        useEffect(() => {
            async function getDataFromTypeQuiz() {
                const { data } = await fetchData(URLS.getQuizData(parseInt(QuizType.PaywallQuizMainPage)));

                let eventType;
                let activitiesData;

                if (data) {
                    activitiesData = data;
                }

                if (data.amplitude_event_name) {
                    eventType = data.amplitude_event_name;
                }

                if (!quizSetting) {
                    setQuizSetting({
                        type: QuizType.PaywallQuizMainPage,
                        options: {
                            actionSubTypeMnlt: activitiesData.activity,
                            eventType: eventType,
                        },
                    });
                }
            }

            getDataFromTypeQuiz();
            if (!planOfCourseOfQuiz) {
                setPlanOfCourseOfQuizAtom({
                    price: '89',
                    trialDays: '7',
                });
            }
        }, []);

        return (
            <>
                <QuizPageHeaderProgress activeStep={3} />
                <ClassesForYouOnboardingPaywall
                    selectedCoursesList={selectedCoursesList}
                    BtnEl={BtnEl}
                    styles={{
                        pt: 7.5,
                    }}
                />
            </>
        );
    };

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <ClassesForYouComponent />
        </ProvidersCsr>,
    );
}

// Страница с поздравлением о покупке трекера
const trackerCongratPageNode = document.getElementById('trackerCongratPage');
if (trackerCongratPageNode) {
    const root = createRoot(trackerCongratPageNode);

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <TrackerCongratPage />
        </ProvidersCsr>,
    );
}

// Страница с поздравлением о покупке гайда
const changeLife60daysNode = document.getElementById('changeLife60days');
if (changeLife60daysNode) {
    const root = createRoot(changeLife60daysNode);

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <ChangeLife60days />
        </ProvidersCsr>,
    );
}

// Страница опциональной покупки (со страйпом)
const checkoutPageNode = document.getElementById('checkoutPage');
if (checkoutPageNode) {
    const root = createRoot(checkoutPageNode);

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <CheckoutPageComponent />
        </ProvidersCsr>,
    );
}
