'use client';

import { useState, useEffect } from 'react';
import { useBoolean } from '@chakra-ui/react';

import { fetchData } from '@/shared/lib';
import { CourseData } from '@/entities/common/model/courses.types';
import { URLS } from '@/common/constants/global';

export default function useCourseCollection(arr: number[]) {
    const [coursesList, setCoursesList] = useState<CourseData[] | null>(null);
    const [isCoursesLoading, setIsCoursesLoading] = useBoolean(true);

    useEffect(() => {
        if (!coursesList) {
            getCourses();
        }
    }, []);

    async function getCourses() {
        setIsCoursesLoading.on();

        const { data } = await fetchData<{ data: CourseData[] }>(URLS.getCoursesByCollection(arr));

        setCoursesList(data);

        setIsCoursesLoading.off();

        return data;
    }

    return { coursesList, isCoursesLoading, getCourses };
}
