/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Button, FormControl, Input, Link, Text, useBoolean, useToast, VStack } from '@chakra-ui/react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { RiGoogleFill } from 'react-icons/ri';

import { CustomCheckbox } from '@/shared/ui';
import { getBrand, isInAppBrowser, validation } from '@/shared/lib';
import { APP_BRAND } from '@/common/constants/brand';
import { EventNames, usePostEventQuizTimed } from '@/entities/quizes/quiz';
import { GOOGLE_AUTH_ENABLED_BRANDS, URLS } from '@/common/constants/global';

interface Inputs {
    email: string;
    agree_terms: boolean;
}

export default function CsrCourseBuyForm() {
    const {
        register,
        getFieldState,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            agree_terms: true,
        },
    });
    const toast = useToast();

    const { postEventQuizTimed } = usePostEventQuizTimed();

    const [isSignUpSubmitted, setSignUpSubmitted] = useBoolean(false);
    const [isSignUpGoogleSubmitted, setSignUpGoogleSubmitted] = useBoolean(false);

    const currentBrand = getBrand(null, typeof window !== 'undefined' ? window.location.host : undefined);
    const isShowGoogleButton = GOOGLE_AUTH_ENABLED_BRANDS.includes(currentBrand) && !isInAppBrowser();

    const onSignUpGoogleBtn = async () => {
        setSignUpGoogleSubmitted.on();
        try {
            await postEventQuizTimed([], EventNames.SignUpGoogleStep);
        } catch (error) {
            console.error('Quiz course event SignUpGoogleStep not send', error);
        } finally {
            window.location.href = URLS.GOOGLE_AUTH_REDIRECT;
        }
    };

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setSignUpSubmitted.on();
        // @ts-expect-error
        if (typeof window.fbq === 'function') {
            // @ts-expect-error
            window.fbq('track', 'InitiateCheckout');
        }
        setTimeout(async () => {
            const encodedEmail = encodeURIComponent(data.email);

            try {
                await postEventQuizTimed([], EventNames.EmailSignUp, { email: encodedEmail });
            } finally {
                window.location.href = URLS.getAuthYogaRedirect(encodedEmail);
            }
        }, 1000);

        // window.payByStripeSubscriptionThroughOrderFromReact(data.email);
    };

    const checkToastVisible = () => {
        const isEmailInvalid = getFieldState('email').invalid;
        const isAgreeTermsInvalid = getFieldState('agree_terms').invalid;

        if (isEmailInvalid || isAgreeTermsInvalid) {
            toast({
                duration: 5000,
                status: 'error',
                title: isEmailInvalid ? `Please check your email address` : `Please agree the terms`,
                position: 'top-right',
            });
        }
    };

    return (
        <Box
            as="form"
            onSubmit={handleSubmit(onSubmit, checkToastVisible)}
        >
            <Text
                mb="2.5"
                fontSize="sm3"
                fontWeight="bold"
            >
                Your email address*
            </Text>

            <FormControl isInvalid={Boolean(errors.email)}>
                <Input
                    variant="green-border"
                    type="text"
                    mb="2.5"
                    placeholder={`Your best Email`}
                    {...register('email', {
                        ...validation.email,
                    })}
                />
            </FormControl>

            <Controller
                name="agree_terms"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }: TObj<any>) => {
                    return (
                        <CustomCheckbox
                            checkboxProps={{
                                isChecked: true,
                                onChange: onChange,
                                isInvalid: Boolean(errors.agree_terms),
                            }}
                            styles={{ mb: 5 }}
                            LabelEl={() => (
                                <Text
                                    display="inline"
                                    color={errors.agree_terms ? 'red' : 'gray.500'}
                                    fontSize="12px"
                                    fontWeight={400}
                                    mt={1}
                                    mb={0}
                                >
                                    
                                        I agree with{' '}
                                        <Link
                                            href={`https://account.${APP_BRAND}.com/terms-of-service`}
                                            textDecoration="underline"
                                        >
                                            Terms of Service
                                        </Link>
                                        ,{' '}
                                        <Link
                                            href={`https://account.${APP_BRAND}.com/privacy-policy`}
                                            textDecoration="underline"
                                        >
                                            Privacy Policy
                                        </Link>{' '}
                                        and{' '}
                                        <Link
                                            href={`https://account.${APP_BRAND}.com/cancellation-refund-policies`}
                                            textDecoration="underline"
                                        >
                                            Refund
                                        </Link>
                                    
                                </Text>
                            )}
                        />
                    );
                }}
            />
            <VStack>
                <Button
                    type="submit"
                    isLoading={isSignUpSubmitted}
                    loadingText={`Submitting`}
                    p={5}
                    w="100%"
                    fontSize="sm3"
                >
                    Sign Up
                </Button>

                {/* {isShowGoogleButton && (
                    <Button
                        onClick={onSignUpGoogleBtn}
                        variant="outline-rounded"
                        isLoading={isSignUpGoogleSubmitted}
                        loadingText={`Submitting`}
                        w="100%"
                        fontSize="sm3"
                    >
                        <Box as={RiGoogleFill} />
                        Sign Up with Google
                    </Button>
                )} */}
            </VStack>
        </Box>
    );
}
