import { APP_BRAND } from '@/common/constants/brand';

export const afterPaymentStepsMock = [
    {
        number: 1,
        text: 'Click the "Get Access" button, enter your email in the field and you will be taken to a secure payment page.',
    },
    {
        number: 2,
        text: `Complete the order process and you will instantly receive a password to access your personal ${APP_BRAND.toUpperCase()} account.`,
    },
    {
        number: 3,
        text: 'Take lessons, perform practical tasks, ask the author questions and discover new courses.',
    },
];
