import { useState } from 'react';
import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react';

import { HTMLRenderer } from '@/shared/ui';

export default function CourseAuthor({ authorList, styles }: any) {
    const [isExpanded, setIsExpanded] = useState(false);

    const [isLargerThanMd] = useMediaQuery('(min-width: 768px)', {
        ssr: true,
        fallback: false,
    });

    return (
        <Box {...styles}>
            <Text as="h4">{authorList.length > 1 ? 'Meet the course authors' : 'Meet the course author'}</Text>

            <Flex
                flexDir="column"
                gap={7.5}
            >
                {authorList.map(({ about, photo }: any, index: number) => (
                    <Flex
                        key={index}
                        gap={{ base: 5, md: 10 }}
                    >
                        <Image
                            src={photo}
                            alt="author-photo"
                            flexShrink={0}
                            w={{ base: '50px', md: '320px' }}
                            h={{ base: '50px', md: '320px' }}
                            objectFit="contain"
                            borderRadius={{ base: 'full', md: '40px' }}
                        />

                        <Box>
                            <HTMLRenderer
                                className="text-wrap"
                                html={
                                    isExpanded
                                        ? about
                                        : `${about.slice(0, isLargerThanMd ? 700 : 330)}${about.length > 700 && '...'}`
                                }
                                styles={{ color: 'white' }}
                            />

                            <Text
                                cursor="pointer"
                                mt={5}
                                color="green.400"
                                _hover={{ opacity: 0.7 }}
                                onClick={() => {
                                    setIsExpanded(!isExpanded);
                                }}
                            >
                                {isExpanded ? 'Show less' : 'Show more'}
                            </Text>
                        </Box>
                    </Flex>
                ))}
            </Flex>
        </Box>
    );
}
