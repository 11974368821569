import { getFormattedDate } from '@/shared/lib';
import { EventData } from './event-data-types';

export function setStartDataToEvents(arr: EventData[]): EventData[] {
    return arr.map((item: EventData) => {
        const startDate = getFormattedDate(item.user_start_date);

        return {
            ...item,
            startDate: startDate,
        };
    });
}
