import { APP_BRAND } from '@/common/constants/brand';
import { CardReviewProps } from '@/entities/cards/cards-review';
import { capitalizeFirstLetter } from '@/shared/lib';

export const TRACKER_REVIEW_DATA: CardReviewProps[] = [
    {
        authorName: 'David',
        photo: '/img/reviews/david.png',
        text: `Life had become a whirlwind, and I struggled to find time for self-reflection and self-care. That's when I started using the Personal Tracker alongside my  ${capitalizeFirstLetter(APP_BRAND)} courses. It reminded me to pause, reflect, and appreciate the little things. The daily mood log helped me identify triggers that affected my well-being, while the self-gratitude journal shifted my focus towards self-love and appreciation. Now, I feel more centered, grounded, and equipped to navigate life's challenges.`,
        rating: 5,
    },
    {
        authorName: 'Liana',
        photo: '/img/reviews/liana.png',
        text: `I started the 'Healthy Eating' course on  ${capitalizeFirstLetter(APP_BRAND)} a few months back. The Personal Tracker's 'Expense Tracker' section surprisingly became my best ally - helping me monitor my grocery expenses and make healthier choices within my budget.`,
        rating: 5,
    },
    {
        authorName: 'Emma',
        photo: '/img/reviews/emma.png',
        text: "The Personal Tracker was the missing piece in my personal growth puzzle. It helped me bridge the gap between my dreams and reality. Tracking my goals, reflecting on my progress, and monitoring my overall wellness became integral parts of my daily routine. The Personal Tracker empowered me to see my own potential, step by step. It's been a remarkable journey of self-discovery and growth, and I am grateful for this transformative tool.",
        rating: 5,
    },
    {
        authorName: 'Carla',
        photo: '/img/reviews/carla.png',
        text: "Juggling a hectic schedule, I often overlooked self-care. The 'Self Gratitude Journal' in the Personal Tracker reminded me to appreciate myself more. It's been a beautiful journey towards self-love.",
        rating: 5,
    },
    {
        authorName: 'Liza',
        photo: '/img/reviews/liza.png',
        text: `I started using the Personal Tracker alongside my 'Workout' course from  ${capitalizeFirstLetter(APP_BRAND)}, and it's kept me accountable. My weekly goals are clearer, and I'm more motivated to reach them.`,
        rating: 5,
    },
];
