import { ReactNode } from 'react';
import { Provider as JotaiProvider } from 'jotai';
import { ChakraProvider } from '@chakra-ui/react';

import theme from '@/common/theme/theme-config-csr';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { messages as enMessages } from '@/common/locales/en/messages';
import { messages as ruMessages } from '@/common/locales/ru/messages';
import { csrStore } from '@/entities/csr/store';
import { UserWrapper } from '@/features/user/user-wrapper';

i18n.load({
    en: enMessages,
    ru: ruMessages,
});
i18n.activate(window.location.hostname.includes('edston') ? 'ru' : 'en');

export default function ProvidersCsr({ isAlwaysRender, children }: { isAlwaysRender?: boolean; children: ReactNode }) {
    return (
        <JotaiProvider store={csrStore}>
            <ChakraProvider theme={theme}>
                <I18nProvider i18n={i18n}>
                    <UserWrapper isAlwaysRender={isAlwaysRender}>{children}</UserWrapper>
                </I18nProvider>
            </ChakraProvider>
        </JotaiProvider>
    );
}
