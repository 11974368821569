import { useToast } from '@chakra-ui/react';
import { createRoot } from 'react-dom/client';
import ProvidersCsr from '../../providers/providers-csr';
import { useEffect } from 'react';

const errorNode = document.getElementById('error-react-component');

if (errorNode) {
    const root = createRoot(errorNode);

    const ErrorComponent = () => {
        const toast = useToast();

        useEffect(() => {
            toast({
                duration: 5000,
                status: 'error',
                title: errorNode.getAttribute('data-error') ?? 'Unknown error',
                position: 'top-right',
            });
        }, [toast]);

        return null;
    };

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <ErrorComponent />
        </ProvidersCsr>,
    );
}
