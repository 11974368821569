import { TOKENS, URLS } from '@/common/constants/global';

/**
 * Стандартные заголовки для API-запросов.
 */
const DEFAULT_HEADERS = {
    Accept: 'application/json',
    Authorization: `Bearer ${TOKENS.USER}`,
};

const FETCH_TIMEOUT = 60000;

/**
 * Класс для ошибок, связанных с запросами fetch.
 */
class FetchError extends Error {
    errorData: any;

    constructor(message: string, errorData?: any) {
        super(message);
        this.errorData = errorData;
        this.name = 'FetchError';
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

/**
 * Класс для ошибок тайм-аута.
 */
class TimeoutError extends Error {
    constructor(message = 'The request timed out.') {
        super(message);
        this.name = 'TimeoutError';
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

/**
 * Класс для сетевых ошибок.
 */
class NetworkError extends Error {
    constructor(message = 'A network error occurred.') {
        super(message);
        this.name = 'NetworkError';
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

/**
 * Функция для парсинга JSON-ответа.
 */
const parseJSONResponse = async (response: Response) => {
    const contentType = response.headers.get('Content-Type')?.toLowerCase() || '';
    if (contentType.includes('application/json')) {
        try {
            return await response.json();
        } catch {
            throw new FetchError('Failed to parse response as JSON');
        }
    } else {
        throw new FetchError('Response is not JSON');
    }
};

/**
 * Пользовательские защитники типов для обработки ошибок.
 */
function isAbortError(error: unknown): error is DOMException {
    return error instanceof DOMException && error.name === 'AbortError';
}

function isTypeError(error: unknown): error is TypeError {
    return error instanceof TypeError;
}

function isFetchError(error: unknown): error is FetchError {
    return error instanceof FetchError;
}

function isNetworkError(error: unknown): error is NetworkError {
    return error instanceof NetworkError;
}

function isTimeoutError(error: unknown): error is TimeoutError {
    return error instanceof TimeoutError;
}

/**
 * Интерфейс для опций повторных попыток
 */
interface RetryOptions {
    /** Максимальное количество повторных попыток */
    maxRetries?: number;

    /** Начальная задержка в мс перед повторной попыткой */
    initialDelay?: number;

    /** Фактор для экспоненциального увеличения времени задержки */
    backoffFactor?: number;

    /** Максимальная задержка в мс */
    maxDelay?: number;

    /** Функция для определения, нужно ли повторять запрос для данной ошибки */
    retryCondition?: (error: unknown, attemptNumber: number) => boolean;

    /** Обратный вызов перед каждой повторной попыткой */
    onRetry?: (error: unknown, attemptNumber: number, delay: number) => void;
}

/**
 * Функция для выполнения API-запросов с поддержкой тайм-аута, расширенной обработкой ошибок
 * и механизмом повторных попыток.
 *
 * @template T Тип ожидаемых данных в ответе.
 * @param {string} path Путь к API-эндпоинту.
 * @param {RequestInit} [options] Дополнительные опции для fetch-запроса.
 * @param {number} [timeout=60000] Время ожидания запроса в миллисекундах.
 * @param {RetryOptions} [retryOptions] Опции для механизма повторных попыток.
 * @returns {Promise<T>} Промис, который разрешается в данные типа T.
 * @throws {FetchError|TimeoutError|NetworkError|Error} Выбрасывает соответствующую ошибку после всех попыток.
 */
const fetchData = async <T = any>(
    path: string,
    options?: RequestInit,
    timeout = FETCH_TIMEOUT,
    retryOptions?: RetryOptions,
): Promise<T> => {
    const {
        maxRetries = 3,
        initialDelay = 1000,
        backoffFactor = 2,
        maxDelay = 30000,
        retryCondition = (error: unknown) => isNetworkError(error) || isTimeoutError(error),
        onRetry = (error: any, attemptNumber: any, delay: any) =>
            console.log(`Retry attempt ${attemptNumber} after ${delay}ms due to:`, error),
    } = retryOptions || {};

    let lastError: unknown;

    for (let attempt = 0; attempt <= maxRetries; attempt++) {
        const controller = new AbortController();
        const { signal } = controller;
        const timeoutId = setTimeout(() => controller.abort(), timeout);

        try {
            const isFormData = options?.body instanceof FormData;

            const requestOptions: RequestInit = {
                ...options,
                headers: {
                    ...DEFAULT_HEADERS,
                    ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
                    ...(options?.headers || {}),
                },
                signal,
            };

            const response = await fetch(`${URLS.API}${path}`, requestOptions);

            if (!response.ok) {
                let errorData: any = null;
                try {
                    errorData = await parseJSONResponse(response);
                } catch (parseError) {
                    console.error('Failed to parse error response as JSON');
                }

                throw new FetchError(
                    errorData?.result_message || `HTTP ${response.status}: An unknown error occurred`,
                    errorData,
                );
            }

            const data = await parseJSONResponse(response);
            return data as T;
        } catch (error: unknown) {
            clearTimeout(timeoutId);

            if (isAbortError(error)) {
                console.warn(`Request aborted after timeout of ${timeout} ms.`);
                lastError = new TimeoutError(`The request exceeded the timeout of ${timeout} ms.`);
            } else if (isTypeError(error)) {
                console.error('Network error:', error);
                lastError = new NetworkError('A network error occurred. Please check your connection.');
            } else if (isFetchError(error)) {
                console.error('FetchError:', error.message, error.errorData, `${URLS.API}${path}`);
                lastError = error;
            } else {
                console.error('Unknown error:', error);
                lastError = new Error('An unknown error occurred.');
            }

            // Проверяем, нужно ли делать повторную попытку
            const isLastAttempt = attempt === maxRetries;
            if (isLastAttempt || !retryCondition(lastError, attempt + 1)) {
                throw lastError; // Выбрасываем ошибку, если нет повторных попыток или это последняя попытка
            }

            // Расчёт задержки с экспоненциальным увеличением
            const delay = Math.min(initialDelay * Math.pow(backoffFactor, attempt), maxDelay);

            // Уведомляем о повторной попытке
            onRetry(lastError, attempt + 1, delay);

            // Ждём перед следующей попыткой
            await new Promise((resolve) => setTimeout(resolve, delay));
        } finally {
            clearTimeout(timeoutId);
        }
    }

    // Эта часть кода никогда не должна выполняться, так как все пути должны либо вернуть данные, либо выбросить ошибку
    throw new Error('Unexpected execution path');
};

// Экспортируем классы ошибок и функцию fetchData
export { FetchError, TimeoutError, NetworkError };
export default fetchData;
