import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
    h: 'auto',
    py: { base: '13.5px', md: '17px' },
    px: 8,
    fontSize: '13px',
    lineHeight: 1,
    fontWeight: 700,
    color: 'white',
    textAlign: 'center',
    transition: 'all 0.2s ease-in-out',
});

const primaryVariant = defineStyle({
    ...baseStyle,
    borderRadius: '555px',
    background: 'green.400',
    textTransform: 'uppercase',

    _hover: {
        background: 'green.350',

        _disabled: {
            background: 'green.400',
        },
    },

    _disabled: {
        color: 'white',
        opacity: '0.7',
    },
});

const whiteBlueVariant = defineStyle({
    ...baseStyle,
    borderRadius: '12px',
    fontSize: '16px',
    fontFamily: "Jeko, 'Rubik', serif",
    color: '#525dd0',
    background: '#fff',

    _hover: {
        color: 'rgba(82, 93, 208, 0.7)',
    },

    _disabled: {
        opacity: '0.7',
    },
});

const whiteBlueOutlineVariant = defineStyle({
    ...baseStyle,
    borderRadius: '12px',
    fontSize: '16px',
    fontFamily: "Jeko, 'Rubik', serif",
    color: '#525dd0',
    background: '#fff',
    border: '1px solid #525dd0',

    _hover: {
        color: 'rgba(82, 93, 208, 0.7)',
    },

    _disabled: {
        opacity: '0.7',
    },
});

const whiteVariant = defineStyle({
    ...baseStyle,
    borderRadius: '100px',
    border: `1px solid`,
    borderColor: 'white',
    backgroundColor: 'white',
    color: 'gray.675',

    _hover: {
        opacity: 0.6,
    },
});

const outlineWhiteRoundedVariant = defineStyle({
    ...baseStyle,
    borderRadius: '100px',
    border: `1px solid`,
    borderColor: 'white',

    _hover: {
        opacity: 0.6,
    },
});

const outlineWhiteRoundedSmVariant = defineStyle({
    h: 'auto',
    py: '14px',
    pr: 8,
    pl: 8,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1,
    color: 'white',
    textAlign: 'center',
    transition: 'all 0.2s ease-in-out',
    borderRadius: '8px',
    border: `1px solid`,
    borderColor: 'white',
    fontFamily: '"Golos Text", "Rubik", serif',

    _hover: {
        bgColor: 'white',
        color: '#111',
    },
});

const outlineGrayRoundedVariant = defineStyle({
    ...baseStyle,
    borderRadius: '100px',
    border: `1px solid`,
    borderColor: 'gray.550',
    py: { base: 'calc(12px - 1px)', md: 'calc(16px - 1px)' },
    color: 'gray.550',

    _hover: {
        opacity: 0.7,
    },
});

const grayLightVariant = defineStyle({
    ...baseStyle,
    borderRadius: '555px',
    backgroundColor: 'gray.378',
    color: 'gray.575',
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'uppercase',
});

const secondInvertedVariant = defineStyle({
    h: 'auto',
    py: 4,
    px: 7.5,
    borderRadius: '555px',
    backgroundColor: 'gray.510',
    fontSize: 'sm',
    fontWeight: 700,
    lineHeight: '15px',
    color: 'white',
    textTransform: 'uppercase',

    _hover: {
        opacity: '0.7',
        color: 'white',
    },
});

const outlineVariant = defineStyle({
    padding: '10px 16px',
    borderRadius: 0,
    background: 'transparent',
    color: 'gray.675',
    fontWeight: 400,
    border: '1px solid',
    borderColor: 'gray.350',
    textTransform: 'none',
    transition: 'all 0.2s ease-in-out',
});

const outlineRoundedVariant = defineStyle({
    gap: '10px',
    h: 'auto',
    px: 8,
    py: 4,
    background: 'transparent',
    border: `1px solid`,
    borderColor: 'gray.675',
    borderRadius: 'full',
    lineHeight: '1',
    fontSize: 'sm',
    fontWeight: 500,
    color: 'gray.675',
    transition: 'all 0.2s ease-in-out',

    _hover: {
        borderColor: 'green.400',
        textDecor: 'none',
        opacity: 1,
    },
});

const outlineRoundedGreenVariant = defineStyle({
    gap: '10px',
    h: 'auto',
    px: 5,
    py: 3,
    background: 'transparent',
    border: `1px solid`,
    borderColor: 'green.400',
    borderRadius: 'full',
    lineHeight: '1',
    fontSize: '13px',
    fontWeight: 500,
    color: 'green.400',
    textTransform: 'uppercase',
    transition: 'all 0.2s ease-in-out',

    _hover: {
        textDecor: 'none',
    },
});

const outlinePrimaryVariant = defineStyle({
    px: 0,
    fontWeight: 400,
    border: `1px solid`,
    borderColor: 'green.400',
    borderRadius: '100px',
    color: 'green.400',

    _hover: {
        opacity: 0.7,
    },
});

const resetVariant = defineStyle({
    background: 'none',
    p: 0,
    minW: 'none',
});

const searchIconVariant = defineStyle({
    pos: 'a',
});

const withShadowVariant = defineStyle({
    bg: 'red.400',
    boxShadow: '0 0 2px 2px #efdfde',
});

const solidVariant = defineStyle({
    bg: 'red.500',
});

const smVariant = defineStyle({
    bg: 'teal.500',
    fontSize: 'md',
});

const telegramVariant = defineStyle({
    pos: 'relative',
    h: 'auto',
    w: { base: 'calc(100% - 10px)', md: '100%' },
    maxW: { md: '300px' },
    ml: { base: '10px', md: 0 },
    padding: '16px 32px',
    border: 'none',
    borderRadius: '555px',
    bg: 'blue.475',
    color: '#fff',
    fontSize: 'sm',
    lineHeight: '15px',
    fontWeight: 500,
    outline: 'none',
    textAlign: 'center',
    textTransform: 'uppercase',
    transition: 'all .2s ease-in-out',

    _hover: {
        opacity: 0.7,
    },

    '&::before': {
        display: { md: 'none' },
        background: 'url(/img/icons/telegram_perspective_matte.png) no-repeat 100% 100%',
        backgroundSize: 'cover',
        content: '""',
        height: '60px',
        left: '-22px',
        position: 'absolute',
        top: '-5px',
        width: '60px',
    },
});

const paginationVariant = defineStyle({
    borderRadius: 'xl',
    border: '1px solid',
    borderColor: 'green.400',
    lineHeight: 1,

    _hover: {
        bgColor: 'green.400',
        color: 'white',
    },

    '&[data-selected]': {
        bgColor: 'green.400',
        color: 'white',
    },
});

const tooltipVariant = defineStyle({
    h: 'auto',
    py: '6px',
    px: '28px',
    border: 'none',
    borderRadius: '555px',
    background: 'white',
    fontSize: 14,
    fontWeight: 600,
    textAlign: 'center',
    color: 'green.500',
    textTransform: 'uppercase',
    transition: 'all 0.2s ease-in-out',

    _hover: {
        opacity: '0.7',
        background: 'green.200',
    },
});

const grayVariant = defineStyle({
    h: 'auto',
    py: 4,
    px: 7.5,
    border: 'none',
    borderRadius: '555px',
    background: 'gray.500',
    fontSize: 'sm',
    lineHeight: '15px',
    fontWeight: 700,
    textAlign: 'center',
    color: 'white',
    textTransform: 'uppercase',
    transition: 'all 0.2s ease-in-out',

    _hover: {
        opacity: '0.7',
        background: 'gray.500',
        color: 'white',
        _disabled: {
            background: 'gray.500',
        },
    },

    _disabled: {
        color: 'gray.500',
        opacity: '0.7',
    },
});

const buttonTheme = defineStyleConfig({
    baseStyle: {},
    sizes: {},
    variants: {
        primary: primaryVariant,
        white: whiteVariant,
        'outline-white-rounded': outlineWhiteRoundedVariant,
        'outline-white-rounded-sm': outlineWhiteRoundedSmVariant,
        'gray-light': grayLightVariant,
        'outline-gray-rounded': outlineGrayRoundedVariant,
        'white-blue': whiteBlueVariant,
        'white-blue-outline': whiteBlueOutlineVariant,

        'second-inverted': secondInvertedVariant,
        outline: outlineVariant,
        'outline-rounded': outlineRoundedVariant,
        'outline-green-rounded': outlineRoundedGreenVariant,
        'outline-primary-rounded': outlinePrimaryVariant,
        'with-shadow': withShadowVariant,
        solid: solidVariant,
        sm: smVariant,
        telegram: telegramVariant,
        pagination: paginationVariant,
        tooltip: tooltipVariant,
        reset: resetVariant,
        gray: grayVariant,
    },
    defaultProps: {
        variant: 'primary',
    },
});

export { buttonTheme };
