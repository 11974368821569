import { ReactNode } from 'react';
import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from '@chakra-ui/react';

import { useOverlayScrollbars } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';

import EventModalBody from './EventModalBody';
import { MONTH_PRICE } from '@/common/constants/brand';
import { CongratulationsModal } from '@/entities/modals';
import { ActionSubType, useCreateSub, useTrialDay } from '@/features/subscription/create-sub';
import { EventData } from '@/entities/events/event-data';
import { OnAirStatus } from '@/entities/socials/events/on-air-status';

interface ToTheEventsProps {
    eventData: EventData;
    isOpen: boolean;
    onClose: () => void;
    BtnElement?: () => ReactNode;
}

export default function ToTheEventModal({ eventData, isOpen, onClose, BtnElement }: ToTheEventsProps) {
    const [initialize, instance] = useOverlayScrollbars();
    const { isSubLoading, isSubError, isSubCreate, createSub, sendError } = useCreateSub(ActionSubType.EVENT_POPUP);
    useTrialDay(7);

    if (!eventData) {
        return null;
    }

    return (
        <>
            {isSubCreate ? (
                <CongratulationsModal
                    isOpen={isSubCreate}
                    onClose={() => location.reload()}
                />
            ) : (
                <Modal
                    variant="primary"
                    scrollBehavior="inside"
                    isOpen={isOpen}
                    onClose={onClose}
                >
                    <ModalOverlay />

                    <ModalContent>
                        <ModalHeader p={0}>
                            <Box
                                h="310px"
                                w="100%"
                                backgroundImage={`url("${eventData.thumb}")`}
                                backgroundRepeat="no-repeat"
                                backgroundSize="contain"
                                borderTopRadius="xl"
                            />
                        </ModalHeader>

                        <ModalCloseButton color={eventData.thumb ? 'white' : 'gray.500'} />

                        <ModalBody
                            ref={(ref) => {
                                if (ref) {
                                    // * потому что ререндер и нужен последний отрендеренный элемент
                                    instance()?.destroy();
                                    initialize(ref);
                                }
                            }}
                        >
                            <EventModalBody eventData={eventData} />
                        </ModalBody>

                        <ModalFooter
                            justifyContent="space-between"
                            flexDir="row"
                            alignItems="flex-start"
                            mt={{ base: 5, md: 10 }}
                            py={{ base: 5, md: 5 }}
                            borderTop="1px solid"
                            borderColor="gray.425"
                        >
                            <Box>
                                <Text
                                    mb={0}
                                    fontWeight={700}
                                    textTransform="uppercase"
                                >
                                    {eventData.startDate?.day ?? ''}
                                </Text>

                                <Text
                                    mb={0}
                                    color="gray.500"
                                >
                                    {eventData.startDate?.time ?? ''}
                                </Text>

                                {eventData.is_on_air && <OnAirStatus />}
                            </Box>

                            {BtnElement ? (
                                <BtnElement />
                            ) : (
                                <VStack>
                                    <Button
                                        onClick={() => createSub()}
                                        isLoading={isSubLoading}
                                        loadingText={`Submitting`}
                                        w="auto !important"
                                        minW={{ base: '222px', md: '322px' }}
                                        px={{ md: '80px' }}
                                    >
                                        Start 7-day free trial
                                    </Button>

                                    {isSubError && (
                                        <Text
                                            mb={0}
                                            color="red.600"
                                        >
                                            {sendError}
                                        </Text>
                                    )}

                                    <Text
                                        as="span"
                                        color="gray.500"
                                        fontSize="sm2"
                                    >
                                        then ${MONTH_PRICE}
                                        /mo
                                    </Text>
                                </VStack>
                            )}
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </>
    );
}
