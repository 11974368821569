/* eslint-disable @typescript-eslint/ban-ts-comment */
import { URLS } from '@/common/constants/global';
import { METHODS, fetchData } from '@/shared/lib';
import { useToast } from '@chakra-ui/react';
import { sessionAtom } from '@/entities/session';
import { useAtomValue } from 'jotai';
import { userAtom } from '@/shared/store';

export enum ActionSubType {
    EVENT_POPUP = 'event_popup',
    EVENT_BANNER_STANDARD_PRICE = 'event_banner_standard',
    DASHBOARD_BANNER_STANDARD_PRICE = 'dashboard_banner_standard',
    CABINET_USER_BANNER_UPGRADE_YEAR = 'cabinet_user_banner_year',
    DASHBOARD_USER_BANNER_UPGRADE_YEAR = 'dashboard_user_banner_year',
    DASHBOARD_WATCH_FREE_COURSE_CARD = 'dashboard_watch_free_course_card',
    SEARCH_WATCH_FREE_COURSE_CARD = 'search_watch_free_course_card',
    WATCH_FREE_COURSE_PAGE = 'watch_free_course_page',
    QUIZ_COURSE_THANK_PAGE = 'quiz_thanku_page',
    NEW_QUIZ_THANKU_PAGE = 'new_quiz_thanku_page',
    BuySubsriptionSocialUser = 'buy_subscription_social_user',
    QuizBannerDashboard = 'quiz_baner_dashboard',
    QuizMailchimp = 'quiz_thanku_page_mailchimp',
    QuizKommo = 'quiz_thanku_page_kommo',
    AfterRecQuizMailchimp = 'after_rec_quiz_mailchimp',
    AfterRecQuizKommo = 'after_rec_quiz_kommo',
    AfterRecQuizBannerDashboard = 'after_rec_quiz_baner_dashboard',
    AfterRecQuizBannerWatchCourse = 'after_rec_quiz_baner_watch_course_page',
    QuizBannerWatchCoursePage = 'quiz_baner_watch_course_page',
    RedirectFromThankPageForQuiz = 'redirect_from_thank_page_for_quiz',
    BannerWatchCoursePage = 'banner_whatch_course_page',
    QuizReconfirmEmail = 'quiz_reconfirm_email',
    StoryModal = 'stories_free_trial',
    QuizQuestionTrial = 'quiz_question_trial',
    YearSubscriptionQuiz = 'year_subscription_quiz',
    YearSubscriptionQuiz30 = 'year_subscription_quiz_30',
    AfterRecQuizMailchimpYear30 = 'after_rec_quiz_mailchimp_year_30',
    AfterRecQuizKommoYear30 = 'after_rec_quiz_kommo_year_30',
    AfterRecBannerYearSubscriptionQuiz30 = 'after_rec_banner_year_subscription_quiz_30',

    YearPlan = 'quiz_year_plan',
    YearPlanDiscount = 'quiz_year_plan_discount',
    LifetimePlan = 'quiz_lifetime_plan',
    LifetimePlanDiscount = 'quiz_lifetime_plan_discount',
    ThirtyDaysForNine = 'quiz_thirty_days_for_nine',
    Typage9Trial49Month = 'quiz_typage_9trial_49month',
    Quiz0Days175YearDiscount20 = 'quiz_0_days_175_year_discount_20',
    QuizNoUtm7_89 = 'quiz_no_utm_7_89',
    QuizMain89 = 'quiz_main_7_89',
    QuizNoUtm7_79 = 'quiz_no_utm_7_79',

    TryCancel49month = 'trycancel_49month',
    TryCancel9month = 'trycancel_9month',
    CustomThankuAles = 'custom_thanku_ales',
    QuizThankuHardTick7_79 = 'quiz_thanku_hard_tick_7_79',
    QuizThankuHardTick7_89 = 'quiz_thanku_hard_tick_7_89',
    QuizThankuTwoBtnHardMonthly = 'quiz_thanku_twobutton_hard_monthly',
    ChallengeTrial = 'challenge_trial',
    QuizThankuHardTick7for1_89 = 'quiz_thanku_hard_tick_7for1_89',
    AfterRecQuizBanerWatchCoursePageMindfuleve = 'after_rec_quiz_baner_watch_course_page_mindfuleve',
    QuizThirtyDaysAfter89 = 'quiz_thirty_days_after_89',
    QuizThankuTwobuttonHardPopup30 = 'quiz_thanku_twobutton_hard_popup30',
    T1QuizThankuTwoBtnHardMonthly = 't1_quiz_thanku_twobutton_hard_monthly',
}

const NEW_SUB_ACTION = [
    ActionSubType.EVENT_POPUP,
    ActionSubType.DASHBOARD_BANNER_STANDARD_PRICE,
    ActionSubType.DASHBOARD_WATCH_FREE_COURSE_CARD,
    ActionSubType.SEARCH_WATCH_FREE_COURSE_CARD,
    ActionSubType.WATCH_FREE_COURSE_PAGE,
    ActionSubType.QUIZ_COURSE_THANK_PAGE,
    ActionSubType.NEW_QUIZ_THANKU_PAGE,
    ActionSubType.QuizBannerDashboard,
    ActionSubType.QuizMailchimp,
    ActionSubType.AfterRecQuizMailchimp,
    ActionSubType.AfterRecQuizBannerDashboard,
    ActionSubType.AfterRecQuizBannerWatchCourse,
    ActionSubType.AfterRecQuizKommo,
    ActionSubType.QuizKommo,
    ActionSubType.RedirectFromThankPageForQuiz,
    ActionSubType.QuizBannerWatchCoursePage,
    ActionSubType.BannerWatchCoursePage,
    ActionSubType.QuizReconfirmEmail,
    ActionSubType.StoryModal,
    ActionSubType.QuizQuestionTrial,
    ActionSubType.YearSubscriptionQuiz,
    ActionSubType.YearSubscriptionQuiz30,
    ActionSubType.AfterRecQuizMailchimpYear30,
    ActionSubType.AfterRecQuizKommoYear30,
    ActionSubType.AfterRecBannerYearSubscriptionQuiz30,

    ActionSubType.YearPlan,
    ActionSubType.YearPlanDiscount,
    ActionSubType.LifetimePlan,
    ActionSubType.LifetimePlanDiscount,
    ActionSubType.ThirtyDaysForNine,
    ActionSubType.Typage9Trial49Month,
    ActionSubType.Quiz0Days175YearDiscount20,
    ActionSubType.QuizNoUtm7_89,
    ActionSubType.QuizNoUtm7_79,
    ActionSubType.QuizMain89,
    ActionSubType.CustomThankuAles,
    ActionSubType.QuizThankuHardTick7_79,
    ActionSubType.QuizThankuHardTick7_89,
    ActionSubType.QuizThankuTwoBtnHardMonthly,
    ActionSubType.ChallengeTrial,
    ActionSubType.QuizThankuHardTick7for1_89,
    ActionSubType.QuizThirtyDaysAfter89,
    ActionSubType.QuizThankuTwobuttonHardPopup30,
    ActionSubType.T1QuizThankuTwoBtnHardMonthly,
];

export function useOrderSub() {
    const toast = useToast();
    const session = useAtomValue(sessionAtom);
    const user = useAtomValue(userAtom);

    const orderSub = async (actionSubType: ActionSubType, isAlwaysPut?: boolean) => {
        const actionBody = {
            action_subscribe: actionSubType,
            order_id: session ? session.orderId : '',
            session_redirect_back_url: session ? session.sessionRedirectBackUrl : '',
        };

        const method = !NEW_SUB_ACTION.includes(actionSubType) || isAlwaysPut ? METHODS.PUT : METHODS.POST;

        try {
            const { data } = await fetchData(URLS.USER_SUB_PATH, {
                method: method,
                body: JSON.stringify(actionBody),
            });

            if (
                user?.fb_pixel_start_trial_data.subscription_count &&
                // @ts-expect-error
                typeof window.fbq === 'function'
            ) {
                // @ts-expect-error
                window.fbq(
                    'track',
                    'StartTrial',
                    {
                        value: 0,
                        currency: user?.fb_pixel_start_trial_data.currency,
                        predicted_ltv: '0.00',
                    },
                    {
                        event_id: user?.fb_pixel_start_trial_data.event_id,
                    },
                );
            }
        } catch (error) {
            let resultCode = (error as any).errorData?.result_code ?? '';

            if (resultCode === 'UNA') {
                toast({
                    duration: 3000,
                    status: 'error',
                    title: 'Your session ended, and you were automatically signed back in.',
                    position: 'top-right',
                });

                const reAuthResult = await reAuthenticateUser(actionBody);
                if (reAuthResult) {
                    // Получаем новый CSRF токен и повторяем запрос
                    await refreshCsrfToken(reAuthResult); // Функция для обновления CSRF токена
                    const { data, result_code } = await fetchData(URLS.USER_SUB_PATH, {
                        method: method,
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${getXToken()}`,
                        },
                        body: JSON.stringify(actionBody),
                    });

                    resultCode = result_code;
                } else {
                    console.log('reAuthResult was failure');
                }
            }

            if (resultCode !== 'COMPLETE') {
                throw error;
            }
        }
    };

    const reAuthenticateUser = async function (actionBody) {
        try {
            const { data, result_code } = await fetchData(URLS.AUTH_BY_ORDER, {
                method: METHODS.POST,
                body: JSON.stringify(actionBody),
            });

            if (result_code === 'COMPLETE') {
                console.log('Re-auth is completed');
                return data;
            }
        } catch (error) {
            console.error('Auth error:', error);
        }
        return false;
    };

    async function refreshCsrfToken(reAuthResult) {
        try {
            document.querySelector('meta[name="X-Token"]').setAttribute('content', reAuthResult);
            console.log('X token is updated');
        } catch (error) {
            console.error('Updating X token was failed:', error);
        }
    }

    function getXToken() {
        // Функция получения CSRF токена из страницы, куки или другого источника
        return document.querySelector('meta[name="X-Token"]').getAttribute('content');
    }

    return { orderSub };
}
