import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useOverlayScrollbars } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';

import { MarkList } from '@/shared/ui';
import { featureListData } from './data';
import { APP_BRAND, MONTH_PRICE } from '@/common/constants/brand';
import { CongratulationsModal } from '@/entities/modals';
import { ActionSubType, useCreateSub, useTrialDay } from '@/features/subscription/create-sub';
import { capitalizeFirstLetter } from '@/shared/lib';
import { EventData } from '@/entities/events/event-data';

interface ToTheEventsProps {
    eventData: EventData;
    isOpen: boolean;
    onClose: () => void;
}

export default function GoToRecordingModal({ eventData, isOpen, onClose }: ToTheEventsProps) {
    const [initialize, instance] = useOverlayScrollbars();
    const { isSubLoading, isSubError, loadingText, isSubCreate, createSub, sendError } = useCreateSub(
        ActionSubType.EVENT_POPUP,
    );
    useTrialDay(7);

    if (!eventData) {
        return null;
    }

    return (
        <>
            {isSubCreate ? (
                <CongratulationsModal
                    isOpen={isSubCreate}
                    onClose={() => location.reload()}
                />
            ) : (
                <Modal
                    variant="primary"
                    scrollBehavior="inside"
                    isOpen={isOpen}
                    onClose={onClose}
                >
                    <ModalOverlay />

                    <ModalContent>
                        <ModalHeader p={0}>
                            <Box
                                h="310px"
                                w="100%"
                                backgroundImage={`url("${eventData.thumb}")`}
                                backgroundRepeat="no-repeat"
                                backgroundSize="cover"
                                borderTopRadius="xl"
                            />
                        </ModalHeader>

                        <ModalCloseButton color={eventData.thumb ? 'white' : 'gray.500'} />

                        <ModalBody
                            ref={(ref) => {
                                if (ref) {
                                    // * потому что ререндер и нужен последний отрендеренный элемент
                                    instance()?.destroy();
                                    initialize(ref);
                                }
                            }}
                        >
                            <Text textStyle="md2">
                                Get A Recording Of This Event and others with the {capitalizeFirstLetter(APP_BRAND)}{' '}
                                subscription
                            </Text>

                            <Text fontWeight={700}>And also get:</Text>

                            <MarkList listData={featureListData} />
                        </ModalBody>

                        <ModalFooter
                            flexDir="column"
                            gap={5}
                            mt={{ base: 5, md: 10 }}
                            py={{ base: 5, md: 5 }}
                            borderTop="1px solid"
                            borderColor="gray.425"
                        >
                            <VStack maxW="100%">
                                <Button
                                    w="100% !important"
                                    px={{ md: '80px' }}
                                    whiteSpace={{ base: 'break-spaces', md: 'nowrap' }}
                                    isLoading={isSubLoading}
                                    loadingText={loadingText}
                                    onClick={() => createSub()}
                                >
                                    Start 7-day free trial & GET ACCESS TO ALL RECORDINGS
                                </Button>

                                {isSubError && (
                                    <Text
                                        mb={0}
                                        color="red.600"
                                    >
                                        {sendError}
                                    </Text>
                                )}
                            </VStack>

                            <Text
                                as="span"
                                color="gray.500"
                                fontSize="sm2"
                            >
                                then ${MONTH_PRICE}
                                /mo
                            </Text>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </>
    );
}
