import { AbsoluteCenter, Box, Image } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { RiCloseLargeLine } from 'react-icons/ri';

import { useVisibleEl } from '@/shared/lib';
import TrailerPlayer from './TrailerPlayer';
import { PlayerType } from './components/controls/PlayerControls';

export default function TrailerFloatPlayer({ videoSrc, videoPosterSrc, styles }: any) {
    const btnObservedBlock = useRef<HTMLDivElement | null>(null);
    const [isFloatVideoEnabled, setIsFloatVideoEnabled] = useState(true);
    const { isBlockVisible: isTargetBlockVisible } = useVisibleEl(btnObservedBlock, 0.75);
    const [isSmallVideoHover, setIsSmallVideoHover] = useState(false);

    const [isStarted, setIsStarted] = useState(false);

    useEffect(() => {
        if (videoSrc) {
            setIsStarted(true);
        }
    }, [videoSrc]);

    return (
        <Box
            aspectRatio="16 / 9"
            pos="relative"
            ref={btnObservedBlock}
            {...styles}
        >
            <Box
                maxW="100%"
                position={isFloatVideoEnabled ? (isTargetBlockVisible ? 'static' : 'fixed') : 'static'}
                bottom={isFloatVideoEnabled ? (isTargetBlockVisible ? 'auto' : { base: '100px', md: '80px' }) : 'auto'}
                right={isFloatVideoEnabled ? (isTargetBlockVisible ? 'auto' : '30px') : 'auto'}
                zIndex={50}
                borderRadius={isFloatVideoEnabled ? (isTargetBlockVisible ? 'none' : 'xl') : 'none'}
                width={isFloatVideoEnabled ? (isTargetBlockVisible ? '100%' : '300px') : '100%'}
                height={isFloatVideoEnabled ? (isTargetBlockVisible ? '100%' : '170px') : '100%'}
                overflow="hidden"
                onMouseOver={() => setIsSmallVideoHover(true)}
                onMouseLeave={() => setIsSmallVideoHover(false)}
            >
                {isStarted && (
                    <TrailerPlayer
                        isStarted={isStarted}
                        videoSrc={videoSrc}
                        setIsStarter={setIsStarted}
                        playerType={!isTargetBlockVisible ? PlayerType.Small : null}
                    />
                )}
                {!isTargetBlockVisible && isFloatVideoEnabled && (
                    <Box
                        onClick={() => setIsFloatVideoEnabled(false)}
                        onMouseOver={() => setIsSmallVideoHover(true)}
                        onMouseLeave={() => setIsSmallVideoHover(false)}
                        opacity={isSmallVideoHover ? '1' : '0'}
                        transition="opacity 0.3s ease-in-out"
                        cursor="pointer"
                        zIndex={100}
                        pos="fixed"
                        right="40px"
                        bottom={{ base: '230px', md: '210px' }}
                        w="30px"
                        h="30px"
                        borderRadius="full"
                        bgColor="rgba(0, 0, 0, 0.5)"
                        _hover={{ opacity: '0.7' }}
                    >
                        <AbsoluteCenter
                            as={RiCloseLargeLine}
                            color="white"
                        />
                    </Box>
                )}
            </Box>

            <Image
                src={videoPosterSrc}
                alt="poster"
                sizes="(max-width: 768px) 100vw, 70vw"
                display="block"
                borderRadius={{ md: 'xl' }}
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
            />
        </Box>
    );
}
