import { Box, Button, Center, Container, Link, Text } from '@chakra-ui/react';
import { RiAttachment2 } from 'react-icons/ri';

export default function TrackerCongratPage() {
    return (
        <Center minH="500px">
            <Container
                maxW="560px"
                textAlign="center"
            >
                <Text textStyle="lg3">Thank You for Your Purchase!</Text>

                <Text>
                    You&#39;re one step closer to your personal transformation. Click the button below to get your{' '}
                    <b>Personal Tracker</b> and start your journey to conscious living, goal achievement, and holistic
                    wellness today.
                </Text>

                <Text>We’re excited to be part of your journey!</Text>

                <Button
                    as={Link}
                    href="https://drive.google.com/file/d/13SD7ZQkY-M9SZl2Vd2pil65cX-jS7zEh/view?usp=share_link"
                >
                    <Box
                        as={RiAttachment2}
                        color="white"
                        mr={2}
                        w={5}
                        h={5}
                    />
                    Get Your Personal Tracker Now
                </Button>
            </Container>
        </Center>
    );
}
