import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { RiArrowLeftLine } from 'react-icons/ri';
import { useAtomValue } from 'jotai';

import { URLS } from '@/common/constants/global';
import { fetchData, METHODS } from '@/shared/lib';
import { useLoadingTextWithFetch } from '@/shared/ui';
import { getNextDateFromToday } from '@/shared/lib';
import { userAtom } from '@/shared/store';

export default function AdditionalDaysOfferScreen({
    onPrev,
    onCancelSub,
    onStayBtn,
}: {
    onPrev: () => void;
    onCancelSub: () => void;
    onStayBtn: () => void;
}) {
    const user = useAtomValue(userAtom);

    const { loadingText, sendError, isSubmitted, textedFetch } = useLoadingTextWithFetch(async () => {
        await fetchData(URLS.SUB_ADD_TRIAL, { method: METHODS.PUT });
    });

    const onSaleClick = async () => {
        await textedFetch();
        onStayBtn();
    };

    if (!user) {
        return null;
    }

    return (
        <Box
            pos="relative"
            px={{ base: 6, md: 10 }}
            py={10}
        >
            <Box
                hideFrom="md"
                as={RiArrowLeftLine}
                pos="absolute"
                top="13px"
                left="16px"
                w="20px"
                h="20px"
                onClick={onPrev}
            />

            <Text
                textStyle="lg"
                textAlign={'center'}
            >
                We&#39;ve Got Something Special for You
            </Text>

            <Text mb={4}>
                We’re confident that our platform is perfect for you! That’s why we’re offering to extend your free
                trial for an <b>additional 7 days</b>, completely free, until{' '}
                {getNextDateFromToday(true, 'dd.MM.yyyy', user.subscription_trial_days + 7)}.
            </Text>

            <Text mb={4}>Give yourself more time to explore everything we have to offer!</Text>

            <Text
                textStyle="sm"
                mb={6}
            >
                Click &#34;Extend My Free Trial&#34; below to continue enjoying our platform.
            </Text>

            <Flex
                flexDir={{ base: 'column', md: 'row' }}
                flexWrap={'wrap'}
                gap={{ base: 2, md: 5 }}
            >
                <Button
                    onClick={onSaleClick}
                    loadingText={loadingText}
                    isLoading={isSubmitted}
                    w={{ md: '100%' }}
                >
                    Extend My Free Trial
                </Button>

                {sendError && (
                    <Text
                        color="red.500"
                        textAlign="center"
                        w="100%"
                        mb={0}
                    >
                        {sendError}
                    </Text>
                )}

                <Button
                    onClick={onPrev}
                    hideBelow="md"
                    w="calc(50% - 10px)"
                    bg="transparent"
                    border="1px solid"
                    borderColor="gray.500"
                    color="gray.500"
                    _hover={{
                        bg: 'transparent',
                        opacity: 0.7,
                    }}
                >
                    Back
                </Button>

                <Button
                    onClick={onCancelSub}
                    variant="gray"
                    w={{ base: '100%', md: 'calc(50% - 10px)' }}
                >
                    Continue to Cancel
                </Button>
            </Flex>
        </Box>
    );
}
