import { FormatData } from '@/shared/lib';
import { TimeZoneData } from '../../../shared/models/time-zone/types';

export enum EventStatus {
    Upcoming = 1,
    Past = 2,
}

export enum EventType {
    ForAll = '1',
    OnlySub = '2',
}

export enum ActivityType {
    Participation = 'participation',
    ViewRecord = 'view_record',
}

export interface EventData {
    id: number;
    start_date: string; // YYYY-MM-DD h m s
    title: string;
    description: string | null;
    event_author_id: number;
    event_link: string | null;
    event_recording_link: string | null;
    created_at: string | null; // YYYY-MM-DD h m s
    updated_at: string | null; // YYYY-MM-DD h m s
    event_author_name: string;

    // expand
    status: EventStatus;
    thumb: string;
    user_start_date: string;
    user_zone: TimeZoneData;
    type: EventType;
    is_user_notification: boolean;
    author_name: string;
    author_description: string;
    author_thumb: string;
    is_on_air: boolean;

    startDate?: FormatData;
}

export interface EventPagination {
    pagination_total_count: number;
    pagination_page_count: number;
    pagination_current_page: number;
    pagination_per_page: number;
}

export interface EventsData {
    data: EventData[];
    pagination: EventPagination;
}
