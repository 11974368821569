import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { RiArrowLeftLine } from 'react-icons/ri';

import { URLS } from '@/common/constants/global';
import { fetchData, METHODS } from '@/shared/lib';
import { useLoadingTextWithFetch } from '@/shared/ui';
import { ActionSubType } from '@/features/subscription/create-sub';
import { ConfomationEvents } from '@/entities/user/user-data';
import { userAtom } from '@/shared/store';
import { useAtomValue } from 'jotai';

export default function DiscountFirstOfferScreen({
    onPrev,
    onCancelSub,
    onStayBtn,
}: {
    onPrev: () => void;
    onCancelSub: () => void;
    onStayBtn: () => void;
}) {
    const user = useAtomValue(userAtom);
    const eventId = user?.confirmation_events.find(
        (event) => event.event_type === ConfomationEvents.ConfirmCancelSubscription,
    )?.id;

    const { loadingText, sendError, isSubmitted, textedFetch } = useLoadingTextWithFetch(async () => {
        await fetchData(URLS.SUB_DISCOUNT, {
            method: METHODS.PUT,
            body: JSON.stringify({ action_subscribe: ActionSubType.TryCancel49month }),
        });

        if (eventId) {
            await fetchData(URLS.getEventInteract(eventId), {
                method: METHODS.POST,
            });
        }
    });

    const onSaleClick = async () => {
        await textedFetch();
        onStayBtn();
    };

    return (
        <Box
            pos="relative"
            px={{ base: 6, md: 10 }}
            py={10}
        >
            <Box
                hideFrom="md"
                as={RiArrowLeftLine}
                pos="absolute"
                top="13px"
                left="16px"
                w="20px"
                h="20px"
                onClick={onPrev}
            />

            <Text
                textStyle="lg"
                textAlign={'center'}
            >
                Your Cancellation Is Confirmed – But Wait!
            </Text>

            <Text mb={4}>
                We’d love for you to stay with us! As a special offer, you can continue your subscription at{' '}
                <b>45% off</b> and pay <b>just $49 per month</b> for unlimited access to all courses and subscription
                features <b>– forever!</b>
            </Text>

            <Text
                textStyle="sm"
                mb={6}
            >
                Enjoy all the powerful features and content, and keep your courses at this special price for as long as
                you stay subscribed.
            </Text>

            <Flex
                flexDir={{ base: 'column', md: 'row' }}
                flexWrap={'wrap'}
                gap={{ base: 2, md: 5 }}
            >
                <Button
                    onClick={onSaleClick}
                    loadingText={loadingText}
                    isLoading={isSubmitted}
                    w={{ md: '100%' }}
                >
                    Claim this offer
                </Button>

                {sendError && (
                    <Text
                        color="red.500"
                        textAlign="center"
                        w="100%"
                        mb={0}
                    >
                        {sendError}
                    </Text>
                )}

                <Button
                    onClick={onPrev}
                    hideBelow="md"
                    w="calc(50% - 10px)"
                    bg="transparent"
                    border="1px solid"
                    borderColor="gray.500"
                    color="gray.500"
                    _hover={{
                        bg: 'transparent',
                        opacity: 0.7,
                    }}
                >
                    Back
                </Button>

                <Button
                    onClick={onCancelSub}
                    variant="gray"
                    w={{ base: '100%', md: 'calc(50% - 10px)' }}
                >
                    Decline this offer
                </Button>
            </Flex>
        </Box>
    );
}
