import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Picture } from '@/shared/ui';
import { useCoursesListByValue, SearchType } from '@/features/courses/courses-list';

export default function BannerManaNextLevel() {
    const { coursesList } = useCoursesListByValue(SearchType.Id, [919, 1134, 1119, 950, 829, 995, 996, 1018]);
    const [isBannerShow, setIsBannerShow] = useState(false);

    useEffect(() => {
        if (coursesList) {
            const isWatchOrBuyed = coursesList.some((course) => course.is_purchased || course.is_watching);

            setIsBannerShow(!isWatchOrBuyed);
        }
    }, [coursesList]);

    if (!isBannerShow) {
        return null;
    }

    return (
        <Box bgColor="#211C1C">
            <Flex
                justify="space-between"
                gap={{ base: '30px', lg: '0' }}
                flexDir={{ base: 'column', lg: 'row' }}
                maxW="1680px"
                mx="auto"
                overflow="hidden"
            >
                <Box
                    zIndex={1}
                    maxW={{ base: '500px', xl: '600px' }}
                    flexShrink={0}
                    pt={{ base: 10, lg: 20 }}
                    pb={{ xl: '90px' }}
                    px={{ base: 6, xl: 0 }}
                    pl={{ xl: '45px' }}
                    mx="auto"
                    ml={{ lg: 'auto' }}
                    mr={{ lg: '0' }}
                    textAlign="center"
                    color="white"
                >
                    <Box
                        fontSize={{ base: '24px', xl: '42px' }}
                        fontWeight={700}
                        mb={5}
                    >
                        The Next Level of MANA Movement{' '}
                        <Box
                            pos="relative"
                            display="inline"
                        >
                            <Text as="span">Awaits!?</Text>

                            <Image
                                src="/img/icons/figures/lines/line-yellow.svg"
                                alt="yellow-line"
                                pos="absolute"
                                bottom={{ base: '-80%', md: '-70%' }}
                                left={0}
                                w={{ base: '171px', lg: '290px' }}
                                h="100%"
                                transform="scale(1.5)"
                            />
                        </Box>
                    </Box>
                    <Text
                        mb={{ lg: 2.5 }}
                        fontSize={{ base: '14px', xl: '20px' }}
                    >
                        You’ve felt the magic — now go deeper. Discover <b>33 lessons</b> across{' '}
                        <b>10 powerful modules</b> to cultivate inner strength, fluidity, and creative expression. Start
                        your next chapter in MANA Movement!
                    </Text>
                    <Button
                        variant="white"
                        onClick={() => {
                            window.location.href = '/mana-movement-full-course?utm_source=dashboard_mana_full';
                        }}
                    >
                        Learn more
                    </Button>
                </Box>

                <Box
                    zIndex={0}
                    flex="1 0 auto"
                    maxW="800px"
                    ml={{ lg: '-135px' }}
                >
                    <Picture
                        src={'promo/banners/mana-mei/mana-next-level.png'}
                        alt={'mana-next-level'}
                        imgStyle={{
                            w: '100%',
                            maxW: { base: '400px', lg: '100%' },
                            h: { lg: '100%' },
                            mx: 'auto',
                            objectFit: 'cover',
                        }}
                    />
                </Box>
            </Flex>
        </Box>
    );
}
