import { Box, Flex, HStack, Image, Text } from '@chakra-ui/react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function ReviewSlider({ reviewList, styles }: any) {
    return (
        <Box
            mx={-5}
            {...styles}
        >
            <Box
                mb={6}
                textAlign="center"
            >
                <Box fontWeight={500}>We helped over</Box>
                <Box
                    fontSize="28px"
                    fontWeight={700}
                >
                    500 000+ people
                </Box>
                <Box fontSize="14px">find happiness and everyday joy</Box>
            </Box>

            <Swiper
                modules={[Pagination]}
                pagination={{
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                    bulletClass: 'swiper-pagination-bullet swiper-pagination-bullet--gray',
                }}
                slidesPerView="auto"
                spaceBetween="35"
                centeredSlides={true}
                loop={true}
            >
                {reviewList.map((review: any, index: number) => (
                    <SwiperSlide
                        key={index}
                        style={{ width: '230px' }}
                    >
                        <Flex
                            gap="7px"
                            align="center"
                            mb={3}
                        >
                            <Image
                                src={review.imgSrc}
                                alt="review author photo"
                                w="20px"
                                h="20px"
                                borderRadius="full"
                                objectFit="cover"
                            />
                            <Box
                                fontSize="12px"
                                color="gray.525"
                            >
                                {review.authorName}
                            </Box>

                            <Flex
                                gap={1}
                                ml="auto"
                            >
                                {[...Array(5)].map((_, index) => (
                                    <Image
                                        key={index}
                                        src="/img/icons/star.svg"
                                        alt="star"
                                        w="18px"
                                        gap="2"
                                    />
                                ))}
                            </Flex>
                        </Flex>

                        <Text
                            mb={0}
                            fontSize="12px"
                            lineHeight={2}
                        >
                            {review.text}
                        </Text>
                    </SwiperSlide>
                ))}

                <HStack
                    className="swiper-pagination"
                    mt={9}
                    px={5}
                    position={'static !important' as any}
                    flexWrap="wrap"
                    justify="center"
                />
            </Swiper>
        </Box>
    );
}
