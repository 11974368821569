import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { textStyles } from '../textStyles';

const baseStyle = defineStyle({
    fontWeight: 700,

    _dark: {
        color: 'yellow.300',
    },
});

const headingTheme = defineStyleConfig({
    baseStyle,
    defaultProps: {
        size: 'xl-custom',
    },
    sizes: {
        'xl-custom': {
            fontSize: {
                base: '18px',
                md: '24px',
            },
        },
        lg5: textStyles.lg5,
        lg3: textStyles.lg3,
        md4: textStyles.md4,
    },
});

export { headingTheme };
