import { METHODS, fetchData } from '@/shared/lib';

const STRIPE_REDIRECT_ENDPOINT = '/api/users/subscription/redirect-to-stripe-data';

export default async function getDataFromStripeByActionSubType(actionSubType: any) {
    return await fetchData(STRIPE_REDIRECT_ENDPOINT, {
        method: METHODS.POST,
        body: JSON.stringify({ action: actionSubType }),
    });
}
