export default function YogaMat(props: any) {
    return (
        <svg
            viewBox="0 0 32 32"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M29.091 7.918H12.033a5.217 5.217 0 00-2.024-3.22A5.409 5.409 0 006.3 3.658a5.372 5.372 0 00-3.453 1.684A5.175 5.175 0 001.455 8.87v14.265a5.186 5.186 0 001.563 3.696c1 .98 2.356 1.532 3.77 1.534H29.09c.386 0 .755-.15 1.028-.418s.426-.63.427-1.009V9.345c0-.378-.154-.74-.427-1.008a1.47 1.47 0 00-1.028-.418zm-26.667.95c0-1.134.46-2.223 1.278-3.025a4.408 4.408 0 013.086-1.254c1.157 0 2.267.451 3.085 1.254a4.238 4.238 0 011.279 3.026v11.26a5.3 5.3 0 00-1.905-1.636 5.413 5.413 0 00-4.919 0 5.299 5.299 0 00-1.904 1.637V8.869zm0 14.266c0-.847.256-1.674.736-2.378a4.347 4.347 0 011.958-1.576 4.443 4.443 0 012.521-.244 4.39 4.39 0 012.234 1.172 4.255 4.255 0 011.195 2.19c.168.83.082 1.691-.249 2.473-.33.782-.89 1.45-1.607 1.92a4.425 4.425 0 01-5.508-.533 4.243 4.243 0 01-1.28-3.024zm27.152 3.803a.471.471 0 01-.142.337.49.49 0 01-.343.139H9.851a5.26 5.26 0 001.668-1.867 5.148 5.148 0 00.602-2.412V8.869h16.97a.49.49 0 01.343.14c.09.088.142.21.142.335v17.593z" />
            <path d="M6.788 21.703c-.288 0-.57.084-.809.24-.239.157-.425.38-.535.64a1.401 1.401 0 00.315 1.555 1.481 1.481 0 001.585.31c.266-.108.493-.291.653-.526a1.407 1.407 0 00-.181-1.8 1.471 1.471 0 00-1.028-.419zm0 1.902a.492.492 0 01-.448-.293.467.467 0 01.105-.519.487.487 0 01.528-.103.47.47 0 01.157.775.49.49 0 01-.342.14z" />
            <path d="M6.788 19.809c-.671 0-1.327.195-1.886.56a3.344 3.344 0 00-1.25 1.494 3.269 3.269 0 00-.193 1.923c.131.646.454 1.24.93 1.705.474.465 1.079.782 1.737.91.658.129 1.34.063 1.96-.189a3.381 3.381 0 001.524-1.226c.373-.547.572-1.19.572-1.849a3.3 3.3 0 00-.995-2.352 3.432 3.432 0 00-2.399-.976zm0 5.706c-.48 0-.948-.14-1.347-.401a2.389 2.389 0 01-.893-1.067 2.335 2.335 0 01-.138-1.374c.094-.461.325-.885.664-1.217a2.439 2.439 0 011.241-.65c.47-.093.958-.045 1.4.135.444.18.822.484 1.089.875.266.391.408.85.408 1.321 0 .63-.256 1.235-.71 1.68a2.451 2.451 0 01-1.714.698z" />
        </svg>
    );
}
