import { APP_BRAND, MONTH_PRICE } from '@/common/constants/brand';
import { capitalizeFirstLetter } from '@/shared/lib';
import { Box, BoxProps, Center, Flex, Text, VStack } from '@chakra-ui/react';
import DanceBallroomIcon from 'mdi-react/DanceBallroomIcon';
import DumbbellIcon from 'mdi-react/DumbbellIcon';
import TimerSandFullIcon from 'mdi-react/TimerSandFullIcon';
import { RiCheckLine } from 'react-icons/ri';

export default function Paywall({ styles }: { styles?: BoxProps }) {
    return (
        <Box {...styles}>
            <Text
                mb={4}
                fontWeight={600}
                fontSize="18px"
                lineHeight={1.3}
                textAlign="center"
            >
                Open access to Newmindstart Membership 7-day FREE trial
            </Text>

            <Flex
                gap="30px"
                mb={6}
            >
                <VStack
                    gap={1}
                    w="33%"
                >
                    <Center
                        w="32px"
                        h="32px"
                        borderRadius="50%"
                        bgColor="#8e4aff"
                    >
                        <Box
                            as={DanceBallroomIcon}
                            w="16px"
                            h="16px"
                            color="white"
                        />
                    </Center>

                    <Text
                        mb={0}
                        textAlign="center"
                        fontSize="12px"
                        lineHeight={1.3}
                    >
                        Discovering yourself and your desires through dance and voice.
                    </Text>
                </VStack>

                <VStack
                    gap={1}
                    w="33%"
                >
                    <Center
                        w="32px"
                        h="32px"
                        borderRadius="50%"
                        bgColor="#8e4aff"
                    >
                        <Box
                            as={DumbbellIcon}
                            w="16px"
                            h="16px"
                            color="white"
                        />
                    </Center>

                    <Text
                        mb={0}
                        textAlign="center"
                        fontSize="12px"
                        lineHeight={1.3}
                    >
                        Reconnecting with your body and relieving tension.
                    </Text>
                </VStack>

                <VStack
                    gap={1}
                    w="33%"
                >
                    <Center
                        w="32px"
                        h="32px"
                        borderRadius="50%"
                        bgColor="#8e4aff"
                    >
                        <Box
                            as={TimerSandFullIcon}
                            w="16px"
                            h="16px"
                            color="white"
                        />
                    </Center>

                    <Text
                        mb={0}
                        textAlign="center"
                        fontSize="12px"
                        lineHeight={1.3}
                    >
                        Reach your dreams with a supportive, caring community.
                    </Text>
                </VStack>
            </Flex>

            <Box
                mx={{ base: -5, md: 0 }}
                px={{ base: 5 }}
                pt="9px"
                borderRadius="16px"
                boxShadow="0 0 20px 0 rgba(128, 128, 128, 0.2)"
            >
                <Text
                    mx="auto"
                    mb="7px"
                    maxW="280px"
                    textAlign="center"
                >
                    Try {capitalizeFirstLetter(APP_BRAND)} Membership today with a{' '}
                    <Text
                        as="span"
                        fontWeight={700}
                    >
                        7-day FREE trial
                    </Text>
                </Text>

                <Flex>
                    <Box flex="1 0 auto"></Box>
                    <Flex
                        justify="center"
                        align="center"
                        w="66px"
                        textAlign="center"
                        fontSize="12px"
                        lineHeight={1.1}
                    >
                        Single Course
                    </Flex>
                    <Flex
                        justify="center"
                        p="2"
                        w="122px"
                        textAlign="center"
                        borderTopRadius="8px"
                        bgColor="#e9f5e9"
                    >
                        <Box
                            p="2px 6px"
                            pt="4px"
                            borderRadius="8px"
                            bgColor="green.400"
                            fontSize="12px"
                            lineHeight={1.1}
                            textTransform="uppercase"
                            fontWeight={500}
                            color="white"
                        >
                            {APP_BRAND} MEMBERSHIP
                        </Box>
                    </Flex>
                </Flex>

                <Flex borderBottom="1px solid rgba(0, 0, 0, 0.1)">
                    <Box
                        pt={4}
                        mr="auto"
                        fontSize="13px"
                        lineHeight={1.3}
                        fontWeight={500}
                    >
                        Osteo Yoga
                    </Box>
                    <Flex
                        justify="center"
                        w="66px"
                        pt={4}
                    >
                        <Box as={RiCheckLine} />
                    </Flex>
                    <Flex
                        justify="center"
                        w="122px"
                        pt={4}
                        pb={2}
                        bgColor="#e9f5e9"
                    >
                        <Box
                            as={RiCheckLine}
                            color="#0c690c"
                        />
                    </Flex>
                </Flex>

                <Flex borderBottom="1px solid rgba(0, 0, 0, 0.1)">
                    <Box
                        pt={4}
                        pb={2}
                        mr="auto"
                        fontSize="13px"
                        lineHeight={1.3}
                        fontWeight={500}
                    >
                        Access 200+ classes for your wellness journey
                    </Box>
                    <Flex
                        justify="center"
                        w="60px"
                        flexShrink={0}
                        pt={4}
                    ></Flex>
                    <Flex
                        flexShrink={0}
                        justify="center"
                        w="122px"
                        pt={'22px'}
                        pb={2}
                        bgColor="#e9f5e9"
                    >
                        <Box
                            as={RiCheckLine}
                            color="#0c690c"
                        />
                    </Flex>
                </Flex>

                <Flex borderBottom="1px solid rgba(0, 0, 0, 0.1)">
                    <Box
                        pt={4}
                        pb={2}
                        mr="auto"
                        fontSize="13px"
                        lineHeight={1.3}
                        fontWeight={500}
                    >
                        Daily live sessions with our top course authors
                    </Box>
                    <Flex
                        justify="center"
                        w="60px"
                        flexShrink={0}
                        pt={4}
                    ></Flex>
                    <Flex
                        flexShrink={0}
                        justify="center"
                        w="122px"
                        pt={'22px'}
                        pb={2}
                        bgColor="#e9f5e9"
                    >
                        <Box
                            as={RiCheckLine}
                            color="#0c690c"
                        />
                    </Flex>
                </Flex>

                <Flex borderBottom="1px solid rgba(0, 0, 0, 0.1)">
                    <Box
                        pt={4}
                        pb={2}
                        mr="auto"
                        fontSize="13px"
                        lineHeight={1.3}
                        fontWeight={500}
                    >
                        Challenges to achieve your wellness goals
                    </Box>
                    <Flex
                        justify="center"
                        w="60px"
                        flexShrink={0}
                        pt={4}
                    ></Flex>
                    <Flex
                        flexShrink={0}
                        justify="center"
                        w="122px"
                        pt={'22px'}
                        pb={2}
                        bgColor="#e9f5e9"
                    >
                        <Box
                            as={RiCheckLine}
                            color="#0c690c"
                        />
                    </Flex>
                </Flex>

                <Flex pb="16px">
                    <Box mr="auto"></Box>

                    <Flex
                        justify="center"
                        w="60px"
                        flexShrink={0}
                        pt={4}
                    ></Flex>

                    <VStack
                        gap="1px"
                        flexShrink={0}
                        justify="center"
                        w="122px"
                        pt={'22px'}
                        pb={4}
                        bgColor="#e9f5e9"
                        color="#0c690c"
                        fontSize="14px"
                        lineHeight={1}
                        borderBottomRadius="16px"
                    >
                        <Box>
                            <Text
                                as="span"
                                textDecor="line-through"
                                color="#cb3030"
                            >
                                $129
                            </Text>{' '}
                            <Text
                                as="span"
                                fontWeight={600}
                            >
                                ${MONTH_PRICE}
                            </Text>
                        </Box>
                        <Text
                            mb={0}
                            fontSize="10px"
                        >
                            per month
                        </Text>
                    </VStack>
                </Flex>
            </Box>
        </Box>
    );
}
