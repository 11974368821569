const breakpoints = {
    sm: '320px',
    '2sm': '480px',
    md: '768px',
    lg: '992px',
    lg2: '1000px',
    lg3: '1160px',
    xl: '1200px',
    '2xl': '1536px',
};

const containerSize = {
    '2': '16.66666667%',
    '10': '83.33333333%',
    sm: '320px',
    sm3: '560px',
    sm4: '640px',
    md: '720px',
    lg: '960px',
    lg2: '1000px',
    lg3: '1160px',
    xl: '1200px',
    '2xl': '1536px',
};

const fontSizes = {
    xs: '0.75rem',
    sm: '13px',
    sm2: '14px',
    sm3: '15px',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '2.5xl': '1.75rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '4.5xl': '2.5rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
    '7xl': '4.5rem',
    '8xl': '6rem',
    '9xl': '8rem',
};

const colors = {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    gray: {
        200: '#fbfbfb',
        225: '#f1f9f1',
        250: '#f0f6f4',
        300: '#f5f5f5',
        310: '#e8e8e8',
        325: '#f5f2f0',
        335: '#e9ecef',
        340: '#dadada',
        350: '#e6e6e6',
        360: '#dfe3e4',
        370: '#EAEAEA',
        375: '#e5e5e5',
        378: '#ececec',
        380: '#d8d5d4',
        400: '#c4c4c4',
        410: '#b8b8b8',
        415: '#d4d4d4',
        425: '#DFDFDF',
        427: '#9CA4AB',
        430: '#9E9E9E',
        450: '#999999',
        475: '#B0B0B0',
        500: '#808080',
        510: '#aeaeae',
        525: '#7a7a7a',
        550: '#5e5e5e',
        575: '#5a5a5a',
        600: '#4f4f4f',
        615: '#434343',
        625: '#464646',
        650: '#3b3b3b',
        675: '#333',
        700: '#2e2e2e',
        710: '#2C2C2C',
        725: '#1D1D1D',
        750: '#252525',
        800: '#1e1e1e',
        815: '#191919',
        825: '#131313',
    },
    green: {
        50: '#f2f9f2',
        200: '#E9F5E9',
        300: '#cfeccd',
        350: '#85e084',
        400: '#73c371',
        500: '#15983a',
        550: 'rgb(115 195 113 / 50%)',
        575: '#4E834D',
        600: 'rgba(23, 41, 31, 0.94)',
    },
    red: {
        200: '#ffdfdc',
        400: '#fa6d53',
        425: '#fd502a',
        450: '#EA2E2E',
        475: '#FF3347',
        500: '#ff3a44',
        525: '#ff0000',
        550: '#EE1818',
        600: '#de1a1a',
    },
    orange: {
        300: '#FFA236',
        600: '#ff5f00',
    },
    yellow: {
        300: '#fdf6e0',
        450: '#f9c000',
        500: '#f7d365',
        550: '#f6d365',
        575: '#f1d202',
        600: '#ffd000',
        800: '#FFA51E',
    },
    blue: {
        250: '#e6f3fa',
        300: '#ebf2fe',
        475: '#08c',
        500: '#2588e4',
        600: '#377bfb',
        700: '#0a58ca',
    },
    purple: {
        50: '#FAF5FF',
        100: '#F0E8FD',
        200: '#D6BCFA',
        450: '#8E4AFF',
        500: '#642EBD',
    },
    boxShadow: {
        primary: '0px 4px 20px 0px rgba(51, 51, 51, 0.1)',
        secondary: '0 4px 20px 0 rgba(128, 128, 128, 0.15)',
    },
};

const space = {
    4.25: '18px',
    7.5: '1.875rem',
    15: '3.75rem',
};

const sizes = { container: containerSize };

export { breakpoints, fontSizes, colors, space, sizes };
