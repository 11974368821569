import { useState, useEffect } from 'react';
import { Box, BoxProps, Flex, HStack, Text, Wrap } from '@chakra-ui/react';

import { ActionTypes, CustomSelect, DataPicker, LoadingContentText, Pagination } from '@/shared/ui';
import useFilterEvents from '../libs/useFilterEvents';
import { EventCardDate } from '@/entities/socials/events/event-card';

import { METHODS, fetchDataCb } from '@/shared/lib';
import { SelectOption } from '@/shared/models';
import { EventsAuthors } from '../models/types';

const AUTHORS_URL = '/api/events/authors';

function getAuthorList(arr: EventsAuthors[]): SelectOption[] {
    return arr.map((item: EventsAuthors) => ({
        label: item.name,
        value: item.name,
    }));
}

export default function EventsCatalog({ styles }: { styles?: BoxProps }) {
    const [authorsList, setAuthorsList] = useState<SelectOption[]>();

    const { eventsList, currentPage, setCurrentPage, isEventsLoading, setFilters } = useFilterEvents();

    const pageTotalCount = eventsList?.pagination.pagination_total_count;
    const pageSize = eventsList?.pagination.pagination_per_page;

    useEffect(() => {
        fetchDataCb(METHODS.GET, AUTHORS_URL, (data) => setAuthorsList(getAuthorList(data.data)));
    }, []);

    function onChangeSelect(value: any, { action }: any) {
        if (action === ActionTypes.Clear) {
            setFilters((state) => ({ ...state, name: null }));
            setCurrentPage(1);
        }
        if (action === ActionTypes.SelectOption) {
            setFilters((state) => ({ ...state, name: value.value }));
            setCurrentPage(1);
        }
    }

    function onPageChange(pageNumber: number) {
        setCurrentPage(pageNumber);

        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }, 0);
    }

    const setDate = (date: string) => {
        setFilters((prevFilters) => ({ ...prevFilters, date: date }));
        setCurrentPage(1);
    };

    return (
        <Box {...styles}>
            <Flex
                gap={{ base: 2.5, md: 6 }}
                wrap="wrap"
                mb={7.5}
            >
                <HStack spacing={4}>
                    <Text mb={0}>Authors:</Text>

                    <CustomSelect
                        instanceId="eventsFilter"
                        options={authorsList}
                        onChange={onChangeSelect}
                        isClearable={true}
                        noOptionsMessage={() => `No variants`}
                        loadingMessage={() => `Loading...`}
                        customStyles={{
                            control: {
                                minH: 'auto',
                                h: { base: '36px', lg: '40px' },
                                w: { base: '170px', lg: '200px' },
                                p: '2px',
                                pr: 2.5,
                                fontSize: { base: 'sm', lg: 'md' },
                            },
                        }}
                    />
                </HStack>

                <HStack spacing={4}>
                    <Text mb={0}>Date:</Text>

                    <DataPicker onDateChange={setDate} />
                </HStack>
            </Flex>

            <Wrap
                pos="relative"
                gap={6}
                minH="324px"
                mb={7.5}
            >
                {eventsList?.data.length && eventsList?.data?.length > 0 ? (
                    <>
                        {eventsList.data.map((item) => {
                            return (
                                <EventCardDate
                                    key={item.id}
                                    eventData={item}
                                    styles={{
                                        w: { md: '49%', xl: '32%' },
                                        h: 'auto',
                                    }}
                                />
                            );
                        })}
                    </>
                ) : (
                    <LoadingContentText isLoading={isEventsLoading}>There are no events</LoadingContentText>
                )}
            </Wrap>

            <Pagination
                currentPage={currentPage}
                pageSize={pageSize ?? 0}
                totalCount={pageTotalCount && pageTotalCount > 0 ? pageTotalCount : 0}
                onPageChange={onPageChange}
            />
        </Box>
    );
}
