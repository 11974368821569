import { useEffect, useRef } from 'react';
import {
    useDisclosure,
    Button,
    Portal,
    Text,
    VStack,
    Link,
    Box,
    Img,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    ModalCloseButton,
    ModalHeader,
    List,
    ListItem,
    ListIcon,
    useToast,
} from '@chakra-ui/react';

import { useAtomValue } from 'jotai';
import { CurrentStepEventNames } from '@/entities/quizes/quiz';
import { userAtom } from '@/shared/store';
import { CountdownGrid, CustomCheckbox, HTMLRenderer } from '@/shared/ui';
import { useCountdownReminigTime } from '@/features/promo/countdown';
import { Controller, useForm } from 'react-hook-form';
import { planOfCourseOfQuizAtom, quizSettingAtom } from '@/entities/quizes/quiz/model/stores/quiz-store';
import { HELLO_EMAIL, MONTH_PRICE } from '@/common/constants/brand';
import { ActionSubType, useCreateSub, useTrialDay } from '@/features/subscription/create-sub';
import { CourseQuizBuyBtnsPlan } from '@/entities/promo/classes-for-you/model/constants';
import { getActivityByNameInArr } from '@/shared/lib';

interface CourseQuizOnboardingBuyBtnProps {
    isCheckboxed?: boolean;
    isSkipBtnShow?: boolean;
    isInsideStickyBox?: boolean;
}

const sentences = [
    'Engage in Weekly Live sessions, where the spiritual journey unfolds in real-time, offering fresh insights and collective energies.',
    'Connect with the universe through Yoga.',
    "Celebrate your soul's rhythm with Dance and Singing.",
    'Experience healing touch with spiritual Massage techniques.',
    'Elevate your consciousness with mindfulness and brain exercises.',
];

const TIMER_COUNTDOWN_HOURS = 12;

interface Inputs {
    agree_terms: boolean;
}

export default function CourseQuizOnboardingBuyBtnCheckboxedHardText1dollar({
    isCheckboxed = true,
    isSkipBtnShow = true,
    isInsideStickyBox = false,
}: CourseQuizOnboardingBuyBtnProps) {
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            agree_terms: true,
        },
    });
    const toast = useToast();

    // Если пользователь уже был на onboarding то перенаправим при скипе в dashboard
    const user = useAtomValue(userAtom);
    const isAlreadyOnboarded =
        user && user?.quiz_info.quiz_thank_page_step_history.includes(CurrentStepEventNames.QuizOnboardingPageStep);
    const skipRedirectLink = isAlreadyOnboarded ? '/client' : '/redirect-to-onboarding-page';

    const { reminingTime } = useCountdownReminigTime(TIMER_COUNTDOWN_HOURS);

    const { isOpen: isOpenSkipPopup, onOpen: onOpenSkipModal, onClose: onCloseSkipModal } = useDisclosure();

    const quizSetting = useAtomValue(quizSettingAtom);

    const { isSubLoading, sendError, loadingText, createSub } = useCreateSub(
        getActivityByNameInArr<ActionSubType>(
            quizSetting?.options.activities,
            CourseQuizBuyBtnsPlan.ContinueWithTrial,
        )!,
    );
    const planOfCourseOfQuiz = useAtomValue(planOfCourseOfQuizAtom);
    useTrialDay(planOfCourseOfQuiz?.trialDays);

    // Наблюдаем за состоянием чекбокса
    const exampleCheckboxValue = watch('agree_terms');
    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        if (!exampleCheckboxValue && isCheckboxed) {
            // Вызываем функцию, если чекбокс не отмечен
            onOpenSkipModal();
        }
    }, [exampleCheckboxValue, isCheckboxed]);

    const onLinkClick = () => {
        window.location.pathname = skipRedirectLink;
    };

    const onBuySubBtnClick = async () => {
        await createSub();

        toast({
            duration: 5000,
            status: 'success',
            title: 'You have successfully subscribed to all the courses!',
            position: 'top-right',
        });

        setTimeout(() => {
            window.location.pathname = '/redirect-to-onboarding-page';
        }, 3000);
    };

    return (
        <>
            <VStack w={{ base: '100%', lg: '340px' }}>
                {isCheckboxed ? (
                    <Controller
                        name="agree_terms"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }: TObj<any>) => {
                            return (
                                <CustomCheckbox
                                    checkboxProps={{
                                        isChecked: Boolean(value),
                                        onChange: onChange,
                                        isInvalid: Boolean(errors.agree_terms),
                                    }}
                                    LabelEl={() => (
                                        <Box>
                                            <Text
                                                display="inline"
                                                fontWeight={700}
                                                color={errors.agree_terms ? 'red' : 'gray.675'}
                                            >
                                                Get {planOfCourseOfQuiz?.trialDays} days of full access to 200+
                                                BestSellers & Daily Live Sessions for just $1!
                                            </Text>
                                        </Box>
                                    )}
                                />
                            );
                        }}
                    />
                ) : null}

                <Button
                    w="100%"
                    maxW="360px"
                    fontSize="sm2"
                    onClick={
                        exampleCheckboxValue
                            ? onBuySubBtnClick
                            : () => (window.location.pathname = '/redirect-to-onboarding-page')
                    }
                    isLoading={isSubLoading}
                    loadingText={loadingText}
                >
                    Continue to the course
                </Button>

                <Text
                    mb={0}
                    fontSize={{ base: 'sm', md: 'sm3' }}
                    textAlign="center"
                >
                    {planOfCourseOfQuiz?.trialDays} days for $1, then $
                    {planOfCourseOfQuiz?.price && parseInt(planOfCourseOfQuiz.price, 10)}/month. Cancel anytime
                </Text>

                {sendError && (
                    <Text
                        mb={0}
                        textAlign="center"
                        color="red.500"
                    >
                        {sendError}
                    </Text>
                )}

                {!isInsideStickyBox && (
                    <Box
                        color={'gray.450'}
                        fontSize={'14px'}
                    >
                        <Text>
                            - By clicking the «Continue to the course» button with the box checked, I agree to start my
                            $1 trial.
                        </Text>
                        <Text>
                            - My card will be charged $1 today. After the {planOfCourseOfQuiz?.trialDays}-day trial
                            period, ${planOfCourseOfQuiz?.price} will be automatically billed to my card.
                        </Text>
                        <Text>
                            - The subscription will renew automatically each month until I cancel the trial or
                            subscription.
                        </Text>
                        <Text>
                            - I can cancel my trial or subscription anytime through my account settings or by reaching
                            out to the support team at <Link href={`mailto:${HELLO_EMAIL}`}>{HELLO_EMAIL}</Link>.
                        </Text>
                        <Text>
                            - If I click the «Continue to the course» button without checking the box, I will proceed
                            and gain access only to my course.
                        </Text>
                    </Box>
                )}
            </VStack>

            <Portal>
                <Modal
                    variant="top-block"
                    scrollBehavior="inside"
                    isOpen={isOpenSkipPopup}
                    onClose={onCloseSkipModal}
                    isCentered
                >
                    <ModalOverlay />

                    <ModalContent>
                        <ModalCloseButton />

                        <ModalHeader
                            background="url(/img/bg/blur-pink-blue-hor.png) no-repeat 50% 50%"
                            backgroundSize="cover"
                            color="white"
                        >
                            <CountdownGrid
                                timerSeconds={reminingTime}
                                styles={{ justifyContent: 'center' }}
                            />

                            <Text
                                textStyle="lg"
                                mt={5}
                                mb={2.5}
                            >
                                Think that 7 days is not enough?
                            </Text>

                            <Text
                                textStyle="md6"
                                fontWeight={700}
                                mb={0}
                            >
                                Try this One Time Offer with{' '}
                                <Text
                                    as="span"
                                    pos="relative"
                                    mb={0}
                                    display={{ base: 'inline-block', md: 'inline' }}
                                >
                                    30-Day Free Trial
                                    <Img
                                        src="/img/elements/ranbow-line.svg"
                                        alt="ranbow-line"
                                        pos="absolute"
                                        top={{ base: '18px', md: '20px' }}
                                        left={0}
                                        w="100%"
                                        h="100%"
                                    />
                                </Text>
                            </Text>
                        </ModalHeader>

                        <ModalBody>
                            <Box>
                                <Text>
                                    Unlock a{' '}
                                    <Text
                                        as="span"
                                        fontWeight={700}
                                    >
                                        30-day free trial
                                    </Text>{' '}
                                    with access to 200+ recorded and live programs. After, it&#39;s{' '}
                                    <Text
                                        as="span"
                                        fontWeight={700}
                                    >
                                        only ${MONTH_PRICE}
                                        /month
                                    </Text>
                                    . Enjoy a complete self-recovery retreat from the comfort of your own home, no
                                    matter where you are:
                                </Text>

                                <List
                                    display="flex"
                                    flexDir="column"
                                    gap={{ base: 2.5, md: 3 }}
                                >
                                    {sentences.map((text, index) => {
                                        return (
                                            <ListItem
                                                display="flex"
                                                flexDir="row"
                                                alignItems="baseline"
                                                gap={2.5}
                                                key={index}
                                            >
                                                <ListIcon
                                                    as={Img}
                                                    src="/img/icons/common/arrow-mark.svg"
                                                    alt="arrow-mark"
                                                />
                                                <HTMLRenderer text={text} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                w={{ md: '100%' }}
                                onClick={onBuySubBtnClick}
                                isLoading={isSubLoading}
                                loadingText={loadingText}
                            >
                                OPEN ACCESS NOW
                            </Button>

                            {sendError && (
                                <Text
                                    mb={0}
                                    textAlign="center"
                                    color="red.500"
                                >
                                    {sendError}
                                </Text>
                            )}

                            {isSkipBtnShow && (
                                <Link
                                    href={skipRedirectLink}
                                    textAlign="center"
                                    color="gray.525"
                                    textDecoration="underline"
                                >
                                    Skip this one time offer
                                </Link>
                            )}
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Portal>
        </>
    );
}
