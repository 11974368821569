import { Box, BoxProps, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';

import { afterPaymentStepsMock } from '../../model/afterPaymentSteps.mock';

export default function AfterPaymentSteps({ styles }: { styles?: BoxProps }) {
    return (
        <Box
            py={{ base: '40px', lg: '80px' }}
            borderRadius="40px"
            bgColor="#5224ba"
            bgImage={{
                base: 'url(/img/bg/cart-gradient-mobile.png)',
                lg: 'url(/img/bg/cart-gradient-desktop.png)',
            }}
            bgRepeat="no-repeat"
            bgSize="cover"
            boxShadow="inset 0 0 60px 0 rgba(255, 255, 255, 0.26)"
            color="white"
            overflow="hidden"
            {...styles}
        >
            <Container>
                <Heading as="h4">After payment, you can immediately start practicing</Heading>

                <Flex
                    flexDir={{ base: 'column', lg: 'row' }}
                    gap={{ base: 2, lg: 4 }}
                >
                    {afterPaymentStepsMock.map(({ number, text }) => (
                        <Flex
                            key={number}
                            flexDir={'column'}
                            justify="space-between"
                            gap={5}
                            px={4}
                            py={6}
                            w={{ lg: 'calc(33% - 8px)' }}
                            h={{ lg: '235px' }}
                            bgColor="rgba(255, 255, 255, 0.08)"
                            boxShadow="inset 0 0 30px 0 rgba(255, 255, 255, 0.2)"
                            borderRadius="20px"
                            fontSize={{ base: '14px', lg: '16px' }}
                        >
                            <Image
                                src={`/img/icons/numbers/${number}.png`}
                                alt={number.toString()}
                                w={{ base: '40px', lg: '60px' }}
                            />
                            <Text mb={0}>{text}</Text>
                        </Flex>
                    ))}
                </Flex>
            </Container>
        </Box>
    );
}
