import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';

import { CountdownGrid, IconMap, IconNames, Picture, PictureType } from '@/shared/ui';
import { PricesFreeDays } from '@/entities/promo/prices';

export default function QuizBannerCountdown({
    timerSeconds,
    styles,
    BtnEl,
}: {
    timerSeconds: number;
    styles?: BoxProps;
    BtnEl?: () => JSX.Element;
}) {
    return (
        <Flex
            direction={{ base: 'column', lg: 'row' }}
            justify="center"
            overflow="hidden"
            px={{ base: 5, lg: '80px' }}
            pt={{ base: 10, lg: '0' }}
            background={{
                base: 'url(/img/bg/blur-blue-to-blue-ver.png) no-repeat 50% 50%',
                lg: 'url(/img/bg/blur-pink-to-blue-hor.png) no-repeat 50% 50%',
            }}
            backgroundSize={{ base: 'cover', lg: 'cover' }}
            borderRadius={{ lg: 'xl' }}
            {...styles}
        >
            <Box
                hideBelow="lg"
                flexGrow={1}
                alignSelf="flex-end"
                maxW="282px"
            >
                <Picture
                    src="promo/people/yoga-woman-stand.png"
                    type={PictureType.Simple2x}
                    alt="yoga-woman-stand"
                    pictureStyle={{
                        display: 'block',
                        h: { base: '100%', lg: '90%' },
                        mt: 'auto',
                    }}
                    imgStyle={{
                        h: 'inherit',
                        objectFit: 'cover',
                    }}
                />
            </Box>

            <Flex
                direction="column"
                align="center"
                py={{ lg: 7.5 }}
                mb={{ base: 5, lg: 0 }}
                maxW="525px"
                alignSelf="center"
            >
                <CountdownGrid
                    timerSeconds={timerSeconds}
                    styles={{ mx: 'auto', mb: 4 }}
                />

                <Text
                    textStyle="lg6"
                    lineHeight={1.2}
                    textAlign="center"
                    color="white"
                >
                    Start your personalized learning journey with over 200 courses and daily live events
                </Text>

                <Box pos="relative">
                    <Box
                        as={IconMap[IconNames.PointingArrowColorful]}
                        pos="absolute"
                        top="-2px"
                        right="-60px"
                        display={BtnEl ? 'block' : 'none'}
                        hideBelow="lg"
                        w="38px"
                        h="36px"
                        color="white"
                    />

                    {BtnEl && <BtnEl />}

                    <PricesFreeDays />
                </Box>
            </Flex>

            <Box
                flexGrow={1}
                maxW={{ lg: '300px' }}
                alignSelf="flex-end"
                pos="relative"
            >
                <Picture
                    src="promo/people/yoga-girl-calm.png"
                    type={PictureType.Simple2x}
                    alt="yoga-girl-calm"
                />
            </Box>
        </Flex>
    );
}
