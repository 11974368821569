export enum TooltipTag {
    Welcome = 'welcome',
    ContinueCourses = 'course_page',
    CourseRecommendation = 'course_recommendation',
    Challenges = 'challenge_page',
    Events = 'live_events',
    TimeZone = 'time_zone_events',
    MissedEvents = 'missed_events',
    ExploreCourses = 'explore_courses',
    SupportCenter = 'help',
    SupportCenterMobile = 'help_mobile',
    ProfilePicture = 'profile_picture',
    MergeAccount = 'merge_account',
}

type ElementId = string;

export const TOOLTIP_TAG_ELEMENT_ID_MAP: Record<TooltipTag, ElementId> = {
    [TooltipTag.Welcome]: '',
    [TooltipTag.ContinueCourses]: 'continue-courses-tooltip-anchor',
    [TooltipTag.CourseRecommendation]: 'courses-recommened-for-you',
    [TooltipTag.Challenges]: 'challengeTabs',
    [TooltipTag.Events]: 'events-tooltip-anchor',
    [TooltipTag.TimeZone]: 'events-timezone-tooltip-anchor',
    [TooltipTag.MissedEvents]: 'events-tabs-tooltip-anchor',
    [TooltipTag.ExploreCourses]: 'courses-tooltip-anchor',
    [TooltipTag.SupportCenter]: 'support_link',
    [TooltipTag.SupportCenterMobile]: 'nav-btn-tooltip-anchor',
    [TooltipTag.ProfilePicture]: 'user-avatar-tooltip-anchor',
    [TooltipTag.MergeAccount]: 'merge-with-google-tooltip-anchor',
};

export const TOOLTIP_PATH_CONTEXT_MAP = {
    '/client': 'dashboard',
    '/client/profile': 'profile',
};
