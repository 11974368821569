import { Box, BoxProps, Flex, Img, Text } from '@chakra-ui/react';

import { IconMap, IconNames, Picture, PictureType } from '@/shared/ui';

export default function QuizBannerFirst({ styles, BtnEl }: { styles?: BoxProps; BtnEl?: () => JSX.Element }) {
    return (
        <Flex
            direction={{ base: 'column', md: 'row' }}
            justify="center"
            px={{ base: 5, md: 0 }}
            pt={{ base: 10, md: 7.5 }}
            background={{
                base: 'url(/img/bg/blur-pink-to-yellow-ver.png) no-repeat 50% 50%',
                md: 'url(/img/bg/blur-blue-to-yellow-hor.png) no-repeat 50% 50%',
            }}
            backgroundSize={{ base: 'cover', md: 'cover' }}
            {...styles}
        >
            <Box
                order={{ base: 1, md: 0 }}
                flexGrow={1}
                maxW={{ md: '282px' }}
                pos="relative"
            >
                <Picture
                    src="promo/people/tablet-man-happy.png"
                    type={PictureType.Simple2x}
                    alt="tablet-man-happy"
                    pictureStyle={{
                        zIndex: 20,
                        pos: 'relative',
                        display: 'block',
                        h: '100%',
                    }}
                    imgStyle={{
                        mx: 'auto',
                        h: '100%',
                        objectFit: 'cover',
                    }}
                />

                <Img
                    src="/img/icons/figures/ellipsis-light.svg"
                    alt="elipses-light"
                    hideFrom="md"
                    pos="absolute"
                    w="calc(100% + 40px)"
                    top={0}
                    left={0}
                    zIndex={10}
                />
            </Box>

            <Flex
                direction="column"
                align="center"
                pb={{ md: 10 }}
                maxW="525px"
                alignSelf="center"
            >
                <Text
                    textStyle="xl3"
                    lineHeight={1.2}
                    textAlign="center"
                    color="white"
                >
                    Customize Your Learning Journey
                </Text>

                <Box pos="relative">
                    <Box
                        as={IconMap[IconNames.PointingArrow]}
                        pos="absolute"
                        top="-2px"
                        right="-60px"
                        display={BtnEl ? 'block' : 'none'}
                        hideBelow="md"
                        w="38px"
                        h="36px"
                        color="white"
                    />

                    {BtnEl && <BtnEl />}
                </Box>
            </Flex>

            <Box
                hideBelow="md"
                flexGrow={1}
                maxW="282px"
            >
                <Picture
                    src="promo/people/phone-girl-happy.png"
                    type={PictureType.Simple2x}
                    alt="phone-girl-happy"
                    pictureStyle={{
                        display: 'block',
                        h: '100%',
                    }}
                    imgStyle={{
                        h: '100%',
                        objectFit: 'cover',
                    }}
                />
            </Box>
        </Flex>
    );
}
