const baseStyle = {
    filledTrack: {
        h: '20px',
        background: 'linear-gradient(90deg,#f2f3bc 28.54%,#f96),#f7d365',
        borderRadius: 'xl',
        transition: 'all ease-in .25s',
    },
    track: {
        minH: '20px',
        bg: 'gray.335',
        borderRadius: 'xl',
    },
};

const cardVariant = {
    filledTrack: {
        h: '5px',
        background: 'green.400',
        borderRadius: 0,
    },
    track: {
        minH: '5px',
        maxH: '5px',
        background: 'transparent',
        borderRadius: 0,
    },
};

const cardRoundedVariant = {
    filledTrack: {
        h: '5px',
        background: 'green.400',
        borderRadius: '16px',
    },
    track: {
        minH: '5px',
        maxH: '5px',
        background: '#dde1e8',
        borderRadius: '16px',
    },
};

const cardRoundedMdVariant = {
    filledTrack: {
        h: '20px',
        background: 'green.400',
        borderRadius: '16px',
    },
    track: {
        minH: '20px',
        maxH: '20px',
        background: '#dde1e8',
        borderRadius: '16px',
    },
};

const progressTheme = {
    baseStyle,
    variants: {
        card: cardVariant,
        'card-rounded': cardRoundedVariant,
        'card-rounded-md': cardRoundedMdVariant,
    },
};

export { progressTheme };
