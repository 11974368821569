import { Center, Spinner } from '@chakra-ui/react';

import useCheckoutPage from '../model/useCheckoutPage';
import CheckoutCartView from './components/CheckoutCartView';

export default function CheckoutPageComponent() {
    const {
        currentCourse,
        checkoutType,
        totalCost,
        checkedItems,
        optionalCourse,
        onDeleteItem,
        onAddTrial,
        onAddOptionalCourse,
    } = useCheckoutPage();

    return checkedItems.length > 0 ? (
        <CheckoutCartView
            totalCost={totalCost}
            checkedItems={checkedItems}
            onDeleteItem={onDeleteItem}
            onAddTrial={onAddTrial}
            optionalCourse={optionalCourse}
            onAddOptionalCourse={onAddOptionalCourse}
        />
    ) : (
        <Center
            minH="400px"
            w="100%"
        >
            <Spinner />
        </Center>
    );
}
