import { SortComments } from '@/features/comments/video/model/comments-video.const';

export enum UTM_SOURCES {
    Facebook = 'facebook',
    Instagram = 'instagram',
    Inst = 'inst',
    Insta = 'insta',
    Mailchimp = 'mailchimp',
}

export const UTM_SOURCES_LIST = [
    UTM_SOURCES.Facebook,
    UTM_SOURCES.Instagram,
    UTM_SOURCES.Inst,
    UTM_SOURCES.Insta,
    UTM_SOURCES.Mailchimp,
];
export const GOOGLE_AUTH_ENABLED_BRANDS = ['sacrill', 'newmindstart'];

export const BREAKPOINTS = {
    MD: 768,
    LG: 1024,
    XL: 1200,
};

export const URLS = {
    API:
        typeof process !== 'undefined' && process.env.NEXT_PUBLIC_API_HOST
            ? process.env.NEXT_PUBLIC_API_HOST
            : window.location.origin,
    EVENT: '/api/amplitude/send-event',
    USER: '/api/users/me?expand=is_subscribe,is_user_subscription,subscription_plan,subscription_trial_days,subscription,is_add_payment_card,quiz_info,is_personal_tracker,continue_watching_course,tooltip_info,fb_pixel_start_trial_data,is_course_purchase,confirmation_events,is_add_trial_period,role,course_purchased',
    GOOGLE_AUTH_REDIRECT: '/auth/google?service=vocal-yoga-sub-step-8-landing-course-collection',
    SEND_PROSPECTIVE: '/api/users/prospective/send-mailchimp',
    QUIZ_THANK_PAGE_ADD_STEP: '/api/users/quiz-thank-page/add-step',
    QUIZ_THANK_PAGE_ADD_OPTIONAL_DETAILS: '/api/users/quiz-thank-page/add-optional-details',
    BUY_TRACKER: '/api/users/personal-tracker/buy',
    USER_SUB_PATH: '/api/users/subscription',
    USERS_DATA: '/api/users',
    CHECKOUT_PAYMENT: '/api/checkout/payment',
    PACKAGE_INFO_COURSE_WATCH: '/api/users/package-info/page/course-watch',
    WISHLIST: '/api/courses/watch-later',
    CONFIRM_EMAIL: '/api/users/me/update-email',
    UPDATE_PHONE: '/api/users/me/update-phone',
    UPDATE_PROFILE: '/api/users/profile/quiz',
    UPDATE_AVATAR: '/api/users/me/update-avatar',
    EVENT_PURCHASE_ATTEMPT: '/api/users/event-purchase-attempt',
    MEETING_CALENDLY: '/api/users/meeting-calendly',
    MEETING_CALENDLY_WAITLIST: '/api/users/meeting-calendly/is-waitlisted',
    ONE_TIME_PURCHASE: '/api/users/one-time-purchase/buy',
    AUTH_BY_ORDER: '/api/users/auth-by-order',
    CHECKOUT_CREATE_PAYMENT: '/api/checkout/payment-method/create',
    CHECKOUT_CREATE_FORM: '/api/checkout/payment-method/create/form',
    // POST - отправка имейла для отмены подпсики
    UNSUB_POST_EMAIL: '/api/users/cancel-confirmation',
    // PUT - подписку на паузу
    SUB_PAUSE: '/api/users/subscription/pause',
    // PUT - подписка со скидкой
    SUB_DISCOUNT: '/api/users/subscription/update-discount',
    // PUT - продление триала
    SUB_ADD_TRIAL: '/api/users/subscription/add-trial',
    // POST - причина отписки
    SUB_CANCEL_REASON: '/api/users/subscription/cancel-reason',
    // POST - отписка
    SUB_CANCEL: '/api/users/subscription/cancel',
    CANCEL_TRIAL: '/api/users/subscription/cancel-trial',
    SKIP_ORDER_SUB: '/skip-order-subscription',
    ALL_COURSE:
        '/api/courses?expand=thumb_big,is_discount,is_purchased,course_card_discount,course_card_price,currency&search[lang]=en',
    ACTIVITY_TRACKER_USER: '/api/users/activity-tracker',
    getCoursePath: (arr: number[]) =>
        `/api/courses?expand=thumb_big,is_discount,is_purchased,course_card_discount,course_card_price,currency&search[collection]=${arr.join(',')}`,
    getAuthYogaRedirect: (email: string) => `/vocal-yoga-subscription/step-8-lending-course-collect?email=${email}`,
    getProspective: (email: string) => `/api/users/prospective?email=${email}`,
    getRecomendedCourses: (type: string, courseId: number) =>
        `/api/courses/recommendations?expand=thumb_big,is_purchased&type=${type}&playing_course_id=${courseId}`,
    getVideoTrouble: (courseId: number) => `/api/courses/${courseId}/trouble-video`,
    getCourseBySlug: (slug: string) =>
        `/api/courses/by-slug/${slug}?expand=thumb_big,price,optional_courses,playlist_sections,authors,thumb_big,promo_blocks_resource,collections,rating_user`,
    getAllCourse: (pageNumber: number) =>
        `/api/courses?expand=thumb_big,is_discount,is_purchased,course_card_discount,course_card_price,currency&search[lang]=en&per-page=10&page=${pageNumber}`,
    getAllCourseByName: (courseName: string, pageNumber: number) =>
        `/api/courses?expand=thumb_big,is_discount,is_purchased,course_card_discount,course_card_price,currency&search[name]=${courseName}&per-page=10&page=${pageNumber}`,
    getCourseById: (id: number) =>
        `/api/courses?expand=buyers_preview,price_addition_course,thumb_big,is_discount,is_purchased,course_card_discount,course_card_price,currency,is_watching,optional_courses&search[id]=${id}`,
    getCoursesByCollection: (collectionIds: number[]) =>
        `/api/courses?sort=lang&expand=thumb_big,video_intro,is_discount,is_purchased,course_card_discount,course_card_price,currency&search[collection]=${collectionIds.join(',')}`,
    getAuthorMeeting: (courseId: number) => `/api/users/author-meeting-by-course/${courseId}`,
    getChallenges: (isUser: boolean = false) =>
        `/api/challenges?scena=dashboard${isUser ? '&search[me]=true' : '&search[active]=true'}`,
    getChallenge: (challengeId: number) => `/api/challenges/${challengeId}?scena=dashboard`,
    /*
        добавление поста для challenge

        POST
        PUT
        {
            "content": "Этот коммент добавлен через api",
            "is_public": true
        }
        DELETE
    */
    getChallengePost: (challengeId: number) => `/api/challenges/${challengeId}/post`,
    //
    /*
        добавление поста для таска челленджа

        POST
        PUT
        {
            "content": "Этот коммент добавлен через api",
            "is_public": true
        }
        DELETE
    */
    getChallengeTaskPost: (taskId: number) => `/api/challenges/task/${taskId}/post`,
    // DELETE удаление коммента под постом юзера
    getRemoveUserTaskComment: (taskId: number) => `/api/challenges/post/${taskId}/comments`,
    /*
        добавление комментария к посту

        POST
        {
            "comment": "Этот коммент добавлен через api"
        }
    */
    getAddChallengeComment: (challengeId: number) => `/api/challenges/post/${challengeId}/comment`,
    // DELETE удаление коммента под постом
    getRemoveTaskComment: (taskId: number, commentId: number) => `/api/challenges/post/${taskId}/comments/${commentId}`,
    /*
        загрузка картинки для коммента

        POST
        {
            "file": "image.png"
        }
    */
    getAddPostImg: (postId: number) => `/api/challenges/post/${postId}/upload-image`,
    // удаление картинки
    getRemovePostImg: (postId: number) => `/api/challenges/post/${postId}/delete-image`,
    // загрузка видео
    getAddVideoPost: (postId: number) => `/api/challenges/post/${postId}/upload-video`,
    getRemoveVideoPost: (postId: number) => `/api/challenges/post/${postId}/delete-video`,
    // присоединение юзера к челленджу
    getAddUserToChallenge: (challengeId: number) => `/api/challenges/${challengeId}/bind-user`,
    // отмечает таск как 100% пройденным
    getTaskComplete: (postId: number) => `/api/challenges/task/${postId}/complete`,
    getQuizData: (quizType: number) => `/api/quiz/type-info/${quizType}`,
    // POST - чтобы сказать, что ивент выполнен
    getEventInteract: (eventId: number) => `/api/users/confirmation-event/${eventId}/interact`,
    // GET - получить план доместики
    getDomestikaPackage: (courseId: number) => `/api/users/package-info/page/payment-checkout-domestika/${courseId}`,
    // GET получение уроков по курсу с комментариями
    getCourseLessons: (courseId: number, pageNumber: number, lessonName?: string) =>
        `/api/courses/lessons?search[course_id]=${courseId}${lessonName ? `&search[name]=${lessonName}` : ``}&expand=comments&per-page=10&page=${pageNumber}`,
    // GET получение данных урока по его id
    getCourseLesson: (lessonId: number) => `/api/courses/lessons/${lessonId}?expand=rating`,
    // POST оценка урока и курса
    // {
    //   "rating":4
    // }
    getLessonRating: (lessonId: number) => `/api/courses/lesson/${lessonId}/rating`,
    getCourseRating: (courseId: number) => `/api/courses/${courseId}/rating`,
};

export const URLS_COMMENTS = {
    // GET получение неодобренных комментариев
    UNAPPROVED_COMMENTS: `/api/comments?search[approved]=0&expand=object`,
    COMMENTS: `/api/comments?search[approved]=1&sort=-created_at&expand=object,author`,
    // GET получение списка стоп слов
    // POST { word: string; locale: string }
    STOP_WORDS_COMMENT: `/api/comments/stop-words`,
    // DELETE - удаление комментариев
    // {
    //     "comment_ids": [
    //         10888,10889
    //     ]
    // }
    DELETE_COMMENTS: `/api/comments/multiple`,
    // POST - одобрение комментариев
    // {
    //     "comment_ids": [
    //         10888,10889
    //     ]
    // }
    APPROVED_COMMENTS: `/api/comments/approve/multiple`,
    getCommentsByCourse: (courseId: number, pageNumber: number, sortType: string) =>
        `/api/comments?search[course_id]=${courseId}${sortType === SortComments.Top ? '&search[popular]=true' : ''}${sortType === SortComments.Newest ? `&sort=-${sortType}` : ''}&search[approved]=1&expand=author,replies,is_liked_by_user,is_approved,like_count,is_reply&per-page=10&page=${pageNumber}`,
    getCommentsByLesson: (lessonId: number, pageNumber: number, sortType: string) =>
        `/api/comments?search[lesson_id]=${lessonId}${sortType === SortComments.Top ? '&search[popular]=true' : ''}${sortType === SortComments.Newest ? `&sort=-${sortType}` : ''}&expand=author,replies,is_liked_by_user,is_approved,like_count,is_reply&per-page=10&page=${pageNumber}`,
    // DELETE - удаление комментария
    getFetchComment: (commentId: number) => `/api/comments/${commentId}`,
    // DELETE - удаление стоп слов
    getDeleteStopWord: (wordId: number) => `/api/comments/stop-words/${wordId}`,
    // GET получение данных урока по его id
    getLessonComments: (lessonId: number) => `/api/courses/lessons/${lessonId}?expand=comments`,
    // POST добавление комментария к уроку
    getPostLessonComment: (lessonId: number) => `/api/courses/lesson/${lessonId}/comment`,
    // DELETE удаление комментария к уроку
    getDeleteLessonComment: (lessonId: number, commentId: number) =>
        `/api/courses/lesson/${lessonId}/comment/${commentId}`,
    // POST отправка комментария к курсу
    getCourseComment: (courseId: number) => `/api/courses/${courseId}/comment`,
    // DELETE удаление комментария к курсу
    getDeleteCourseComment: (courseId: number, commentId: number) => `/api/courses/${courseId}/comment/${commentId}`,
    // POST отправка реплая к комментарию
    // DELETE удаление реплая к комментарию
    getFetchLessonCommentReply: (commentId: number) => `/api/comments/${commentId}/reply `,
    // POST добавить лайк к комментарию
    // DELETE удалить лайк к комментарию
    getFetchLessonCommentLike: (commentId: number) => `/api/comments/${commentId}/like `,
};

export const URLS_COURSES = {};

export const TOKENS = {
    CSRF:
        (typeof window !== 'undefined' && document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')) ||
        '',
    USER:
        (typeof window !== 'undefined' && (document.querySelector('meta[name="X-Token"]') as any)?.content) ||
        (typeof process !== 'undefined' && process.env.USER_TOKEN_DEV) ||
        '',
};
