import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const colorfulVariant = definePartsStyle((props) => {
    const { colorScheme: c } = props; // extract colorScheme from component props

    return {
        tab: {
            border: '2px solid',
            borderColor: 'transparent',
            // use colorScheme to change background color with dark and light mode options
            bg: mode(`${c}.300`, `${c}.600`)(props),
            borderTopRadius: 'lg',
            borderBottom: 'none',

            _selected: {
                bg: mode('#fff', 'gray.800')(props),
                color: mode(`${c}.500`, `${c}.300`)(props),
                borderColor: 'inherit',
                borderBottom: 'none',
                mb: '-2px',
            },
        },
        tablist: {
            borderBottom: '2x solid',
            borderColor: 'inherit',
        },
        tabpanel: {
            border: '2px solid',
            borderColor: 'inherit',
            borderBottomRadius: 'lg',
            borderTopRightRadius: 'lg',
        },
    };
});

const primaryVariant = definePartsStyle({
    tab: {
        flexShrink: 0,
        px: 5,
        py: { base: '12.5px', md: '15px' },
        borderRadius: 'xl',
        bg: 'gray.225',
        fontSize: 'sm',

        _selected: {
            bg: 'green.400',
            color: '#fff',
        },

        '&[aria-selected=false]:hover': {
            opacity: 0.7,
        },
    },
    tablist: {
        gap: 2.5,
    },
    tabpanels: {
        mt: 7.5,
    },
});

const spacelessVariant = definePartsStyle({
    tab: {
        zIndex: 20,
        py: 2.5,
        px: 0,
        minW: { base: '90px', md: '150px' },
        borderRadius: 'xl',
        fontSize: { base: 'sm', md: 'md' },

        _selected: {
            color: 'white',
        },

        _disabled: {
            color: 'gray.500',
        },
    },
    tablist: {
        pos: 'relative',
        gap: 0,
        w: 'fit-content',
        borderRadius: 'xl',
        border: '1px solid',
        borderColor: 'green.400',
    },
    tabpanel: {
        display: 'flex',
        gap: 5,
        h: '100%',
        px: 0,
    },
});

const skeuomorphVariant = definePartsStyle({
    tab: {
        zIndex: 20,
        py: 2,
        px: 0,
        minW: { base: '90px', md: '150px' },
        borderRadius: 'xl',
        fontWeight: 700,
        fontSize: { base: '14px', md: 'md' },
    },
    tablist: {
        bgColor: '#F1F2F4',
        pos: 'relative',
        gap: 0,
        w: 'fit-content',
        py: 1,
        px: 2,
        borderRadius: '48px',
    },
    tabpanel: {
        display: 'flex',
        gap: 5,
        h: '100%',
        px: 0,
    },
});

const darkVariant = definePartsStyle({
    tab: {
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        borderRadius: '8px',
        px: 4,
        py: '9px',
        fontFamily: '"Golos Text", "Rubik", serif',
        lineHeight: 1,
        cursor: 'pointer',
        color: '#c1c1c1',
        bgColor: '#252525',

        svg: {
            w: '20px',
            h: '20px',
            color: '#c1c1c1',
        },

        _hover: {
            bgColor: '#535353',
            color: '#fff',

            svg: {
                color: '#fff',
            },
        },

        _selected: {
            color: 'white',
            border: '1px solid #f2a943',

            svg: {
                color: '#f2a943',
            },
        },
    },
    tablist: {
        display: 'flex',
        gap: 2,
        justifyContent: { md: 'center' },
    },
    tabpanel: {},
});

const blueVariant = definePartsStyle({
    tab: {
        borderRadius: '12px',
        px: 4,
        py: '8px',
        fontFamily: '"Jeko", "Rubik", serif',
        lineHeight: 1,
        fontWeight: 800,
        fontSize: '13px',
        cursor: 'pointer',
        color: '#525dd0',
        bgColor: '#fff',

        _hover: {
            color: 'rgba(82, 93, 208, 0.7)',
        },

        _selected: {
            color: 'white',
            background: '#525dd0',
        },
    },
    tablist: {
        display: 'flex',
        gap: 2,
    },
    tabpanel: {},
});

export const tabsTheme = defineMultiStyleConfig({
    variants: {
        colorful: colorfulVariant,
        primary: primaryVariant,
        spaceless: spacelessVariant,
        skeuomorph: skeuomorphVariant,
        dark: darkVariant,
        blue: blueVariant,
    },
    defaultProps: {
        variant: 'primary',
    },
});
