import { createRoot } from 'react-dom/client';

import ProvidersCsr from '../../providers/providers-csr';

import { ActionSubType } from '@/features/subscription/create-sub';
import CsrPaywallWrapper from '@/entities/csr/csr-paywall-wrapper/CsrPaywallWrapper';
import { csrStore, paywallOpenStateAtom, paywallCourseName } from '@/entities/csr/store';
import { TooltipTutorial } from '@/entities/tooltip-tutorial';

/* очистим данные для пользователя и загрузим новые*/
sessionStorage.removeItem('user');
sessionStorage.removeItem('fetchingUser');

// Пейволл на карточке курса
const paywallContainerNode = document.getElementById('paywallContainer');

if (paywallContainerNode) {
    const subAction = paywallContainerNode.getAttribute('data-sub-action') as ActionSubType;
    // Управляем состоянием через глобальный стор
    csrStore.set(paywallOpenStateAtom, false);

    const root = createRoot(paywallContainerNode);

    root.render(
        <ProvidersCsr>
            <CsrPaywallWrapper actionSubType={subAction} />
        </ProvidersCsr>,
    );

    const paywallOpenNodes = document.querySelectorAll('.paywall-open-el');

    if (paywallOpenNodes) {
        for (const paywallOpenEl of paywallOpenNodes) {
            paywallOpenEl.addEventListener('click', () => {
                const courseName = paywallOpenEl.getAttribute('data-course-name')!;

                csrStore.set(paywallCourseName, courseName);
                csrStore.set(paywallOpenStateAtom, true);
            });
        }
    }
}

// Онбординг в дашборде/профиле
const tooltipTutorialNode = document.getElementById('tooltipTutorial');
if (tooltipTutorialNode) {
    const root = createRoot(tooltipTutorialNode);

    root.render(
        <ProvidersCsr>
            <TooltipTutorial />
        </ProvidersCsr>,
    );
}
