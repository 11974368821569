import { useEffect, useState } from 'react';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';

import { URLS } from '@/common/constants/global';
import { RecommendedCourseTypes } from '@/entities/courses/lists/recommended/recommended-courses';
import { fetchData } from '@/shared/lib';
import { userAtom } from '@/shared/store';
import { useEventsList } from '@/features/socials/events/get-events';
import { LoadingContentText } from '@/shared/ui';
import { EventCardDate } from '@/entities/socials/events/event-card';

export default function YouLoseAccessScreen({
    onKeepSub,
    onCancelSub,
}: {
    onKeepSub: () => void;
    onCancelSub: () => void;
}) {
    const user = useAtomValue(userAtom);
    const [courseList, setCourseList] = useState(null);
    const { eventList, isEventsLoading } = useEventsList();
    const [isRecommendedCoursesLoading, setIsRecommendedCoursesLoading] = useState(true);

    useEffect(() => {
        if (user) {
            const getRecommendedCourses = async () => {
                const courseId = user.continue_watching_course[0]?.id || 1008;

                const { data: dataThree } = await fetchData(
                    URLS.getRecomendedCourses(RecommendedCourseTypes.Three, courseId),
                );
                const courseFilteredByPurchased = dataThree.filter((course: any) => course.is_purchased === false);
                setCourseList(courseFilteredByPurchased);
                setIsRecommendedCoursesLoading(false);
            };

            getRecommendedCourses();
        }
    }, [user]);

    return (
        <Box
            px={{ base: 6, md: 10 }}
            py={10}
        >
            <Text
                textStyle="lg"
                textAlign={'center'}
            >
                Wait! Don’t miss out on these exclusive perks!
            </Text>

            <Text
                textStyle="md5"
                mb={0}
                fontWeight={600}
            >
                By unsubscribing, you’ll lose access to:
            </Text>

            <Box mb={2}>
                <Text
                    textStyle="sm"
                    mb={2}
                >
                    Free access to over 200 courses
                </Text>
            </Box>

            <Box mb={4}>
                <OverlayScrollbarsComponent>
                    <Flex
                        gap="8px"
                        pb={2.5}
                        minH="100px"
                    >
                        {courseList ? (
                            courseList.map((course: any) => (
                                <Image
                                    key={course.id}
                                    src={course.thumb_big}
                                    alt={course.title}
                                    w={{ base: '176px', md: '220px' }}
                                    h={{ base: '100px', md: '125px' }}
                                    borderRadius="xl"
                                />
                            ))
                        ) : (
                            <LoadingContentText isLoading={isRecommendedCoursesLoading}>
                                There are no upcoming events
                            </LoadingContentText>
                        )}
                    </Flex>
                </OverlayScrollbarsComponent>
            </Box>

            <Text
                textStyle="sm"
                mb={2}
            >
                Free access to Future Live Events
            </Text>

            <Box mb={3}>
                <Box
                    as={OverlayScrollbarsComponent}
                    m={-4}
                    p={4}
                >
                    <Flex
                        gap="8px"
                        pb={2.5}
                        minH="150px"
                    >
                        {eventList ? (
                            eventList.upcomingEvents.map((event: any) => (
                                <EventCardDate
                                    key={event.id}
                                    eventData={event}
                                    styles={{
                                        fontSize: { base: '13px', md: '14px' },
                                        w: { base: '176px', md: '220px' },
                                        h: { base: '133px', md: '158px' },
                                    }}
                                />
                            ))
                        ) : (
                            <LoadingContentText isLoading={isEventsLoading}>
                                There are no upcoming events
                            </LoadingContentText>
                        )}
                    </Flex>
                </Box>
            </Box>

            <Text
                textStyle="sm"
                mb={6}
            >
                Any courses you&#39;ve already purchased will still be available in your account.
            </Text>

            <Text
                textStyle="md5"
                mb={6}
                textAlign={'center'}
                fontWeight={600}
            >
                Stay connected to keep learning and growing with us!
            </Text>

            <Flex
                flexDir={{ base: 'column', md: 'row' }}
                gap={{ base: 2, md: 5 }}
            >
                <Button
                    onClick={onKeepSub}
                    w={{ base: '100%', md: 'calc(50% - 10px)' }}
                >
                    Keep Subscription
                </Button>

                <Button
                    variant="gray"
                    onClick={onCancelSub}
                    w={{ base: '100%', md: 'calc(50% - 10px)' }}
                >
                    Continue to Cancel
                </Button>
            </Flex>
        </Box>
    );
}
