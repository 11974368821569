import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from '@chakra-ui/react';

import useUnsubFlow from '../../model/useUnsubFlow';
import AdditionalDaysOfferScreen from './various/AdditionalDaysOfferScreen';
import ReasonCancelScreen from './various/ReasonCancelScreen';
import AdditionalDaysScreen from './stay/AdditionalDaysScreen';
import KeepSubScreen from './stay/KeepSubScreen';
import YouLoseAccessScreen from './various/YouLoseAccessScreen';
import CheckYourInboxScreen from './various/CheckYourInboxScreen';

export default function TrialFlow() {
    const {
        currentStep,
        setCurrentStep,
        goToNextStep,
        goToPreviousStep,
        isUnsubFlowOpen,
        onClosePopup,
        goToDashboard,
    } = useUnsubFlow();

    return (
        <Modal
            variant="primary"
            scrollBehavior="inside"
            isOpen={isUnsubFlowOpen}
            onClose={onClosePopup}
            isCentered
        >
            <ModalOverlay />

            <ModalContent
                maxH="98vh"
                overflowY="auto"
            >
                <ModalCloseButton zIndex={10} />

                {currentStep === 1 && (
                    <YouLoseAccessScreen
                        onKeepSub={() => setCurrentStep(1.5)}
                        onCancelSub={() => goToNextStep()}
                    />
                )}

                {currentStep === 1.5 && <KeepSubScreen onBtnClick={goToDashboard} />}

                {currentStep === 2 && (
                    <AdditionalDaysOfferScreen
                        onPrev={goToPreviousStep}
                        onCancelSub={goToNextStep}
                        onStayBtn={() => setCurrentStep(2.5)}
                    />
                )}

                {currentStep === 2.5 && <AdditionalDaysScreen onNext={goToDashboard} />}

                {currentStep === 3 && (
                    <ReasonCancelScreen
                        onPrev={goToPreviousStep}
                        onNext={goToNextStep}
                        onStayBtn={() => setCurrentStep(1.5)}
                    />
                )}

                {currentStep === 4 && <CheckYourInboxScreen onBtnClick={onClosePopup} />}
            </ModalContent>
        </Modal>
    );
}
