'use client';

import { createContext, useContext, ReactNode } from 'react';

import { CourseData, CourseInfo } from '@/entities/common/model/courses.types';
import { getMappedCourseInfoData, getMappedReviews, getMappedTrailerData } from './getMappedCourseData';
import { CardReviewProps } from '@/entities/cards/cards-review';

interface LandingContextType {
    courseData: CourseData;
    courseCartData: {
        id: number;
        title: string;
        price: number;
    };
    courseTrailerData: any;
    courseInfoData: CourseInfo;
    courseReviews?: CardReviewProps[];
    optionsCourses?: CourseData[];
}

export const useLandingProvider = (): LandingContextType => {
    const context = useContext(LandingProviderContext);

    if (!context) {
        throw new Error('useLandingProvider must be used within a LandingProvider');
    }
    return context;
};

const LandingProviderContext = createContext<LandingContextType | undefined>(undefined);

export const LandingProvider = ({ landingData, children }: { landingData: CourseData; children: ReactNode }) => {
    const courseTrailerData = getMappedTrailerData(landingData);
    const courseInfoData = getMappedCourseInfoData(landingData);

    let reviews = landingData.testimonials && getMappedReviews(landingData.testimonials);

    if (reviews) {
        reviews = reviews.splice(0, 8);
    }

    const courseCartData = {
        id: landingData.id,
        title: landingData.title,
        price: typeof landingData.price === 'string' ? parseInt(landingData.price, 10) : landingData.price,
    };
    let optionsCourses = landingData.optional_courses;

    if (optionsCourses && optionsCourses.length > 0) {
        optionsCourses = landingData.optional_courses.map((currentCourse: any) => ({
            id: currentCourse.id,
            title: currentCourse.title,
            price: currentCourse.upsale_price,
            imgSrc: currentCourse.thumb_big,
        }));
    }

    const context: LandingContextType = {
        courseData: landingData,
        courseCartData,
        courseTrailerData,
        courseInfoData,
        optionsCourses,
        courseReviews: reviews,
    };

    return <LandingProviderContext.Provider value={context}>{children}</LandingProviderContext.Provider>;
};
