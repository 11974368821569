import { useEffect, useState } from 'react';
import { useBoolean } from '@chakra-ui/react';

import { METHODS, fetchData } from '@/shared/lib';
import { EventData, EventsData, setStartDataToEvents } from '@/entities/events/event-data';

const PAST_COUNT = 6;
const EXPAND_UPCOMING_EVENTS =
    'expand=status,thumb,user_start_date,author_name,author_description,author_thumb,type,is_user_notification,is_on_air&search[soon_start]=true&sort=start_date&per-page=10&page=1';

const UPCOMING_EVENTS_PATH = `/api/events?${EXPAND_UPCOMING_EVENTS}`;
const UPCOMING_ALL_EVENTS_PATH = `/api/events/all-user?${EXPAND_UPCOMING_EVENTS}`;

const PAST_EVENTS_PATH = `/api/events?expand=status,thumb,user_start_date,type,is_user_notification&search[past]=true&sort=-start_date&per-page=${PAST_COUNT}&page=1`;

interface EventLists {
    upcomingEvents: EventData[];
    pastEvents: EventData[] | undefined;
}

export default function useEventsList(isAuthEvents: boolean = true) {
    const [eventList, setEventList] = useState<EventLists | null>(null);
    const [isEventsLoading, setIsEventsLoading] = useBoolean(true);

    useEffect(() => {
        if (!eventList) {
            getEvents();
        }
    }, []);

    async function getEvents() {
        setIsEventsLoading.on();

        let upcomingEvents;
        let pastEvents;

        if (isAuthEvents) {
            const { data: upcomingUserEvents } = await fetchData<EventsData>(UPCOMING_EVENTS_PATH, {
                method: METHODS.GET,
            });
            upcomingEvents = setStartDataToEvents(upcomingUserEvents);

            const { data: pastUserEvents } = await fetchData<EventsData>(PAST_EVENTS_PATH, { method: METHODS.GET });
            pastEvents = setStartDataToEvents(pastUserEvents);
        } else {
            const { data: upcomingAllEvents } = await fetchData<EventsData>(UPCOMING_ALL_EVENTS_PATH, {
                method: METHODS.GET,
            });
            upcomingEvents = upcomingAllEvents;
        }

        setEventList({
            upcomingEvents: setStartDataToEvents(upcomingEvents),
            pastEvents: pastEvents,
        });

        setIsEventsLoading.off();
    }

    return { eventList, isEventsLoading, getEvents };
}
