export const USER_SESSION_ID_KEY = 'user-session-id';
export const SELECT_CATEGORIES = 'select_categorie';

export const LS_ONBOARDING_DATA = 'onboarding-data';

export enum LS_ONBOARDING_DATA_KEYS {
    ActionSubType = 'action_sub_type',
    ActionSubTypeYear = 'action_sub_type_year',
    RedirectFrom = 'redirect_from',
    ActionFirstStep = 'action_first_step',
    QuizType = 'quiz_type',

    Email = 'email',
    FirstName = 'first_name',
    LastName = 'last_name',
    Password = 'password',
    UrlCheckEmail = 'url_check_email',

    CourseTitle = 'course_title',
    CourseSlug = 'course_slug',
    CourseId = 'course_id',

    PlanPrice = 'plan_price',
    PlanTrialDays = 'plan_trial_days',

    DataOrderId = 'data-order-id',
    DataSessionRedirectBackUrl = 'data-session-redirect-back-url',
}

export const LS_ONE_CLICK_CHECKOUT_CART = 'one_click_checkout_cart';
