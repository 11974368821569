'use client';

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';

import OneClickCheckoutItemTrial from './components/OneClickCheckoutItemTrial';
import OneClickCheckoutItemRegular from './components/OneClickCheckoutItemRegular';
import { LS_ONE_CLICK_CHECKOUT_CART } from '@/common/constants/local-storage';
import { fetchData } from '@/shared/lib';
import { URLS } from '@/common/constants/global';
import { CourseSubscriptionPriceData } from '../../model/domestika.types';

export enum CartTypeTariffs {
    Trial = 'trial',
    RegularPrice = 'regular',
}

export default function OneClickCheckoutCart({ courseId }: { courseId: number }) {
    const [activeCartType, setActiveCartType] = useState<CartTypeTariffs>(CartTypeTariffs.Trial);
    const [coursePrices, setCoursePrices] = useState<CourseSubscriptionPriceData>();

    useEffect(() => {
        async function fetchCoursePrices() {
            const { data } = await fetchData<{ data: any }>(URLS.getDomestikaPackage(courseId));

            setCoursePrices(data);
        }

        if (courseId) {
            fetchCoursePrices();
        }
    }, [courseId]);

    const onBtnClick = async () => {
        // @ts-expect-error
        if (typeof window.fbq === 'function') {
            // @ts-expect-error
            window.fbq('track', 'AddToCart');
        }

        // Сохраняем информацию о покуке в localStorage (для перехода на страницу checkout)
        localStorage.setItem(
            LS_ONE_CLICK_CHECKOUT_CART,
            JSON.stringify({
                type: activeCartType,
                id: courseId,
            }),
        );

        setTimeout(() => {
            // window.location.pathname = '/checkout';
            window.location.pathname = '/add-course-to-cart/' + courseId;
        }, 0);
    };

    return (
        <Flex
            flexDirection="column"
            gap={4}
        >
            <OneClickCheckoutItemTrial
                isChecked={activeCartType === CartTypeTariffs.Trial}
                onClick={() => setActiveCartType(CartTypeTariffs.Trial)}
                coursePrices={coursePrices}
                onBtnClick={onBtnClick}
            />
            <OneClickCheckoutItemRegular
                isChecked={activeCartType === CartTypeTariffs.RegularPrice}
                onClick={() => setActiveCartType(CartTypeTariffs.RegularPrice)}
                coursePrices={coursePrices}
                onBtnClick={onBtnClick}
            />
        </Flex>
    );
}
