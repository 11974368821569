import { AbsoluteCenter, Box, Button, HStack, Image, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';

import AuthorPromoImg from '@/widgets/author/landing/ui/components/AuthorPromoImg';
import AuthorPromoImgRounded from '@/widgets/author/landing/ui/components/AuthorPromoImgRounded';
import { SearchType, useCoursesListByValue } from '@/features/courses/courses-list';
import { authorLandingAtom } from '@/widgets/author/landing/model/authorLandingAtom';

const AUTHOR_COURSE_IDS = [1133, 1175];

export default function PromoAuthorBanner() {
    const authorLandingData = useAtomValue(authorLandingAtom);

    const { coursesList } = useCoursesListByValue(SearchType.Id, AUTHOR_COURSE_IDS);
    const [isBannerShow, setIsBannerShow] = useState(false);

    useEffect(() => {
        if (coursesList) {
            // Проверяем, что курсокурс куплен
            const isWatchOrBuyed = coursesList.some((course) => course.is_purchased);

            setIsBannerShow(!isWatchOrBuyed);
        }
    }, [coursesList]);

    if (!isBannerShow) {
        return null;
    }

    return (
        <>
            <Box
                pos="relative"
                pb={{ base: '50px', lg: '60px' }}
                pt={{ base: '70px', lg: '60px' }}
                backgroundImage={{
                    base: 'url(/img/bg/orange-green-ver.png)',
                    md: 'url(/img/bg/green-pink-white-hor.png)',
                }}
                backgroundSize="cover"
                overflow="hidden"
                textAlign="center"
            >
                <Box
                    pos="relative"
                    maxW="650px"
                    px={5}
                    mx="auto"
                    textAlign="center"
                >
                    <Box
                        zIndex={10}
                        pos="relative"
                    >
                        <Text textStyle="lg3">
                            Create Your Course and Earn
                            <br />
                            Up to{' '}
                            <Text
                                as="span"
                                color="#731dff"
                            >
                                $120,000
                            </Text>{' '}
                            A Year
                        </Text>

                        <Text
                            textStyle="md"
                            mb={{ base: 7.5, md: 5 }}
                            fontWeight={400}
                        >
                            Ready to unlock your potential and turn your knowledge into income? Participate in our{' '}
                            <Text as="b">course creation marathon</Text>, publish your course on our platform, and start
                            earning up to $120,000 a year!
                        </Text>
                    </Box>

                    <Box
                        pos="relative"
                        mb={{ base: 5, md: 6 }}
                    >
                        <Button
                            zIndex={10}
                            w="260px"
                            onClick={() => window.open(authorLandingData?.buttonUrl || '/author/landing', '_blank')}
                        >
                            Learn More
                        </Button>

                        <AbsoluteCenter
                            hideFrom="md"
                            w={{ base: '485px' }}
                            h={{ base: '485px' }}
                        >
                            <Image
                                src="/img/icons/figures/lines-circle.svg"
                                alt="line-rainbow"
                            />
                        </AbsoluteCenter>
                    </Box>

                    <Box hideBelow="lg">
                        <AuthorPromoImgRounded
                            priceValue="8,000"
                            imgSrc={'/authors/landing/top-banner/author-girl-5.png'}
                            styles={{
                                position: 'absolute',
                                top: '-20px',
                                left: '-235px',
                            }}
                            tagStyles={{
                                top: '65%',
                                left: '65%',
                            }}
                        />
                        <AuthorPromoImgRounded
                            priceValue="10,000"
                            imgSrc={'/authors/landing/top-banner/author-girl-6.pn'}
                            styles={{
                                position: 'absolute',
                                top: '220px',
                                left: '-180px',
                                w: '72px',
                                h: '72px',
                            }}
                            tagStyles={{
                                top: '65%',
                                left: '65%',
                            }}
                        />
                        <AuthorPromoImgRounded
                            priceValue="6,000"
                            imgSrc={'/authors/landing/top-banner/author-girl-7.pn'}
                            styles={{
                                position: 'absolute',
                                top: '-20px',
                                right: '-235px',
                                w: '80px',
                                h: '80px',
                            }}
                            tagStyles={{
                                top: '65%',
                                left: '-120%',
                            }}
                        />
                        <AuthorPromoImgRounded
                            priceValue="6,000"
                            imgSrc={'/authors/landing/top-banner/author-girl-8.pn'}
                            styles={{
                                position: 'absolute',
                                top: '220px',
                                right: '-200px',
                            }}
                            tagStyles={{
                                top: '65%',
                                left: '-90%',
                            }}
                        />
                    </Box>
                </Box>

                <AbsoluteCenter
                    hideBelow="md"
                    w={{ base: '1220px' }}
                    h={{ base: '1220px' }}
                >
                    <Image
                        src="/img/icons/figures/lines-circle-big.svg"
                        alt="line-rainbow"
                    />
                </AbsoluteCenter>

                <HStack
                    pos="relative"
                    zIndex={10}
                    gap={0}
                    mt={10}
                    justify="center"
                    hideFrom="lg"
                >
                    <AuthorPromoImg
                        priceValue="6,000"
                        imgName={'author-girl-2'}
                        imgStyles={{
                            transform: {
                                base: 'rotate(-3deg)',
                            },
                        }}
                        tagStyles={{
                            top: '-12px',
                            left: '12px',
                        }}
                    />
                    <AuthorPromoImg
                        priceValue="10,000"
                        imgName={'author-girl-3'}
                        imgStyles={{
                            zIndex: 20,
                            position: 'relative',
                        }}
                        tagStyles={{
                            top: '-12px',
                            left: '12px',
                        }}
                    />
                    <AuthorPromoImg
                        priceValue="5,000"
                        imgName={'author-man-1'}
                        imgStyles={{
                            transform: {
                                base: 'rotate(5deg)',
                            },
                            zIndex: 10,
                            position: 'relative',
                        }}
                        tagStyles={{
                            top: '-12px',
                            left: '12px',
                        }}
                    />
                    <AuthorPromoImg
                        priceValue="8,000"
                        imgName={'author-girl-4'}
                        imgStyles={{
                            position: 'relative',
                            zIndex: 0,
                        }}
                        tagStyles={{
                            top: '-12px',
                            left: '12px',
                        }}
                    />
                </HStack>
            </Box>
        </>
    );
}
