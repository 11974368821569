import { atom } from 'jotai';
import { CourseSubscriptionPriceData } from '../../model/domestika.types';

interface SubmitCheckoutData {
    is_trial: boolean;
    course_id: number;
    additional_courses: number[] | undefined;
    email?: string;
}

interface EmailData {
    email: string | null;
    validate: () => Promise<boolean>;
}

export const submitCheckoutDataAtom = atom<SubmitCheckoutData | null>(null);
export const clientSecretAtom = atom<string | null>(null);
export const emailForStripeAtom = atom<EmailData | null>(null);
export const stripeAtom = atom<any>(null);
export const typeCheckoutCheckedItemsAtom = atom<any>(null);

export const checkoutPricesAtom = atom<CourseSubscriptionPriceData | null>(null);
