import { CardReviewProps } from '@/entities/cards/cards-review';
import { CourseInfo, Testimonial } from '@/entities/common/model/courses.types';
import { getFormattedDate } from '@/shared/lib';
import { CourseData } from '@/entities/common/model/courses.types';

export function getMappedReviews(reviews: Testimonial[]): CardReviewProps[] {
    return reviews.map((item) => ({
        authorName: item.name,
        photo: item.avatar_url,
        text: item.text,
        rating: item.rating,
        date: getFormattedDate(item.updated_at).day,
    }));
}

export function getMappedTrailerData(landingData: CourseData): any {
    return {
        videoSrc: landingData.video_intro,
        videoPosterSrc: landingData.thumb_big,
    };
}

export function getMappedCourseInfoData(landingData: CourseData): CourseInfo {
    return {
        title: landingData.title,
        rating: landingData.rating,
        description: landingData.excerpt,
        authors: landingData.authors!,
        updateDate: getFormattedDate(landingData.updated_at).monthYear,

        reviewCount: landingData.buyers_preview ? landingData.buyers_preview.testimonials_count : 0,
        buyersCount: landingData.buyers_preview ? landingData.buyers_preview.buyers_count : 0,

        videoCount: landingData.sections
            ? landingData.sections.reduce((acc, item) => (acc += item.lessons.length), 0)
            : 0,
    };
}
