import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
    stepper: {},
    step: {},
    description: {},
    indicator: {
        width: '32px',
        height: '32px',
        borderWidth: '1px !important',
    },
    icon: {},
    number: {
        fontSize: '13px',
    },
});

const quizVariant = definePartsStyle({
    stepper: {},
    icon: {},
    title: {
        flexShrink: '1 !important',
        fontWeight: '400',
    },
    indicator: {
        // width: { base: '120px', md: '144px' },
        w: '100%',
        height: '4px',
        border: 'none',
        borderRadius: '0',
        background: 'gray.375',

        '&[data-status=complete]': {
            background: 'green.400',
        },
    },
    separator: {
        top: '100% !important',
        left: '24px !important',
        width: '1px !important',
        maxHeight: '24px !important',
    },
});

const purpleVariant = definePartsStyle({
    indicator: {
        zIndex: 1,
        fontSize: 'sm',
        padding: 0,
        width: '40px',
        height: '40px',
        background: 'white !important',
        border: '1px solid',
        borderColor: 'white',
        color: '#584aff !important',
        boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.1)',
    },
    separator: {
        left: '19px !important',
    },
});

const stepperTheme = defineMultiStyleConfig({
    baseStyle,
    variants: {
        quiz: quizVariant,
        purple: purpleVariant,
    },
});

export { stepperTheme };
