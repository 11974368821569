import { Box, BoxProps, Flex, Text, useMediaQuery } from '@chakra-ui/react';

import TagsElements, { tagsArray2 } from './components/TagsElements';
import { AvatarList } from '@/entities/lists/avatars';
import BestsellersListSimple from './components/BestsellersListSimple';
import ReviewSlider from './components/ReviewSlider';
import { testimonials } from '../model/testimonials';
import JoinThePeople from './components/JoinThePeople';
import { APP_BRAND } from '@/common/constants/brand';
import Paywall from './components/Paywall';
import CartBlockCheckMark from './components/CartBlockCheckMark';
import BestsellersList from './components/BestsellersList';
import { HeadingNavEventsSlider, SlidesQuiz } from '@/features/socials/events/events-slider';
import { GuaranteeBlock } from '@/entities/info/guarantee-block';
import { capitalizeFirstLetter } from '@/shared/lib';

interface ClassesForYouProps {
    selectedCoursesList: any;
    BtnEl: any;
    styles?: BoxProps;
}

export default function ClassesForYouOnboardingPaywall({ selectedCoursesList, BtnEl, styles }: ClassesForYouProps) {
    const [isLargerThanMd] = useMediaQuery('(min-width: 768px)', {
        ssr: true,
        fallback: false,
    });

    return (
        <Box {...styles}>
            <Flex
                justify="space-between"
                align="center"
                mb={{ base: 2, md: 4 }}
            >
                <Text
                    fontSize="sm3"
                    mb={{ base: 0, md: 4 }}
                >
                    Join 500 000+ students worldwide
                </Text>

                <AvatarList
                    count={7}
                    maxCount={7}
                    styles={{ size: isLargerThanMd ? 'sm' : 'sm3' }}
                />
            </Flex>

            <Box
                mb={{ base: 3, md: 4 }}
                mx={{ base: -5, md: 0 }}
                px={5}
                py={4}
                borderRadius={{ md: 'xl' }}
                bgImage="url(/img/bg/pink-blue-fade-vertical.png)"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
            >
                <Text
                    mb={0}
                    fontSize="17px"
                    fontWeight={700}
                >
                    Increase Your Chance of Success by 46% With Access to 200+ Programs
                </Text>
            </Box>

            <Box pos="relative">
                <Text
                    textStyle="lg3"
                    mb={4}
                >
                    Unlock Health, Confidence, Happiness and Joy Right From Home
                </Text>
            </Box>

            <TagsElements
                tags={tagsArray2}
                styles={{ mb: { base: 3, md: 4 } }}
            />

            <Text
                mb={{ base: 3, md: 4 }}
                fontSize={{ base: '14px', md: '16px' }}
            >
                Start your journey with these {selectedCoursesList?.length ?? '6'} classes perfectly suited to your
                goals:
            </Text>

            <BestsellersListSimple
                selectedCoursesList={selectedCoursesList}
                styles={{ mb: { base: 4, md: 10 } }}
            />

            <Text fontSize={{ base: '14px', md: '16px' }}>
                Studying multiple programs at once truly boosts your results. Unlock these{' '}
                {selectedCoursesList?.length ?? '6'}, plus all 200+ of our other programs with{' '}
                {capitalizeFirstLetter(APP_BRAND)} Membership, at a special, one-time-only discount —{' '}
                <Text
                    as="span"
                    fontWeight={500}
                >
                    exclusively on this page.
                </Text>
            </Text>

            <Flex
                flexDir={{ base: 'column', md: 'row' }}
                gap={{ base: '40px', lg: '60px' }}
                mt={{ base: 7.5, md: 15 }}
                mb={{ base: 5, md: 15 }}
            >
                <Paywall styles={{ w: { md: '50%' }, flexShrink: 0 }} />

                <CartBlockCheckMark BtnEl={BtnEl} />
            </Flex>

            <BestsellersList
                styles={{
                    minH: '230px',
                    my: { base: 10, md: 15 },
                    overflow: 'hidden',
                }}
            />

            <HeadingNavEventsSlider
                title="Join upcoming events with your 7-day free trial"
                SlideEl={({ eventData }) => <SlidesQuiz eventData={eventData} />}
                slideCount={2}
                styles={{
                    mb: { base: 10, md: 15 },
                    sx: {
                        h2: {
                            fontSize: {
                                base: '18px',
                                md: '24px',
                            },
                        },
                    },
                }}
            />

            <GuaranteeBlock
                styles={{
                    mx: { base: -5, md: 0 },
                    mb: { base: 10, md: 15 },
                }}
            />

            <ReviewSlider
                reviewList={testimonials}
                styles={{ mb: { base: 7.5, md: 10 } }}
            />

            <JoinThePeople />
        </Box>
    );
}
