import { avatarAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(avatarAnatomy.keys);

const md = definePartsStyle({
    container: {
        width: '60px',
        height: '60px',
        border: 'none',
    },
});

const sm2 = definePartsStyle({
    container: {
        width: '40px',
        height: '40px',
        border: 'none',
    },
});

const sm3 = definePartsStyle({
    group: {
        '.chakra-avatar': {
            marginInlineEnd: '-0.5rem',

            '&:first-of-type': {
                marginInlineEnd: '0',
            },
        },
    },

    container: {
        width: '20px',
        height: '20px',
        border: 'none',
    },
});

const reverse = definePartsStyle({
    group: {
        flexDir: 'row',
        pr: '1.25rem',

        '.chakra-avatar': {
            marginEnd: '-1.25rem',
        },
    },
    container: {
        w: { base: '40px', xl: '60px' },
        h: { base: '40px', xl: '60px' },
        borderWidth: '0 !important',

        '&:first-of-type': {
            marginEnd: '-1.25rem',
        },

        '&:last-of-type': {
            bgColor: '#fff',
            fontSize: { base: 'sm', xl: 'xl' },
            fontWeight: 700,
            color: 'orange.600',
        },
    },
});

const reverseSmVariant = definePartsStyle({
    group: {
        flexDir: 'row',
        pr: '10px',

        '.chakra-avatar': {
            marginEnd: '-10px',
        },
    },
    container: {
        w: '34px',
        h: '34px',
        borderWidth: '0 !important',

        '&:first-of-type': {
            marginEnd: '-10px',
        },

        '&:last-of-type': {
            bgColor: 'gray.675',
            fontSize: '12px',
            fontWeight: 700,
        },
    },
});

const avatarTheme = defineMultiStyleConfig({
    defaultProps: {},
    sizes: {
        sm3,
        sm2,
        md,
    },
    variants: {
        reverse,
        reverseSm: reverseSmVariant,
    },
});

export { avatarTheme };
