'use client';

import { useAtom, useSetAtom } from 'jotai';
import { useState, useEffect } from 'react';

import { generateUUID } from '@/shared/lib';
import usePostEventQuizTimed from './usePostEventQuizTimed';
import { setLocalStorageCoursesList, getAnswersList, getSelectedCoursesList } from '../../utils/utils';
import { USER_SESSION_ID_KEY } from '@/common/constants/local-storage';
import { Gender } from '@/entities/quizes/quiz/model/lists/radio-list';
import { QuizGroup, selectedCoursesAtom, selectedCourseValuesAtom, QuizGroupType } from '../..';

export default function useCourseQuiz(
    quizList: QuizGroup[],
    prevLink?: string,
    extraStepNum: number = 0,
    firstCourse?: any,
) {
    const [quizListState, setQuizListState] = useState<QuizGroup[]>(quizList);

    // Зачем это?
    const [userSessionId, setUserSessionId] = useState(() => {
        if (typeof window !== 'undefined') {
            const savedId = localStorage.getItem(USER_SESSION_ID_KEY);
            return savedId || generateUUID();
        }
        return '';
    });

    useEffect(() => {
        if (typeof window !== 'undefined') {
            localStorage.setItem(USER_SESSION_ID_KEY, userSessionId);
        }
    }, [userSessionId]);

    const [selectedCourses, setSelectedCourses] = useAtom(selectedCoursesAtom);
    const setSelectedCourseValues = useSetAtom(selectedCourseValuesAtom);

    const [currentQuizStepNumber, setCurrentQuizStepNumber] = useState<number>(0);
    const [quizLastStepNumber, setQuizLastStepNumber] = useState<number>(quizListState.length + extraStepNum);
    const [genderVariant, setGenderVariant] = useState<Gender | null>(null);

    const [activeStep, setActiveStep] = useState(1);

    const { postEventQuizTimed } = usePostEventQuizTimed();

    useEffect(() => {
        if (extraStepNum && currentQuizStepNumber === quizLastStepNumber - extraStepNum) {
            const selectedCourseIdsList = getSelectedCoursesList(selectedCourses);
            setSelectedCourseValues(selectedCourseIdsList);
        }

        if (quizLastStepNumber === currentQuizStepNumber) {
            setActiveStep(activeStep + 1);
        }
    }, [currentQuizStepNumber]);

    const currentQuiz = quizListState[currentQuizStepNumber];

    const changePage = async (data: any) => {
        setCurrentQuizStepNumber(data.nextIndex);
        setQuizLastStepNumber(quizListState.length + extraStepNum);

        document.body.scrollIntoView({ behavior: 'smooth' });

        if (data.title) {
            try {
                await postEventQuizTimed(data.answersList, data.title.toLowerCase());
            } catch (error) {
                console.error(`Quiz course event ${data.title.toLowerCase()} not send`, error);
            }
        }
    };

    const onNextBtnClick = (data: any) => {
        if (currentQuiz.isNextStep) {
            // если новый блок шагов квиза
            setActiveStep(activeStep + 1);
        }

        if (currentQuiz.type === QuizGroupType.Picture || currentQuiz.type === QuizGroupType.Other) {
            // если шаг с картинкой или любой другой, кроме стандартного с формой
            changePage({
                nextIndex: currentQuizStepNumber + 1,
                title: currentQuiz.eventName,
            });
            return;
        }

        const singleAnswer = data.formData.radio;
        const isCategoryStep = currentQuiz.type === QuizGroupType.CategorySingle;
        const isSingleToVariants = currentQuiz.type === QuizGroupType.GenderSingle;

        // Проверяем условие пропуска шагов
        const condition = currentQuiz.nextStepsCondition;
        let stepsToSkip = 0;

        if (condition && condition[singleAnswer] !== undefined) {
            stepsToSkip = condition[singleAnswer]; // Пропуск шагов согласно условию
        }

        if (isCategoryStep && singleAnswer) {
            setLocalStorageCoursesList(
                currentQuiz.categoryList,
                singleAnswer,
                genderVariant ? genderVariant : null,
                firstCourse,
            );
        }

        if (isSingleToVariants && singleAnswer) {
            setGenderVariant(data.formData.radio);
        }

        const answersList = getAnswersList(data, currentQuiz);

        changePage({
            nextIndex: data.nextIndex + stepsToSkip,
            title: currentQuiz.eventName,
            answersList,
        });
    };

    const onPrevBtnClick = () => {
        if (currentQuizStepNumber === 0 && prevLink) {
            window.location.pathname = prevLink;
            return;
        }
        const prevQuizNumber = currentQuizStepNumber - 1;

        if (quizListState[prevQuizNumber] && quizListState[prevQuizNumber].type === QuizGroupType.Picture) {
            setActiveStep(activeStep - 1);
        }

        changePage({ nextIndex: prevQuizNumber });
    };

    return {
        currentQuiz,
        activeStep,
        onNextBtnClick,
        onPrevBtnClick,
        currentQuizStepNumber,
        quizLastStepNumber,
    };
}
