import { Button } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';

import { userAtom } from '@/shared/store';
import { CurrentStepEventNames } from '@/entities/quizes/quiz';
import { QuizBannerFirst } from '@/entities/promo/banners/quiz/quiz-banner-1';
import { QuizBannerCountdown } from '@/entities/promo/banners/quiz/quiz-banner-countdown';
import { BannerCourseQuizBtn } from '@/entities/btns/banner-course-quiz';
import { checkPassedHours } from '@/shared/lib';
import { ActionSubType, useCreateSub } from '@/features/subscription/create-sub';
import { getCountdownReminingTime } from '@/features/promo/countdown';

import DashboardBannerStandard from './DashboardBannerStandard';

const TIMER_COUNTDOWN_HOURS = 12;

export default function DashboardTopBanner() {
    const user = useAtomValue(userAtom);
    const { isSubLoading, isSubError, isSubCreate, loadingText, createSub } = useCreateSub();

    if (!user) {
        return null;
    }

    const isLeaveFirstStep =
        user?.quiz_info.quiz_thank_page_step_history.includes(CurrentStepEventNames.QuizAgeQuestionStep) &&
        !user?.quiz_info.quiz_thank_page_step_history.includes(CurrentStepEventNames.QuizFinalPageStep);

    const isLeavePreFirstStep =
        user?.quiz_info.quiz_thank_page_step_history.includes(CurrentStepEventNames.QuizCheckEmailStep) &&
        !user?.quiz_info.quiz_thank_page_step_history.includes(CurrentStepEventNames.QuizAgeQuestionStep);

    const isLeaveFinalStep = user?.quiz_info.quiz_thank_page_step_history.includes(
        CurrentStepEventNames.QuizFinalPageStep,
    );

    const isTimePassed = user.quiz_info.date_timer_start
        ? checkPassedHours(user.quiz_info.date_timer_start, TIMER_COUNTDOWN_HOURS)
        : null;

    // Если юзер бросил квиз на первом шаге, то первый баннер и он весит пока юзер не пройдет квиз
    if ((isLeavePreFirstStep && !user.is_subscribe) || (isLeaveFirstStep && !user.is_subscribe)) {
        return (
            <QuizBannerFirst
                BtnEl={() => (
                    <Button
                        onClick={() => (window.location.pathname = '/quiz-onboarding/banner')}
                        display="flex"
                        mx="auto"
                        variant="white"
                    >
                        Start the Quiz Now
                    </Button>
                )}
            />
        );
    }
    // Если юзер бросил на последнем шаге, то баннер с таймером и он висит 12 часов (тайм штамп) и потом появляется текущий
    else if (isLeaveFinalStep && !user.is_subscribe && !isTimePassed) {
        const reminingTime = getCountdownReminingTime(user.quiz_info.date_timer_start, TIMER_COUNTDOWN_HOURS);

        // Текуший висит, если время вышло
        if (reminingTime === 0) {
            return <DashboardBannerStandard />;
        } else {
            return (
                <QuizBannerCountdown
                    timerSeconds={reminingTime}
                    BtnEl={() => (
                        <BannerCourseQuizBtn
                            btnText={'Get Started Now'}
                            onClick={() =>
                                createSub(
                                    user.quiz_info.is_quiz_year_sub
                                        ? ActionSubType.AfterRecBannerYearSubscriptionQuiz30
                                        : ActionSubType.AfterRecQuizBannerDashboard,
                                )
                            }
                            state={{
                                isLoading: isSubLoading,
                                isError: isSubError,
                                loadingText: loadingText,
                                isSubCreate: isSubCreate,
                            }}
                            styles={{
                                display: 'flex',
                                mx: 'auto',
                            }}
                        />
                    )}
                />
            );
        }
    }
    // Текуший висит пока юзер не оформит подписку
    else if (!user.is_subscribe) {
        return <DashboardBannerStandard />;
    }
}
