import { URLS } from '@/common/constants/global';
import { fetchData, getRemainingTimeInSeconds, METHODS } from '@/shared/lib';

export default function getCountdownReminingTime(dataTimerStart: any, coundownTime: number): number {
    let formattedDate: string | null = null;

    if (!dataTimerStart) {
        const date = new Date();
        formattedDate = date.toISOString();

        fetchData(URLS.QUIZ_THANK_PAGE_ADD_OPTIONAL_DETAILS, {
            method: METHODS.POST,
            body: JSON.stringify({
                optional_details: {
                    date_timer_start: formattedDate,
                },
            }),
        });
    }

    const reminingTime = getRemainingTimeInSeconds(dataTimerStart ?? formattedDate, coundownTime);
    return reminingTime;
}
