import { APP_BRAND } from '@/common/constants/brand';
import { CardReviewProps } from '@/entities/cards/cards-review';
import { capitalizeFirstLetter } from '@/shared/lib';

export const AVATAR_REVIEW_DATA: CardReviewProps[] = [
    {
        authorName: 'Cora C',
        photo: '/img/reviews/cora.png',
        rating: 5,
        text: `When I first came across ${capitalizeFirstLetter(APP_BRAND)}, the variety of courses intrigued me. Despite the price, I decided to give the trial a shot. That decision has led to some of the most productive and enriching months of my life. Their unique brain exercises, yoga, and meditation sessions have not only boosted my energy levels but also improved my overall well-being. It's safe to say that a ${capitalizeFirstLetter(APP_BRAND)} subscription is one of the best investments I've made in myself.`,
    },
    {
        authorName: 'Melisa',
        photo: '/img/reviews/melissa-1.png',
        rating: 5,
        text: `Initially skeptical, I signed up for a 7-day trial with ${capitalizeFirstLetter(APP_BRAND)}. The experience has been transformative, leading me to start yoga and dance, and explore new brain exercises. After a month, my energy levels and mood have significantly improved, bringing vibrant changes to my life.`,
    },
    {
        authorName: 'Jeep',
        photo: '/img/reviews/melissa-2.png',
        rating: 5,
        text: `My relationship was salvaged by a ${capitalizeFirstLetter(APP_BRAND)} subscription. I accidentally discovered it and was drawn to their diverse courses. After a two-month subscription filled with engaging content, my relationship woes and constant irritability have dissipated. The courses have enhanced my energy, mood and even added spark to my relationship, notably the couples massage course.`,
    },
    {
        authorName: 'Juliette',
        photo: '/img/reviews/juliette.png',
        rating: 5,
        text: `The courses are exhilarating! Initially, the $97 price tag seemed steep, but considering it amounts to a daily cup of coffee, the boost in energy and mood I receive is beyond comparison.`,
    },
];
