import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { useAtomValue } from 'jotai';

import { HTMLRenderer } from '@/shared/ui';
import { userAtom } from '@/shared/store';
import { getRandomNumber } from '@/shared/lib';
import { PlacementPromoBlock, PromoBlock } from '@/entities/common/model/courses.types';
import { CourseList } from '@/entities/common/model/course-list.types';

export default function PromoBlockDashboard() {
    const user = useAtomValue(userAtom);

    const [promoBlock, setPromoBlock] = useState<PromoBlock | null>(null);
    const { width } = useWindowSize();

    useEffect(() => {
        if (user) {
            const { course_purchased: coursePurchased } = user;

            const coursesWithPromoBlock = coursePurchased.filter(
                (item) =>
                    item.promo_blocks_resource &&
                    item.promo_blocks_resource.find((item) => item.placement === PlacementPromoBlock.Dashboard),
            );

            if ((coursesWithPromoBlock as any[]).length === 0) {
                return;
            }

            const randomCourse = coursesWithPromoBlock[getRandomNumber(0, coursesWithPromoBlock.length - 1)];
            const { promo_blocks_resource: promoBlocks } = randomCourse;
            const randomPromoBlock = promoBlocks[getRandomNumber(0, promoBlocks.length - 1)];

            if (!randomPromoBlock) {
                return;
            }

            setPromoBlock(randomPromoBlock);
        }
    }, [user]);

    if (!promoBlock) {
        return null;
    }

    return (
        <Box maxW={''}>
            <Flex
                pos={'relative'}
                justify="center"
                align={{ base: 'center', xl: 'stretch' }}
                gap={{ lg: '80px' }}
                flexDir={{ base: 'column', lg: 'row' }}
                maxW="1680px"
                mx="auto"
                overflow="hidden"
                borderRadius={'16px'}
            >
                <Box
                    zIndex={1}
                    maxW={{ base: '500px', xl: '600px' }}
                    flexShrink={0}
                    pt={{ base: 10, lg: 20 }}
                    pb={{ base: 10, lg: 20 }}
                    px={{ base: 6, xl: 0 }}
                    pl={{ xl: '45px' }}
                    mx="auto"
                    ml={{ lg: 'auto' }}
                    mr={{ lg: '0' }}
                    textAlign={'left'}
                >
                    <HTMLRenderer
                        html={promoBlock.title}
                        styles={{
                            mb: 5,
                            fontSize: { base: '24px', xl: '42px' },
                            fontWeight: 700,
                            lineHeight: 1.3,
                        }}
                    />

                    <HTMLRenderer
                        html={promoBlock.description}
                        styles={{
                            mb: 5,
                            fontSize: { base: '14px', xl: '20px' },
                        }}
                    />

                    <Button
                        onClick={() => {
                            if (promoBlock.product.id === CourseList.AuthorCourse) {
                                window.location.href =
                                    window.location.origin + '/author/landing' + '?utm_source=dashboard';
                            } else {
                                window.location.href =
                                    window.location.origin + '/' + promoBlock.product.slug + '?utm_source=dashboard';
                            }
                        }}
                        variant={'primary'}
                        bgColor={promoBlock.button_background_color}
                        color={promoBlock.button_text_color}
                    >
                        {promoBlock.button_text}
                    </Button>
                </Box>

                <Box
                    zIndex={0}
                    flex="1 0 auto"
                    maxW="800px"
                    h={{ base: '270px', md: 'auto' }}
                >
                    <Image
                        src={
                            width > 768
                                ? promoBlock.background_image_url.desktop
                                : promoBlock.background_image_url.mobile
                        }
                        alt="banner course"
                        pos={'absolute'}
                        top={0}
                        left={0}
                        w="100%"
                        h={'100%'}
                        objectFit="cover"
                    />
                </Box>
            </Flex>
        </Box>
    );
}
