import { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';

import WatchQuizBannerFirst from './WatchQuizBannerFirst';
import WatchQuizBannerCountdown from './WatchQuizBannerCountdown';
import WatchTrySubBanner from './WatchTrySubBanner';

import { URLS } from '@/common/constants/global';
import { CurrentStepEventNames } from '@/entities/quizes/quiz';
import { checkPassedHours, fetchData, METHODS } from '@/shared/lib';
import { userAtom } from '@/shared/store';
import { ActionSubType, useCreateSub } from '@/features/subscription/create-sub';
import { Portal, useDisclosure } from '@chakra-ui/react';
import { CongratulationsModal } from '@/entities/modals';
import { getCountdownReminingTime } from '@/features/promo/countdown';

const TIMER_COUNTDOWN_HOURS = 12;

export default function WatchQuizBanner() {
    const user = useAtomValue(userAtom);
    const [packageInfo, setPackageInfo] = useState(null);
    const { isSubLoading, isSubError, sendError, loadingText, createSub } = useCreateSub();
    const { isOpen: isOpenCongrat, onOpen: onOpenCongrat } = useDisclosure();

    useEffect(() => {
        async function getPackageInfo() {
            const { data } = await fetchData(URLS.PACKAGE_INFO_COURSE_WATCH, {
                method: METHODS.GET,
            });
            setPackageInfo(data.banner);
        }

        if (!packageInfo) {
            getPackageInfo();
        }
    }, [user]);

    const onLastBannerBtnClick = async (action_type: ActionSubType) => {
        await createSub(action_type);
        onOpenCongrat();
    };

    if (!user) {
        return null;
    }

    const isLeaveFirstStep =
        user &&
        user?.quiz_info.quiz_thank_page_step_history.includes(CurrentStepEventNames.QuizAgeQuestionStep) &&
        !user?.quiz_info.quiz_thank_page_step_history.includes(CurrentStepEventNames.QuizFinalPageStep);

    const isLeaveFinalStep =
        user && user?.quiz_info.quiz_thank_page_step_history.includes(CurrentStepEventNames.QuizFinalPageStep);

    const isTimePassed = user.quiz_info.date_timer_start
        ? checkPassedHours(user.quiz_info.date_timer_start, TIMER_COUNTDOWN_HOURS)
        : null;

    // Если юзер бросил квиз на первом шаге, то первый баннер и он весит пока юзер не пройдет квиз
    if (isLeaveFirstStep && !user.is_subscribe) {
        return (
            <WatchQuizBannerFirst
                styles={{
                    mx: { base: -5, md: 0 },
                }}
            />
        );
    }
    // Если юзер бросил на последнем шаге, то баннер с таймером и он висит 12 часов (тайм штамп) и потом появляется текущий
    else if (isLeaveFinalStep && !user.is_subscribe && !isTimePassed) {
        const reminingTime = getCountdownReminingTime(user.quiz_info.date_timer_start, TIMER_COUNTDOWN_HOURS);

        // Текуший висит, если время вышло
        if (reminingTime === 0) {
            return (
                <>
                    <WatchTrySubBanner
                        data={packageInfo}
                        onClick={onLastBannerBtnClick}
                        state={{
                            isLoading: isSubLoading,
                            isError: isSubError,
                            loadingText: loadingText,
                            sendError: sendError!,
                        }}
                    />
                    <Portal>
                        <CongratulationsModal
                            isOpen={isOpenCongrat}
                            onClose={() => location.reload()}
                        />
                    </Portal>
                </>
            );
        } else {
            return <WatchQuizBannerCountdown timerSeconds={reminingTime} />;
        }
    }
    // Текуший висит пока юзер не оформит подписку
    else if (!user.is_subscribe) {
        return (
            <>
                <WatchTrySubBanner
                    data={packageInfo}
                    onClick={onLastBannerBtnClick}
                    state={{
                        isLoading: isSubLoading,
                        isError: isSubError,
                        loadingText: loadingText,
                        sendError: sendError!,
                    }}
                />
                <Portal>
                    <CongratulationsModal
                        isOpen={isOpenCongrat}
                        onClose={() => location.reload()}
                    />
                </Portal>
            </>
        );
    }
}
