import { useEffect, useState } from 'react';
import { useBoolean } from '@chakra-ui/react';

import { METHODS, fetchDataCb } from '@/shared/lib';
import { EventsFilters } from '../models/types';
import { EventsData, setStartDataToEvents } from '@/entities/events/event-data';

const getPageEventUrl = (pageNumber: number, filters: EventsFilters | null) => {
    const authorQuery = filters?.name ? `&search[author_name]=${filters?.name}` : '';
    const dateQuery = filters?.date ? `&search[start_date]=${filters?.date}` : '';

    return `/api/events?expand=thumb,user_start_date&search[past]=true${authorQuery}${dateQuery}&sort=-start_date&per-page=8&page=${pageNumber}`;
};

export default function useFilterEvents() {
    const [eventsList, setEventsList] = useState<EventsData | null>(null);

    const [isEventsLoading, setIsEventsLoading] = useBoolean(true);

    const [filters, setFilters] = useState<EventsFilters | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        setEventsList(null);

        fetchDataCb<EventsData>(
            METHODS.GET,
            getPageEventUrl(currentPage, filters),
            (data) =>
                setEventsList({
                    data: setStartDataToEvents(data.data),
                    pagination: data.pagination,
                }),
            setIsEventsLoading,
        );
    }, [filters, currentPage, setIsEventsLoading]);

    return {
        currentPage,
        eventsList,
        isEventsLoading,
        setCurrentPage,
        setFilters,
    };
}
