import { QuizList } from '../types/quiz-list-types';

export const COURSE_QUIZ_LIST_AGE: QuizList[] = [
    {
        name: 'your_age',
        label: '18-29',
        value: '18-29',
    },
    {
        name: 'your_age',
        label: '30-39',
        value: '30-39',
    },
    {
        name: 'your_age',
        label: '40-49',
        value: '40-49',
    },
    {
        name: 'your_age',
        label: '50+',
        value: '50+',
    },
];

export const COURSE_QUIZ_LIST_AGE_SHORT: QuizList[] = [
    {
        name: 'your_age',
        label: 'Under 40',
        value: '40-',
    },
    {
        name: 'your_age',
        label: '40-50',
        value: '40-50',
    },
    {
        name: 'your_age',
        label: '50+',
        value: '50+',
    },
];

export const COURSE_QUIZ_LIST_AGE_EXPANDED: QuizList[] = [
    {
        name: 'your_age',
        label: '18-29',
        value: '18-29',
    },
    {
        name: 'your_age',
        label: '30-39',
        value: '30-39',
    },
    {
        name: 'your_age',
        label: '40-49',
        value: '40-49',
    },
    {
        name: 'your_age',
        label: '50-59',
        value: '50-59',
    },
    {
        name: 'your_age',
        label: '60-69',
        value: '60-69',
    },
    {
        name: 'your_age',
        label: '70+',
        value: '70+',
    },
];

export const COURSE_QUIZ_LIST_TIME: QuizList[] = [
    {
        name: 'how-much-time',
        label: '30 minutes or less',
        value: '30-minutes',
    },
    {
        name: 'how-much-time',
        label: '1-2 hours',
        value: '1-2-hours',
    },
    {
        name: 'how-much-time',
        label: 'more than 2 hours',
        value: 'more-than-2-hours',
    },
];

export enum Gender {
    Female = 'female',
    Male = 'male',
    Other = 'other',
}

export const COURSE_QUIZ_LIST_GENDER: QuizList[] = [
    {
        name: 'your_gender',
        label: 'Female',
        value: Gender.Female,
    },
    {
        name: 'your_gender',
        label: 'Male',
        value: Gender.Male,
    },
    {
        name: 'your_gender',
        label: 'Other',
        value: Gender.Other,
    },
];
