import { forwardRef, ForwardedRef } from 'react';
import { Box, BoxProps, Flex, Img, RadioProps, Text, useRadio } from '@chakra-ui/react';

import { CheckboxData } from '@/shared/models';
import { RiCheckLine } from 'react-icons/ri';

type CustomRadioMarkProp = {
    label: string;
    radioData: CheckboxData | string;
    styles?: BoxProps;
    onChange?: any; // * потому что у события плеера другой аргумент
} & Omit<RadioProps, 'onChange'>;

const CustomRadioMark = forwardRef(
    ({ radioData, label, styles, ...props }: CustomRadioMarkProp, ref: ForwardedRef<unknown>) => {
        const { getInputProps, getRadioProps, state } = useRadio(props);

        const input = getInputProps({ ref });
        const radio = getRadioProps();

        return (
            <Box
                as="label"
                cursor="pointer"
            >
                <input {...input} />

                <Flex
                    align="center"
                    gap={2}
                    color={state.isChecked ? 'white' : 'gray.430'}
                    {...styles}
                >
                    {state.isChecked ? (
                        <Box
                            as={RiCheckLine}
                            w={{ base: '16px', lg: '20px' }}
                            h={{ base: '16px', lg: '20px' }}
                            {...radio}
                        />
                    ) : (
                        <Box
                            w={{ base: '16px', lg: '20px' }}
                            h={{ base: '16px', lg: '20px' }}
                        />
                    )}

                    <Text as="span">{label}</Text>
                </Flex>
            </Box>
        );
    },
);

CustomRadioMark.displayName = 'CustomRadioMark';

export default CustomRadioMark;
