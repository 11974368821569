import { Box, Button, Center, Container, Link, Text } from '@chakra-ui/react';
import { RiAttachment2 } from 'react-icons/ri';

export default function ChangeLife60days() {
    return (
        <Center minH="500px">
            <Container
                maxW="560px"
                textAlign="center"
            >
                <Text textStyle="lg3">Thank You for Your Purchase!</Text>

                <Text>
                    You&#39;re one step closer to your personal transformation. Click the button below to get your{' '}
                    <b>«60 Days Guide to Change Your Life»</b> and start your journey to conscious living, goal
                    achievement, and holistic wellness today.
                </Text>

                <Text>We’re excited to be part of your journey!</Text>

                <Button
                    as={Link}
                    href="https://stump-dash-b50.notion.site/60-Days-Guide-to-Change-Your-Life-Before-2025-13686b831db780ae9794feed14db748b?pvs=4"
                >
                    <Box
                        as={RiAttachment2}
                        color="white"
                        mr={2}
                        w={5}
                        h={5}
                    />
                    GET YOUR «60 DAYS GUIDE TO CHANGE YOUR LIFE»
                </Button>
            </Container>
        </Center>
    );
}
