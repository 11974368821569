import { Box, BoxProps, Flex, Heading, Spinner } from '@chakra-ui/react';

import { SwiperSlide } from 'swiper/react';

import { SliderNavigation } from '@/entities/lists/sliders/primary-slider';
import { EventCard } from '@/entities/socials/events/event-card';
import { useCourseCollection } from '@/features/courses/courses-list';

const BEST_SELLER_COLLECTION_NUMBER = 26;

export default function BestsellersList({ title, styles }: { title?: string; styles?: BoxProps }) {
    const { coursesList, isCoursesLoading } = useCourseCollection([BEST_SELLER_COLLECTION_NUMBER]);

    return (
        <Box {...styles}>
            {!isCoursesLoading && coursesList ? (
                <>
                    <Heading mb={7.5}>{title ? title : 'Top Picks of 300,000+ Students Worldwide'}</Heading>

                    <SliderNavigation>
                        {coursesList.map((item: any) => {
                            const cardData = {
                                thumb: item.thumb_big,
                                title: item.title,
                                description: null,
                            };

                            return (
                                <SwiperSlide key={`selected-course-${item.id}`}>
                                    <EventCard
                                        eventData={{ ...item, ...cardData }}
                                        styles={{
                                            w: '307px',
                                            mb: -4,
                                        }}
                                    >
                                        <></>
                                    </EventCard>
                                </SwiperSlide>
                            );
                        })}
                    </SliderNavigation>
                </>
            ) : (
                <Flex
                    minH="inherit"
                    align="center"
                    justify="center"
                >
                    <Spinner color="green.400" />
                </Flex>
            )}
        </Box>
    );
}
