import { createRoot } from 'react-dom/client';
import { Spinner } from '@chakra-ui/react';

import ProvidersCsr from '../../providers/providers-csr';

import { DashboardComments } from '@/features/comments/dashboard';
import { lazy, Suspense } from 'react';

const LazyComponent = lazy(() => import('./CourseComments'));

export const courseCommentsNode = document.getElementById('courseComments');
if (courseCommentsNode) {
    const root = createRoot(courseCommentsNode);

    root.render(
        <Suspense
            fallback={
                <Spinner
                    w={'20px'}
                    h={'20px'}
                />
            }
        >
            <LazyComponent courseCommentsNode={courseCommentsNode} />
        </Suspense>,
    );
}

const dashboardCommentsNode = document.querySelectorAll('.dashboardComments');
if (dashboardCommentsNode) {
    dashboardCommentsNode.forEach((item) => {
        const root = createRoot(item);

        root.render(
            <ProvidersCsr isAlwaysRender={true}>
                <DashboardComments />
            </ProvidersCsr>,
        );
    });
}
