import { createRoot } from 'react-dom/client';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import ProvidersCsr from '../../providers/providers-csr';

import { PromoAuthorBanner } from '@/entities/promo/banners/author';
import { CabinetUserBannerYear } from '@/entities/promo/banners/cabinet-user-banner-year';
import { DashboardTopBanner } from '@/entities/promo/banners/dashboard-banner-standard';
import { DashboardUserBannerYear } from '@/entities/promo/banners/dashboard-user-banner-year';
import { InstagramBanner } from '@/entities/promo/banners/instagram-banner';
import { WatchQuizBanner } from '@/entities/promo/banners/quiz/watch-quiz-banner';
import { BannerManaNextLevel } from '@/entities/promo/banners/expensive-products/mana-next-level';
import { BannerWomensHealth } from '@/entities/promo/banners/expensive-products/womens-health';
import { PromoBlockDashboard } from '@/entities/promo/banners/promo-block';
import { authorLandingAtom, AuthorLandingType } from '@/widgets/author/landing/model/authorLandingAtom';

// Тройной баннер на странице видео курса
const watchCourseBannerNode = document.getElementById('watch-course-banner');
if (watchCourseBannerNode) {
    const root = createRoot(watchCourseBannerNode);
    root.render(
        <ProvidersCsr>
            <WatchQuizBanner />
        </ProvidersCsr>,
    );
}

// Тройной баннер в дашборде
const DASHBOARD_BANNER_STANDARD_PRICE_EL = document.getElementById('DASHBOARD_BANNER_STANDARD_PRICE');
if (DASHBOARD_BANNER_STANDARD_PRICE_EL) {
    const root = createRoot(DASHBOARD_BANNER_STANDARD_PRICE_EL);
    root.render(
        <ProvidersCsr>
            <DashboardTopBanner />
        </ProvidersCsr>,
    );
}

// Баннер с годовой подпиской в дашборде
const DASHBOARD_USER_BANNER_UPGRADE_YEAR_EL = document.getElementById('DASHBOARD_USER_BANNER_UPGRADE_YEAR');
if (DASHBOARD_USER_BANNER_UPGRADE_YEAR_EL) {
    const root = createRoot(DASHBOARD_USER_BANNER_UPGRADE_YEAR_EL);
    root.render(
        <ProvidersCsr>
            <DashboardUserBannerYear styles={{ ml: 0 }} />
        </ProvidersCsr>,
    );
}

// Баннер с годовой подпиской в лк
const CABINET_USER_BANNER_UPGRADE_YEAR_EL = document.getElementById('CABINET_USER_BANNER_UPGRADE_YEAR');
if (CABINET_USER_BANNER_UPGRADE_YEAR_EL) {
    const root = createRoot(CABINET_USER_BANNER_UPGRADE_YEAR_EL);
    root.render(
        <ProvidersCsr>
            <CabinetUserBannerYear styles={{ mt: 5 }} />
        </ProvidersCsr>,
    );
}

// Инста баннер на главной и в дашборде
const instagramBannerNode = document.getElementById('instagram-banner');
if (instagramBannerNode) {
    const root = createRoot(instagramBannerNode);

    root.render(
        <ProvidersCsr>
            <InstagramBanner styles={{ mt: { base: '60px', md: '90px' } }} />
        </ProvidersCsr>,
    );
}

// Баннер со ссылкой на страницу курса авторов на главной и в дашборде
const authorLandingBannerNode = document.getElementById('author-landing-banner');
if (authorLandingBannerNode) {
    const root = createRoot(authorLandingBannerNode);

    const PromoAuthorBannerWithBtnUrl = () => {
        const setAuthorLandingAtom = useSetAtom(authorLandingAtom);

        const buttonUrl = authorLandingBannerNode.getAttribute('data-button-url');

        useEffect(() => {
            setAuthorLandingAtom({
                type: AuthorLandingType.PUBLIC,
                buttonUrl: buttonUrl,
            });
        }, []);

        return <PromoAuthorBanner />;
    };

    root.render(
        <ProvidersCsr>
            <PromoAuthorBannerWithBtnUrl />
        </ProvidersCsr>,
    );
}

// Баннер с новым курсом маны
const manaMeiNextLevelBannerNode = document.getElementById('mana-mei-next-level');
if (manaMeiNextLevelBannerNode) {
    const root = createRoot(manaMeiNextLevelBannerNode);

    root.render(
        <ProvidersCsr>
            <BannerManaNextLevel />
        </ProvidersCsr>,
    );
}

// Баннер с новым курсом Алеса
const womensHealthBannerNode = document.getElementById('womens-health-banner');
if (womensHealthBannerNode) {
    const root = createRoot(womensHealthBannerNode);

    root.render(
        <ProvidersCsr>
            <BannerWomensHealth />
        </ProvidersCsr>,
    );
}

// Баннер с новым курсом Алеса
const promoBlockBannerDashboardNode = document.getElementById('promoBlockBannerDashboard');
if (promoBlockBannerDashboardNode) {
    const root = createRoot(promoBlockBannerDashboardNode);

    root.render(
        <ProvidersCsr>
            <PromoBlockDashboard />
        </ProvidersCsr>,
    );
}
