import { createRoot } from 'react-dom/client';

import ProvidersCsr from '../../providers/providers-csr';

import { CabinetUserNameData } from '@/entities/client/cabinet/name-data';
import { unsubFlowOpenStateAtom, UnsubscribeFlowModal } from '@/entities/unsubscribe/unsubscribe-flow';
import { csrStore } from '@/entities/csr/store';
import { UploadAvatar } from '@/features/profile/upload-avatar';

// Изменение имени в лк
const CABINET_USER_NAME_DATA = document.getElementById('react-user-name-data');
if (CABINET_USER_NAME_DATA) {
    const root = createRoot(CABINET_USER_NAME_DATA);
    root.render(
        <ProvidersCsr>
            <CabinetUserNameData />
        </ProvidersCsr>,
    );
}

// Изменение имени в лк
const profileAvatarNode = document.getElementById('profileAvatar');

if (profileAvatarNode) {
    const root = createRoot(profileAvatarNode);

    root.render(
        <ProvidersCsr>
            <UploadAvatar />
        </ProvidersCsr>,
    );
}

// Модалка отписки
const unsubFlowModalNode = document.getElementById('unsubFlowModal');

if (unsubFlowModalNode) {
    const root = createRoot(unsubFlowModalNode);

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <UnsubscribeFlowModal />
        </ProvidersCsr>,
    );

    const openUnsubFlowNodes = document.querySelectorAll('.open-unsub-flow');

    if (openUnsubFlowNodes) {
        for (const openUnsubEl of openUnsubFlowNodes) {
            openUnsubEl.addEventListener('click', () => {
                // Управляем состоянием через глобальный стор
                csrStore.set(unsubFlowOpenStateAtom, true);
            });
        }
    }
}
