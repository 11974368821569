import { Box, useRadio } from '@chakra-ui/react';
import { RiStarFill } from 'react-icons/ri';

import { colors } from '@/common/theme/constant';
import { ForwardedRef, MouseEventHandler, forwardRef } from 'react';

interface RadioRateProps {
    hoverIndex: number;
    fixedIndex: number;
    isHover: boolean;
    onMouseEnter: MouseEventHandler<HTMLInputElement>;
    onClick: MouseEventHandler<HTMLInputElement>;
}

const RadioRate = forwardRef(
    (
        { hoverIndex, fixedIndex, isHover, onMouseEnter, onClick, ...props }: RadioRateProps,
        ref: ForwardedRef<unknown>,
    ) => {
        const { getInputProps } = useRadio(props);

        const input = getInputProps({ ref });

        return (
            <Box
                as="label"
                cursor="pointer"
                onMouseEnter={onMouseEnter}
                onClick={onClick}
            >
                <input {...input} />

                <RiStarFill
                    fontSize="24px"
                    color={
                        hoverIndex >= parseInt(input.value) || (!isHover && fixedIndex >= parseInt(input.value))
                            ? colors.yellow[800]
                            : colors.gray[400]
                    }
                />
            </Box>
        );
    },
);

RadioRate.displayName = 'RadioRate';

export default RadioRate;
