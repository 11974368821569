import { Box, BoxProps, Button, Fade, Text, VStack } from '@chakra-ui/react';


import { Picture } from '@/shared/ui';
import { APP_BRAND } from '@/common/constants/brand';
import { currencyMap } from '@/entities/formatted/common';
import { capitalizeFirstLetter } from '@/shared/lib';

interface BannerProps {
    trial_days: number;
    price: string;
    currency: string;
    plan_id: number;
    action_type: string;
}

export interface WatchTrySubBannerProps {
    data: BannerProps | null;
    onClick: (action_type: any) => void;
    state?: {
        isLoading: boolean;
        isError: boolean;
        loadingText: string;
        sendError: string;
    };
    styles?: BoxProps;
}

export default function WatchTrySubBanner({ data, onClick, state, styles }: WatchTrySubBannerProps) {
    if (!data) {
        return null;
    }

    const { trial_days: trialDays, price, currency, plan_id, action_type } = data;

    return (
        <Box
            pt={7.5}
            px={{ base: 5, lg: 7.5 }}
            background="url(/img/common/bg-peach-blue-vertical.png) no-repeat 100% 100%"
            backgroundSize="cover"
            borderRadius={{ md: 'xl' }}
            {...styles}
        >
            <Text
                textStyle="md3"
                mb={5}
            >
                Subscribe Once, Learn Without Limits on {capitalizeFirstLetter(APP_BRAND)}
            </Text>

            <Text
                fontSize="sm3"
                mb={{ md: 7.5 }}
            >
                With one subscription, open doors to more than 140 present and future courses focusing on the enrichment
                of mind, body, relationships, efficiency, the art of dance, and various other fields. Start with a free{' '}
                {trialDays}-day trial, and then continue learning at {currencyMap[currency]}
                {price} per month.
            </Text>

            <VStack>
                <Button
                    w="100%"
                    textTransform="none"
                    onClick={() => onClick(action_type)}
                    isLoading={state?.isLoading}
                    loadingText={state?.loadingText}
                >
                    TRY {capitalizeFirstLetter(APP_BRAND)} COURSES
                </Button>

                {state?.sendError && (
                    <Fade in={true}>
                        <Text
                            mb={0}
                            textAlign="center"
                            color="red.500"
                        >
                            {state.sendError}
                        </Text>
                    </Fade>
                )}
            </VStack>

            <Picture
                src="promo/try-sub-box.png"
                alt="try-sub-box"
                pictureStyle={{
                    display: 'block',
                    mt: { base: 5, xl: 3 },
                    mx: { base: -5, xl: -7.5 },
                }}
                imgStyle={{
                    mx: 'auto',
                }}
            />
        </Box>
    );
}
