import { useAtomValue } from 'jotai';
import { useBoolean, useToast } from '@chakra-ui/react';

import { ActionSubType, useCreateSub } from '../create-sub';
import { CurrentStepEventNames, quizSettingAtom } from '@/entities/quizes/quiz';
import { RedirectQuizType } from '@/entities/quizes/quiz/model/stores/quiz-store';
import { dispatchBrowserEvent } from '@/shared/lib';
import { tariffAtom } from '../tariffs/model/tariff-store';
import { userAtom } from '@/shared/store';
import { userDataFromBladeAtom } from '@/entities/user/user-data-from-blade/user-data-from-blade-store';
import { useLoadingTextWithFetch } from '@/shared/ui';

export default function useSubFromMonolith(actionSubType?: ActionSubType) {
    const subData = useAtomValue(tariffAtom);
    const user = useAtomValue(userAtom);
    const quizSetting = useAtomValue(quizSettingAtom);
    const toast = useToast();

    const [isSubmitted, setIsSubmitted] = useBoolean(false);

    const actionSubTypeMnltYear = quizSetting?.options.actionSubTypeMnltYear || '';
    const actionSubTypeMnlt = quizSetting?.options.actionSubTypeMnlt || '';
    // у годовой подписки приоритет
    const { createSub } = useCreateSub((actionSubType || actionSubTypeMnltYear || actionSubTypeMnlt) as ActionSubType);

    const userDataFromBlade = useAtomValue(userDataFromBladeAtom);
    const email = userDataFromBlade?.email || '';

    const isInitOnboarding = userDataFromBlade?.tp;

    const quizRedirect = quizSetting?.options.redirect || '';
    const isStripedSub = quizRedirect === RedirectQuizType.Stripe;
    // приход с баннера, почты или вотсапа
    const isContinuedOnboarding =
        [RedirectQuizType.BannerDashboad, RedirectQuizType.Mailchimp, RedirectQuizType.Whatsapp].includes(
            quizRedirect as RedirectQuizType,
        ) || !user?.quiz_info.quiz_thank_page_step_history.includes(CurrentStepEventNames.QuizOnboardingPageStep);

    const { loadingText, sendError, textedFetch } = useLoadingTextWithFetch(async () => {
        setIsSubmitted.on();

        try {
            if (isInitOnboarding) {
                await createSub();
                toast({
                    duration: 5000,
                    status: 'success',
                    title: 'You have successfully subscribed to all the courses!',
                    position: 'top-right',
                });

                setTimeout(() => {
                    window.location.pathname = '/redirect-to-onboarding-page';
                }, 3000);
            } else if (subData) {
                dispatchBrowserEvent('buy_subscription_social_user', { data_sub: subData });
            } else if (isContinuedOnboarding) {
                await createSub();
                window.location.pathname = '/client';
            } else if (isStripedSub) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                window.payByStripeSubscriptionThroughOrderFromReact(email); // ! монолит
            }
        } catch (e) {
            toast({
                title: 'Error occurred during subscription',
                status: 'error',
                duration: 5000,
                position: 'top-right',
            });

            setTimeout(() => {
                window.location.pathname = '/redirect-to-onboarding-page';
            }, 2000);
        }

        setTimeout(() => {
            setIsSubmitted.off();
        }, 2000);
    });

    return { isSubmitted, createSub: textedFetch, loadingText, sendError };
}
