import { Box, Button, Text } from '@chakra-ui/react';

export default function PauseScreen({ onNext }: { onNext: () => void }) {
    return (
        <Box
            px={{ base: 6, md: 10 }}
            py={10}
        >
            <Text
                textStyle="lg"
                textAlign={'center'}
            >
                Take a break – we’ll be here when you’re ready!
            </Text>

            <Text mb={4}>
                Your subscription is now paused. <b>During this time, you won’t be charged,</b> but don’t worry – you’ll
                keep access to all the courses you’ve purchased.
            </Text>

            <Text
                textStyle="sm"
                mb={6}
            >
                This pause will last for one month. If you’d like to resume sooner, simply log into your account and
                reactivate your subscription.
            </Text>

            <Button
                onClick={onNext}
                w="100%"
            >
                Explore Courses Now
            </Button>
        </Box>
    );
}
