import { atom } from 'jotai';

interface UserDataFromBladeAtomProps {
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    tp?: string | null;
    urlCheckEmail?: string | null;
}

export const userDataFromBladeAtom = atom<UserDataFromBladeAtomProps | null>(null);
