import { ReactNode } from 'react';
import { Text, Box, FlexProps, HStack, Flex } from '@chakra-ui/react';

import { EventData } from '@/entities/events/event-data';
import EventCard from './EventCard';
import { OnAirStatus } from '@/entities/socials/events/on-air-status';
import { IconMap, IconNames } from '@/shared/ui';

interface EventCardProps {
    eventData: EventData;
    children?: ReactNode;
    styles?: FlexProps;
}

export default function EventCardDate({ eventData, children, styles }: EventCardProps) {
    return (
        <Flex
            flexDir="column"
            maxW="100%"
            h="100%"
            borderRadius="xl"
            bgColor={eventData.startDate!.isToday ? 'green.200' : ''}
            boxShadow="0 0 20px 0 rgba(128, 128, 128, 0.2)"
            {...styles}
        >
            <HStack
                px={4}
                pt={3}
                pb={8}
                borderRadius="inherit"
                border={eventData.startDate!.isToday ? '1px solid' : ''}
                borderColor={eventData.startDate!.isToday ? 'green.350' : ''}
            >
                <Box
                    as={IconMap[IconNames.Calendar]}
                    w="20px"
                    h="20px"
                    color={eventData.startDate!.isToday ? 'green.400' : 'gray.475'}
                />

                <Text
                    as="span"
                    color={eventData.startDate!.isToday ? 'green.400' : ''}
                    fontWeight={700}
                >
                    {eventData.startDate!.day}
                </Text>

                <Text as="span">{eventData.startDate!.time}</Text>

                {eventData.is_on_air && (
                    <OnAirStatus
                        styles={{
                            ml: 1,
                            transform: 'translateY(-1px)',
                        }}
                    />
                )}
            </HStack>

            {children ? (
                children
            ) : (
                <EventCard
                    eventData={eventData}
                    styles={{
                        h: 'auto',
                        flex: 1,
                        mt: -6,
                    }}
                />
            )}
        </Flex>
    );
}
