import { Box, BoxProps } from '@chakra-ui/react';

import { MONTH_PRICE } from '@/common/constants/brand';
import { useAtomValue } from 'jotai';
import { userAtom } from '@/shared/store';

export default function PricesFreeDays({ styles }: { styles?: BoxProps }) {
    const user = useAtomValue(userAtom);

    if (!user) {
        return null;
    }

    return (
        <Box
            maxW="142px"
            mx="auto"
            mb={0}
            mt={4}
            color="white"
            textAlign="center"
            {...styles}
        >
            30{' '}
            <Box
                className="prices-free-day__throught-day"
                display="inline-block"
                opacity={0.7}
                mx="2px"
                pos="relative"
            >
                7
                <Box
                    as="span"
                    position="absolute"
                    left="-3px"
                    top="49%"
                    width="15px"
                    borderTop="1px solid"
                    borderColor="currentColor"
                    transform="rotate(-20deg)"
                />
            </Box>{' '}
            days free, then {user.quiz_info.is_quiz_year_sub ? '$190/year' : `${MONTH_PRICE}/month`}
        </Box>
    );
}
