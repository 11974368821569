import { fetchData, METHODS } from '@/shared/lib';

export async function updateEventNotification(isNotificationEnabled: boolean, eventId: number) {
    try {
        await fetchData(`/api/events/${eventId}/user-notification`, {
            method: isNotificationEnabled ? METHODS.DELETE : METHODS.POST,
        });
    } catch (error) {
        throw new Error(`Not update event notification: ${error}`);
    }
}
