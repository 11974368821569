import { Box, Button, Text } from '@chakra-ui/react';

import { getNextDateFromToday } from '@/shared/lib';
import { userAtom } from '@/shared/store';
import { useAtomValue } from 'jotai';

export default function AdditionalDaysScreen({ onNext }: { onNext: () => void }) {
    const user = useAtomValue(userAtom);

    if (!user) {
        return null;
    }

    return (
        <Box
            px={{ base: 6, md: 10 }}
            py={10}
        >
            <Text
                textStyle="lg"
                textAlign={'center'}
            >
                Your Free Trial Extended!
            </Text>

            <Text mb={4}>
                Your free trial has been successfully extended for an additional 7 days, completely free, until{' '}
                {getNextDateFromToday(true, 'dd.MM.yyyy', user.subscription_trial_days + 7)}.
            </Text>

            <Text
                textStyle="sm"
                mb={6}
            >
                We’re confident you’ll love exploring everything our platform has to offer. Enjoy unlimited access to
                all courses and features during this extended trial period!
            </Text>

            <Text
                textStyle="md5"
                mb={6}
                textAlign={'center'}
                fontWeight={600}
            >
                Ready to dive back in?
            </Text>

            <Button
                onClick={onNext}
                w="100%"
            >
                Explore Courses Now
            </Button>
        </Box>
    );
}
