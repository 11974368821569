import { Box, BoxProps, Flex, HStack, Image, Text } from '@chakra-ui/react';
import { RiStarSFill } from 'react-icons/ri';

import { useLandingProvider } from '../../model/LandingProvider';
import { TrailerPlayerLight } from '@/features/video-player';

export default function TopBanner({ styles }: { styles?: BoxProps }) {
    const { courseData, courseInfoData } = useLandingProvider();
    const { title, rating, reviewCount, description } = courseInfoData;

    return (
        <Flex
            pos="relative"
            flexDir={{ base: 'column', lg: 'row-reverse' }}
            color="white"
            {...styles}
        >
            <Box
                hideBelow="lg"
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                background="linear-gradient(90deg, #111 33.48%, rgba(17, 17, 17, 0.5) 55.54%, rgba(17, 17, 17, 0) 75.75%)"
                zIndex={10}
            />
            <Box
                pos={{ lg: 'absolute' }}
                top="0"
                right="-200px"
                w={{ lg: '1120px' }}
                h="100%"
            >
                <Box
                    pos="relative"
                    mx={{ base: -5, lg: 0 }}
                    mb={{ base: 5, lg: 0 }}
                    h="100%"
                >
                    <TrailerPlayerLight videoSrc={courseData.video_intro} />
                </Box>
            </Box>

            <Box
                maxW={{ lg: '500px' }}
                pt={{ lg: '160px' }}
                pb={{ lg: '80px' }}
                mr={'auto'}
                zIndex={10}
            >
                <Image
                    src="/img/icons/texts/premium.svg"
                    alt="premium"
                    mb={2}
                    w="83px"
                />

                <HStack
                    gap="2.5"
                    mb={4}
                    fontWeight={700}
                >
                    <HStack gap="2">
                        <Box
                            as={RiStarSFill}
                            fontSize="24px"
                            color="yellow.450"
                            mb="1px"
                        />
                        <Text as="span">{rating}</Text>
                    </HStack>

                    <Text as="span"> / </Text>

                    <Text
                        as="span"
                        color="green.400"
                    >
                        {reviewCount} reviews
                    </Text>
                </HStack>

                <Text
                    as="h1"
                    mb={{ base: 2, lg: 4 }}
                    fontSize={{ base: '20px', lg: '40px' }}
                    fontWeight={700}
                    lineHeight={1.2}
                >
                    {title}
                </Text>

                <Text mb={0}>{description}</Text>
            </Box>
        </Flex>
    );
}
