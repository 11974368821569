import { ReactNode, useState } from 'react';
import { Box, BoxProps, Img } from '@chakra-ui/react';
import { Swiper, SwiperClass } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import 'swiper/css';

interface SliderProps {
    children: ReactNode;
    options?: SwiperOptions;
    isNavShow?: boolean;
    styles?: BoxProps;
}

export default function SliderNavigation({ children, options, isNavShow, styles }: SliderProps) {
    const [swiperState, setSwiperState] = useState<SwiperClass>();

    const handleNext = () => {
        swiperState?.slideNext();
    };
    const handlePrevious = () => {
        swiperState?.slidePrev();
    };

    return (
        <Box
            layerStyle="swiper-navigation-fullwidth"
            {...styles}
        >
            <Swiper
                onSwiper={(swiper) => setSwiperState(swiper)}
                slidesPerView="auto"
                spaceBetween="20"
                modules={[Navigation]}
                watchSlidesProgress={true}
                breakpoints={{
                    767: {
                        freeMode: false,
                    },
                }}
                {...options}
            >
                {children}
            </Swiper>

            <Box
                hideBelow="md"
                display={isNavShow ? '' : 'none'}
            >
                <Box
                    className="image-swiper-button-next"
                    onClick={handleNext}
                >
                    <Img
                        src="/img/icons/arrow-right-s-line.svg"
                        alt="arrow"
                        w="24px"
                    />
                </Box>

                <Box
                    className="image-swiper-button-prev"
                    onClick={handlePrevious}
                >
                    <Img
                        src="/img/icons/arrow-left-s-line.svg"
                        alt="arrow"
                        w="24px"
                    />
                </Box>
            </Box>
        </Box>
    );
}
