import { Box, BoxProps, Flex, Image, Spinner } from '@chakra-ui/react';
import { SwiperSlide } from 'swiper/react';

import { SliderNavigation } from '@/entities/lists/sliders/primary-slider';
import { useCourseCollection } from '@/features/courses/courses-list';

const BEST_SELLER_COLLECTION_NUMBER = 26;

export default function BestsellersListSimple({
    selectedCoursesList,
    styles,
}: {
    selectedCoursesList?: any;
    styles?: BoxProps;
}) {
    const { coursesList, isCoursesLoading } = useCourseCollection([BEST_SELLER_COLLECTION_NUMBER]);

    if (selectedCoursesList) {
        return (
            <Box {...styles}>
                {!isCoursesLoading && coursesList ? (
                    <SliderNavigation options={{ spaceBetween: 10 }}>
                        {selectedCoursesList.map((item: any) => {
                            return (
                                <SwiperSlide key={`selected-course-${item.id}`}>
                                    <Image
                                        src={item.thumb_big}
                                        alt={item.title}
                                        borderRadius="xl"
                                        w="200px"
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </SliderNavigation>
                ) : (
                    <Flex
                        minH="inherit"
                        align="center"
                        justify="center"
                    >
                        <Spinner color="green.400" />
                    </Flex>
                )}
            </Box>
        );
    }

    return (
        <Box {...styles}>
            {!isCoursesLoading && coursesList ? (
                <SliderNavigation options={{ spaceBetween: 10 }}>
                    {coursesList.slice(0, 6).map((item: any) => {
                        return (
                            <SwiperSlide key={`selected-course-${item.id}`}>
                                <Image
                                    src={item.thumb_big}
                                    alt={item.title}
                                    borderRadius="xl"
                                    w="200px"
                                />
                            </SwiperSlide>
                        );
                    })}
                </SliderNavigation>
            ) : (
                <Flex
                    minH="inherit"
                    align="center"
                    justify="center"
                >
                    <Spinner color="green.400" />
                </Flex>
            )}
        </Box>
    );
}
