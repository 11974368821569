import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(cardAnatomy.keys);

const blurVariants = definePartsStyle({
    container: {
        flexShrink: 0,
        overflow: 'hidden',
        w: '310px',
        maxW: '100%',
        h: '100%',
        borderRadius: 'xl',
    },
    body: {
        justifyContent: 'space-between',
        pos: 'relative',
        p: 4,
    },
    footer: {
        p: 4,
        pt: 0,
    },
});

export const cardTheme = defineMultiStyleConfig({
    variants: {
        blur: blurVariants,
    },
});
