import { Box, HStack, Image, Text } from '@chakra-ui/react';
import { RiInformation2Fill } from 'react-icons/ri';

export default function CheckoutPaymentDesc() {
    return (
        <Box mt={4}>
            <HStack>
                <Text
                    mb={0}
                    fontSize="15px"
                >
                    100% secure payment
                </Text>

                <Image
                    src="/img/payment/cards.png"
                    alt="cards"
                />
            </HStack>

            <HStack
                mt={3}
                color="gray.500"
            >
                <Box
                    as={RiInformation2Fill}
                    w="13px"
                    h="13px"
                />

                <Text
                    mb={0}
                    fontSize="13px"
                >
                    Your cards are safely stored so you can reuse the payment method.
                </Text>
            </HStack>
        </Box>
    );
}
