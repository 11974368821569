import { useBoolean } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';

import { SearchType, useCoursesListByValue } from '@/features/courses/courses-list';
import { ISelectedCourses } from './types';
import { SELECT_CATEGORIES } from '@/common/constants/local-storage';
import { findCategoryWithMostMatches, getLabeledSelectedCourses } from './utils';
import { quizSettingAtom } from '@/entities/quizes/quiz';
import { CourseQuizPriceTextsMap, CourseQuizBtnElementsMap } from './constants';
import { courseOfQuizAtom, planOfCourseOfQuizAtom } from '@/entities/quizes/quiz/model/stores/quiz-store';
import { prospectiveGet, prospectivePost } from './prospectiveFetch';
import { userDataFromBladeAtom } from '@/entities/user/user-data-from-blade/user-data-from-blade-store';

const DEFAULT_COURSE_TITLE = 'Vocal Yoga';
const DEFAULT_COURSE_ID = 1008;

export default function useClassesForYouLanding() {
    // Локальные состояния для хранения значений курсов
    const [selectedCourseValues, setSelectedCourseValues] = useState<string[]>([]);
    const [selectedCourses, setSelectedCourses] = useState<any[]>([]);
    const [selectedCoursesListFormat, setSelectedCoursesListGroup] = useState<ISelectedCourses[] | null>(null);
    const [isFetch, setIsFetch] = useBoolean(true);

    const userEmail = useAtomValue(userDataFromBladeAtom); // store
    const courseOfQuiz = useAtomValue(courseOfQuizAtom); // store

    const quizSetting = useAtomValue(quizSettingAtom);
    const planOfCourseOfQuiz = useAtomValue(planOfCourseOfQuizAtom);

    const promoText = quizSetting
        ? CourseQuizPriceTextsMap[quizSetting.type](
            planOfCourseOfQuiz?.trialDays || '..',
            planOfCourseOfQuiz?.price || '..',
        )
        : '';
    const BtnEl = quizSetting ? CourseQuizBtnElementsMap[quizSetting.type] : null;

    const nameOfCourse = (courseOfQuiz && courseOfQuiz.title) || DEFAULT_COURSE_TITLE;
    const idOfCourse = (courseOfQuiz?.id && parseInt(courseOfQuiz.id)) || DEFAULT_COURSE_ID;

    // Инициализация из localStorage
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const storedData = window.localStorage.getItem(SELECT_CATEGORIES);

            if (storedData) {
                const parsedData = JSON.parse(storedData);
                setSelectedCourseValues(parsedData.selectedCourseValues);
                setSelectedCourses(parsedData.courseList);
            }
        }
    }, []);

    // Логика для получения данных от сервера
    useEffect(() => {
        if (!isFetch) {
            return;
        }
        async function prospectiveFetch() {
            // Если нет выбранных курсов, то делаем запрос
            if (userEmail?.email && selectedCourseValues.length === 0) {
                const data = await prospectiveGet(userEmail.email);
                // Если есть данные о курсах
                if (data && data.recommends_course_by_user.length > 0) {
                    setSelectedCourseValues(data.recommends_course_by_user);

                    // Находим категорию с наибольшим количеством совпадений и получаем курсы с лейблами
                    const selectedCoursesWithLabel = findCategoryWithMostMatches(data.recommends_course_by_user);
                    setSelectedCourses(selectedCoursesWithLabel);
                }
                setIsFetch.off();
            } else if (userEmail?.email && selectedCourseValues.length > 0) {
                await prospectivePost({
                    recommend_courses_id: Array.from(new Set(selectedCourseValues)).map((item) => parseInt(item)),
                    course_id: courseOfQuiz?.id,
                    email: userEmail.email,
                });
                setIsFetch.off();
            }
        }

        prospectiveFetch();
    }, [userEmail, selectedCourseValues]);

    // Получаем список курсов по значениям
    const {
        coursesList: selectedCoursesList,
        isCoursesLoading: isSelectedCoursesLoading,
        getCourses,
    } = useCoursesListByValue(SearchType.Id, Array.from(new Set(selectedCourseValues)), idOfCourse);

    useEffect(() => {
        if (selectedCoursesList) {
            getCourses();
        }
    }, [selectedCourseValues]);

    useEffect(() => {
        if (!selectedCoursesList) {
            getCourses();
        }
    }, [courseOfQuiz]);

    // Обновление списка курсов с добавлением label
    useEffect(() => {
        if (selectedCoursesList) {
            const newList = getLabeledSelectedCourses(selectedCourses, selectedCoursesList);
            setSelectedCoursesListGroup(newList);
        }
    }, [selectedCoursesList]);

    return {
        classesQuantity: selectedCourseValues.length,
        isSelectedCoursesLoading,
        selectedCoursesList: selectedCoursesListFormat,
        promoText,
        nameOfCourse,
        BtnEl,
    };
}
