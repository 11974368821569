import {
    BoxProps,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Link,
    Text,
    useBoolean,
    useToast,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { CustomCheckbox } from '@/shared/ui';
import { validation } from '@/shared/lib';
import { APP_BRAND } from '@/common/constants/brand';
import { UTM_SOURCES_LIST } from '@/common/constants/global';

interface Inputs {
    email: string;
    agree_terms: boolean;
}

export default function TildaCourseBuyFormDark({ coursesId, styles }: { coursesId: number[]; styles?: BoxProps }) {
    const isCheckedTerms = UTM_SOURCES_LIST.some((platform: string) => window.location.search.includes(platform));
    const [isSubmitted, setIsSubmitted] = useBoolean(false);

    const {
        register,
        getFieldState,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            email: (document.getElementById('realEmail') as HTMLInputElement)?.value || '',
            agree_terms: isCheckedTerms,
        },
    });
    const toast = useToast();

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        setIsSubmitted.on();

        setTimeout(() => {
            setIsSubmitted.off();
        }, 10000);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        window.payByStripeOptionsFromReact(data.email, { optional_courses: coursesId });
    };

    const checkToastVisible = () => {
        const isEmailInvalid = getFieldState('email').invalid;
        const isAgreeTermsInvalid = getFieldState('agree_terms').invalid;

        if (isEmailInvalid || isAgreeTermsInvalid) {
            toast({
                duration: 5000,
                status: 'error',
                title: isEmailInvalid ? `Please check your email address` : `Please agree the terms`,
                position: 'top-right',
            });
        }
    };

    return (
        <Flex
            flexDir="column"
            gap={5}
            as="form"
            onSubmit={handleSubmit(onSubmit, checkToastVisible)}
            {...styles}
        >
            <FormControl isInvalid={Boolean(errors.email)}>
                <FormLabel
                    mb={1}
                    fontWeight={400}
                >
                    Your Email
                </FormLabel>
                <Input
                    variant={'default'}
                    type="text"
                    placeholder="example@mail.com"
                    border="1px solid #fff"
                    borderRadius="555px"
                    bgColor="#000"
                    {...register('email', validation.email)}
                />
            </FormControl>

            <Controller
                name="agree_terms"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }: TObj<any>) => {
                    return (
                        <CustomCheckbox
                            checkboxProps={{
                                isChecked: Boolean(value),
                                onChange: onChange,
                                isInvalid: Boolean(errors.agree_terms),
                            }}
                            LabelEl={() => (
                                <Text
                                    display="inline"
                                    mb={0}
                                    lineHeight={1.75}
                                    fontWeight={400}
                                    letterSpacing="0.5px"
                                    color={errors.agree_terms ? 'red' : 'white'}
                                >
                                    I agree with the terms of the{' '}
                                    <Link
                                        href={`https://account.${APP_BRAND}.com/cancellation-refund-policies`}
                                        textDecoration="underline"
                                    >
                                        Refund Policies
                                    </Link>
                                    ,{' '}
                                    <Link
                                        href={`https://account.${APP_BRAND}.com/terms-of-service`}
                                        textDecoration="underline"
                                    >
                                        Terms of Service
                                    </Link>
                                    ,{' '}
                                    <Link
                                        href={`https://account.${APP_BRAND}.com/privacy-policy`}
                                        textDecoration="underline"
                                    >
                                        Privacy Policy
                                    </Link>
                                </Text>
                            )}
                        />
                    );
                }}
            />

            <Button
                type="submit"
                p={5}
                mt={2}
                w="100%"
                fontSize="sm3"
                fontWeight={500}
                isLoading={isSubmitted}
                loadingText="Submitting..."
            >
                OPEN ACCESS NOW
            </Button>
        </Flex>
    );
}
