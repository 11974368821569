import { Stepper, Step, VStack, StepIndicator, StepStatus, Text, BoxProps } from '@chakra-ui/react';


export default function NavInfoStepper({ activeStep, styles }: { activeStep: number; styles?: BoxProps }) {
    return (
        <Stepper
            index={activeStep}
            variant="quiz"
            gap={2}
            {...styles}
        >
            <VStack
                as={Step}
                maxW={{ base: '33%' }}
                flex="1 !important"
            >
                <StepIndicator>
                    <StepStatus />
                </StepIndicator>

                <Text
                    mb={0}
                    hideBelow="md"
                >
                    Goals
                </Text>
            </VStack>

            <VStack
                as={Step}
                maxW={{ base: '33%' }}
                flex="1 !important"
            >
                <StepIndicator>
                    <StepStatus />
                </StepIndicator>

                <Text
                    mb={0}
                    hideBelow="md"
                >
                    Time
                </Text>
            </VStack>

            <VStack
                as={Step}
                maxW={{ base: '33%' }}
                flex="1 !important"
            >
                <StepIndicator>
                    <StepStatus />
                </StepIndicator>

                <Text
                    mb={0}
                    hideBelow="md"
                >
                    For you
                </Text>
            </VStack>
        </Stepper>
    );
}
