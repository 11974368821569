import { Button } from '@chakra-ui/react';
import { RiArrowLeftSLine } from 'react-icons/ri';

import { URLS } from '@/common/constants/global';
import { CheckEmailQuiz } from '@/entities/onboarding/check-email';
import { CheckPhoneQuiz } from '@/entities/onboarding/check-phone';
import { capitalizeFirstLetter, fetchData, METHODS } from '@/shared/lib';
import CustomQuiz from '../../ui/components/CustomQuiz';
import CustomQuizBtned from '../../ui/components/CustomQuizBtned';
import { EventNames } from '../types/event-quiz-types';
import { QuizGroup, QuizGroupType, isCheckboxesQuiz } from '../types/quiz-list-types';
import { COURSE_CATEGORIES_QUIZ_TRIAL_NEW } from './course-categories-list';
import { ALL_COURSES_LIST_NEW_TEST_GENDERED_LIFESTYLE } from './courses-by-category-gender-lists-test-quiz';
import { Gender, COURSE_QUIZ_LIST_AGE_SHORT } from './radio-list';
import { APP_BRAND } from '@/common/constants/brand';
import PictureQuizSlide from '../../ui/components/PictureQuizSlide';

export const QUIZ_LONGEVITY_LIFESTYLE: QuizGroup[] = [
    {
        type: QuizGroupType.GenderSingle,
        eventName: EventNames.Gender,
        title: `Thank you for purchasing the course!`,
        subTitle: 'Let’s personalize your experience. Please select your gender.',
        quizList: [
            {
                name: 'your_gender',
                label: 'Female',
                value: Gender.Female,
            },
            {
                name: 'your_gender',
                label: 'Male',
                value: Gender.Male,
            },
        ],
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            const onClickOption = async (formData: any) => {
                try {
                    await fetchData(URLS.UPDATE_PROFILE, {
                        method: METHODS.POST,
                        body: JSON.stringify({
                            gender: formData.formData.radio === 'female' ? 0 : 1,
                        }),
                    });
                } finally {
                    onNextBtnClick(formData);
                }
            };

            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onClickOption}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.Other,
        eventName: EventNames.CheckEmail,
        title: 'Please confirm your email to ensure you receive important updates and access details.',
        Component: ({ userDataFromBlade, onNextBtnClick }) => {
            return (
                <CheckEmailQuiz
                    styles={{
                        maxW: '500px',
                        mx: 'auto',
                        textAlign: 'center',
                        pt: { base: 7.5, md: 12 },
                        sx: {
                            form: {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                px: { md: 12 },
                            },
                        },
                    }}
                    title="Please confirm your email to ensure you receive important updates and access details."
                    userEmail={userDataFromBlade?.email ?? ''}
                    postFormUrl={userDataFromBlade?.urlCheckEmail ?? ''}
                    cb={onNextBtnClick}
                />
            );
        },
    },
    {
        type: QuizGroupType.Other,
        eventName: EventNames.AddPhone,
        title: 'Please provide your phone number to securely restore access via WhatsApp or SMS if needed.',
        Component: ({ onNextBtnClick }) => {
            return (
                <CheckPhoneQuiz
                    title="Keep Your Access Secure: Share Your Phone Number for Easy Recovery"
                    subTitle="We’ll use this to help restore your access via WhatsApp or SMS if needed"
                    styles={{
                        maxW: '500px',
                        mx: 'auto',
                        textAlign: 'center',
                        pt: { base: 7.5, md: 12 },
                        sx: {
                            form: {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            },
                        },
                    }}
                    cb={onNextBtnClick}
                />
            );
        },
    },
    {
        type: QuizGroupType.DefaultSingle,
        eventName: EventNames.Age,
        title: `Tell Us Your Age to Tailor the Best Programs for You`,
        subTitle: 'Receive personalized recommendations based on your age and needs',
        quizList: COURSE_QUIZ_LIST_AGE_SHORT,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            const onClickOption = async (formData: any) => {
                try {
                    await fetchData(URLS.UPDATE_PROFILE, {
                        method: METHODS.POST,
                        body: JSON.stringify({
                            age: formData.formData.radio,
                        }),
                    });
                } finally {
                    onNextBtnClick(formData);
                }
            };

            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onClickOption}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.DefaultSingle,
        eventName: EventNames.HowOftenDoYouEngage,
        title: `How Often Do You Engage in Physical Activities?`,
        subTitle: 'Let us know to recommend wellness programs that match your lifestyle for health and longevity',
        quizList: [
            {
                name: 'how_often',
                label: 'Daily',
                value: 'Daily',
            },
            {
                name: 'how_often',
                label: 'A few times a week',
                value: 'A few times a week',
            },
            {
                name: 'how_often',
                label: 'Rarely',
                value: 'Rarely',
            },
        ],
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.DefaultSingle,
        eventName: EventNames.PhysicalActivitiesReduceStress,
        title: `Did You Know That Staying Active Boosts Your Well-being?`,
        subTitle:
            'Regular fitness, yoga, singing, dance, and meditation can greatly reduce stress and improve your health',
        quizList: [
            {
                name: 'extend_life',
                label: 'Yes, I knew',
                value: 'Yes, I knew',
            },
            {
                name: 'extend_life',
                label: "No, that's new to me",
                value: "No, that's new to me",
            },
        ],
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.DefaultSingle,
        eventName: EventNames.HealthLifestyle,
        title: `Do You Feel Like You’re Leading a Healthy Lifestyle?`,
        subTitle: 'Your answer will help us tailor recommendations that fit your current habits and goals',
        quizList: [
            {
                name: 'health_lifestyle',
                label: 'Yes, I’m pretty healthy',
                value: 'Yes, I’m pretty healthy',
            },
            {
                name: 'health_lifestyle',
                label: 'Sometimes, but I could do better',
                value: 'Sometimes, but I could do better',
            },
            {
                name: 'health_lifestyle',
                label: 'Not really, I struggle with it',
                value: 'Not really, I struggle with it',
            },
            {
                name: 'health_lifestyle',
                label: 'I’m just starting my journey',
                value: 'I’m just starting my journey',
            },
        ],
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.DefaultSingle,
        eventName: EventNames.HealthLifestyleExtendLife,
        title: `Did You Know That a Healthy Lifestyle Can Extend Your Life?`,
        subTitle:
            'Proper sleep, healthy nutrition, and regular physical activity are scientifically proven to extend life. Recent studies from the Journal of the American Medical Association and Oxford University Press predict that with the right practices, humans could live up to 120-130 years.',
        quizList: [
            {
                name: 'awareness',
                label: 'Yes, I’ve heard about this',
                value: 'Yes, I’ve heard about this',
            },
            {
                name: 'awareness',
                label: 'No, that’s news to me',
                value: 'No, that’s news to me',
            },
        ],
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.CategorySingle,
        eventName: EventNames.ChooseCategory,
        title: `Which Area Would You Like to Focus on for Personal Growth?`,
        subTitle: 'Choose the area that will bring you the most happiness and vitality',
        quizList: COURSE_CATEGORIES_QUIZ_TRIAL_NEW,
        categoryList: ALL_COURSES_LIST_NEW_TEST_GENDERED_LIFESTYLE,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.Picture,
        eventName: EventNames.DiscoverPrograms,
        isNextStep: true,
        imgSrc: 'courses/courses-grid.png',
        title: 'Discover Programs to Support Your Personal Growth',
        imgDesc: `At ${capitalizeFirstLetter(APP_BRAND)}+, we offer over 250 specialized programs tailored to enhance your energy, clarity, fitness, and emotional well-being. Join us to make meaningful progress toward a happier, healthier you!`,
        Component: ({ currentQuiz, onNextBtnClick, onPrevBtnClick }) => {
            return (
                <PictureQuizSlide
                    slideData={currentQuiz}
                    NextBtnEl={() => (
                        <Button
                            width="100%"
                            height="45px"
                            onClick={onNextBtnClick}
                        >
                            Okay
                        </Button>
                    )}
                    PrevBtnEl={() => (
                        <Button
                            variant="pagination"
                            h="45px"
                            onClick={onPrevBtnClick}
                        >
                            <RiArrowLeftSLine />
                        </Button>
                    )}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        px: { base: 5, md: 15 },
                        py: { base: 7.5, md: 15 },
                        borderRadius: 'xl',
                        mx: { base: -5, md: 'auto' },
                        bg: 'gray.300',
                    }}
                />
            );
        },
    },
    {
        type: QuizGroupType.DefaultSingle,
        eventName: EventNames.JourneyNotAlone,
        title: `Do You Feel Like You’re on This Journey Alone?`,
        subTitle:
            'Many people find it challenging to make lasting changes without a community. Together, we can make your path to well-being more connected and supportive.',
        quizList: [
            {
                name: 'connection_preference',
                label: 'Yes, I’d love more connection',
                value: 'Yes, I’d love more connection',
            },
            {
                name: 'connection_preference',
                label: 'No, I’m okay on my own',
                value: 'No, I’m okay on my own',
            },
        ],
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.Picture,
        eventName: EventNames.SupportiveCommunity,
        title: 'Join a Supportive Community for Your Journey',
        imgSrc: 'courses/courses-motion.gif',
        imgDesc: `At ${capitalizeFirstLetter(APP_BRAND)}+, we host daily live events where you can ask questions to course authors, participate in guided practices, and connect with like-minded individuals. It’s a great way to stay motivated and share your journey with others!`,
        Component: ({ currentQuiz, onNextBtnClick, onPrevBtnClick }) => {
            return (
                <PictureQuizSlide
                    slideData={currentQuiz}
                    NextBtnEl={() => (
                        <Button
                            width="100%"
                            height="45px"
                            onClick={onNextBtnClick}
                        >
                            Okay
                        </Button>
                    )}
                    PrevBtnEl={() => (
                        <Button
                            variant="pagination"
                            h="45px"
                            onClick={onPrevBtnClick}
                        >
                            <RiArrowLeftSLine />
                        </Button>
                    )}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        px: { base: 5, md: 15 },
                        py: { base: 7.5, md: 15 },
                        borderRadius: 'xl',
                        mx: { base: -5, md: 'auto' },
                        bg: 'gray.300',
                    }}
                />
            );
        },
    },
    {
        type: QuizGroupType.DefaultSingle,
        eventName: EventNames.AchieveYourGoals,
        title: `Do You Find It Challenging to Achieve Your Goals and See Results?`,
        subTitle:
            'Many people struggle with maintaining motivation and focus when working towards their personal goals.',
        quizList: [
            {
                name: 'achieve_your_goals',
                label: 'Yes, I often lose motivation',
                value: 'Yes, I often lose motivation',
            },
            {
                name: 'achieve_your_goals',
                label: 'Sometimes, it’s hard to stay consistent',
                value: 'Sometimes, it’s hard to stay consistent',
            },
            {
                name: 'achieve_your_goals',
                label: 'I’m doing okay but could use a boost',
                value: 'I’m doing okay but could use a boost',
            },
            {
                name: 'achieve_your_goals',
                label: 'No, I usually stay on track',
                value: 'No, I usually stay on track',
            },
        ],
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.Picture,
        eventName: EventNames.SupportiveCommunity,
        title: 'Join Fun and Motivating Challenges to Stay on Track',
        imgSrc: 'promo/people/people-met-sunset.png',
        imgDesc: `At ${capitalizeFirstLetter(APP_BRAND)}, we offer a variety of challenges that allow you to work on your goals together with a supportive community. These challenges can help you improve your life, reach your targets, and even have fun while completing exciting tasks that make every day better!`,
        Component: ({ currentQuiz, onNextBtnClick, onPrevBtnClick }) => {
            return (
                <PictureQuizSlide
                    slideData={currentQuiz}
                    NextBtnEl={() => (
                        <Button
                            width="100%"
                            height="45px"
                            onClick={onNextBtnClick}
                        >
                            Okay
                        </Button>
                    )}
                    PrevBtnEl={() => (
                        <Button
                            variant="pagination"
                            h="45px"
                            onClick={onPrevBtnClick}
                        >
                            <RiArrowLeftSLine />
                        </Button>
                    )}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        px: { base: 5, md: 15 },
                        py: { base: 7.5, md: 15 },
                        borderRadius: 'xl',
                        mx: { base: -5, md: 'auto' },
                        bg: 'gray.300',
                    }}
                />
            );
        },
    },
    {
        type: QuizGroupType.DefaultSingle,
        eventName: EventNames.MakeYourEffortsMoreEffective,
        title: `Want to Learn How to Make Your Efforts More Effective?`,
        subTitle: 'Let us share tips and tricks to help you reach your wellness goals faster and easier.',
        quizList: [
            {
                name: EventNames.MakeYourEffortsMoreEffective,
                label: 'Yes, I’d love to learn more!',
                value: 'Yes, I’d love to learn more!',
            },
            {
                name: EventNames.MakeYourEffortsMoreEffective,
                label: 'I’m open to new ideas',
                value: 'I’m open to new ideas',
            },
        ],
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.Picture,
        eventName: EventNames.LongevityExpertAdvice,
        title: 'Get Longevity Expert Advice',
        imgSrc: 'promo/people/zoom-girl.png',
        imgDesc: `Our platform offers personalized support with a health and longevity tracker who regularly checks in to help you stay consistent with your wellness goals. Imagine having a dedicated guide to keep you motivated! With our platform, you can schedule a call with our health coach and receive a customized roadmap for your journey toward lasting vitality and well-being.`,
        Component: ({ currentQuiz, onNextBtnClick, onPrevBtnClick }) => {
            return (
                <PictureQuizSlide
                    slideData={currentQuiz}
                    NextBtnEl={() => (
                        <Button
                            width="100%"
                            height="45px"
                            onClick={onNextBtnClick}
                        >
                            Okay
                        </Button>
                    )}
                    PrevBtnEl={() => (
                        <Button
                            variant="pagination"
                            h="45px"
                            onClick={onPrevBtnClick}
                        >
                            <RiArrowLeftSLine />
                        </Button>
                    )}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        px: { base: 5, md: 15 },
                        py: { base: 7.5, md: 15 },
                        borderRadius: 'xl',
                        mx: { base: -5, md: 'auto' },
                        bg: 'gray.300',
                    }}
                />
            );
        },
    },
    {
        type: QuizGroupType.Other,
        title: `Subscribe to ${capitalizeFirstLetter(APP_BRAND)}+ and get your first 7 days for free!`,
        subTitle: `At ${capitalizeFirstLetter(APP_BRAND)}+, you’ll have access to over 250 programs, including:`,
        eventName: EventNames.TrialSub,
        Component: ({ currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuizBtned
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    onSkipBtnClick={() => (window.location.pathname = '/redirect-to-quiz-final-page')}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                />
            );
        },
    },
];
