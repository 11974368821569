import { APP_BRAND, HELLO_EMAIL } from '@/common/constants/brand';
import { capitalizeFirstLetter } from '@/shared/lib';


export const COMMON_FAQ = [
    {
        title: `How long do I have access to the course?`,
        description: `Forever. You can take your time and go through the lessons at a pace that is comfortable for you and at a convenient time.`,
    },
    {
        title: `When does the course start?`,
        description: `Forever. You can take your time and go through the lessons at a pace that is comfortable for you and at a convenient time.`,
    },
    {
        title: `How to access the course?`,
        description: `Forever. You can take your time and go through the lessons at a pace that is comfortable for you and at a convenient time.`,
    },
    {
        title: `How to pay for the course?`,
        description: `Forever. You can take your time and go through the lessons at a pace that is comfortable for you and at a convenient time.`,
    },
    {
        title: `Can I pay from Europe, USA or another country?`,
        description: `Forever. You can take your time and go through the lessons at a pace that is comfortable for you and at a convenient time.`,
    },
    {
        title: `Can I buy a course as a gift?`,
        description: `Forever. You can take your time and go through the lessons at a pace that is comfortable for you and at a convenient time.`,
    },
    {
        title: "If I don't like the course, can I get my money back?",
        description: `Forever. You can take your time and go through the lessons at a pace that is comfortable for you and at a convenient time.`,
    },
    {
        title: `How to cancel a membership?`,
        description: `Forever. You can take your time and go through the lessons at a pace that is comfortable for you and at a convenient time.`,
    },
];

export const TILDA_FAQ_DATA = [
    {
        title: `For how long do I have access to the course?`,
        description: `You have unlimited time access to the course. You can take the course at your convenience, at your own pace.`,
    },
    {
        title: `When does the course start?`,
        description: `After you pay and sign up you will receive an email link witn an access password to your personal cabinet where all course materials are already in place.`,
    },
    {
        title: `How do I get access to the course?`,
        description: `To access the course you have to make a payment and give your email address, to which you will receive an email with a link and a password.`,
    },
    {
        title: `How do I pay for the course?`,
        description: `You can pay for the course using any Visa, MasterCard, Maestro, Visa Electron cards.`,
    },
    {
        title: `Can I buy the course as a present for someone?`,
        description: `Yes. In that case, when you register for the course please provide us with the email address of the person you want to receive the gift, and you will receive an authorization letter and access to your personal account.`,
    },
    {
        title: `If I don't like the course, can I get my money back?`,
        description: `Yes, you can. You do not run any risks. If the course doesn't meet your expectations within the first 14 days of purchase, we'll give you a full refund on your card, no questions asked. Just email our support at ${HELLO_EMAIL}`,
    },
];

export const SHORT_FAQ_DATA = [
    {
        title: `What's included in the membership?`,
        description: `As a ${capitalizeFirstLetter(APP_BRAND)} member, you get unlimited access to all courses anytime, anywhere for as long as your membership is active. Plus, you'll join an exclusive community, networking and participating in new and upcoming courses, weekly online events with our top authors.`,
    },
    {
        title: `How to pay for the membership?`,
        description: `When purchasing a course on our platform you can also add a free 7-day or 30-day trial period to your order for all our courses and events, which ends with an automatic membership payment for the first month of use. Thereafter, the membership will be charged on a monthly basis. You can cancel your membership at any time in personal account or by contacting our Support Team.`,
    },
    {
        title: `How do I cancel or get a refund if I’m not satisfied?`,
        description: `You can unsubscribe at any time that’s convenient for you. To cancel your membership, go to the "Settings and membership" section in your personal account and click the "Cancel membership" button. The link to your personal account can be found here: <a href="${typeof window !== 'undefined' ? window.location.origin : ''}">${typeof window !== 'undefined' ? window.location.origin : ''}/client/</a> We can't issue refunds once your card is charged. All memberships are non-refundable.`,
    },
];
