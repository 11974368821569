import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const brandPrimary = definePartsStyle({
    container: {
        bg: 'orange.400',
        color: 'blackAlpha.700',
    },
});

const thick = definePartsStyle({
    container: {
        px: '4',
        py: '2',
        bg: 'blue.400',
    },
});

const roundedVariant = definePartsStyle({
    container: {
        py: 1,
        px: 2.5,
        background: 'gray.410',
        borderRadius: 'xl',
        textTransform: 'uppercase',
        color: 'white',
        fontWeight: 500,
    },
});

const roundedOutlineVariant = definePartsStyle({
    container: {
        py: '6px',
        px: 2.5,
        borderRadius: 'xl',
        border: '1px solid',
        borderColor: 'gray.500',
        fontSize: '14px',
        lineHeight: 1,
        fontWeight: 400,
    },
});

export const tagTheme = defineMultiStyleConfig({
    variants: {
        brand: brandPrimary,
        thick: thick,
        rounded: roundedVariant,
        'rounded-outline': roundedOutlineVariant,
    },
});
