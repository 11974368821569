import { Box, Button, Fade, Flex, HStack, Text } from '@chakra-ui/react';
import { RiCloseCircleLine } from 'react-icons/ri';

import { OptionalCourseDataItem } from '../../../model/types';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';

export default function CartSecondStep({
    handleSubmit,
    goToNextStep,
    courseList,
    onDeleteOptionClick,
    OptionEl,
    optionListFilter,
    onOptionClick,
    totalPrice,
    isSubmitted,
}: any) {
    return (
        <Box
            as="form"
            onSubmit={handleSubmit(goToNextStep)}
        >
            <Text
                mt={2}
                mb={2.5}
                fontSize={{ base: '24px', md: '26px' }}
                lineHeight={1.4}
                fontWeight={600}
            >
                Wait! Unlock Special Limited-Time Offer!
            </Text>

            <Text
                mb={5}
                fontSize={{ base: 'sm', md: 'sm2' }}
                lineHeight={1.4}
                fontWeight={600}
            >
                Before you complete your purchase, grab these exclusive courses at a huge discount!
            </Text>

            <Box
                layerStyle="hr"
                as="hr"
            />

            {courseList &&
                courseList.map((item: any, index: number) => {
                    return (
                        <Fade
                            key={item.id}
                            in={true}
                        >
                            <HStack
                                my={8}
                                gap={6}
                                justifyContent="space-between"
                            >
                                <Text
                                    as="span"
                                    fontWeight={600}
                                >
                                    {item.title}
                                </Text>
                                <Text
                                    as="span"
                                    flexShrink={0}
                                    ml="auto"
                                >
                                    ${item.price}
                                </Text>
                                <Box
                                    flexShrink={0}
                                    w={5}
                                    h={5}
                                >
                                    {index !== 0 && (
                                        <Box
                                            as={RiCloseCircleLine}
                                            color="gray.400"
                                            cursor="pointer"
                                            w="100%"
                                            h="100%"
                                            _hover={{ opacity: 0.7 }}
                                            onClick={() => onDeleteOptionClick(item)}
                                        />
                                    )}
                                </Box>
                            </HStack>
                        </Fade>
                    );
                })}

            <Box
                layerStyle="hr"
                as="hr"
            />

            {OptionEl && optionListFilter && (
                <Flex
                    justify="flex-start"
                    gap={2.5}
                    mt={5}
                    mb={7.5}
                >
                    <OverlayScrollbarsComponent>
                        <Flex
                            gap={2.5}
                            pb={2.5}
                        >
                            {optionListFilter.map((course: OptionalCourseDataItem) => (
                                <Box
                                    as={Fade}
                                    in={true}
                                    key={course.id}
                                    w="155px"
                                >
                                    <OptionEl
                                        onOptionClick={onOptionClick}
                                        courseData={course}
                                    />
                                </Box>
                            ))}
                        </Flex>
                    </OverlayScrollbarsComponent>
                </Flex>
            )}

            <Box
                mt={8}
                mb={4}
                fontWeight={600}
                textAlign="right"
            >
                <Text as="span">Total:</Text>{' '}
                <Text as="span">{courseList && <>$ {Number(totalPrice).toFixed(2)}</>}</Text>
            </Box>

            <Box
                layerStyle="hr"
                as="hr"
                mb={5}
            />

            <Button
                type="submit"
                p={5}
                mt={2}
                w="100%"
                fontSize="sm3"
                fontWeight={500}
                isLoading={isSubmitted}
                loadingText="Submitting..."
            >
                PROCEED TO CHECKOUT
            </Button>
        </Box>
    );
}
