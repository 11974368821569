import { Box, Flex, Text } from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';

import useDashboardComments from '../model/useDashboardComments';
import CommentItem from './component/CommentItem';

export default function DashboardComments() {
    const { comments } = useDashboardComments();

    return (
        <Box
            h={{ lg: '325px' }}
            overflow={'hidden'}
        >
            <Text
                mb={4}
                fontSize={'18px'}
                fontWeight={500}
            >
                Recent Comments
            </Text>

            <Box
                as={OverlayScrollbarsComponent}
                h={'calc(100% - 43px)'}
            >
                <Flex
                    flexDir={'column'}
                    gap={4}
                    pr={'10px'}
                >
                    {comments.map((comment) => (
                        <CommentItem
                            key={comment.id}
                            commentData={comment}
                        />
                    ))}
                </Flex>
            </Box>
        </Box>
    );
}
