import { Box, BoxProps, Button, Text } from '@chakra-ui/react';

import { Picture, PictureType } from '@/shared/ui';
import { fetchData, METHODS } from '@/shared/lib';
import { URLS } from '@/common/constants/global';
import { USER_SESSION_ID_KEY } from '@/common/constants/local-storage';
import { ActionSubType } from '@/features/subscription/create-sub';

export default function WatchQuizBannerFirst({ styles }: { styles?: BoxProps }) {
    const onBtnClick = async () => {
        const userId = localStorage.getItem(USER_SESSION_ID_KEY); // mnlt

        await fetchData(URLS.EVENT, {
            method: METHODS.POST,
            body: JSON.stringify({
                event_type: 'start_free_trial',
                user_session_uid: userId,
                event_properties: {
                    name: ActionSubType.QuizBannerWatchCoursePage,
                },
            }),
        });
        window.location.pathname = '/quiz-onboarding/banner/watch';
    };

    return (
        <Box
            background="url(/img/bg/pink-pink-ver.png) no-repeat 50% 50%"
            backgroundSize="cover"
            borderRadius={{ md: 'xl' }}
            pt="10"
            {...styles}
        >
            <Text
                maxW={{ base: '264px', lg: '186px' }}
                mb={7.5}
                mx="auto"
                textAlign="center"
                fontSize="26px"
                fontWeight={700}
            >
                Create Your Custom Learning Plan
            </Text>

            <Button
                onClick={onBtnClick}
                variant="white"
                display="flex"
                mx="auto"
                textTransform="uppercase"
                fontWeight={700}
                fontSize="sm"
            >
                Get started
            </Button>

            <Picture
                src="promo/people/thinking-girl.png"
                alt="thinking-girl"
                type={PictureType.Simple2x}
                imgStyle={{
                    display: 'block',
                    mx: 'auto',
                    mt: '6',
                    w: { md: '95%' },
                    mr: { md: 0 },
                }}
            />
        </Box>
    );
}
