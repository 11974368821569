import { Box, Button, Fade, Flex, FormControl, FormLabel, HStack, Input, Link, Text } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { RiCloseCircleLine } from 'react-icons/ri';

import { APP_BRAND } from '@/common/constants/brand';
import { validation } from '@/shared/lib';
import { CustomCheckbox } from '@/shared/ui';
import { CourseDataItem } from '../../../model/types';

export default function CartFirstStep({
    courseList,
    onDeleteOptionClick,
    totalPrice,
    handleSubmit,
    goToNextStep,
    checkToastVisible,
    isSubmitted,
    errors,
    register,
    control,
}: any) {
    return (
        <>
            <Text
                mb={7.5}
                fontSize={{ base: '24px', md: '26px' }}
                lineHeight={1.4}
                fontWeight={600}
            >
                Save your seat right now with a special price
            </Text>

            <Box
                layerStyle="hr"
                as="hr"
            />

            {courseList &&
                courseList.map((item: CourseDataItem, index: number) => {
                    return (
                        <Fade
                            key={item.id}
                            in={true}
                        >
                            <HStack
                                my={8}
                                gap={6}
                                justifyContent="space-between"
                            >
                                <Text
                                    as="span"
                                    fontWeight={600}
                                >
                                    {item.title}
                                </Text>
                                <Text
                                    as="span"
                                    flexShrink={0}
                                    ml="auto"
                                >
                                    ${item.price}
                                </Text>
                                <Box
                                    flexShrink={0}
                                    w={5}
                                    h={5}
                                >
                                    {index !== 0 && (
                                        <Box
                                            as={RiCloseCircleLine}
                                            color="gray.400"
                                            cursor="pointer"
                                            w="100%"
                                            h="100%"
                                            _hover={{ opacity: 0.7 }}
                                            onClick={() => onDeleteOptionClick(item)}
                                        />
                                    )}
                                </Box>
                            </HStack>
                        </Fade>
                    );
                })}

            <Box
                layerStyle="hr"
                as="hr"
            />

            <Box
                mt={8}
                mb={4}
                fontWeight={600}
                textAlign="right"
            >
                <Text as="span">Total:</Text>{' '}
                <Text as="span">{courseList && <>$ {Number(totalPrice).toFixed(2)}</>}</Text>
            </Box>

            <Box
                layerStyle="hr"
                as="hr"
                mb={5}
            />

            <Flex
                flexDir="column"
                gap={5}
                as="form"
                onSubmit={handleSubmit(goToNextStep, checkToastVisible)}
            >
                <FormControl isInvalid={Boolean(errors.email)}>
                    <FormLabel
                        mb={1}
                        fontWeight={400}
                    >
                        Your Email
                    </FormLabel>
                    <Input
                        variant="tilda"
                        type="text"
                        placeholder="example@mail.com"
                        {...register('email', validation.email)}
                    />
                </FormControl>

                <Controller
                    name="agree_terms"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }: TObj<any>) => {
                        return (
                            <CustomCheckbox
                                checkboxProps={{
                                    isChecked: Boolean(value),
                                    onChange: onChange,
                                    isInvalid: Boolean(errors.agree_terms),
                                }}
                                iconColor="#000"
                                LabelEl={() => (
                                    <Text
                                        display="inline"
                                        mb={0}
                                        lineHeight={1.75}
                                        fontWeight={400}
                                        letterSpacing="0.5px"
                                        color={errors.agree_terms ? 'red' : 'gray.500'}
                                    >
                                        I agree with the terms of the{' '}
                                        <Link
                                            href={`https://account.${APP_BRAND}.com/cancellation-refund-policies`}
                                            textDecoration="underline"
                                            color="#000"
                                        >
                                            Refund Policies
                                        </Link>
                                        ,{' '}
                                        <Link
                                            href={`https://account.${APP_BRAND}.com/terms-of-service`}
                                            textDecoration="underline"
                                            color="#000"
                                        >
                                            Terms of Service
                                        </Link>
                                        ,{' '}
                                        <Link
                                            href={`https://account.${APP_BRAND}.com/privacy-policy`}
                                            textDecoration="underline"
                                            color="#000"
                                        >
                                            Privacy Policy
                                        </Link>
                                    </Text>
                                )}
                            />
                        );
                    }}
                />

                <Button
                    type="submit"
                    p={5}
                    mt={2}
                    w="100%"
                    fontSize="sm3"
                    fontWeight={500}
                    isLoading={isSubmitted}
                    loadingText="Submitting..."
                >
                    Open Access Now
                </Button>
            </Flex>
        </>
    );
}
