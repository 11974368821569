'use client';

import { ReactNode, useEffect } from 'react';
import { Box, BoxProps, Button, Fade, Flex, Text } from '@chakra-ui/react';
import { useForm, useWatch } from 'react-hook-form';

import { CheckboxData } from '@/shared/models';
import { CheckboxGroup, CustomCheckbox, RadioGroup, RadioVariants } from '@/shared/ui';
import { consoleLogger } from '@/shared/lib';

interface QuizSubmitData {
    formData: any;
    nextIndex: number;
}

interface QuizProps {
    title: string;
    subTitle?: string;
    quizNumber: number;
    quizList: CheckboxData[];
    isCheckboxes: boolean;
    isSubmitOnSelect?: boolean;
    onNextBtnClick?: (data: QuizSubmitData) => void;
    SkipBtn?: () => ReactNode;
    NextBtn?: () => ReactNode;
    PrevBtn?: () => ReactNode;
    DescEl?: () => ReactNode;
    BottomEl?: (errors: any) => ReactNode;
    ErrorEl?: (errorText: any) => ReactNode;
    styles?: BoxProps;
}

interface InputsData {
    topics: string | [];
    other: string;
    radio: string;
}

export default function CustomQuiz({
    title,
    subTitle,
    quizNumber,
    quizList,
    isCheckboxes,
    isSubmitOnSelect,
    onNextBtnClick,
    SkipBtn,
    NextBtn,
    PrevBtn,
    DescEl,
    BottomEl,
    ErrorEl,
    styles,
}: QuizProps) {
    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty, errors },
    } = useForm<InputsData>({
        defaultValues: {
            topics: [],
            other: '',
            radio: '',
        },
    });

    const radioValue = useWatch({
        control,
        name: 'radio',
    });

    useEffect(() => {
        if (isSubmitOnSelect && !isCheckboxes && radioValue) {
            handleSubmit(onSubmit)();
        }
    }, [radioValue]);

    function onSubmit(data: InputsData) {
        consoleLogger.log('data: ', data);

        reset({
            topics: [],
            other: undefined,
            radio: '',
        });

        onNextBtnClick &&
            onNextBtnClick({
                nextIndex: quizNumber + 1,
                formData: data,
            });
    }

    return (
        <Box
            as="section"
            {...styles}
        >
            <Text
                as="h2"
                textStyle="lg4"
                textAlign="center"
                whiteSpace="pre-wrap"
            >
                {title}
            </Text>

            {subTitle && (
                <Text
                    mb={5}
                    textAlign="center"
                    whiteSpace="pre-wrap"
                >
                    {subTitle}
                </Text>
            )}

            <Box
                as="form"
                onSubmit={handleSubmit(onSubmit)}
                px={{ md: 12 }}
            >
                {isCheckboxes ? (
                    <CheckboxGroup
                        control={control}
                        name="topics"
                        rules={{ required: { value: true, message: 'Select option' } }}
                        checkboxList={quizList}
                        styles={{
                            flexDir: 'column',
                            gap: '5px',
                        }}
                        CheckboxElement={(props) => {
                            const { checkboxData, isRequired, ...restProps } = props;

                            return (
                                <CustomCheckbox
                                    checkboxData={checkboxData}
                                    checkboxProps={restProps}
                                    styles={{
                                        p: 4,
                                        backgroundColor: 'gray.300',
                                        borderRadius: 'xl',
                                    }}
                                />
                            );
                        }}
                    />
                ) : (
                    <RadioGroup
                        control={control}
                        name="radio"
                        radioList={quizList}
                        rules={{ required: { value: true, message: 'Select option' } }}
                        variant={RadioVariants.RadioQuiz}
                        styles={{
                            gap: 2,
                            flexDir: 'column',
                        }}
                        labelStyles={{
                            backgroundColor: 'gray.300',
                        }}
                    />
                )}

                {Object.keys(errors).length > 0 && (
                    <Fade in={true}>
                        {ErrorEl ? (
                            <ErrorEl errorText={errors[Object.keys(errors)[0] as keyof InputsData]?.message} />
                        ) : (
                            <Text
                                mt={5}
                                mb={0}
                                textAlign="center"
                                color="red.500"
                            >
                                {errors[Object.keys(errors)[0] as keyof InputsData]?.message}
                            </Text>
                        )}
                    </Fade>
                )}

                {BottomEl ? (
                    <BottomEl
                        errors={errors}
                        emptyValue={!isDirty}
                    />
                ) : (
                    <>
                        {SkipBtn && <SkipBtn />}
                        {DescEl && <DescEl />}

                        <Flex
                            gap={2.5}
                            justify="center"
                            mt={4}
                        >
                            {PrevBtn && <PrevBtn />}

                            {NextBtn ? (
                                <NextBtn />
                            ) : (
                                <Button
                                    type="submit"
                                    minW={{ base: '120px', md: '140px' }}
                                >
                                    Next
                                </Button>
                            )}
                        </Flex>
                    </>
                )}
            </Box>
        </Box>
    );
}
