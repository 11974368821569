import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { RiArrowRightLine } from 'react-icons/ri';

import { checkoutPricesAtom } from '../../model/checkout-store';

export default function CheckoutAddTrial({ onAddTrial }: { onAddTrial: () => void }) {
    const coursePrices = useAtomValue(checkoutPricesAtom);
    const yearPrice = coursePrices ? Number(coursePrices.subscription_price) : '';

    const discountPercentMainCourse =
        coursePrices &&
        Math.round(
            ((coursePrices?.price_base_course - coursePrices.price_course_by_subscription) /
                coursePrices?.price_base_course) *
                100,
        );

    return (
        <Flex
            flexDir={{ base: 'column', md: 'row' }}
            align="flex-start"
            gap={5}
            p={4}
            borderRadius="xl"
            border="1px solid"
            borderColor="gray.350"
            bgColor="green.50"
        >
            <Box>
                <Box>
                    <Text
                        as="span"
                        px="6px"
                        bgImage="linear-gradient(90deg,#ff4e10,#ff1b17 28%,#ff0f47 53%,#ff0571 67%,#f101e5)"
                        borderRadius="6px"
                        color="white"
                        fontSize="11px"
                        lineHeight="17px"
                        textTransform="uppercase"
                    >
                        Plus
                    </Text>
                </Box>

                <HStack color="#ff001f">
                    <Text
                        as="span"
                        textDecor="line-through"
                    >
                        ${yearPrice}
                    </Text>
                    <Box as={RiArrowRightLine} />
                    <Text as="span">$0</Text>
                </HStack>

                <Text
                    mb={0}
                    fontSize="15px"
                >
                    <b>Free Trial</b> + <b>get {discountPercentMainCourse}% OFF</b> in your purchase
                </Text>
            </Box>

            <Button
                onClick={onAddTrial}
                w="100%"
                maxW="200px"
            >
                Add trial
            </Button>
        </Flex>
    );
}
