import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { Picture } from '@/shared/ui';
import { useCoursesListByValue, SearchType } from '@/features/courses/courses-list';

export default function BannerWomensHealth() {
    const { coursesList } = useCoursesListByValue(SearchType.Id, [1174, 1186, 1111, 1146, 1110, 1209]);
    const [isBannerShow, setIsBannerShow] = useState(false);

    useEffect(() => {
        if (coursesList) {
            const isWatchOrBuyed = coursesList.some((course) => course.is_purchased || course.is_watching);

            setIsBannerShow(!isWatchOrBuyed);
        }
    }, [coursesList]);

    if (!isBannerShow) {
        return null;
    }

    return (
        <Box bgColor="#D5D4D5">
            <Flex
                justify="center"
                align={{ base: 'center', xl: 'stretch' }}
                gap={{ lg: '80px' }}
                flexDir={{ base: 'column', lg: 'row' }}
                maxW="1680px"
                mx="auto"
                overflow="hidden"
            >
                <Box
                    maxW={{ base: '500px', xl: '600px' }}
                    pt={{ base: 10, lg: 20 }}
                    pb={{ xl: '90px' }}
                    px={{ base: 6, xl: 0 }}
                    mr={{ lg: '0' }}
                    textAlign="center"
                    color="#003274"
                >
                    <Text
                        fontSize={{ base: '24px', xl: '42px' }}
                        fontWeight={700}
                        lineHeight={1.2}
                    >
                        Feel Strong, Radiant, and Balanced!
                    </Text>

                    <Text
                        mb={{ lg: 2.5 }}
                        fontSize={{ base: '14px', xl: '20px' }}
                    >
                        Go beyond Face Lifting with <b>PRO Women’s Health</b> by Dr. Ales Ulishchenko.{' '}
                        <b>Relieve pelvic discomfort,</b> enhance <b>posture,</b> and <b>boost your energy</b> with this
                        transformative, all-in-one wellness course.
                    </Text>

                    <Button
                        variant="white"
                        bgColor="#003274"
                        color="white"
                        fontWeight={400}
                        onClick={() => {
                            window.location.href = '/womans-health-course?utm_source=dashboard_ales_full';
                        }}
                    >
                        Learn more
                    </Button>
                </Box>

                <Box
                    flex="1 0 auto"
                    maxW="418px"
                    mt={{ base: -4, lg: 0 }}
                >
                    <Picture
                        src={'promo/banners/womens-health/womens-health.png'}
                        alt={'mana-next-level'}
                        imgStyle={{
                            w: '100%',
                            maxW: { base: '400px', lg: '100%' },
                            h: { lg: '100%' },
                            mx: 'auto',
                            objectFit: 'cover',
                        }}
                    />
                </Box>
            </Flex>
        </Box>
    );
}
