import { defineStyle } from '@chakra-ui/react';

const centerElement = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
};

const layerStyles = defineStyle({
    alert: {
        backgroundColor: 'red.200',
        textAlign: 'center',
        fontSize: { base: '11px', md: '13px' },
        lineHeight: { base: '20px', md: '23px' },
        fontWeight: 500,
        borderRadius: { base: 0, md: 'xl' },
    },
    'outline-radius': {
        border: '1px solid',
        borderColor: 'gray.375',
        borderRadius: 'xl',
    },
    'yellow-radius': {
        px: { base: 3, md: 4 },
        backgroundColor: 'yellow.550',
        borderRadius: 'xl',
        fontSize: { base: '11px', md: '13px' },
        lineHeight: { base: '25px', md: '31px' },
    },
    'swiper-navigation-fullwidth': {
        '.swiper': {
            m: -5,
            p: 5,
        },

        '.swiper-pagination': {
            position: 'relative',
        },

        '.swiper-slide': {
            w: 'auto !important',
            h: 'auto !important',
        },

        '.swiper-slide.swiper-slide-prev > div': {
            opacity: 0,
        },
        '.swiper-slide > div': {
            opacity: 0,
        },
        '.swiper-slide.swiper-slide-visible > div': {
            opacity: 1,
            transition: '0.23s ease-in-out',
        },

        '.image-swiper-button-next': {
            cursor: 'pointer',
            zIndex: 20,
            position: 'absolute',
            top: 'calc(50% - 24px)',
            right: '-20px',
            w: '48px',
            h: '48px',
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

            img: centerElement,

            '&.swiper-button-disabled img': {
                opacity: 0.3,
            },

            '&:not(.swiper-button-disabled):hover img': {
                opacity: 0.7,
            },
        },
        '.image-swiper-button-prev': {
            cursor: 'pointer',
            zIndex: 20,
            position: 'absolute',
            top: 'calc(50% - 24px)',
            left: '-20px',
            w: '48px',
            h: '48px',
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

            img: centerElement,

            '&.swiper-button-disabled img': {
                opacity: 0.3,
            },

            '&:not(.swiper-button-disabled):hover img': {
                opacity: 0.7,
            },
        },
    },
    'swiper-navigation-small': {
        width: '100%',
        maxW: 408,
        '.swiper': {
            // w: 'auto !important',
            // m: -5,
            // p: 5,
        },

        '.swiper-pagination': {
            position: 'relative',
        },

        // '.swiper-slide .swiper-slide-visible .swiper-slide-fully-visible .swiper-slide-active':
        '.swiper-slide': {
            maxW: '100% !important',
            h: 'auto !important',
            // border: '1px solid red !important',
        },

        '.swiper-slide.swiper-slide-prev > div': {
            opacity: 0,
        },
        '.swiper-slide > div': {
            opacity: 0,
        },
        '.swiper-slide.swiper-slide-visible > div': {
            opacity: 1,
            transition: '0.23s ease-in-out',
        },

        '.image-swiper-button-next': {
            cursor: 'pointer',
            zIndex: 20,
            position: 'absolute',
            top: 'calc(50% - 15px)',
            right: '-15px',
            w: '30px',
            h: '30px',
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

            img: centerElement,

            '&.swiper-button-disabled img': {
                opacity: 0.3,
            },

            '&:not(.swiper-button-disabled):hover img': {
                opacity: 0.7,
            },
        },
        '.image-swiper-button-prev': {
            cursor: 'pointer',
            zIndex: 20,
            position: 'absolute',
            top: 'calc(50% - 15px)',
            left: '-15px',
            w: '30px',
            h: '30px',
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

            img: centerElement,

            '&.swiper-button-disabled img': {
                opacity: 0.3,
            },

            '&:not(.swiper-button-disabled):hover img': {
                opacity: 0.7,
            },
        },
    },
    'swiper-tilda': {
        position: 'relative',

        '.swiper-pagination': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
        },
        '.image-swiper-button-next': {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 20,
            position: 'absolute',
            right: { base: '-15px', md: '-25px' },
            top: {
                base: 'calc(50% - 12px)',
                md: 'calc(50% - 25px)',
            },
            w: { base: '24px', md: '50px' },
            h: { base: '24px', md: '50px' },
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

            '&:not(.swiper-button-disabled):hover img': {
                opacity: 0.7,
            },
        },
        '.image-swiper-button-prev': {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 20,
            position: 'absolute',
            left: { base: '-15px', md: '-25px' },
            top: {
                base: 'calc(50% - 12px)',
                md: 'calc(50% - 25px)',
            },
            w: { base: '24px', md: '50px' },
            h: { base: '24px', md: '50px' },
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

            '&:not(.swiper-button-disabled):hover img': {
                opacity: 0.7,
            },
        },
    },
    'radio-quiz': {
        w: '100%',
        p: '15px',
        gap: 2.5,
        borderRadius: 'xl',
        border: '2px solid transparent',
        backgroundColor: '#fff',

        _checked: {
            borderColor: 'green.400',
        },

        '.custom-radio-icon': {
            flexShrink: 0,
            w: '24px',
            h: '24px',
            ml: 'auto',
        },
    },
    'radio-inline': {
        color: 'gray.500',

        _checked: {
            color: 'gray.675',
        },
        _focus: {
            boxShadow: 'outline',
        },

        '.custom-radio-icon': {
            flexShrink: 0,
            w: '20px',
            h: '20px',
            mr: 2.5,
        },
    },
    'radio-menu': {
        display: 'block',
        px: '12px',
        py: '3px',
        color: 'gray.500',

        _hover: {
            div: {
                color: 'green.400',
            },
        },
        _checked: {
            color: 'gray.675',
        },
        _focus: {
            boxShadow: 'outline',
        },

        '.custom-radio-icon': {
            flexShrink: 0,
            w: '20px',
            h: '20px',
            mr: 2.5,
        },
    },
    'range-date-picker': {
        // не Positioner, потому что не работает
        zIndex: 20,
        pos: 'relative',
        maxW: '320px',
        p: 4,
        bgColor: 'white',
        border: '2px solid',
        borderColor: 'green.400',
        borderRadius: 'xl',

        '[data-part="view-control"]': {
            display: 'flex',
            gap: 4,
            justifyContent: 'space-between',
        },

        '[data-part="table"]': {
            m: 'auto',
            mt: 2.5,
        },

        '[data-part="table-header"]': {
            w: '42px',
            h: '42px',
            verticalAlign: 'middle',
            textAlign: 'center',
            borderRadius: 'xl',
        },

        '[data-part="table-row"]': {
            display: 'block',
        },

        '[data-part="table-cell-trigger"]': {
            w: '40px',
            h: '40px',
            verticalAlign: 'middle',
            textAlign: 'center',
            borderRadius: 'xl',

            '&[aria-disabled="true"]': {
                color: 'gray.500',
            },
            '&[data-in-range]': {
                bgColor: 'green.200',
            },
            '&:is(:hover,[data-hover]):not([data-outside-range])': {
                bgColor: 'green.300',
            },
            '&[data-selected]': {
                bgColor: 'green.300',
            },
            '&[data-range-start]': {
                bgColor: 'green.300',
            },
            '&[data-range-end]': {
                bgColor: 'green.300',
            },
        },

        '[data-part="year-select"]': {
            mb: 2.5,
            mr: 2.5,
        },
    },
    hr: {
        h: '1px',
        borderTop: '1px dashed',
        borderColor: 'gray.380',
    },
    'center-inner': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    // 'swiper-btn-next': {
    //     cursor: 'pointer',
    //     position: 'relative',
    //     w: '48px',
    //     h: '48px',
    //     borderRadius: '50%',
    //     backgroundColor: '#fff',
    //     boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.1)',

    //     img: centerElement,

    //     '&:not(.swiper-button-disabled):hover img': {
    //         opacity: 0.7,
    //     },
    // },
});

export { layerStyles };
