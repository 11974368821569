import { HStack, Flex, Text, Box, Img } from '@chakra-ui/react';

import { IconMap, IconNames } from '@/shared/ui';
import { EventData } from '@/entities/events/event-data';
import { stuffData } from '../model/constant';

export default function EventModalBody({ eventData }: { eventData: EventData }) {
    const authorThumb = eventData.author_thumb;

    return (
        <>
            <Text textStyle="md2">{eventData.title}</Text>

            <HStack
                spacing={7.5}
                mb={5}
            >
                {stuffData.map((item, index) => {
                    return (
                        <HStack
                            key={index}
                            spacing={3}
                        >
                            {IconMap[item.icon as IconNames] && (
                                <Box
                                    as={IconMap[item.icon as IconNames]}
                                    w="32px"
                                    h="32px"
                                />
                            )}

                            <Text
                                as="span"
                                color="gray.500"
                                fontSize="sm2"
                            >
                                {item.desc}
                            </Text>
                        </HStack>
                    );
                })}
            </HStack>

            <Text
                mb={8}
                whiteSpace="pre-line"
            >
                {eventData.description}
            </Text>

            <Flex gap={5}>
                {Boolean(authorThumb) && (
                    <Img
                        src={authorThumb}
                        alt="author-thumb"
                        w="80px"
                        h="80px"
                        flexShrink={0}
                        objectFit="cover"
                        borderRadius="50%"
                    />
                )}

                <Box>
                    <Text
                        mb={0}
                        fontWeight={700}
                    >
                        {eventData.author_name}
                    </Text>

                    <Text
                        mt={4}
                        mb={0}
                        fontSize="sm2"
                        color="gray.500"
                    >
                        {eventData.author_description}
                    </Text>
                </Box>
            </Flex>
        </>
    );
}
