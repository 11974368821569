import { Box, Flex, Image, Text } from '@chakra-ui/react';

import { HTMLRenderer } from '@/shared/ui';
import { useLandingProvider } from '../../model/LandingProvider';

export default function CourseForYouIf() {
    const { courseData } = useLandingProvider();
    const { info } = courseData;

    if (!info || !info.for || !info.for.additional_list) {
        return null;
    }

    return (
        <Box>
            <Flex
                flexWrap="wrap"
                gap={{ base: 2, lg: 5 }}
                mb={{ base: 5, lg: 10 }}
            >
                {Object.values(info.for.additional_list).map((item, index) => (
                    <Flex
                        key={index}
                        flexDir={'column'}
                        justify="space-between"
                        gap={5}
                        px={4}
                        py={6}
                        w={{ base: '100%', lg: 'calc(25% - 15px)' }}
                        h={{ lg: '235px' }}
                        bgColor="rgba(255, 255, 255, 0.08)"
                        boxShadow="inset 0 0 30px 0 rgba(255, 255, 255, 0.2)"
                        borderRadius="20px"
                        fontSize="14px"
                        color="white"
                    >
                        <Image
                            src={`/img/icons/numbers/${index + 1}.png`}
                            alt="course-for-you-if"
                            w={{ base: '40px', lg: '60px' }}
                        />
                        <HTMLRenderer
                            html={item}
                            className="text-wrap"
                            styles={{ minH: { lg: '63px' } }}
                        />
                    </Flex>
                ))}
            </Flex>

            <HTMLRenderer
                html={info.for.text_after_list}
                className="text-wrap"
                styles={{
                    color: 'white',
                }}
            />
        </Box>
    );
}
