import { Box, BoxProps, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';

import { HTMLRenderer } from '@/shared/ui';
import { useLandingProvider } from '../../model/LandingProvider';

export default function WhatWillYouLearn({ styles }: { styles?: BoxProps }) {
    const { courseData } = useLandingProvider();
    const { info } = courseData;

    if (!info || !info.learn_to || !info.learn_to.additional_list) {
        return null;
    }

    return (
        <Box
            py={{ base: '40px', lg: '80px' }}
            mx={{ base: -5, lg: 0 }}
            bgColor="#5224BA"
            boxShadow="inset 0 0 60px 0 rgba(255, 255, 255, 0.26)"
            bgImage={{
                base: 'url(/img/bg/what-will-you-learn-gradient-mobile.png)',
                lg: 'url(/img/bg/what-will-you-learn-gradient-desktop.png)',
            }}
            bgRepeat="no-repeat"
            bgSize="cover"
            color="white"
            borderRadius="40px"
            {...styles}
        >
            <Container>
                <Heading as="h4">{info.learn_to.subtitle}</Heading>

                <Text mb={5}>{info.learn_to.text}</Text>

                <Text mb={5}>{info.learn_to.list_title}</Text>

                <Flex
                    flexWrap="wrap"
                    gap={{ base: 2, lg: 5 }}
                >
                    {Object.values(info.learn_to.additional_list).map((item, index) => (
                        <Flex
                            key={index}
                            flexDir={'column'}
                            justify="space-between"
                            gap={5}
                            px={4}
                            py={6}
                            w={{
                                base: '100%',
                                lg: [0, 1, 2].includes(index) ? 'calc(33% - 11px)' : 'calc(50% - 10px)',
                            }}
                            h={{ lg: '235px' }}
                            bgColor="rgba(255, 255, 255, 0.08)"
                            boxShadow="inset 0 0 30px 0 rgba(255, 255, 255, 0.2)"
                            borderRadius="20px"
                            fontSize={{ base: '14px', lg: '16px' }}
                        >
                            <Image
                                src={`/img/icons/figures/landing/${index + 1}.png`}
                                alt="what-will-you-learn"
                                w={{ base: '40px', lg: '60px' }}
                            />

                            <HTMLRenderer
                                html={item}
                                className="text-wrap"
                                styles={{ minH: { lg: '72px' } }}
                            />
                        </Flex>
                    ))}
                </Flex>
            </Container>
        </Box>
    );
}
