import { loadStripe } from '@stripe/stripe-js';

let stripePromise: any;

export const getStripe = () => {
    if (!stripePromise) {
        stripePromise = loadStripe(
            (typeof process !== 'undefined' && process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY) ||
                (document.getElementById('stripe_public_key') as any)?.content,
        );
    }
    return stripePromise;
};
