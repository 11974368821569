import { Img, Text, Wrap, WrapItem } from '@chakra-ui/react';

import { getPromoCategoriesCardList } from '../data';

export default function CategoriesCards({ isLargerThanMd }: { isLargerThanMd: boolean }) {
    return (
        <Wrap
            spacing="4px"
            justify="center"
            flexShrink={0}
            maxW={{ base: '375px', md: '352px' }}
            h={{ md: '253px' }}
            p="4px"
            margin="auto"
            sx={{
                ul: { h: '100%' },
            }}
        >
            {getPromoCategoriesCardList(isLargerThanMd).map(({ imgSrc, text }) => {
                return (
                    <WrapItem
                        key={text}
                        pos="relative"
                        w="calc(50% - 4px)"
                    >
                        <Img
                            src={imgSrc}
                            alt={text}
                            w="100%"
                            h="100%"
                            objectFit="cover"
                            borderRadius="xl"
                        />

                        <Text
                            as="span"
                            pos="absolute"
                            left="8px"
                            bottom="8px"
                            fontSize="sm2"
                            color="white"
                            fontWeight={300}
                            textTransform="uppercase"
                        >
                            {text}
                        </Text>
                    </WrapItem>
                );
            })}
        </Wrap>
    );
}
