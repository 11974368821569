import { QuizGroup, QuizGroupType } from '../types/quiz-list-types';
import { EventNames } from '@/entities/quizes/quiz/model/types/event-quiz-types';
import { CheckEmailAndPhoneQuiz } from '@/entities/onboarding/check-email';
import { UserInformation } from '@/entities/onboarding/user-information';
import { CongratulationHeader } from '@/entities/onboarding/congratulation-header';

export const QUIZ_OPTIONS_CHECKOUT: QuizGroup[] = [
    {
        type: QuizGroupType.Other,
        title: 'Check email',
        eventName: EventNames.CheckEmail,
        Component: ({ userDataFromBlade, onNextBtnClick }) => {
            return (
                <CheckEmailAndPhoneQuiz
                    styles={{
                        maxW: '380px',
                        px: 5,
                        mx: 'auto',
                        textAlign: 'center',
                        sx: {
                            form: {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            },
                        },
                    }}
                    userEmail={userDataFromBlade?.email ?? ''}
                    postFormUrl={userDataFromBlade?.urlCheckEmail ?? ''}
                    cb={() => onNextBtnClick()}
                />
            );
        },
    },
    {
        type: QuizGroupType.Other,
        title: 'User information',
        eventName: EventNames.CheckEmail,
        Component: ({ userDataFromBlade, onNextBtnClick }) => {
            return (
                <>
                    <CongratulationHeader
                        title="Thank You for Your Trust!"
                        styles={{
                            mt: { base: 5, md: 7.5 },
                        }}
                    />
                    <UserInformation
                        nextPageLink={cb}
                        styles={{
                            mt: { base: 10, md: 15 },
                            pb: { md: 7.5 },
                        }}
                    />
                </>
            );
        },
    },
];
