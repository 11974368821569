import {
    Box,
    BoxProps,
    Button,
    Container,
    Flex,
    HStack,
    Image,
    Link,
    Portal,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { RiArrowRightLine, RiCalendarLine, RiGroupLine, RiShieldCheckLine, RiUser3Line } from 'react-icons/ri';

import { HELLO_EMAIL } from '@/common/constants/brand';
import { CoursePrice } from '@/entities/landing/course-buy-block/course-price';
import { useLandingProvider } from '../../model/LandingProvider';
import { CartTildaLandingModalDark } from '@/entities/modals/cart';

export default function Cart({ styles }: { styles?: BoxProps }) {
    const { courseCartData, courseInfoData } = useLandingProvider();
    const { videoCount, buyersCount, authors, updateDate } = courseInfoData;
    const utmUser = localStorage.getItem('utmUser');
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box
            py={{ base: '40px', lg: '60px' }}
            borderRadius="40px"
            bgColor="#5224ba"
            bgImage={{
                base: 'url(/img/bg/cart-gradient-mobile.png)',
                lg: 'url(/img/bg/cart-gradient-desktop.png)',
            }}
            bgRepeat="no-repeat"
            bgSize="cover"
            boxShadow="inset 0 0 60px 0 rgba(255, 255, 255, 0.26)"
            color="white"
            overflow="hidden"
            {...styles}
        >
            <Container
                pos="relative"
                display={{ lg: 'flex' }}
                alignItems={{ lg: 'center' }}
                justifyContent={{ lg: 'space-between' }}
            >
                <Box>
                    <CoursePrice
                        price={courseCartData.price}
                        isSale={utmUser ? true : false}
                        isCountdown={utmUser ? true : false}
                        styles={{ mb: 4 }}
                    />

                    <Button
                        onClick={onOpen}
                        className="btn--shine"
                        display="flex"
                        w="100%"
                        maxW="360px"
                        py="20px !important"
                        mb={6}
                        fontSize="lg !important"
                        bgColor="white"
                        color="black"
                        textTransform="none"
                        _hover={{ bgColor: 'gray.310', color: 'black' }}
                    >
                        <Text as="span">Get access</Text>

                        <Box
                            as={RiArrowRightLine}
                            pos="absolute"
                            top="calc(50% - 15px) !important"
                            right="27px"
                            w="28px"
                            h="28px"
                            color="black !important"
                        />
                    </Button>

                    <HStack
                        gap={4}
                        mb={4}
                    >
                        <Box
                            as={RiShieldCheckLine}
                            fontSize="26px"
                        />

                        <Text mb={0}>We guarantee your money back</Text>
                    </HStack>

                    <Text
                        maxW="585px"
                        mb={{ base: '40px', lg: 0 }}
                        fontSize="14px"
                        color="#e1e1e1"
                    >
                        For any reason the course does not meet your expectations, we have you covered. Email support
                        within 14 days at{' '}
                        <Link
                            href={`mailto:${HELLO_EMAIL}`}
                            textDecoration="underline"
                        >
                            {HELLO_EMAIL}
                        </Link>{' '}
                        for a full refund, no questions ask.
                    </Text>
                </Box>

                <Flex
                    flexDir="column"
                    alignItems="flex-start"
                    gap={2}
                >
                    <HStack
                        gap={4}
                        borderRadius="50px"
                        px={5}
                        py={2.5}
                        boxShadow="inset 0 0 12px 0 rgba(255, 255, 255, 0.4)"
                        background="rgba(255, 255, 255, 0.09)"
                    >
                        <Box
                            as={RiUser3Line}
                            flexShrink={0}
                            width="26px"
                            height="26px"
                            color="#E1E1E1"
                        />

                        <Box>
                            <Text as="span">{authors.length > 1 ? 'Authors' : 'Author'}: </Text>

                            {authors.map((item, index) => {
                                return (
                                    <Box
                                        as="span"
                                        key={index}
                                    >
                                        {index > 0 && ' • '}
                                        <Text
                                            as="span"
                                            fontWeight={500}
                                        >
                                            {item.name}
                                        </Text>
                                    </Box>
                                );
                            })}
                        </Box>
                    </HStack>

                    <HStack
                        gap={4}
                        borderRadius="50px"
                        px={5}
                        py={2.5}
                        boxShadow="inset 0 0 12px 0 rgba(255, 255, 255, 0.4)"
                        background="rgba(255, 255, 255, 0.09)"
                    >
                        <Box
                            as={RiGroupLine}
                            flexShrink={0}
                            width="26px"
                            height="26px"
                            color="#E1E1E1"
                        />

                        <Box>
                            <Text
                                as="span"
                                fontWeight={500}
                            >
                                {buyersCount}
                            </Text>{' '}
                            <Text as="span">participants</Text>
                        </Box>
                    </HStack>

                    <HStack
                        gap={4}
                        borderRadius="50px"
                        px={5}
                        py={2.5}
                        boxShadow="inset 0 0 12px 0 rgba(255, 255, 255, 0.4)"
                        background="rgba(255, 255, 255, 0.09)"
                    >
                        <Box
                            as={RiCalendarLine}
                            flexShrink={0}
                            width="26px"
                            height="26px"
                            color="#E1E1E1"
                        />

                        <Box>
                            <Text as="span">Last update: </Text>

                            <Text
                                as="span"
                                fontWeight={500}
                            >
                                {updateDate}
                            </Text>
                        </Box>
                    </HStack>

                    <HStack
                        gap={4}
                        borderRadius="50px"
                        px={5}
                        py={2.5}
                        boxShadow="inset 0 0 12px 0 rgba(255, 255, 255, 0.4)"
                        background="rgba(255, 255, 255, 0.09)"
                    >
                        <Box
                            as={RiCalendarLine}
                            flexShrink={0}
                            width="26px"
                            height="26px"
                            color="#E1E1E1"
                        />

                        <Box>
                            <Text
                                as="span"
                                fontWeight={500}
                            >
                                {videoCount} video
                            </Text>{' '}
                            <Text as="span">lessons</Text>
                        </Box>
                    </HStack>
                </Flex>

                <Image
                    src="/img/icons/figures/lotus.svg"
                    alt="lotus"
                    pos="absolute"
                    right="0"
                    bottom={{ base: '-50px', lg: '-80px' }}
                />
            </Container>

            <Portal>
                <CartTildaLandingModalDark
                    isOpen={isOpen}
                    onClose={onClose}
                    courseList={[courseCartData]}
                />
            </Portal>
        </Box>
    );
}
