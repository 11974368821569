import { createRoot } from 'react-dom/client';

import { EventsCatalog } from '@/features/socials/events/events-catalog';
import { HeadingNavEventsSlider } from '@/features/socials/events/events-slider';
import { EventsTab } from '@/features/socials/events/events-tab';
import ProvidersCsr from '../../providers/providers-csr';
import {
    HeadingNavEventsSliderSimple,
    HeadingNavEventsSliderSimpleBlue,
} from '@/features/socials/events/events-slider';

// Ивенты в дашборде
const eventsNode = document.getElementById('events');
if (eventsNode) {
    const root = createRoot(eventsNode);
    root.render(
        <ProvidersCsr>
            <EventsTab />
        </ProvidersCsr>,
    );
}

// Ивенты на главной
const eventsUpcomingSliderNode = document.getElementById('events-upcoming-slider');
if (eventsUpcomingSliderNode) {
    const root = createRoot(eventsUpcomingSliderNode);
    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <HeadingNavEventsSlider />
        </ProvidersCsr>,
    );
}

// Слайдер ивентов
const eventsSimpleNode = document.getElementById('eventsSimple');
if (eventsSimpleNode) {
    const root = createRoot(eventsSimpleNode);

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <HeadingNavEventsSliderSimple />
        </ProvidersCsr>,
    );
}

// Слайдер ивентов для zenovaspace
const eventsPublicBlueNode = document.getElementById('eventsPublicBlue');
if (eventsPublicBlueNode) {
    const root = createRoot(eventsPublicBlueNode);

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <HeadingNavEventsSliderSimpleBlue />
        </ProvidersCsr>,
    );
}

// Страница ивентов
const eventsCatalogNode = document.getElementById('eventsCatalog');
if (eventsCatalogNode) {
    const root = createRoot(eventsCatalogNode);
    root.render(
        <ProvidersCsr>
            <EventsCatalog
                styles={{
                    py: {
                        base: 7.5,
                        md: '60px',
                    },
                }}
            />
        </ProvidersCsr>,
    );
}
