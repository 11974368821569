import { Box, Flex, Image, Text } from '@chakra-ui/react';

import { CardReviewProps } from '../model/types';

export default function CardReviewBlack({ photo, authorName, text, rating }: CardReviewProps) {
    return (
        <Box
            h="100%"
            p={6}
            borderRadius={{ base: '20px', md: '32px' }}
            boxShadow="inset 0 0 30px 0 rgba(255, 255, 255, 0.2)"
            bgColor="rgba(255, 255, 255, 0.1)"
            color="#fff"
        >
            <Flex
                justify="space-between"
                mb="8"
            >
                <Flex align="center">
                    {photo ? (
                        <Image
                            src={photo}
                            alt="photo"
                            w="60px"
                            h="60px"
                            mr={5}
                            borderRadius="full"
                            flexShrink="0"
                        />
                    ) : (
                        <Box
                            w="60px"
                            h="60px"
                            mr={5}
                            borderRadius="full"
                            flexShrink="0"
                            backgroundColor="rgba(115,195,113,.3)"
                        />
                    )}

                    <Box>
                        <Flex
                            gap={1}
                            mb={1}
                        >
                            {[...Array(rating)].map((_, index) => (
                                <Image
                                    key={index}
                                    src="/img/icons/star.svg"
                                    alt="star"
                                    w="18px"
                                    gap="2"
                                />
                            ))}
                        </Flex>

                        <Flex
                            align="baseline"
                            wrap="wrap"
                            mb={1}
                        >
                            <Text
                                as="span"
                                fontWeight="700"
                            >
                                {authorName}
                            </Text>
                        </Flex>
                    </Box>
                </Flex>
            </Flex>

            <Text mb={0}>{text}</Text>
        </Box>
    );
}
