import { Box, Flex, HStack, Img, Text } from '@chakra-ui/react';

import { APP_BRAND } from '@/common/constants/brand';
import { Picture, PictureType } from '@/shared/ui';

export default function InstagramBanner({ styles }: { styles?: BoxProps }) {
    const onInstagramBtnClick = () => {
        window.open(`https://www.instagram.com/newmindstart?igsh=MWxkODFpajFuazE0aQ==`, '_blank');
    };

    return (
        <Flex
            flexDir={{ base: 'column', md: 'row-reverse' }}
            align="center"
            justify="space-between"
            gap={{ base: 5, lg: '100px' }}
            px={5}
            pl={{ md: '60px' }}
            pr={{ lg: '60px' }}
            pt={{ base: 5, md: '0' }}
            pb={{ base: 7.5, md: '0' }}
            backgroundColor="blue.300"
            borderRadius="xl"
            {...styles}
        >
            <Picture
                src="promo/socials/instagram-likes.png"
                alt="instagram-promo"
                type={PictureType.Simple}
                pictureStyle={{
                    flexShrink: 0,
                }}
            />

            <Box
                maxW="550px"
                pt={{ md: '40px' }}
                pb={{ md: '40px' }}
            >
                <Text
                    textStyle="md5"
                    mb={5}
                >
                    Stay updated with{' '}
                    <Text
                        as="span"
                        background="linear-gradient(270deg, #2d2ad4 7.5%, #eb476f 59.5%)"
                        backgroundClip="text"
                        fontWeight={700}
                    >
                        valuable insights,
                    </Text>{' '}
                    <Text
                        as="span"
                        background="linear-gradient(270deg, #2d2ad4 0%, #eb476f 36.5%)"
                        backgroundClip="text"
                        fontWeight={700}
                    >
                        exclusive offers,
                    </Text>{' '}
                    and{' '}
                    <Text
                        as="span"
                        background="linear-gradient(270deg, #412ad4 9.6%, #eb476f 34.5%)"
                        backgroundClip="text"
                        fontWeight={700}
                    >
                        live sessions
                    </Text>{' '}
                    with our authors. Follow us for more!
                </Text>

                <HStack
                    onClick={onInstagramBtnClick}
                    cursor="pointer"
                    pos="relative"
                    backgroundImage="linear-gradient(90deg, #fbc55a 3%, #ff0c42 20%, #e142a9 100%)"
                    borderRadius="full"
                    color="white"
                    pl={{ base: '70px', md: '80px' }}
                    minH={{ base: '60px', md: '70px' }}
                    maxW="290px"
                    _hover={{ opacity: 0.7 }}
                >
                    <Img
                        src="/img/promo/socials/icon-instagram-user.png"
                        alt="icon-instagram-user"
                        pos="absolute"
                        left="2px"
                        top="2px"
                        w={{ base: '56px', md: '66px' }}
                        h={{ base: '56px', md: '66px' }}
                    />

                    <Flex flexDir="column">
                        <Text
                            as="span"
                            fontWeight={700}
                        >
                            JOIN
                        </Text>
                        <Text
                            as="span"
                            fontSize="xl"
                        >
                            @{APP_BRAND}
                        </Text>
                    </Flex>

                    <Img
                        src="/img/icons/helpers/thumb-pointer.png"
                        alt="thumb-pointer"
                        pos="absolute"
                        right="0"
                        bottom={{ base: '-25px', md: '-35px' }}
                        h={{ base: '44px', md: '61px' }}
                        w={{ base: '37px', md: '51px' }}
                    />
                </HStack>
            </Box>
        </Flex>
    );
}
