import { useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { MediaPlayer, MediaProvider, Controls, MediaPlayerInstance } from '@vidstack/react';

import './style.scss';

import { isInstagramIos, isSafari } from '@/shared/lib';
import PlayControlSimple from './components/controls/PlayControlSimple';
import PlayerControlsSimple from './components/controls/PlayerControlsSimple';

export default function TrailerPlayer({ isStarted, videoSrc, playerType, setIsStarter, styles }: any) {
    const playerRef = useRef<MediaPlayerInstance>(null);
    const isSafariBrowser = isSafari();
    const isInstagramIosBrowser = isInstagramIos();

    return (
        <Box
            pos="relative"
            zIndex={10}
            sx={{
                video: {
                    borderRadius: { base: 'none', md: '12px' },
                },
            }}
            {...styles}
        >
            <MediaPlayer
                ref={playerRef}
                onStarted={setIsStarter.on}
                src={videoSrc}
                title="video"
                load="eager"
                autoPlay
                muted
                playsInline
                preferNativeHLS={isSafariBrowser || isInstagramIosBrowser ? true : false}
                className="vds-video-layout"
            >
                <Box
                    as={MediaProvider}
                    bgColor="transparent"
                >
                    <Controls.Root className="vds-controls">
                        <PlayControlSimple playerRef={playerRef} />

                        <PlayerControlsSimple
                            playerRef={playerRef}
                            styles={{
                                display: isStarted ? 'block' : 'none',
                            }}
                            courseId={0}
                            type={playerType}
                        />
                    </Controls.Root>
                </Box>
            </MediaPlayer>
        </Box>
    );
}
