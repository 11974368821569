import { Box, Center } from '@chakra-ui/react';

import { NavBtnType } from '../nav-btn-const';

export default function NavBtn({ onClick, type, styles }: any) {
    return (
        <Center
            className="image-swiper-button"
            onClick={onClick}
            cursor="pointer"
            zIndex={20}
            w={{ base: '24px', md: '48px' }}
            h={{ base: '24px', md: '48px' }}
            borderRadius="50%"
            bg="white"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
            color="#333333"
            {...styles}
        >
            <Box
                w={{ base: '6px', md: '12px' }}
                h={{ base: '11px', md: '20px' }}
                transform={type === NavBtnType.Next ? 'translateX(1px)' : 'translateX(-1px)'}
            >
                {type === NavBtnType.Next ? (
                    <svg
                        role="presentation"
                        viewBox="0 0 12.6 22"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            strokeLinejoin="inherit"
                            strokeLinecap="butt"
                            strokeWidth="2"
                            points="1,1 11,11 1,21"
                        ></polyline>
                    </svg>
                ) : (
                    <svg
                        role="presentation"
                        viewBox="0 0 12.6 22"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        style={{ transform: 'rotate(180deg)' }}
                    >
                        <polyline
                            fill="none"
                            stroke="currentColor"
                            strokeLinejoin="inherit"
                            strokeLinecap="butt"
                            strokeWidth="2"
                            points="1,1 11,11 1,21"
                        ></polyline>
                    </svg>
                )}
            </Box>
        </Center>
    );
}
