'use client';
import { useEffect, useState } from 'react';

export default function useIsClient() {
    const [isClient, setIsClient] = useState<boolean>(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    return isClient;
}
