import { Box, Button, Flex, FlexProps, HStack, Text, VStack, useMediaQuery } from '@chakra-ui/react';

import { useAtomValue } from 'jotai';

import CategoriesCards from './CategoriesCards';
import { CongratulationsModal } from '@/entities/modals';
import { userAtom } from '@/shared/store';
import { ActionSubType, useCreateSub } from '@/features/subscription/create-sub';
import { SubPeriod } from '@/entities/user/user-data';

const FULL_PRICE = 828;
const SAVE_PRICE = 569;
const MONTH_PRICE = 21;
const YEAR_PRICE = 259;

const PROGRAM_COUNT = 100;

export default function DashboardUserBannerYear({ styles }: { styles?: FlexProps }) {
    const { isSubLoading, isSubError, isSubCreate, createSub, sendError } = useCreateSub(
        ActionSubType.DASHBOARD_USER_BANNER_UPGRADE_YEAR,
    );
    const user = useAtomValue(userAtom);

    const [isLargerThanMd] = useMediaQuery('(min-width: 768px)', {
        ssr: true,
        fallback: false,
    });

    if (!user?.is_subscribe || (user?.is_subscribe && user?.subscription_plan?.period === SubPeriod.Year)) {
        return null;
    }

    return (
        <Flex
            flexDir={{ base: 'column', lg: 'row' }}
            justify="center"
            mx="auto"
            borderRadius="xl"
            backgroundImage="url(/img/bg/green-gradient.png)"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            {...styles}
        >
            <Box
                display={{ lg: 'flex' }}
                gap="50px"
                flexDir={{ base: 'column', xl: 'row' }}
                alignItems={{ xl: 'flex-end' }}
                pt={{ base: 6, lg: 7.5 }}
                pb={10}
                px={{ base: 5, lg: 10 }}
                borderRadius="xl"
                bgColor="green.600"
                color="white"
            >
                <Box
                    flexShrink={0}
                    mb={{ base: 6, lg: 0 }}
                >
                    <HStack
                        spacing={2.5}
                        mb={{ base: 4, lg: 2 }}
                    >
                        <Box
                            display="inline"
                            pos="relative"
                        >
                            <Text
                                as="span"
                                opacity={0.5}
                                fontSize="xl"
                            >
                                ${FULL_PRICE}
                            </Text>

                            <Box
                                pos="absolute"
                                w="120%"
                                h="1px"
                                bgColor="white"
                                top="14px"
                                left="-5px"
                                transform="rotate(-10deg)"
                            />
                        </Box>

                        <Text
                            as="span"
                            padding="4px 8px"
                            border="1px solid"
                            borderColor="white"
                            borderRadius="64px"
                        >
                            Save ${SAVE_PRICE}
                        </Text>
                    </HStack>

                    <Text
                        // TODO: change for next
                        // className={playfairDisplay.className}
                        className={'playfair-display-italic-regular'}
                        textStyle="xl2"
                        mb={{ base: 7.5, lg: 0 }}
                        color="green.400"
                    >
                        Be in the flow
                    </Text>

                    <Box>
                        <Text
                            mb="6px"
                            fontSize="2xl"
                            fontWeight={700}
                        >
                            ${MONTH_PRICE} /month
                        </Text>
                        <Text
                            opacity={0.5}
                            mb={0}
                        >
                            bill yearly ${YEAR_PRICE}
                        </Text>
                    </Box>
                </Box>

                <Box mb={{ lg: 1 }}>
                    <Text mb={{ base: 7, lg: 8 }}>
                        Explore more than {PROGRAM_COUNT}+ DIVERSE PROGRAMS and attend DAILY LIVE SESSIONS, forgetting
                        about payment and with huge savings.
                    </Text>

                    <VStack
                        pos="relative"
                        maxW={{ base: '320px', lg: '100%' }}
                    >
                        <Button
                            variant="white"
                            w="100%"
                            py={5}
                            fontSize="sm"
                            fontWeight={700}
                            isLoading={isSubLoading}
                            loadingText={`Submitting`}
                            onClick={() => createSub()}
                        >
                            UPGRADE TO YEARLY PLAN
                        </Button>

                        {isSubError && (
                            <Text
                                pos="absolute"
                                bottom={-7.5}
                                mb={0}
                                color="red.500"
                            >
                                {sendError}
                            </Text>
                        )}
                    </VStack>
                </Box>
            </Box>

            <CategoriesCards isLargerThanMd={isLargerThanMd} />

            <CongratulationsModal
                isOpen={isSubCreate}
                onClose={() => location.reload()}
            />
        </Flex>
    );
}
