import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Text,
    FormErrorMessage,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react';
import ReactSelect from 'react-select';
import { IMaskInput } from 'react-imask';

import { getCountryList } from '@/features/form/select/city-select/api/getCountryList';
import { SelectOption } from '@/shared/models';
import { CustomDropdownIndicator, IconMap, IconNames } from '@/shared/ui';
import PaymentStepFooter from './PaymentStepFooter';

interface FormValues {
    name: string;
    cardNumber: string;
    cvv: string;
    expirationDate: string;
    // country: SelectOption | null;
}

export default function PaymentStep({ goToNextStep, handleSubmit, isSubmitted }: any) {
    // const [countryList, setCountryList] = useState<SelectOption[]>([]);
    const [isFormValid, setIsFormValid] = useState(false);

    const {
        register,
        control,
        setValue,
        getValues,
        trigger,
        watch,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues: {
            name: '',
            cardNumber: '',
            cvv: '',
            expirationDate: '',
            // country: null,
        },
    });

    // Отслеживаем все поля
    const watchAllFields = watch();

    // Проверка заполненности формы (не валидации) при изменении полей
    useEffect(() => {
        // Проверяем, заполнены ли все поля
        const allFieldsFilled =
            !!watchAllFields.name &&
            !!watchAllFields.cardNumber &&
            !!watchAllFields.cvv &&
            !!watchAllFields.expirationDate;
            // !!watchAllFields.country;

        setIsFormValid(allFieldsFilled);
    }, [watchAllFields]);

    // useEffect(function () {
    //     async function fetchCountries() {
    //         const list = await getCountryList();
    //         setCountryList(list);
    //     }
    //     fetchCountries();
    // }, []);

    async function goToPayment(data: any) {
        // Запускает валидацию для всех полей
        const isValid = await trigger();

        if (isValid) {
            goToNextStep({ ...data, ...getValues() });
        }
    }

    return (
        <Box>
            <Box
                as="form"
                onSubmit={handleSubmit(goToPayment)}
                p={6}
                borderRadius={'8px'}
                mb={6}
                boxShadow={'0px 0px 20px 0px rgba(128, 128, 128, 0.2)'}
            >
                <Text
                    mb={8}
                    fontSize={{ base: '26px', md: '28px' }}
                    lineHeight={1.3}
                    fontWeight={700}
                >
                    Enter Your Payment Details
                </Text>

                <FormControl
                    isInvalid={!!errors.cardNumber}
                    mb={4}
                >
                    <FormLabel
                        color={'gray.815'}
                        fontSize={{ base: '12px', md: '14px' }}
                        fontWeight={400}
                    >
                        Card Number
                    </FormLabel>

                    <InputGroup>
                        <Input
                            as={IMaskInput}
                            variant="light-gray-border"
                            mask="0000 0000 0000 0000"
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            definitions={{
                                '#': /[0-9]/,
                            }}
                            onAccept={(value) => setValue('cardNumber', value.replace(/\s/g, ''))}
                            placeholder="**** **** **** ****"
                            {...register('cardNumber', {
                                required: 'Card number is required',
                                pattern: {
                                    value: /^[0-9]{16}$/,
                                    message: 'This card number doesn’t look correct. Please review it and try again. ',
                                },
                            })}
                        />

                        <InputRightElement display={errors.cardNumber ? 'flex' : 'none'}>
                            <Box as={IconMap[IconNames.ErrorWarning]} />
                        </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.cardNumber?.message}</FormErrorMessage>
                </FormControl>

                <Flex
                    gap={4}
                    mb={4}
                >
                    <FormControl isInvalid={!!errors.expirationDate}>
                        <FormLabel
                            color={'gray.815'}
                            fontSize={{ base: '12px', md: '14px' }}
                            fontWeight={400}
                        >
                            Expiration Date
                        </FormLabel>

                        <InputGroup>
                            <Input
                                as={IMaskInput}
                                variant="light-gray-border"
                                mask="00/00"
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-expect-error
                                definitions={{
                                    '#': /[0-9]/,
                                }}
                                onAccept={(value) => setValue('expirationDate', value)}
                                placeholder="MM/YY"
                                {...register('expirationDate', {
                                    required: 'Expiration date is required',
                                })}
                            />

                            <InputRightElement display={errors.expirationDate ? 'flex' : 'none'}>
                                <Box as={IconMap[IconNames.ErrorWarning]} />
                            </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{errors.expirationDate?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!errors.cvv}>
                        <FormLabel
                            color={'gray.815'}
                            fontSize={{ base: '12px', md: '14px' }}
                            fontWeight={400}
                        >
                            Security code
                        </FormLabel>

                        <InputGroup>
                            <Input
                                id="cvv"
                                type="text"
                                placeholder="123"
                                maxLength={4}
                                variant="light-gray-border"
                                {...register('cvv', {
                                    required: 'Security code is required',
                                    pattern: {
                                        value: /^[0-9]{3,4}$/,
                                        message: 'CVV must be 3-4 digits',
                                    },
                                    setValueAs: (value) => value.replace(/\D/g, ''),
                                })}
                            />

                            <InputRightElement display={errors.cvv ? 'flex' : 'none'}>
                                <Box as={IconMap[IconNames.ErrorWarning]} />
                            </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>{errors.cvv?.message}</FormErrorMessage>
                    </FormControl>
                </Flex>

                <FormControl
                    mb={6}
                    isInvalid={!!errors.name}
                >
                    <FormLabel
                        color={'gray.815'}
                        fontSize={{ base: '12px', md: '14px' }}
                        fontWeight={400}
                    >
                        Card Holder Name
                    </FormLabel>

                    <InputGroup>
                        <Input
                            id="name"
                            type="text"
                            placeholder="Your name"
                            variant="light-gray-border"
                            {...register('name', {
                                required: 'Name is required',
                            })}
                        />

                        <InputRightElement display={errors.name ? 'flex' : 'none'}>
                            <Box as={IconMap[IconNames.ErrorWarning]} />
                        </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                </FormControl>

                {/*<FormControl*/}
                {/*    mb={6}*/}
                {/*    isInvalid={!!errors.country}*/}
                {/*>*/}
                {/*    <FormLabel*/}
                {/*        color={'gray.815'}*/}
                {/*        fontSize={{ base: '12px', md: '14px' }}*/}
                {/*        fontWeight={400}*/}
                {/*    >*/}
                {/*        Country*/}
                {/*    </FormLabel>*/}
                {/*    <Controller*/}
                {/*        name="country"*/}
                {/*        control={control}*/}
                {/*        rules={{ required: 'Country is required' }}*/}
                {/*        render={({ field }) => (*/}
                {/*            <Box*/}
                {/*                sx={{*/}
                {/*                    '.control': {*/}
                {/*                        width: '100%',*/}
                {/*                        border: '1px solid',*/}
                {/*                        fontSize: { base: '14px', md: '16px' },*/}
                {/*                        borderColor: 'gray.415',*/}
                {/*                        borderRadius: '8px',*/}
                {/*                        backgroundColor: '#fff',*/}
                {/*                    },*/}
                {/*                    '.indicatorSeparator': {*/}
                {/*                        display: 'none',*/}
                {/*                    },*/}
                {/*                }}*/}
                {/*            >*/}
                {/*                <ReactSelect*/}
                {/*                    {...field}*/}
                {/*                    instanceId="country"*/}
                {/*                    placeholder="Your Country"*/}
                {/*                    options={countryList}*/}
                {/*                    closeMenuOnSelect={true}*/}
                {/*                    isClearable={true}*/}
                {/*                    noOptionsMessage={(_) => `No variants`}*/}
                {/*                    loadingMessage={(_) => `Loading...`}*/}
                {/*                    components={{ DropdownIndicator: CustomDropdownIndicator }}*/}
                {/*                    classNames={{*/}
                {/*                        control: () => 'control',*/}
                {/*                        indicatorSeparator: () => 'indicatorSeparator',*/}
                {/*                    }}*/}
                {/*                />*/}
                {/*            </Box>*/}
                {/*        )}*/}
                {/*    />*/}
                {/*    <FormErrorMessage>{errors.country?.message}</FormErrorMessage>*/}
                {/*</FormControl>*/}

                <Flex justify={'flex-end'}>
                    <Button
                        type="submit"
                        isLoading={isSubmitted}
                        loadingText="Paying..."
                        mb={5}
                        w={'100%'}
                        variant={isFormValid ? 'primary' : 'gray-light'}
                        ml="auto"
                        fontSize={'16px'}
                        fontWeight={600}
                    >
                        Submit order
                    </Button>
                </Flex>
            </Box>

            <PaymentStepFooter />
        </Box>
    );
}
