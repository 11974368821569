import { useEffect } from 'react';
import { useDisclosure, Button, Portal, Text, VStack } from '@chakra-ui/react';

import { useAtomValue } from 'jotai';

import { CourseCartModal } from '@/entities/modals/cart';
import { useSubFromMonolith } from '@/features/subscription/sub-from-monolith';
import { EventNames, usePostEventQuizTimed } from '@/entities/quizes/quiz';
import { userDataFromBladeAtom } from '@/entities/user/user-data-from-blade/user-data-from-blade-store';
import { MONTH_PRICE } from '@/common/constants/brand';

export default function CourseQuizBuyBtn({ isTimed, popupText }: { isTimed?: boolean; popupText?: string }) {
    const { isOpen: isOpenCartModal, onOpen: onOpenCartModal, onClose: onCloseCartModal } = useDisclosure();

    const { sendError, loadingText, isSubmitted, createSub: handleSignUp } = useSubFromMonolith();
    const { postEventQuizTimed } = usePostEventQuizTimed();

    const userDataFromBlade = useAtomValue(userDataFromBladeAtom);
    const email = userDataFromBlade?.email || '';

    const onClickBtn = async () => {
        await postEventQuizTimed([], EventNames.FinalStep, { email: email });
        await handleSignUp();
    };

    useEffect(() => {
        if (isTimed) {
            const timerId = setTimeout(() => onOpenCartModal(), 40000);

            return () => clearTimeout(timerId);
        }
    }, []);

    return (
        <>
            <VStack w={{ base: '100%', lg: '340px' }}>
                <Button
                    w="100%"
                    maxW="360px"
                    fontSize="sm2"
                    onClick={onClickBtn}
                    isLoading={isSubmitted}
                    loadingText={loadingText}
                >
                    OPEN ACCESS NOW
                </Button>

                {sendError && (
                    <Text
                        mb={0}
                        textAlign="center"
                        color="red.500"
                    >
                        {sendError}
                    </Text>
                )}
            </VStack>

            <Portal>
                <CourseCartModal
                    isOpen={isOpenCartModal}
                    onClose={onCloseCartModal}
                >
                    <Text textStyle="md2">
                        Let&#39;s Get Started!
                    </Text>

                    <Text mb={7.5}>
                        {popupText ? (
                            popupText
                        ) : (
                            <>
                                Take advantage of our special offer: 7 days for $1 and a lifetime subscription discount
                                of just $29/month (regularly ${MONTH_PRICE}
                                /month).
                            </>
                        )}
                    </Text>

                    <Button
                        w="100%"
                        mx="auto"
                        maxW="360px"
                        isLoading={isSubmitted}
                        loadingText={loadingText}
                        onClick={onClickBtn}
                    >
                        OPEN ACCESS NOW
                    </Button>

                    {sendError && (
                        <Text
                            mb={0}
                            mt={2}
                            textAlign="center"
                            color="red.500"
                        >
                            {sendError}
                        </Text>
                    )}
                </CourseCartModal>
            </Portal>
        </>
    );
}
