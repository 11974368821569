import { fetchData, METHODS } from '@/shared/lib';
import { EventNames } from '../model/types/event-quiz-types';
import { USER_SESSION_ID_KEY } from '@/common/constants/local-storage';
import { URLS } from '@/common/constants/global';

interface PostEventQuizProps {
    quizEventType: string;
    whatTime: number;
    answerList: string[];
    stepEventName: EventNames;
    stepProperties?: TObj;
}

export async function postEventQuiz({
    quizEventType,
    whatTime,
    answerList,
    stepEventName,
    stepProperties,
}: PostEventQuizProps) {
    const userId = localStorage.getItem(USER_SESSION_ID_KEY); // mnlt

    const eventBody = {
        event_type: quizEventType,
        user_properties: {
            what_time: whatTime,
            answers: answerList,
            ...stepProperties,
        },
        user_session_uid: userId,
        event_properties: {
            name: stepEventName,
        },
    };

    try {
        await fetchData(URLS.EVENT, {
            method: METHODS.POST,
            body: JSON.stringify(eventBody),
        });
    } catch (error) {
        throw new Error(`Not quiz event post ${error}`);
    }
}
