import { useAtomValue } from 'jotai';
import { useBoolean } from '@chakra-ui/react';

import { postEventQuiz } from '../../utils/postEventQuiz';
import { useTimeDuration } from '@/shared/lib';
import { EventNames } from '../types/event-quiz-types';
import { quizSettingAtom } from '../stores/quiz-store';

export default function usePostEventQuizTimed() {
    const { resetStartTime, getDuration } = useTimeDuration();
    const [isSendLoading, setIsSendLoading] = useBoolean(false);
    const quizSetting = useAtomValue(quizSettingAtom); // store

    const postEventQuizTimed = async (answerList: string[], title: EventNames, properties?: TObj) => {
        setIsSendLoading.on();

        const formattedDuration = getDuration();

        try {
            await postEventQuiz({
                quizEventType: quizSetting!.options.eventType || '',
                whatTime: formattedDuration,
                answerList: answerList,
                stepEventName: title,
                stepProperties: properties,
            });
        } catch (error) {
            console.error('Quiz course event with time not send', error);
            throw new Error('Quiz course event with time not send');
        } finally {
            setIsSendLoading.off();
            resetStartTime();
        }
    };

    return {
        isSendLoading,
        postEventQuizTimed,
    };
}
