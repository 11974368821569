import { Box, BoxProps, HStack, Link, Text } from '@chakra-ui/react';
import { RiFilmLine, RiGroupLine, RiQuestionFill, RiUserSmileLine } from 'react-icons/ri';

import { APP_BRAND, HELLO_EMAIL } from '@/common/constants/brand';

export default function CheckoutCartDesc({ styles }: { styles?: BoxProps }) {
    return (
        <Box {...styles}>
            <Text
                fontSize="13px"
                color="gray.500"
            >
                You have 14 days from the time of your purchase to request a refund or exchange. Courses purchased with
                a Free Trial offer, Plus, and Plus Free Trial subscriptions are non-refundable.
            </Text>

            <Box mt={4}>
                <Text
                    mb={4}
                    fontSize="15px"
                >
                    What are you buying?
                </Text>

                <Box color="gray.500">
                    <HStack>
                        <Box
                            as={RiUserSmileLine}
                            flexShrink={0}
                            w="15px"
                            h="15px"
                        />
                        <Text
                            mb={0}
                            fontSize="15px"
                        >
                            Unlimited access to all purchased courses, whenever you want.
                        </Text>
                    </HStack>

                    <HStack mt={2}>
                        <Box
                            as={RiFilmLine}
                            flexShrink={0}
                            w="15px"
                            h="15px"
                        />
                        <Text
                            mb={0}
                            fontSize="15px"
                        >
                            Unlimited access to different course units, lessons, informational text, and additional
                            resources.
                        </Text>
                    </HStack>

                    <HStack mt={2}>
                        <Box
                            as={RiGroupLine}
                            flexShrink={0}
                            w="15px"
                            h="15px"
                        />
                        <Text
                            mb={0}
                            fontSize="15px"
                        >
                            Access to the exclusive course community where you can share experiences with the teacher
                            and other students.
                        </Text>
                    </HStack>
                </Box>
            </Box>

            <HStack mt={4}>
                <Box
                    as={RiQuestionFill}
                    flexShrink={0}
                    w="15px"
                    h="15px"
                />

                <Text
                    mb={0}
                    fontSize="15px"
                >
                    Need help? Visit our{' '}
                    <Link
                        href={`https://account.${APP_BRAND}.com/support`}
                        textDecor="underline"
                    >
                        Support Center
                    </Link>{' '}
                    or{' '}
                    <Link
                        fontWeight={500}
                        textDecoration="underline"
                        href={`mailto:${HELLO_EMAIL}`}
                    >
                        contact us
                    </Link>
                    .
                </Text>
            </HStack>
        </Box>
    );
}
