import { useEffect, useRef, useState } from 'react';
import {
    useDisclosure,
    Button,
    Portal,
    Text,
    VStack,
    Link,
    Box,
    Img,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    ModalCloseButton,
    ModalHeader,
    List,
    ListItem,
    ListIcon,
} from '@chakra-ui/react';

import { CourseCartModal } from '@/entities/modals/cart';
import { useSubFromMonolith } from '@/features/subscription/sub-from-monolith';
import { useAtomValue } from 'jotai';
import { planOfCourseOfQuizAtom } from '@/entities/quizes/quiz/model/stores/quiz-store';
import { CurrentStepEventNames } from '@/entities/quizes/quiz';
import { userAtom } from '@/shared/store';
import { CountdownGrid, HTMLRenderer } from '@/shared/ui';
import { useCountdownReminigTime } from '@/features/promo/countdown';
import { MONTH_PRICE } from '@/common/constants/brand';
import { useTrialDay } from '@/features/subscription/create-sub';

interface CourseQuizOnboardingBuyBtnProps {
    isTimed?: boolean;
    isSkipBtnShow?: boolean;
}

const sentences = [
    'Engage in Weekly Live sessions, where the spiritual journey unfolds in real-time, offering fresh insights and collective energies.',
    'Connect with the universe through Yoga.',
    "Celebrate your soul's rhythm with Dance and Singing.",
    'Experience healing touch with spiritual Massage techniques.',
    'Elevate your consciousness with mindfulness and brain exercises.',
];

const TIMER_COUNTDOWN_HOURS = 12;

export default function CourseQuizOnboardingBuyBtn({ isTimed, isSkipBtnShow = true }: CourseQuizOnboardingBuyBtnProps) {
    // Если пользователь уже был на onboarding то перенаправим при скипе в dashboard
    const user = useAtomValue(userAtom);
    const isAlreadyOnboarded =
        user && user?.quiz_info.quiz_thank_page_step_history.includes(CurrentStepEventNames.QuizOnboardingPageStep);
    const skipRedirectLink = isAlreadyOnboarded ? '/client' : '/redirect-to-onboarding-page';
    const { reminingTime } = useCountdownReminigTime(TIMER_COUNTDOWN_HOURS);
    // Показываем попап при первом посещении
    const isFirstTimeOnboarding = !user?.quiz_info.quiz_thank_page_step_history.includes(
        CurrentStepEventNames.QuizFinalPageStep,
    );

    const { isOpen: isOpenCartModal, onOpen: onOpenCartModal, onClose: onCloseCartModal } = useDisclosure();
    const { isOpen: isOpenSkipPopup, onOpen: onOpenSkipModal, onClose: onCloseSkipModal } = useDisclosure();

    const { sendError, loadingText, isSubmitted, createSub: handleSignUp } = useSubFromMonolith();
    const planOfCourseOfQuiz = useAtomValue(planOfCourseOfQuizAtom);
    const [isNotOpenPopup, setIsNotOpenPopup] = useState(false);
    const isNotTimedPopupRef = useRef(false);
    useTrialDay(planOfCourseOfQuiz?.trialDays);

    useEffect(() => {
        if (isNotOpenPopup) {
            isNotTimedPopupRef.current = true;
        }
    }, [isNotOpenPopup]);

    useEffect(() => {
        if (isTimed) {
            const checkPopupState = () => {
                if (!isNotTimedPopupRef.current) {
                    onOpenCartModal();
                }
            };

            const timerId = setTimeout(checkPopupState, 40000);

            return () => clearTimeout(timerId);
        }
    }, [isTimed]);

    const onLinkClick = () => {
        if (isFirstTimeOnboarding) {
            setIsNotOpenPopup(true);
            onOpenSkipModal();
        } else {
            window.location.pathname = skipRedirectLink;
        }
    };

    return (
        <>
            <VStack w={{ base: '100%', lg: '340px' }}>
                <Text
                    mb={0}
                    fontSize="12px"
                    color="gray.675"
                    textAlign="center"
                >
                    7 days for free, then ${MONTH_PRICE}/month. Cancel anytime
                </Text>

                <Button
                    w="100%"
                    maxW="360px"
                    fontSize="sm2"
                    onClick={handleSignUp}
                    isLoading={isSubmitted}
                    loadingText={loadingText}
                >
                    Continue with free trial
                </Button>

                {sendError && (
                    <Text
                        mb={0}
                        textAlign="center"
                        color="red.500"
                    >
                        {sendError}
                    </Text>
                )}

                {isSkipBtnShow && (
                    <Link
                        onClick={onLinkClick}
                        w="100%"
                        maxW="360px"
                        textAlign="center"
                        fontWeight={500}
                        color="gray.500"
                        textDecoration="underline"
                    >
                        Skip this one time offer
                    </Link>
                )}
            </VStack>

            <Portal>
                <CourseCartModal
                    isOpen={isOpenCartModal}
                    onClose={onCloseCartModal}
                >
                    <Text textStyle="md2">Let&#39;s Get Started!</Text>

                    <Text mb={7.5}>
                        Take advantage of our special offer: {planOfCourseOfQuiz?.trialDays} days free, then just $
                        {planOfCourseOfQuiz?.price && parseInt(planOfCourseOfQuiz.price, 10)}/month.
                    </Text>

                    <VStack>
                        <Button
                            w="100%"
                            maxW="360px"
                            onClick={handleSignUp}
                            isLoading={isSubmitted}
                            loadingText={loadingText}
                        >
                            OPEN ACCESS NOW
                        </Button>

                        {sendError && (
                            <Text
                                mb={0}
                                textAlign="center"
                                color="red.500"
                            >
                                {sendError}
                            </Text>
                        )}
                    </VStack>
                </CourseCartModal>

                <Modal
                    variant="top-block"
                    scrollBehavior="inside"
                    isOpen={isOpenSkipPopup}
                    onClose={onCloseSkipModal}
                    isCentered
                >
                    <ModalOverlay />

                    <ModalContent>
                        <ModalCloseButton />

                        <ModalHeader
                            background="url(/img/bg/blur-pink-blue-hor.png) no-repeat 50% 50%"
                            backgroundSize="cover"
                            color="white"
                        >
                            <CountdownGrid
                                timerSeconds={reminingTime}
                                styles={{ justifyContent: 'center' }}
                            />

                            <Text
                                textStyle="lg"
                                mt={5}
                                mb={2.5}
                            >
                                Think that 7 days is not enough?
                            </Text>

                            <Text
                                textStyle="md6"
                                fontWeight={700}
                                mb={0}
                            >
                                Try this One Time Offer with{' '}
                                <Text
                                    as="span"
                                    pos="relative"
                                    mb={0}
                                    display={{ base: 'inline-block', md: 'inline' }}
                                >
                                    30-Day Free Trial
                                    <Img
                                        src="/img/elements/ranbow-line.svg"
                                        alt="ranbow-line"
                                        pos="absolute"
                                        top={{ base: '18px', md: '20px' }}
                                        left={0}
                                        w="100%"
                                        h="100%"
                                    />
                                </Text>
                            </Text>
                        </ModalHeader>

                        <ModalBody>
                            <Box>
                                <Text>
                                    Unlock a{' '}
                                    <Text
                                        as="span"
                                        fontWeight={700}
                                    >
                                        30-day free trial
                                    </Text>{' '}
                                    with access to 200+ recorded and live programs. After, it&#39;s{' '}
                                    <Text
                                        as="span"
                                        fontWeight={700}
                                    >
                                        only ${MONTH_PRICE}
                                        /month
                                    </Text>
                                    . Enjoy a complete self-recovery retreat from the comfort of your own home, no
                                    matter where you are:
                                </Text>

                                <List
                                    display="flex"
                                    flexDir="column"
                                    gap={{ base: 2.5, md: 3 }}
                                >
                                    {sentences.map((text, index) => {
                                        return (
                                            <ListItem
                                                display="flex"
                                                flexDir="row"
                                                alignItems="baseline"
                                                gap={2.5}
                                                key={index}
                                            >
                                                <ListIcon
                                                    as={Img}
                                                    src="/img/icons/common/arrow-mark.svg"
                                                    alt="arrow-mark"
                                                />
                                                <HTMLRenderer text={text} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                w={{ md: '100%' }}
                                onClick={handleSignUp}
                                isLoading={isSubmitted}
                                loadingText={loadingText}
                            >
                                OPEN ACCESS NOW
                            </Button>

                            {sendError && (
                                <Text
                                    mb={0}
                                    textAlign="center"
                                    color="red.500"
                                >
                                    {sendError}
                                </Text>
                            )}

                            {isSkipBtnShow && (
                                <Link
                                    href={skipRedirectLink}
                                    textAlign="center"
                                    color="gray.525"
                                    textDecoration="underline"
                                >
                                    Skip this one time offer
                                </Link>
                            )}
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Portal>
        </>
    );
}
