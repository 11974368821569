/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { getBrand, isHostInList } from '@/shared/lib';

export const APP_BRAND: any =
    (typeof process !== 'undefined' && (process.env.NEXT_PUBLIC_BRAND as DOMAIN_NAMES)) ||
    (typeof window !== 'undefined' && (getBrand(null, window.location.host) as DOMAIN_NAMES));

export enum DOMAIN_NAMES {
    Edston = 'edston',
    FullOfMind = 'fullofmind',
    Sacrill = 'sacrill',
    NewMindStart = 'newmindstart',
    WellnessWealthWay = 'wellnesswealthway',
    Mindfuleve = 'mindfuleve',
    Mindcloud = 'mindcloud',
    Harmonymingle = 'harmonymingle',
    Vitalyra = 'vitalyra',
    Omnimente = 'omnimente',
    Zenovaspace = 'zenovaspace',
    Localhost = 'localhost',
}

export const APP_ICON = {
    dark: `/assets/${APP_BRAND}/${APP_BRAND}-dark-logo.png`,
    light: `/assets/${APP_BRAND}/${APP_BRAND}-logo.png`,
};

export const HELLO_EMAIL = `hello@${APP_BRAND}.com`;

export const SUPPORT_LINK = {
    title: 'Visit Support Center',
    href: '/support-link',
};

export const INSTAGRAM_LINK = '#';
export const GP_STORE = '#';
export const AS_STORE = '#';

export const MONTH_PRICE = isHostInList([
    DOMAIN_NAMES.Mindfuleve,
    DOMAIN_NAMES.Vitalyra,
    DOMAIN_NAMES.Harmonymingle,
    DOMAIN_NAMES.NewMindStart,
])
    ? 89
    : 79;
export const MONTH_PRICE_SMALL = 39;

export const DOMESTIKA_PRICES = {
    year: 149,
    year_discount: 119.2,
};

export const BRAND_URLS = {
    PrivacyPolicy: `account.${APP_BRAND}newmindstart.com/privacy-policy`,
};
