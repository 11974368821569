// useCartLogic.ts
import { useEffect, useState } from 'react';
import { useBoolean, useToast } from '@chakra-ui/react';
import { SubmitHandler } from 'react-hook-form';

import { CourseDataItem, Inputs, OptionalCourseDataItem } from '@/entities/modals/cart/model/types';
import { SelectOption } from '@/shared/models';

interface UseCartProps {
    courseData: CourseDataItem[];
    optionsList: OptionalCourseDataItem[];
    getFieldState: any;
    steps: string[]; // ID шагов в порядке отображения
}

export default function useCart({ courseData, optionsList, getFieldState, steps }: UseCartProps) {
    // Основное состояние корзины
    const [courseList, setCourseList] = useState(courseData);
    const [optionListFilter, setOptionListFilter] = useState(optionsList);
    const [submitOptionCourses, setSubmitOptionCourses] = useState<number[]>([]);

    // Индекс текущего шага в списке шагов
    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    // Флаги состояний процесса
    const [isSubmitted, setIsSubmitted] = useBoolean(false);
    const [isTrackerAdded, setIsTrackerAdded] = useBoolean(false);

    const toast = useToast();

    // Получение ID текущего шага
    const getCurrentStepId = () => steps[currentStepIndex];

    // Функция для перехода на следующий шаг
    const goToNextStep: SubmitHandler<Inputs> = (data) => {
        setIsSubmitted.on();

        setTimeout(() => {
            setIsSubmitted.off();

            // Если есть следующий шаг, переходим к нему
            if (currentStepIndex < steps.length - 1) {
                setCurrentStepIndex(currentStepIndex + 1);
            }
            // Иначе переходим к оплате
            else {
                goToPayment(data);
            }
        }, 1000);
    };

    // Перейти к указанному шагу по его индексу
    const goToStepByIndex = (index: number) => {
        if (index >= 0 && index < steps.length) {
            setCurrentStepIndex(index);
        }
    };

    // Перейти к указанному шагу по его ID
    const goToStepById = (stepId: string) => {
        const stepIndex = steps.findIndex((id) => id === stepId);
        if (stepIndex !== -1) {
            setCurrentStepIndex(stepIndex);
        }
    };

    // Функция для перехода к оплате
    const goToPayment: SubmitHandler<
        Inputs & {
            name: string;
            cardNumber: string;
            cvv: string;
            expirationDate: string;
            country: SelectOption | null;
        }
    > = async (data) => {
        setIsSubmitted.on();

        setTimeout(() => {
            setIsSubmitted.off();
        }, 20000);

        // Формируем параметры для оплаты
        const paymentParams: Record<string, any> = {
            optional_courses: submitOptionCourses,
        };

        // Если используется трекер и шаг трекера есть в списке, добавляем его в параметры
        if (steps.includes('tracker')) {
            paymentParams.is_tracker_added = Number(isTrackerAdded);
        }

        // Если есть опциональные курсы, добавляем информацию о них
        if (optionsList.length > 0) {
            paymentParams.optionalCourseListForFbp =
                courseList.length > 1
                    ? courseList.slice(1).map((course) => ({ id: course.id, price: course.price }))
                    : null;
        }

        if (data.cardNumber) {
            paymentParams.cardNumber = data.cardNumber;
            paymentParams.name = data.name;
            paymentParams.cvv = data.cvv;
            paymentParams.expirationDate = data.expirationDate;
            paymentParams.country = data.country;
        }

        // @ts-expect-error - глобальный метод окна
        window.payByStripeOptionsFromReact(data.email, paymentParams);
    };

    // Проверка валидности полей и отображение соответствующего тоста
    const checkToastVisible = () => {
        const isEmailInvalid = getFieldState('email').invalid;
        const isAgreeTermsInvalid = getFieldState('agree_terms').invalid;

        if (isEmailInvalid || isAgreeTermsInvalid) {
            toast({
                duration: 5000,
                status: 'error',
                title: isEmailInvalid ? 'Please check your email address' : 'Please agree the terms',
                position: 'top-right',
            });
        }
    };

    // Отфильтровываем опции, которые уже добавлены в корзину
    useEffect(() => {
        if (courseList && optionsList.length > 0) {
            const uniqueArray = optionsList.filter(
                (item) => !courseList.some((course: CourseDataItem) => course.id === item.id),
            );
            setOptionListFilter(uniqueArray);
        }
    }, [courseList, optionsList]);

    // Подготавливаем массив ID опциональных курсов для отправки
    useEffect(() => {
        if (courseList) {
            const submitCourses = courseList.map((item) => item.id).filter((item) => item !== courseData[0].id);
            setSubmitOptionCourses(submitCourses);
        }
    }, [courseList, courseData]);

    // Добавление опционального курса в корзину
    const onOptionClick = (course: OptionalCourseDataItem) => {
        setCourseList((prevCourses) => [...prevCourses, course]);

        const newOptionList = optionListFilter.filter((item) => item.id !== course.id);
        setOptionListFilter(newOptionList);
    };

    // Удаление опционального курса из корзины
    const onDeleteOptionClick = (course: CourseDataItem) => {
        const newCourseList = courseList.filter((item) => item.id !== course.id);
        setCourseList(newCourseList);

        // Проверяем, был ли это опциональный курс (не основной)
        if (course.id !== courseData[0].id) {
            // Возвращение элемента в optionListFilter на его оригинальное место
            const originalIndex = optionsList.findIndex((item) => item.id === course.id);

            if (originalIndex !== -1) {
                setOptionListFilter((prevOptions) => [
                    ...prevOptions.slice(0, originalIndex),
                    course,
                    ...prevOptions.slice(originalIndex),
                ]);
            }
        }
    };

    return {
        currentStepIndex,
        currentStepId: getCurrentStepId(),
        goToNextStep,
        goToStepByIndex,
        goToStepById,
        courseList,
        onDeleteOptionClick,
        checkToastVisible,
        isSubmitted,
        goToPayment,
        optionListFilter,
        onOptionClick,
        setIsTrackerAdded,
        isTrackerAdded,
    };
}
