import { Flex, Center, Box, BoxProps, HStack } from '@chakra-ui/react';
import { PlayButton, SeekButton, MuteButton, FullscreenButton, Controls, useMediaState } from '@vidstack/react';
import {
    RiVolumeMuteLine,
    RiVolumeDownLine,
    RiVolumeUpLine,
    RiFullscreenExitFill,
    RiFullscreenFill,
    RiForward15Line,
    RiReplay15Line,
    RiPlayLine,
    RiPauseLine,
} from 'react-icons/ri';

import PlaybackRateSubmenu from '../menues/PlaybackRateSubmenu';
import VideoQualitySubmenu from '../menues/VideoQualitySubmenu';
import AutoplaySwitch from './AutoplaySwitch';
import TimeVideo from './TimeVideo';
import TimeSliderVideo from './TimeSliderVideo';

export enum PlayerType {
    Small = 'small',
}

interface PlayerControlsProps {
    playerRef: any;
    type?: PlayerType | null;
    styles?: BoxProps;
}

const PlayerControls: React.FC<PlayerControlsProps> = ({ playerRef, type, styles }) => {
    const isPaused = useMediaState('paused', playerRef);
    const volume = useMediaState('volume', playerRef);
    const isMuted = useMediaState('muted', playerRef);
    const isFullscreen = useMediaState('fullscreen', playerRef);

    return (
        <Box
            as={Controls.Group}
            pt="4px"
            pb="10px"
            px="10px"
            {...styles}
        >
            <HStack>
                <TimeSliderVideo />

                {type === PlayerType.Small && (
                    <Box
                        as={MuteButton}
                        ml="auto"
                        color="white"
                    >
                        {isMuted || volume === 0 ? (
                            <RiVolumeMuteLine />
                        ) : volume < 0.5 ? (
                            <RiVolumeDownLine />
                        ) : (
                            <RiVolumeUpLine />
                        )}
                    </Box>
                )}
            </HStack>

            {type !== PlayerType.Small && (
                <Flex
                    align={'center'}
                    color={'gray.200'}
                >
                    <Flex gap={1}>
                        <Box
                            as={SeekButton}
                            seconds={-15}
                            _hover={{ color: 'gray.340' }}
                        >
                            <RiReplay15Line />
                        </Box>

                        <Center
                            as={PlayButton}
                            w={'32px'}
                            h={'32px'}
                            _hover={{ color: 'gray.340' }}
                        >
                            {isPaused ? <RiPlayLine fontSize={'32px'} /> : <RiPauseLine fontSize={'32px'} />}
                        </Center>

                        <Box
                            as={SeekButton}
                            seconds={15}
                            _hover={{ color: 'gray.340' }}
                        >
                            <RiForward15Line />
                        </Box>
                    </Flex>

                    <TimeVideo />

                    <PlaybackRateSubmenu />

                    <AutoplaySwitch styles={{ ml: 'auto', mr: 4 }} />

                    <Box
                        as={MuteButton}
                        mr={4}
                    >
                        {isMuted || volume === 0 ? (
                            <RiVolumeMuteLine />
                        ) : volume < 0.5 ? (
                            <RiVolumeDownLine />
                        ) : (
                            <RiVolumeUpLine />
                        )}
                    </Box>

                    <VideoQualitySubmenu styles={{ mr: 4 }} />

                    <Box
                        as={FullscreenButton}
                        _hover={{
                            color: 'white',
                        }}
                    >
                        {isFullscreen ? <RiFullscreenExitFill /> : <RiFullscreenFill />}
                    </Box>
                </Flex>
            )}
        </Box>
    );
};

export default PlayerControls;
