import { URLS_COMMENTS } from '@/common/constants/global';
import { Comment } from '@/entities/common/model/comments.types';
import { fetchData } from '@/shared/lib';
import { useEffect, useState } from 'react';

export default function useDashboardComments() {
    const [comments, setComments] = useState<Comment[]>([]);

    useEffect(() => {
        async function fetchComments() {
            const { data } = await fetchData<{ data: Comment[] }>(URLS_COMMENTS.COMMENTS);

            const comments = data
                .reduce((acc, item) => {
                    if (item.object) {
                        acc.push(item);
                    }
                    return acc;
                }, [])
                .slice(0, 3);

            setComments(comments);
        }

        if (!comments.length) {
            fetchComments();
        }
    }, []);

    return {
        comments,
    };
}
