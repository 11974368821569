import { Tooltip, Button, Spinner, Fade, ButtonGroup, Portal } from '@chakra-ui/react';
import { RiNotificationOffFill, RiNotification2Fill } from 'react-icons/ri';

import { GoToRecordingModal, ToTheEventModal } from '@/entities/modals';
import { EventData } from '@/entities/events/event-data';
import useEventBtnWithNotify from '../model/useEventBtnWithNotify';

interface EventNotificationBtnProps {
    eventData: EventData;
}

export default function EventBtnWithNotify({ eventData }: EventNotificationBtnProps) {
    const {
        isUpcoming,
        onEventBtnClick,
        upcomingBtnText,
        isNotificationEnabledState,
        setNotification,
        isSubLoading,
        isSubModalOpen,
        onSubModalClose,
    } = useEventBtnWithNotify(eventData);

    return (
        <>
            {isUpcoming ? (
                <ButtonGroup
                    spacing="2"
                    justifyContent="center"
                >
                    <Button
                        variant="outline-white-rounded"
                        minW={{ base: 'auto', md: '220px' }}
                        px={6}
                        onClick={onEventBtnClick}
                    >
                        {upcomingBtnText}
                    </Button>

                    <Tooltip
                        hasArrow
                        label={isNotificationEnabledState ? `Disable email notification` : `Enable email notification`}
                        placement="top"
                        bgColor="white"
                        color="gray.675"
                    >
                        <Button
                            variant="outline-white-rounded"
                            onClick={setNotification}
                        >
                            {isSubLoading ? (
                                <Spinner
                                    w="14px"
                                    h="14px"
                                />
                            ) : (
                                <Fade in={!isSubLoading}>
                                    {isNotificationEnabledState ? <RiNotificationOffFill /> : <RiNotification2Fill />}
                                </Fade>
                            )}
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            ) : (
                <Button
                    variant="outline-white-rounded"
                    display="flex"
                    minW={{ base: '140px', md: '220px' }}
                    px={6}
                    m="auto"
                    onClick={onEventBtnClick}
                >
                    Go to recording
                </Button>
            )}

            <Portal>
                {isUpcoming ? (
                    <ToTheEventModal
                        eventData={eventData}
                        isOpen={isSubModalOpen}
                        onClose={onSubModalClose}
                    />
                ) : (
                    <GoToRecordingModal
                        eventData={eventData}
                        isOpen={isSubModalOpen}
                        onClose={onSubModalClose}
                    />
                )}
            </Portal>
        </>
    );
}
