import { Modal, ModalOverlay, ModalContent, ModalBody, Text, ModalCloseButton } from '@chakra-ui/react';

import { CsrCourseBuyForm } from '@/entities/landing/course-buy-block/course-buy-form';
import { AvatarList } from '@/entities/lists/avatars';
import { ReactNode } from 'react';

interface CartLandingModalProps {
    isOpen: boolean;
    onClose: () => void;
    children?: ReactNode;
}

export default function CourseCartModal({ isOpen, onClose, children }: CartLandingModalProps) {
    return (
        <Modal
            variant="primary"
            isOpen={isOpen}
            onClose={onClose}
            scrollBehavior="inside"
            size={{ base: 'full', md: 'md' }}
        >
            <ModalOverlay />

            <ModalContent mx={{ base: 0, md: 2 }}>
                <ModalCloseButton />

                <ModalBody
                    py={10}
                    display="flex"
                    flexDir="column"
                    justifyContent="center"
                >
                    {children ? (
                        children
                    ) : (
                        <>
                            <AvatarList styles={{ mb: 4 }} />

                            <Text textStyle="md2">Your classes are waiting</Text>

                            <Text mb={7.5}>Create an account to get started. You&#39re only one step away.</Text>

                            <CsrCourseBuyForm />
                        </>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
