/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum ALL_COURSES_LIST_BY_ID {
    AfroJazzModernDanceSoulBody = '1009',
    BeatYourBrainRhythmicNeurogymnastics = '847',
    BodyAndSoulConnectionChakras = '1061',
    BrainBiohackingHowToBecomeSmarterAndImproveYourMemory = '758',
    BreastMassageAwakenSensitivity = '1090',
    BreatheBeyondStressMindfulness = '982',
    BreathingLoveTantricMagic = '951',
    BreathingOfSexuality = '914',
    DanceMovementTherapyWithAnnaTrish = '981',
    DivineYouChakra = '1020',
    DiyUnforgettableNights = '1041',
    ExtremeTransformationChallenge = '980',
    FallLoveBody = '914',
    FeelBodyThaiMassageTechniques = '839',
    FeminineAlchemySacredPractices4Elements = '1069',
    HappyRhythmsPlayYourWayToStressFreeLiving = '1120',
    HealingChakraChargeEnergyCenters = '863',
    HealthySpine16Exercises = '922',
    LuminaMultidimensionalSoundPractice = '973',
    MagicVoice = '836',
    MassageAsDanceLove = '831',
    MedicineSongsGeetaFeelHeal = '966',
    MoonManifestingLunarEnergiesEft = '1063',
    MorningAlchemy = '918',
    NadaYogaSoundToHealMindBodySoul = '964',
    NeuroDanceGrooveToGenius = '1052',
    NeurogeniusElevateCognitivePerformance = '921',
    OpenVoiceLearnSingBeautifully = '770',
    OuroborosBrainBody = '771',
    PracticalOnlineCourseSacredIntimacy = '1004',
    QigongForStressAndAnxiety = '1016',
    QigongNeuroSoundscapeBetterSleep = '1065',
    ReclaimVitalityEnergySavingQigong = '1100',
    RhythmicMoodFeelBetterInstant = '963',
    RisingLoveHealingJourney = '1019',
    RoyalPosture = '944',
    SacredTantricManifestationRitual = '1073',
    SensualAbundantUnlockKundalini = '1037',
    SensualGoddessWorkout = '829',
    SexualAlchemyVitalityPractices = '996',
    SingBetterIn21Days = '874',
    SingWithAgaBestSingingCourse = '896',
    TheArtOfSpeakingSonnectPowerfullyAndPersuadeEffectively = '1021',
    TwerkingBasicsLoveBody = '940',
    VocalYogaPeruquois = '1008',
    VoiceAlchemyHealingVoice = '1049',
    WarmupMobilitySpineHipShoulder = '1040',
    WomensLingamMassageWorkshop = '876',
    YogaTherapy15min = '1007',
    TheNudieFoodieEatWithLove = '833',
    ProperFoodCombiningFatLoss = '943',
}
