import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Link, Text, useToast, VStack } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';

import { APP_BRAND } from '@/common/constants/brand';
import { quizSettingAtom } from '@/entities/quizes/quiz';
import { ActionSubType, useCreateSub } from '@/features/subscription/create-sub';
import { CustomCheckbox } from '@/shared/ui';
import { CourseQuizBuyBtnsPlan } from '@/entities/promo/classes-for-you/model/constants';
import { getActivityByNameInArr } from '@/shared/lib';

interface Inputs {
    agree_terms: boolean;
}
export default function CourseQuizBuyBtnCheckboxPaywall({
    isTimed,
    popupText,
}: {
    isTimed?: boolean;
    popupText?: string;
}) {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();
    const toast = useToast();
    const quizSetting = useAtomValue(quizSettingAtom);

    const { isSubLoading, sendError, loadingText, createSub } = useCreateSub(
        getActivityByNameInArr<ActionSubType>(
            quizSetting?.options.activities,
            CourseQuizBuyBtnsPlan.ContinueWithTrial,
        )!,
    );

    const handleSignUp = async (data: Inputs) => {
        if (!data.agree_terms) {
            toast({
                duration: 5000,
                status: 'error',
                title: `Please agree the terms`,
                position: 'top-right',
            });
            return;
        }

        await createSub();
        toast({
            duration: 5000,
            status: 'success',
            title: 'You have successfully subscribed to all the courses!',
            position: 'top-right',
        });

        setTimeout(() => {
            window.location.pathname = '/redirect-to-onboarding-page';
        }, 3000);
    };

    return (
        <>
            <VStack
                as="form"
                onSubmit={handleSubmit(handleSignUp)}
                align={'flex-start'}
                w={{ base: '100%', lg: '340px' }}
                px="10px"
                pb="20px"
                pt="18px"
                mb="4"
                mx={'auto'}
                border="2px solid"
                borderColor="green.400"
                borderRadius="20px"
            >
                <Controller
                    name="agree_terms"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }: TObj<any>) => {
                        return (
                            <CustomCheckbox
                                checkboxProps={{
                                    isChecked: Boolean(value),
                                    onChange: onChange,
                                    isInvalid: Boolean(errors.agree_terms),
                                }}
                                LabelEl={() => (
                                    <Box>
                                        <Text
                                            display="inline"
                                            fontSize="14px"
                                            fontWeight={400}
                                            color={errors.agree_terms ? 'red' : 'gray.675'}
                                        >
                                            I agree to the above Membership terms
                                        </Text>
                                    </Box>
                                )}
                            />
                        );
                    }}
                />

                <Button
                    type="submit"
                    w="100%"
                    maxW="360px"
                    fontSize="sm2"
                    textTransform="uppercase"
                    isLoading={isSubLoading}
                    loadingText={loadingText}
                >
                    Try {APP_BRAND} MEMBERSHIP for free
                </Button>

                {sendError && (
                    <Text
                        mb={0}
                        w="100%"
                        textAlign="center"
                        color="red.500"
                    >
                        {sendError}
                    </Text>
                )}

                <Button
                    as={Link}
                    variant={'gray'}
                    onClick={() => (window.location.pathname = '/redirect-to-onboarding-page')}
                    w="100%"
                    maxW="360px"
                    fontSize="14px"
                >
                    Skip this one time offer
                </Button>
            </VStack>
        </>
    );
}
