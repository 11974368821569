import { Box, Divider, Flex, Text } from '@chakra-ui/react';

import CheckoutCartDesc from './CheckoutCartDesc';
import CheckoutOptionCourses from './CheckoutCourseList';
import CheckoutStripe from './CheckoutStripe';

export default function CheckoutCartView({
    totalCost,
    checkedItems,
    optionalCourse,
    onAddOptionalCourse,
    onDeleteItem,
    onAddTrial,
}: any) {
    return (
        <Flex
            flexDir={{ base: 'column', lg: 'row-reverse' }}
            gap={5}
            align="flex-start"
        >
            <Box w={{ base: '100%', lg: '50%' }}>
                <Box
                    p={4}
                    bgColor="white"
                >
                    <Text fontWeight={700}>Cart</Text>

                    <Divider />

                    <CheckoutOptionCourses
                        onAddTrial={onAddTrial}
                        totalCost={totalCost}
                        checkedItems={checkedItems}
                        onDeleteItem={onDeleteItem}
                        optionalCourse={optionalCourse}
                        onAddOptionalCourse={onAddOptionalCourse}
                    />
                </Box>

                {checkedItems.length > 0 && <CheckoutCartDesc styles={{ mt: 5, hideBelow: 'lg' }} />}
            </Box>

            {checkedItems.length > 0 && <CheckoutStripe />}
        </Flex>
    );
}
