import { fetchData, METHODS } from '@/shared/lib';
import { URLS } from '@/common/constants/global';

export async function prospectivePost(body: any) {
    try {
        await fetchData(URLS.SEND_PROSPECTIVE, {
            method: METHODS.POST,
            body: JSON.stringify(body),
        });
    } catch (error) {
        console.error(error);
    }
}

export async function prospectiveGet(email: string) {
    try {
        const { data } = await fetchData(URLS.getProspective(email), {
            method: METHODS.GET,
        });

        return data;
    } catch (error) {
        console.error(error);
    }
}

// 1. GET за пользователь
// 2. Если нет, то POST за пользователем.
// 3. Если да, то GET за списком курсов.
