import { Avatar, Box, Flex, Link, Text } from '@chakra-ui/react';

import { getFormattedDateSimple } from '@/shared/lib';
import { HTMLRenderer } from '@/shared/ui';

export default function CommentItem({ commentData }: { commentData: any }) {
    const { author, comment, created_at, object } = commentData;

    const formattedDate = getFormattedDateSimple(created_at);
    const authorName = author.full_name.length > 2 ? author.full_name : 'user';

    return (
        <Box
            pb={4}
            borderBottom={'1px solid'}
            borderColor={'gray.500'}
        >
            <Flex
                gap={2}
                mb={3}
            >
                <Avatar
                    src={author.avatar ? author.avatar : ''}
                    name={author.full_name}
                    w={{ base: '32px', lg: '40px' }}
                    h={{ base: '32px', lg: '40px' }}
                    bgColor={'#8E4AFF'}
                />

                <Box flexGrow={1}>
                    <Flex
                        gap={3}
                        mb={2.5}
                    >
                        <Text
                            fontSize={{ base: '14px', lg: '15px' }}
                            fontWeight={700}
                            color={'gray.800'}
                            textStyle={'truncate-line'}
                            maxW={'140px'}
                            title={authorName}
                        >
                            {authorName}
                        </Text>

                        <Text
                            fontSize={{ base: '14px', lg: '15px' }}
                            color={'gray.500'}
                        >
                            {formattedDate}
                        </Text>
                    </Flex>

                    <HTMLRenderer
                        html={comment}
                        styles={{
                            minH: '57px',
                            maxH: '140px',
                            color: 'gray.800',
                            overflow: 'hidden',
                        }}
                    />
                </Box>
            </Flex>

            <Link
                href={object.slug}
                fontSize={'13px'}
                color={'gray.500'}
            >
                {object.title}
            </Link>
        </Box>
    );
}
