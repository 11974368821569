import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { RiArrowLeftLine } from 'react-icons/ri';

import { URLS } from '@/common/constants/global';
import { fetchData, METHODS } from '@/shared/lib';
import { useLoadingTextWithFetch } from '@/shared/ui';
import { userAtom } from '@/shared/store';
import { useAtomValue } from 'jotai';
import { ConfomationEvents } from '@/entities/user/user-data';
import { ActionSubType } from '@/features/subscription/create-sub';

export default function DiscountSecondOfferScreen({
    onPrev,
    onCancelSub,
    onStayBtn,
}: {
    onPrev: () => void;
    onCancelSub: () => void;
    onStayBtn: () => void;
}) {
    const user = useAtomValue(userAtom);
    const eventId = user?.confirmation_events.find(
        (event) => event.event_type === ConfomationEvents.ConfirmCancelSubscription,
    )?.id;

    const { loadingText, sendError, isSubmitted, textedFetch } = useLoadingTextWithFetch(async () => {
        await fetchData(URLS.SUB_DISCOUNT, {
            method: METHODS.PUT,
            body: JSON.stringify({ action_subscribe: ActionSubType.TryCancel9month }),
        });

        if (eventId) {
            await fetchData(URLS.getEventInteract(eventId), {
                method: METHODS.POST,
            });
        }
    });

    const {
        loadingText: loadingTextCancel,
        sendError: sendErrorCancel,
        isSubmitted: isSubmittedCancel,
        textedFetch: textedFetchCancel,
    } = useLoadingTextWithFetch(async () => {
        await fetchData(URLS.SUB_CANCEL, {
            method: METHODS.POST,
        });

        if (eventId) {
            await fetchData(URLS.getEventInteract(eventId), {
                method: METHODS.POST,
            });
        }

        onCancelSub();
    });

    const onSaleClick = async () => {
        await textedFetch();
        onStayBtn();
    };

    return (
        <Box
            pos="relative"
            px={{ base: 6, md: 10 }}
            py={10}
        >
            <Box
                hideFrom="md"
                as={RiArrowLeftLine}
                pos="absolute"
                top="13px"
                left="16px"
                w="20px"
                h="20px"
                onClick={onPrev}
            />

            <Text
                textStyle="lg"
                textAlign={'center'}
            >
                This Is Our Final and Best Offer!
            </Text>

            <Text mb={4}>
                We don’t want you to miss out, so we’re giving you one last chance to stay with us at the lowest price
                ever:
            </Text>

            <Text
                mb={4}
                fontWeight={700}
            >
                Get 90% off – pay just $9 per month!
            </Text>

            <Text mb={6}>
                Enjoy unlimited access to all courses and features at this unbeatable price. This is your{' '}
                <b>final opportunity</b> to keep everything you love at the best value possible.
            </Text>

            <Flex
                flexDir={{ base: 'column', md: 'row' }}
                flexWrap={'wrap'}
                gap={{ base: 2, md: 5 }}
            >
                <Button
                    onClick={onSaleClick}
                    loadingText={loadingText}
                    isLoading={isSubmitted}
                    w={{ md: '100%' }}
                >
                    Claim this offer
                </Button>

                {sendError && (
                    <Text
                        color="red.500"
                        textAlign="center"
                        w="100%"
                        mb={0}
                    >
                        {sendError}
                    </Text>
                )}

                <Button
                    onClick={onPrev}
                    hideBelow="md"
                    w="calc(50% - 10px)"
                    bg="transparent"
                    border="1px solid"
                    borderColor="gray.500"
                    color="gray.500"
                    _hover={{
                        bg: 'transparent',
                        opacity: 0.7,
                    }}
                >
                    Back
                </Button>

                <Button
                    onClick={textedFetchCancel}
                    loadingText={loadingTextCancel}
                    isLoading={isSubmittedCancel}
                    variant="gray"
                    w={{ base: '100%', md: 'calc(50% - 10px)' }}
                >
                    CONFIRM CANCELATION
                </Button>

                {sendErrorCancel && (
                    <Text
                        color="red.500"
                        textAlign="center"
                        w="100%"
                        mb={0}
                    >
                        {sendErrorCancel}
                    </Text>
                )}
            </Flex>
        </Box>
    );
}
