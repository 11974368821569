import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const checkboxVariant = defineStyle({
    background: '#fff',
    borderRadius: '0',
    borderBottomRadius: 'xl',
    border: '2px solid',
    borderColor: 'green.400',
    borderTop: 'none',
    resize: 'none',

    _dark: {
        background: 'orange.300',
        color: 'orange.800',
    },
});

const simpleVariant = defineStyle({
    minH: '110px',
    py: '18px',
    px: 5,
    background: '#fff',
    borderRadius: 'xl',
    border: '2px solid',
    borderColor: 'green.400',
    resize: 'none',

    _dark: {
        background: 'orange.300',
        color: 'orange.800',
    },
});

const textareaTheme = defineStyleConfig({
    variants: {
        checkbox: checkboxVariant,
        simple: simpleVariant,
    },
});

export { textareaTheme };
