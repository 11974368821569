const textStyles = {
    xl3: {
        mb: 7.5,
        fontSize: { base: '32px', md: '50px' },
        fontWeight: 700,
    },
    xl2: {
        fontSize: { base: '40px', md: '50px' },
    },
    xl: {
        mb: 7.5,
        fontSize: { base: '24px', md: '42px' },
        fontWeight: 700,
        lineHeight: 1.3,
    },
    lg6: {
        mb: { base: 7.5, md: 5 },
        fontSize: { base: '26px', lg: '32px' },
        fontWeight: 700,
    },
    lg5: {
        mb: { base: 5, md: 7.5 },
        fontSize: '20px',
        fontWeight: 500,
    },
    lg4: {
        mb: 5,
        fontSize: { base: '20px', md: '24px' },
        fontWeight: 500,
    },
    lg3: {
        mb: { base: 5, md: 7.5 },
        fontSize: { base: '20px', lg: '32px' },
        fontWeight: 700,
    },
    lg2: {
        fontSize: { base: '20px', xl: '46px' },
        fontWeight: 700,
    },
    lg: {
        mb: 5,
        fontSize: { base: '20px', lg: '28px' },
        fontWeight: 700,
    },
    md7: {
        fontSize: { base: '18px', md: '24px' },
        fontWeight: 700,
    },
    md6: {
        fontSize: { base: '18px', md: '20px' },
        fontWeight: 500,
    },
    md5: {
        mb: 0,
        fontSize: { base: '16px', md: '20px' },
        fontWeight: 500,
    },
    md4: {
        fontSize: {
            base: '18px',
            md: '20px',
            lg: '28px',
        },
        fontWeight: 700,
    },
    md3: {
        mb: 0,
        fontSize: '18px',
        fontWeight: 700,
    },
    md2: {
        fontSize: { base: '18px', md: '28px' },
        fontWeight: 700,
    },
    md: {
        mb: 0,
        fontSize: { base: '16px', md: '18px' },
        fontWeight: 700,
    },
    sm8: {
        mb: 0,
        fontSize: { base: '16px', md: '24px' },
        fontWeight: 500,
    },
    sm7: {
        mb: 0,
        fontSize: { base: '15px', md: '24px' },
        fontWeight: 700,
    },
    sm6: {
        mb: 0,
        fontSize: { base: '14px', md: '18px' },
    },
    sm5: {
        mb: 0,
        fontSize: { base: '12px', md: '16px' },
    },
    sm4: {
        mb: 0,
        fontSize: { base: '14px', md: '16px' },
    },
    sm3: {
        mb: 0,
        fontSize: '15px',
    },
    sm2: {
        mb: 0,
        fontSize: { base: '15px', xl: '16px' },
    },
    sm: {
        mb: 0,
        fontSize: ['13px', '13px', '16px'],
    },
    xs: {
        mb: 0,
        fontSize: { base: '12px', xl: '14px' },
    },
    'sub-text': {
        mb: 0,
        color: 'gray.500',
        fontSize: 'sm',
    },
    'sm-bold': {
        mb: 0,
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: 'sm',
    },
    'see-all': {
        mb: 0,
        textTransform: 'uppercase',
        textDecoration: 'none',
        fontWeight: 700,
        color: 'green.400',
        cursor: 'pointer',

        _hover: {
            opacity: 0.7,
        },
    },
    'see-all-default': {
        mb: 0,
        textDecoration: 'underline',
    },
    'truncate-line': {
        mb: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    truncate: {
        mb: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    'price-through-line': {
        as: 'span',
        textDecoration: 'line-through',
        color: 'gray.500',
    },
};

export { textStyles };
