import { Box, BoxProps, Text } from '@chakra-ui/react';


export default function GuaranteeBlock({ styles }: { styles?: BoxProps }) {
    return (
        <Box
            p={{ base: 5, md: 7.5 }}
            borderRadius="xl"
            backgroundImage="/img/common/bg-pink-blue-3-mobile.png"
            backgroundRepeat="no-repeat"
            backgroundPosition="100% center"
            backgroundSize="cover"
            {...styles}
        >
            <Text
                fontSize={{ md: '20px' }}
                fontWeight={700}
            >
                100% Guaranteed:
            </Text>

            <Text mb={0}>
                Feel free to cancel your trial for any reason, completely obligation-free.
            </Text>
        </Box>
    );
}
