import { ReactNode, useEffect } from 'react';
import { useAtom } from 'jotai';

import { fetchUserAtom, userAtom } from '@/shared/store';
import { TOKENS } from '@/common/constants/global';

export default function UserWrapper({ isAlwaysRender, children }: { isAlwaysRender?: boolean; children: ReactNode }) {
    const [user, setUser] = useAtom(userAtom);
    const [fetchedUser] = useAtom(fetchUserAtom);

    useEffect(() => {
        if (TOKENS.USER && !user) {
            setUser(fetchedUser);
        }
    }, [fetchedUser, user, setUser]);

    return user || isAlwaysRender ? children : null;
}
