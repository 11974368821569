// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { QuizGroup } from '@/entities/feedback/quiz';
import { ISelectedCourses, UnselectedCoursesType } from './types';
import { CourseData } from '@/entities/common/model/courses.types';
import {
    ALL_COURSES_LIST,
    ALL_COURSES_LIST_WITH_OUTSIDE_COURSES,
} from '@/entities/quizes/quiz/model/lists/courses-by-category-lists';

interface SelectedCourse {
    label: string;
    value: string;
}

export function getLabeledSelectedCourses(
    selectedCourses: SelectedCourse[],
    selectedCoursesList: CourseData[],
): ISelectedCourses[] {
    // Создаем словарь для быстрого поиска label по value
    const labelMap: Record<string, string> = selectedCourses.reduce((acc, { label, value }) => {
        acc[value] = label;
        return acc;
    }, {});

    const selectedCoursesListFormatted = selectedCoursesList.map((course) => ({
        ...course,
        label: labelMap[course.id.toString()] || '',
    }));
    return selectedCoursesListFormatted;
}

export function mappingCourseList(selectedCoursesList) {
    const labelMap: Record<string, string> = ALL_COURSES_LIST_WITH_OUTSIDE_COURSES.flat().reduce(
        (acc, { label, value }) => {
            acc[value] = label;
            return acc;
        },
        {},
    );

    const selectedCoursesListFormatted = selectedCoursesList.map((course) => ({
        label: labelMap[course.toString()] || '',
        value: parseInt(course),
    }));
    return selectedCoursesListFormatted;
}

export function findCategoryWithMostMatches(numbers: any) {
    let bestMatchCategory = null;
    let maxMatches = 0;

    // Находим категорию с наибольшим количеством совпадений
    for (const category of ALL_COURSES_LIST) {
        const { categoryName, coursesList } = category;

        const valuesInCoursesList = coursesList.map((course) => parseInt(course.value, 10));

        const matchesCount = numbers.filter((number) => valuesInCoursesList.includes(number)).length;

        if (matchesCount > maxMatches) {
            maxMatches = matchesCount;
            bestMatchCategory = category;
        }
    }

    if (!bestMatchCategory) {
        return []; // Если ни одна категория не найдена
    }

    const { coursesList } = bestMatchCategory;

    // Создаем массив объектов с `label` и `value`
    return numbers.reduce((acc: { label: string; value: number }[], number) => {
        const findedCourse = coursesList.find((course) => parseInt(course.value, 10) === number);

        if (findedCourse) {
            acc.push({
                label: findedCourse.label,
                value: number,
            });
        }

        return acc;
    }, []);
}

export function getMappedUnselectedCourses(
    unselectedCourses: QuizGroup[],
    unselectedCoursesList: any[],
): UnselectedCoursesType<QuizGroup>[] {
    return unselectedCourses.map((group) => ({
        ...group,
        courseList:
            'courseList' in group &&
            Array.isArray(group.courseList) &&
            group.courseList.map((courseItem: any) => {
                const courseData = unselectedCoursesList.find((course: any) => course.slug === courseItem.value);
                return { ...courseData, label: courseItem.label };
            }),
    }));
}
