import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react';
import { RiCheckFill, RiEyeLine, RiShoppingCart2Fill } from 'react-icons/ri';

import { Countdown, CustomRadio, RadioVariants } from '@/shared/ui';
import { CourseSubscriptionPriceData } from '@/features/checkout/model/domestika.types';

export default function OneClickCheckoutItemTrial({
    isChecked,
    coursePrices,
    onClick,
    onBtnClick,
}: {
    isChecked: boolean;
    coursePrices?: CourseSubscriptionPriceData;
    onClick: () => void;
    onBtnClick: () => void;
}) {
    const yearPrice = coursePrices ? Number(coursePrices.subscription_price) : '';
    const monthPrice = coursePrices ? Math.floor((Number(coursePrices.subscription_price) / 12) * 100) / 100 : '';

    return (
        <Box
            onClick={onClick}
            p={4}
            borderRadius="xl"
            border="1px solid"
            borderColor={isChecked ? 'green.400' : 'gray.350'}
            bgColor={isChecked ? 'green.50' : 'white'}
        >
            <Box mb={4}>
                <HStack
                    justify="space-between"
                    mb={3}
                >
                    <Text as="span">Price with Plus Free Trial</Text>

                    <Text
                        as="span"
                        px="6px"
                        bgImage="linear-gradient(90deg,#ff4e10,#ff1b17 28%,#ff0f47 53%,#ff0571 67%,#f101e5)"
                        borderRadius="6px"
                        color="white"
                        fontSize="11px"
                        lineHeight="17px"
                        textTransform="uppercase"
                    >
                        Plus
                    </Text>
                </HStack>

                <HStack
                    justify="space-between"
                    align="flex-start"
                >
                    <Box>
                        <CustomRadio
                            label={`$${coursePrices ? coursePrices.price_course_by_subscription : '...'}`}
                            radioData={{ value: 'plus', label: 'Plus', icon: 'plus' }}
                            variant={RadioVariants.RadioInline}
                            isChecked={isChecked}
                            styles={{
                                color: 'gray.675',
                                fontSize: '26px',
                                fontWeight: 500,
                            }}
                        />

                        <Box>
                            <Text as="span">98% Disc.</Text>{' '}
                            <Text
                                as="span"
                                textDecor="line-through"
                                fontWeight={500}
                            >
                                ${coursePrices?.price_base_course} USD
                            </Text>
                        </Box>
                    </Box>

                    <Box
                        p="8px"
                        boxShadow="0px 4px 20px 0px rgba(51, 51, 51, 0.1)"
                        borderRadius="6px"
                        color="red.500"
                        textAlign="center"
                        bgColor="white"
                    >
                        Special Offer
                    </Box>
                </HStack>
            </Box>

            <Box
                maxH={isChecked ? '1000px' : '0'}
                opacity={isChecked ? 1 : 0}
                overflow="hidden"
                transition="all .2s ease-in-out"
            >
                <Button
                    onClick={onBtnClick}
                    display="flex"
                    gap={2}
                    w="100%"
                    mb={2}
                >
                    <Box
                        as={RiShoppingCart2Fill}
                        w="15px"
                        h="15px"
                    />
                    Buy
                </Button>

                <Box
                    textAlign="center"
                    color="red.500"
                    fontSize="13px"
                    mb={4}
                >
                    <Text as="span">This offer ends in</Text> <Countdown isEndlessHour={true} />
                </Box>

                <Flex
                    flexDir="column"
                    gap={2}
                    fontSize="13px"
                >
                    <Flex gap={1}>
                        <Box
                            as={RiCheckFill}
                            w="16px"
                            h="16px"
                            flexShrink={0}
                        />
                        <Text as="span">
                            Enjoy <b>this course</b> and <b>200+ others unlimitedly.</b>
                        </Text>
                    </Flex>

                    <Flex gap={1}>
                        <Box
                            as={RiEyeLine}
                            w="16px"
                            h="16px"
                            flexShrink={0}
                        />
                        <Text as="span">
                            After your 7-day trial period, ${monthPrice} USD/month (one payment of ${yearPrice} USD).
                            Cancel any time.
                        </Text>
                    </Flex>
                </Flex>
            </Box>
        </Box>
    );
}
