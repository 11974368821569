import { Box, Button, Text } from '@chakra-ui/react';

export default function KeepSubScreen({ onBtnClick }: { onBtnClick: () => void }) {
    return (
        <Box
            px={{ base: 6, md: 10 }}
            py={10}
        >
            <Text
                textStyle="lg"
                textAlign={'center'}
            >
                Great to have you stay with us!
            </Text>

            <Text mb={4}>
                <b>Your subscription is active,</b> and you’ll continue to enjoy full access to all courses, tools, and
                features without interruption.
            </Text>

            <Text
                textStyle="sm"
                mb={6}
            >
                We’re thrilled to support your learning journey.
            </Text>

            <Text
                textStyle="md5"
                mb={6}
                textAlign={'center'}
                fontWeight={600}
            >
                Ready to dive back in?
            </Text>

            <Button
                onClick={onBtnClick}
                w="100%"
            >
                Explore Courses Now
            </Button>
        </Box>
    );
}
