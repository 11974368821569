import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys);

const defaultVariant = definePartsStyle({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
    },
    container: {
        borderWidth: '1px',
        borderColor: 'gray.375',
        borderRadius: 'lg',
    },
    button: {
        display: 'flex',
        gap: 5,
        p: 5,
    },
    panel: {
        p: 5,
        borderTopWidth: '1px',
        borderColor: 'gray.375',
    },
});

const solidTildaVariant = definePartsStyle({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
    },
    container: {
        backgroundColor: 'gray.300',
        border: 0,
        borderRadius: 'lg',
    },
    button: {
        display: 'flex',
        gap: 4,
        p: 5,
        pl: { md: 7.5 },
        py: { md: 6 },
        pr: 16,
        borderRadius: 'lg',

        div: {
            right: {
                base: 5,
                md: 6,
            },
        },

        _hover: {
            background: 'transparent',
            color: 'gray.500',
        },
    },
    panel: {
        pb: {
            base: 5,
            md: 6,
        },
        px: {
            base: 5,
            md: 7.5,
        },
    },
});

const tinyTildaVariant = definePartsStyle({
    root: {},
    container: {
        span: {
            fontWeight: 500,
        },
    },
    button: {
        display: 'flex',
        gap: 4,
        alignItems: 'flex-start',
        pl: 0,
        py: { base: 4, md: 7 },
        pr: 12,

        _hover: {
            background: 'transparent',
        },
    },
    panel: {
        pt: 0,
        pb: 5,
        px: 0,
    },
});

const lightVariant = definePartsStyle({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        p: 0,
    },
    container: {
        borderWidth: 0,
        borderRadius: 0,

        _last: {
            borderWidth: 0,
        },
    },
    button: {
        padding: '7.5px 0',

        span: {
            fontSize: 'sm2',
            fontWeight: 400,
            color: 'gray.675',
        },

        _hover: {
            background: 'transparent',
        },
    },
    panel: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2.5,
        ps: 0,
        pe: 0,
        pt: 2.5,
        pb: 3,
        borderTopWidth: '0',
        borderColor: 'gray.375',

        p: {
            mb: 0,
            fontSize: 'sm',
            color: 'gray.500',
        },
    },
});

const playlistVariant = definePartsStyle({
    root: {
        display: 'flex',
        flexDir: 'column',
        gap: 0,
        w: '100%',
        maxH: '100%',
        p: 0,
    },
    container: {
        borderWidth: 0,
        borderRadius: 0,
        borderBottom: '2px solid',
        borderColor: { base: 'gray.700 !important', md: 'gray.800 !important' },
        color: '#fff',

        _last: {
            borderWidth: 0,
        },
    },
    button: {
        padding: '20px',
        bgColor: { base: 'gray.675', md: 'gray.750' },
        fontSize: '15px',
        fontWeight: 400,

        _hover: {
            color: 'green.400',
        },
    },
    panel: {
        cursor: 'pointer',
        padding: '0',
        fontSize: '13px',
        border: 'none',
    },
});

const skeuomorphVariant = definePartsStyle({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
    },
    container: {
        border: 'none',
        borderRadius: { base: '20px', lg: '40px' },
        boxShadow: 'inset 0 0 30px 0 rgba(255, 255, 255, 0.14)',
        background: '#1d1d1d',
        color: '#fff',
    },
    button: {
        gap: 5,
        p: { base: 4, lg: 7.5 },

        _hover: {
            opacity: 0.8,
        }
    },
    panel: {
        px: { base: 4, lg: 7.5 },
        pb: { base: 4, lg: 7.5 },
    },
});

const accordionTheme = defineMultiStyleConfig({
    variants: {
        default: defaultVariant,
        light: lightVariant,
        playlist: playlistVariant,
        'solid-tilda': solidTildaVariant,
        'tiny-tilda': tinyTildaVariant,
        skeuomorph: skeuomorphVariant,
    },
});

export { accordionTheme };
