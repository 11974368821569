'use client';

import { useEffect, useState } from 'react';

import { isElementPartiallyVisible } from '@/shared/lib';

export default function useVisibleEl(btnBlock: any, visibilityThreshold?: number) {
    const [isBlockVisible, setIsBlockVisible] = useState(true);

    useEffect(() => {
        const checkVisible = () => {
            if (btnBlock.current && isElementPartiallyVisible(btnBlock.current, visibilityThreshold)) {
                setIsBlockVisible(true);
                return;
            }
            setIsBlockVisible(false);
        };

        if (btnBlock.current) {
            checkVisible();
            window.addEventListener('scroll', checkVisible);
        }

        return () => {
            window.removeEventListener('scroll', checkVisible);
        };
    }, [btnBlock]);

    return { isBlockVisible };
}
