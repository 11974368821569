import { CourseData } from '@/entities/common/model/courses.types';
import { CourseUser } from '@/entities/courses/model';
import { CurrentStepEventNames } from '@/entities/quizes/quiz';

export enum SubPeriod {
    OneMonth = '1',
    Year = '12',
}

export enum ConfomationEvents {
    ConfirmCancelSubscription = 'confirm_cancel_subscription',
}

export enum UserRoles {
    Admin = 'admin',
}

export interface UserData {
    id: number;
    name: string;
    email: string;
    content_language: string | null;
    country_code: string | null;
    phone: string;
    verification_code: string | null;
    last_name: string;
    first_name: string;
    balance: number;
    balance_usd: number;
    bonus_balance: number;
    info: string | null;
    email_verified_at: string | null;
    created_at: string;
    updated_at: string;
    provider: string | null;
    provider_id: string | null;
    avatar: boolean | string;
    google_id: string | null;
    apple_id: string | null;
    reasons: string | null;
    interests: string | null;
    notification_time: string | null;
    disabled_notifications: string[];
    utm_source: string | null;
    year_of_birth: number | null;
    gender: string | null;
    city: string | null;
    age: number | null;
    api_integration: string | null;
    social: string | null;
    personal_data_hidden: number;
    stripe_id: string | null;
    pm_type: string | null;
    pm_last_four: string | null;
    trial_ends_at: string | null;
    was_watching: number;
    last_login_at: string;
    last_login_ip_address: string;
    visit_counts: number;
    visit_counts_updated_at: string;
    email_double_checked: 0 | 1;
    is_sent_subscription_email: number;
    has_refund: number;
    was_moved_between_accounts: number;
    movement_history_id: number;
    time_zone_id: string | null;
    media: any[];

    is_subscribe: boolean;
    is_add_payment_card: boolean;
    subscription_plan: {
        id: number;
        title: string;
        base_price: number | null;
        description: string | null;
        period: SubPeriod;
        trial_days: number;
        price: string;
        currency_id: number;
        stripe_price_id: string;
        status: number;
        type: number;
        locale: string;
        lookup_key: string;
        created_at: string;
        updated_at: string;
    };
    subscription_trial_days: number;
    is_add_trial_period: boolean;
    subscription: {
        created_at: string;
    };
    confirmation_events: {
        id: number;
        event_type: ConfomationEvents;
        is_confirmed: 1 | 0;
        is_interacted: 1 | 0;
        confirmation_error: string | null;
    }[];
    quiz_info: {
        quiz_thank_page_step: CurrentStepEventNames;
        quiz_thank_page_step_history: string[];
        quiz_recommend_course_id: number[];
        quiz_is_send_data_to_mailchimp: boolean;
        quiz_is_send_data_to_komm: null;
        date_timer_start: string;
        is_quiz_year_sub: boolean;
    };
    is_personal_tracker: {
        is_personal_tracker: boolean;
    };
    continue_watching_course: CourseUser[];
    tooltip_info: {
        viewed_tooltips: number[];
        is_skipped: number;
    };
    is_user_subscription: boolean;
    fb_pixel_start_trial_data: {
        currency: string;
        event_id: string;
        subscription_count: number;
    };
    is_course_purchase: boolean;
    role: UserRoles[];
    course_purchased: CourseData[];
}
