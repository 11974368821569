export default function PlusTiny(props: any) {
    return (
        <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g
                stroke="currentColor"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="square"
            >
                <path d="M1 12h22M12 1v22" />
            </g>
        </svg>
    );
}
