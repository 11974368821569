export default function Star(props: any) {
    return (
        <svg
            viewBox="0 0 52 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M23.993 1.164a4 4 0 014.014 0l10.968 6.363 10.994 6.317a4 4 0 012.007 3.476L51.95 30l.026 12.68a4 4 0 01-2.007 3.476l-10.994 6.317-10.968 6.363a4 4 0 01-4.014 0l-10.968-6.363-10.994-6.317A4 4 0 01.024 42.68L.05 30 .024 17.32a4 4 0 012.007-3.476l10.994-6.317 10.968-6.363z"
                fill="currentColor"
            />
        </svg>
    );
}
