import { Button, ButtonProps, Fade, Text, VStack } from '@chakra-ui/react';
import { useAtomValue } from 'jotai/index';

import { userAtom } from '@/shared/store';
import { CongratulationsModal } from '@/entities/modals';

interface BannerCourseQuizBtnProps {
    state: {
        isLoading: boolean;
        isError: boolean;
        isSubCreate: boolean;
        loadingText: string;
    };
    btnText?: string;
    onClick?: () => void;
    styles?: ButtonProps;
}

export default function BannerCourseQuizBtn({ state, btnText, onClick, styles }: BannerCourseQuizBtnProps) {
    const user = useAtomValue(userAtom);

    if (!user || user?.is_subscribe) {
        return null;
    }

    return (
        <VStack>
            <Button
                isLoading={state.isLoading}
                loadingText={state.loadingText}
                onClick={onClick}
                variant="white"
                {...styles}
            >
                {btnText ? btnText : 'Start the Quiz Now'}
            </Button>
            {state.isError && (
                <Fade in={true}>
                    <Text
                        mb={0}
                        color="red.600"
                    >
                        Something wrong. Try again
                    </Text>
                </Fade>
            )}
            <CongratulationsModal
                isOpen={state.isSubCreate}
                onClose={() => location.reload()}
            />
        </VStack>
    );
}
