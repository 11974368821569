import { Box, Button, Modal, ModalCloseButton, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';
import useUnsubFlow from '../../../model/useUnsubFlow';

export default function SomethingWentWrongEmail({ setSomethingWentWrongEmailScreenShowed }: any) {
    const { isUnsubFlowOpen, onClosePopup } = useUnsubFlow();

    return (
        <Modal
            variant="primary"
            scrollBehavior="inside"
            isOpen={isUnsubFlowOpen}
            onClose={() => {
                setSomethingWentWrongEmailScreenShowed(true);
                onClosePopup();
            }}
            isCentered
        >
            <ModalOverlay />

            <ModalContent
                maxH="98vh"
                overflowY="auto"
            >
                <ModalCloseButton zIndex={10} />
                <Box
                    px={{ base: 6, md: 10 }}
                    py={10}
                >
                    <Text
                        textStyle="lg"
                        textAlign={'center'}
                    >
                        Oops! Something Went Wrong
                    </Text>

                    <Text mb={4}>It seems the link has expired or an error occurred.</Text>

                    <Text mb={6}>
                        Please try again to complete your request. If the issue persists, feel free to contact our
                        support team for assistance.
                    </Text>

                    <Button
                        onClick={onClosePopup}
                        w="100%"
                    >
                        Okay
                    </Button>
                </Box>
            </ModalContent>
        </Modal>
    );
}
