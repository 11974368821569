export enum QuizType {
    Landing = 'landing',
    Longevity = '1', // синхрон с беком
    LongevityLifestyle = '2',
    Default = '3',
    DefaultCheckEmail = '4',
    OptionsCheckout = '5', // Чекаут без редиректа в STRIPE
    DefaultCheckEmailYear = '6', // с годовой подпиской
    DefaultCheckEmailLifetimeSub = '7',
    Days30For9 = '8',
    PaywallQuiz = '9',
    PaywallQuizMainPage = '10',
    DefaultWithEmailCheckboxMonthly = '11',
    DefaultWithEmailCheckButtonsMonthly = '12',
    DefaultWithEmailCheckBoxMonthly1Trial = '13',
    T1DefaultWithEmailCheckButtonsMonthly = '14',
    PublicQuiz = 'public_quiz',
}

// Тип ивента (события) квиза
export enum EventTypes {
    CourseChooseQuiz = 'courses_choose_quiz_v3',
    ThankPageQuiz = 'quiz_thanku_page',
    QuizNewTest = 'quiz_new_test',
}

export enum CurrentStepEventNames {
    QuizAgeQuestionStep = 'quiz_age_question_step', // начало квиза
    QuizFinalPageStep = 'quiz_final_page_step', // страница рекомендаций
    QuizOnboardingPageStep = 'quiz_onboarding_page_step', // онбординг
    QuizCheckEmailStep = 'quiz_check_email_step',
}

// Имя проперти (свойства) ивента (события)
export enum EventNames {
    SignUpGoogle = 'sign_up_google',
    SignUp = 'sign_up',

    CheckEmail = 'check_email',
    AddPhone = 'add_phone',

    Age = 'unlock_the_superpower_age',
    ChooseCategory = 'what_brings_you_here_today',
    UnlockPotential = 'unlock_your_potential_today',
    HowMuchTime = 'how_much_time',
    ThatGreat = 'thats_great',
    EmailSignUp = 'discover_superpower_of_your_voice_input_email_sign_up',
    SignUpGoogleStep = 'google_discover_superpower_of_your_voice_input_email_sign_up',
    FinalStep = 'vocal_yoga_and_10_other_pay_1',
    FinalStepPopup = 'popup_vocal_yoga_and_10_other_pay_1',
    SkipToTheOffer = 'skip_to_the_offer',
    Gender = 'gender',

    ExtendLife = 'extend_life',
    ShortYearsLife = '7_years_life',
    HowOftenWellBeing = 'how_often_well-being',
    TryProgramm = 'try_programm',
    BenefitProgramm = 'benefit_programm',
    TrialSub = 'trial_sub',

    HowOftenDoYouEngage = 'how_often_do_you_engage',
    LongYearsLife = '130_years_life',
    PhysicalActivitiesReduceStress = 'physical_activities_reduce_stress',
    OurCompanyHasPrograms = 'our_company_has_programs',
    OurBrandPlus = 'our_brand_plus',
    IfYouHeadPersonalTracker = 'if_you_head_personal_tracker',
    WeHaveTracker = 'we_have_tracker',
    WhenYouFocus = 'when_you_focus',

    HealthLifestyle = 'health_lifestyle',
    HealthLifestyleExtendLife = 'health_lifestyle_extend_life',
    DiscoverPrograms = 'discover_programs',
    JourneyNotAlone = 'journey_not_alone',
    SupportiveCommunity = 'supportive_community',
    AchieveYourGoals = 'achieve_your_goals',
    MakeYourEffortsMoreEffective = 'make_your_efforts_more_effective',
    LongevityExpertAdvice = 'get_longevity_expert_advice',

    GetLongevityExpertAdvice = 'Get Longevity Expert Advice',
    YourPersonalizedPathToSuccess = 'Your Personalized Path to Success',
}

export enum QuizOptionComponents {
    Priced = 'priced',
}
