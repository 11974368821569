import { useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { MediaPlayer, MediaProvider, MediaPlayerInstance } from '@vidstack/react';

import './style.scss';

import { isInstagramIos, isSafari } from '@/shared/lib';
import PlayerControlLight from './components/controls/PlayerControlLight';

export default function TrailerPlayerLight({ videoSrc, styles, controlStyles }: any) {
    const playerRef = useRef<MediaPlayerInstance>(null);

    const isSafariBrowser = isSafari();
    const isInstagramIosBrowser = isInstagramIos();

    const [isHovered, setIsHovered] = useState(false);

    return (
        <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            pos="relative"
            h="100%"
            sx={{
                video: {
                    borderRadius: { base: 'none', md: '12px' },
                },
            }}
            {...styles}
        >
            <MediaPlayer
                ref={playerRef}
                src={videoSrc}
                title="video"
                load="eager"
                autoPlay
                muted
                playsInline
                preferNativeHLS={isSafariBrowser || isInstagramIosBrowser ? true : false}
                className="vds-video-layout"
                style={{ height: '100%' }}
            >
                <Box
                    as={MediaProvider}
                    bgColor="transparent"
                ></Box>
            </MediaPlayer>

            <PlayerControlLight
                playerRef={playerRef}
                styles={{
                    opacity: isHovered ? 1 : 0,
                    ...controlStyles,
                }}
            />

            <Box
                position="absolute"
                zIndex={10}
                top="0"
                left="0"
                width="100%"
                height="100%"
                background="linear-gradient(180deg, #111 0%, rgba(17, 17, 17, 0) 15%, rgba(17, 17, 17, 0) 85%, #111 100%)"
                transition="all 0.25s ease-in-out"
            />
        </Box>
    );
}
