import { modalAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(modalAnatomy.keys);

const primaryVariant = definePartsStyle({
    closeButton: {
        color: 'gray.500',
    },
    overlay: {
        background: 'rgba(0,0,0,0.3)',
    },
    dialogContainer: {
        overflow: 'hidden',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minH: { base: 150, md: 200 },
        borderTopRadius: 'xl',
        overflow: 'hidden',
        backgroundColor: 'gray.325',

        img: {
            w: { base: '52px', md: '80px' },
        },
    },
    dialog: {
        maxW: '560px',
        minW: { md: '560px' },
        maxH: 'calc(100% - 2.5rem)',
        mx: 2,
        mb: { base: 2, md: 5 },
        mt: { base: 'auto', md: 5 },
        borderRadius: 'xl',
    },
    body: {
        px: { base: 5, md: 10 },
        pt: { base: 5, md: 7.5 },
        pb: 0,
    },
    footer: {
        display: 'flex',
        flexDir: { base: 'column-reverse', md: 'row' },
        mt: 7.5,
        mb: { base: -2.5, md: 0 }, // old safari fix
        mr: { md: -5 }, // old safari fix
        px: { base: 5, md: 10 },
        pb: { base: 5, md: 10 },
        pt: 0,

        button: {
            w: { base: '100%', md: '50%' },
            mt: { base: 2.5, md: 0 }, // old safari fix
            mr: { md: 5 }, // old safari fix
        },
    },
});

const topBlockVariant = definePartsStyle({
    closeButton: {
        color: 'white',
    },
    overlay: {
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    dialogContainer: {
        // overflow: 'hidden',
    },
    header: {
        py: 7.5,
        px: 4,
        borderTopRadius: 'xl',
        textAlign: 'center',
    },
    dialog: {
        maxW: '560px',
        minW: { md: '560px' },
        maxH: 'calc(100% - 2.5rem)',
        mx: 2,
        mb: { base: 2, md: 5 },
        mt: { base: 'auto', md: 5 },
        borderRadius: 'xl',
    },
    body: {
        px: { base: 5, md: 10 },
        pt: { base: 5, md: 10 },
        pb: 0,
    },
    footer: {
        display: 'flex',
        flexDir: { base: 'column' },
        gap: 5,
        mt: { base: 5, md: 7.5 },
        px: { base: 5, md: 10 },
        pb: { base: 7.5, md: 10 },
        pt: 0,

        button: {
            w: '100%',
        },
    },
});

const singleBtnVariant = definePartsStyle({
    header: {
        img: {
            w: 'auto',
            borderTopRadius: 'xl',
        },
    },
    footer: {
        mt: 7.5,

        button: {
            w: '100%',
        },
    },
});

const glassmorphismVariant = definePartsStyle({
    closeButton: {
        zIndex: 20,
        pos: 'absolute',
        top: '20px',
        right: '20px',
        color: '#fff',
        sx: {
            svg: {
                w: '14px',
                h: '14px',
            },
        },
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        zIndex: 1000,
    },
    dialogContainer: {
        zIndex: 1001,
    },
    dialog: {
        my: { md: 16 },
        borderRadius: '32px',
        maxW: '560px',
        boxShadow: 'inset 0 0 30px 0 rgba(255, 255, 255, 0.2)',
        bg: '#242424',
        color: 'white',
        zIndex: 100,
    },
    body: {
        pt: { base: '70px', md: 0 },
        p: { md: 10 },
        pb: { base: '80px', md: '80px' },
    },
});

const modalTheme = defineMultiStyleConfig({
    variants: {
        'single-btn': singleBtnVariant,
        'top-block': topBlockVariant,
        primary: primaryVariant,
        glassmorphism: glassmorphismVariant,
    },
});

export { modalTheme };
