import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    Box,
    ModalFooter,
    Button,
    Text,
    Image,
    Link,
    Flex,
    Grid,
    Spinner,
    Center,
} from '@chakra-ui/react';
import { useAtomValue } from 'jotai';

import { CountdownGrid, HTMLRenderer, LoadingContentText } from '@/shared/ui';
import { ActionSubType } from '@/features/subscription/create-sub';
import { useSubFromMonolith } from '@/features/subscription/sub-from-monolith';
import { useCountdownReminigTime } from '@/features/promo/countdown';
import { EventCardDate } from '@/entities/socials/events/event-card';
import { useEventsList } from '@/features/socials/events/get-events';
import { selectedCoursesFullDataAtom } from '@/entities/quizes/quiz/model/stores/quiz-store';

const sentences = [
    '<b>Mindfulness & Brain Exercises:</b> Expand your consciousness and sharpen your mental clarity.',
    '<b>Yoga Practices:</b> Align your body and mind while connecting with the universe.',
    '<b>Dance & Singing:</b> Celebrate your soul’s rhythm and unleash your inner joy.',
    '<b>Spiritual Massage:</b> Experience healing through transformative touch techniques.',
];

const TIMER_COUNTDOWN_HOURS = 12;

export default function TimedClassesForYouModalTimer({
    isOpenSkipPopup,
    onCloseSkipModal,
    isSkipBtnShow,
    skipRedirectLink,
}: any) {
    const {
        sendError: sendError30,
        loadingText: loadingText30,
        isSubmitted: isSubmitted30,
        createSub: handleSignUp30,
    } = useSubFromMonolith(ActionSubType.QuizThankuTwobuttonHardPopup30);
    const { eventList, isEventsLoading } = useEventsList();

    const { reminingTime } = useCountdownReminigTime(TIMER_COUNTDOWN_HOURS); // чтобы всё равно отправить таймер?

    const selectedCoursesFullDataAtomData = useAtomValue(selectedCoursesFullDataAtom);

    return (
        <Modal
            variant="top-block"
            scrollBehavior="inside"
            isOpen={isOpenSkipPopup}
            onClose={onCloseSkipModal}
            isCentered
        >
            <ModalOverlay />

            <ModalContent>
                <ModalCloseButton />

                <ModalHeader
                    background="url(/img/bg/blur-pink-blue-hor.png) no-repeat 50% 50%"
                    backgroundSize="cover"
                    color="white"
                >
                    <CountdownGrid
                        timerSeconds={180}
                        styles={{ justifyContent: 'center' }}
                    />

                    <Text
                        textStyle="lg"
                        mt={5}
                        mb={2.5}
                    >
                        Think that 7 days is not enough?
                    </Text>

                    <Text
                        textStyle="md6"
                        fontWeight={700}
                        mb={0}
                    >
                        Try this One Time Offer with{' '}
                        <Text
                            as="span"
                            pos="relative"
                            mb={0}
                            display={{ base: 'inline-block', md: 'inline' }}
                        >
                            30-Day Free Trial
                            <Image
                                src="/img/elements/ranbow-line.svg"
                                alt="ranbow-line"
                                pos="absolute"
                                top={{ base: '18px', md: '20px' }}
                                left={0}
                                w="100%"
                                h="100%"
                            />
                        </Text>
                    </Text>
                </ModalHeader>

                <ModalBody>
                    <Box>
                        <Text mb={6}>
                            Unlock a{' '}
                            <Text
                                as="span"
                                fontWeight={700}
                            >
                                30-day free trial
                            </Text>{' '}
                            with access to 200+ recorded and live programs. After, it&#39;s only{' '}
                            <Text
                                as="span"
                                textDecor={'line-through'}
                            >
                                $129
                            </Text>{' '}
                            $89/month. Enjoy a complete self-recovery retreat from the comfort of your own home, no
                            matter where no matter where you are:
                        </Text>

                        <Box
                            display="flex"
                            flexDir="column"
                            gap={{ base: 2.5, md: 3 }}
                            mb={8}
                            fontSize={'14px'}
                        >
                            <Box
                                display="flex"
                                flexDir="row"
                                alignItems="baseline"
                                gap={2.5}
                            >
                                <Image
                                    src="/img/icons/common/arrow-mark.svg"
                                    alt="arrow-mark"
                                    w={'12px'}
                                />
                                <Text>
                                    <b>Engage in Weekly Live sessions</b>, where the spiritual journey unfolds in
                                    real-time, offering fresh insights and collective energies.
                                </Text>
                            </Box>

                            <Flex
                                pos={'relative'}
                                gap="8px"
                                minH="150px"
                            >
                                {eventList && eventList.upcomingEvents.length > 0 ? (
                                    eventList.upcomingEvents.slice(0, 2).map((event: any) => (
                                        <EventCardDate
                                            key={event.id}
                                            eventData={event}
                                            styles={{
                                                fontSize: { base: '13px', md: '14px' },
                                                w: { base: 'calc(50% - 4px)', md: '220px' },
                                                h: { base: '133px', md: '158px' },
                                            }}
                                        />
                                    ))
                                ) : (
                                    <LoadingContentText isLoading={isEventsLoading}>
                                        There are no upcoming events
                                    </LoadingContentText>
                                )}
                            </Flex>

                            {sentences.map((text, index) => {
                                return (
                                    <Box
                                        display="flex"
                                        flexDir="row"
                                        alignItems="baseline"
                                        gap={2.5}
                                        key={index}
                                    >
                                        <Image
                                            src="/img/icons/common/arrow-mark.svg"
                                            alt="arrow-mark"
                                            w={'12px'}
                                        />
                                        <HTMLRenderer text={text} />
                                    </Box>
                                );
                            })}
                        </Box>

                        <Box>
                            <Text
                                mb={6}
                                textAlign={'center'}
                                fontWeight={700}
                            >
                                Start Your Journey Now with Unlimited 30-Day Free Access to These Courses:
                            </Text>

                            {selectedCoursesFullDataAtomData ? (
                                <Grid
                                    templateColumns={'repeat(2, 1fr)'}
                                    gap={4}
                                    mb={6}
                                >
                                    {selectedCoursesFullDataAtomData.slice(0, 4).map((course: any) => (
                                        <Image
                                            key={course.id}
                                            src={course.thumb_big}
                                            alt={course.title}
                                            borderRadius={'8px'}
                                        />
                                    ))}
                                </Grid>
                            ) : (
                                <Center
                                    h={'200px'}
                                    w={'100%'}
                                >
                                    <Spinner color="green.400" />
                                </Center>
                            )}

                            <Text
                                textAlign={'center'}
                                fontWeight={700}
                            >
                                and 250+ courses for your well-being goals
                            </Text>
                        </Box>
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button
                        w={{ md: '100%' }}
                        onClick={handleSignUp30}
                        isLoading={isSubmitted30}
                        loadingText={loadingText30}
                    >
                        OPEN ACCESS NOW
                    </Button>

                    {sendError30 && (
                        <Text
                            mb={0}
                            textAlign="center"
                            color="red.500"
                        >
                            {sendError30}
                        </Text>
                    )}

                    {isSkipBtnShow && (
                        <Link
                            href={skipRedirectLink}
                            textAlign="center"
                            color="gray.525"
                            textDecoration="underline"
                        >
                            Skip this one time offer
                        </Link>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
