import { useMemo, useState } from 'react';
import { useMediaState, useVideoQualityOptions, VideoQualityOption } from '@vidstack/react';
import { RiHdLine } from 'react-icons/ri';
import { Menu } from '@ark-ui/react/menu';
import { Box, BoxProps, Flex, RadioGroup, Text, useMediaQuery } from '@chakra-ui/react';

import { CustomRadioMark } from '@/shared/ui';

export default function VideoQualitySubmenu({ styles }: { styles?: BoxProps }) {
    const options = useVideoQualityOptions({ auto: true, sort: 'descending' });

    const [isLargerThan1220] = useMediaQuery('(min-width: 1220px)');

    const filteredOptions: VideoQualityOption[] = useMemo(
        () =>
            options.reduce((acc: VideoQualityOption[], obj: VideoQualityOption) => {
                const existingObjIndex = acc.findIndex((item) => item.quality?.height === obj.quality?.height);

                if (existingObjIndex !== -1 && obj.quality && acc[existingObjIndex].quality) {
                    acc[existingObjIndex] = obj;
                } else {
                    acc.push(obj);
                }
                return acc;
            }, []),
        [options],
    );

    const autoQuality = useMediaState('autoQuality');
    const autoHint = `Auto (${options.selectedQuality?.height}p)`;

    return (
        <Menu.Root
            positioning={{
                overflowPadding: 0,
                gutter: isLargerThan1220 ? 42 : 8,
                placement: isLargerThan1220 ? 'top-end' : 'bottom-end',
            }}
        >
            <Box
                as={Menu.Trigger}
                color={'gray.200'}
                _hover={{
                    color: 'gray.340',
                }}
                {...styles}
            >
                <RiHdLine />
            </Box>

            <Menu.Positioner>
                <Box
                    as={Menu.Content}
                    pos={'relative'}
                    zIndex={20}
                    py={{ base: '8px', md: '20px' }}
                    px={{ base: '10px', md: '20px' }}
                    bgColor={'gray.675'}
                    borderRadius={'8px'}
                >
                    <Flex
                        align={'center'}
                        mb={{ base: 2, lg: 4 }}
                        gap={2}
                    >
                        <Text
                            as={'span'}
                            color={'white'}
                            fontSize={{ base: '14px', lg: '18px' }}
                        >
                            Quality
                        </Text>
                        <Text
                            as={'span'}
                            color={'gray.430'}
                            fontSize={{ base: '10px', lg: '12px' }}
                        >
                            ·
                        </Text>
                        <Text
                            as={'span'}
                            color={'gray.430'}
                            fontSize={{ base: '10px', lg: '12px' }}
                        >
                            {`${options.selectedQuality?.height}p`}
                        </Text>
                    </Flex>

                    <RadioGroup
                        value={options.selectedValue}
                        display={'flex'}
                        flexDir={'column'}
                        gap={{ base: 1, lg: 2 }}
                        fontSize={{ base: '12px', lg: '14px' }}
                    >
                        {filteredOptions.map(({ quality, label, value, bitrateText, selected, select }) => {
                            const isChecked = options.selectedValue === value; // * потому что auto совпадает с соответствующим битрейтом
                            const labelText = autoQuality && value === 'auto' ? autoHint : label;

                            return (
                                <Menu.Item
                                    key={value}
                                    value={value}
                                >
                                    <CustomRadioMark
                                        radioData={value}
                                        label={labelText}
                                        isChecked={isChecked}
                                        onChange={() => {
                                            select();
                                        }}
                                    />
                                </Menu.Item>
                            );
                        })}
                    </RadioGroup>
                </Box>
            </Menu.Positioner>
        </Menu.Root>
    );
}
