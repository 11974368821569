import { ReactNode } from 'react';
import { Box, Flex, FlexProps, useCheckboxGroup } from '@chakra-ui/react';
import { useController } from 'react-hook-form';

import { CheckboxData } from '@/shared/models';
import CustomCheckboxIcon from '../custom-checkbox/CustomCheckboxIcon';

interface CheckboxGroupProps {
    control: any;
    name: string;
    rules?: any;
    checkboxList: CheckboxData[];
    styles?: FlexProps;
    defaultValue?: string | undefined;
    CheckboxElement?: (props: any) => ReactNode;
    textareaControlledFn?: ((isChecked: boolean) => void) | null;
}

export default function CheckboxGroup({
    control,
    name,
    rules,
    checkboxList,
    styles,
    defaultValue,
    CheckboxElement = CustomCheckboxIcon,
    textareaControlledFn = null,
}: CheckboxGroupProps) {
    const { field } = useController({
        control,
        name,
        rules,
        defaultValue,
    });

    const { getCheckboxProps } = useCheckboxGroup({ ...field });

    return (
        <Flex
            {...styles}
            direction="column"
            gap={4}
        >
            {checkboxList.map((checkboxItem) => (
                <Box key={checkboxItem.value}>
                    <CheckboxElement
                        checkboxData={checkboxItem}
                        {...getCheckboxProps({ value: checkboxItem.value })}
                        onClick={(e: any) => {
                            e.preventDefault();
                            const isChecked = field.value?.includes(checkboxItem.value);
                            const newValue = isChecked
                                ? field.value.filter((v: string) => v !== checkboxItem.value)
                                : [...(field.value || []), checkboxItem.value];

                            field.onChange(newValue);

                            if (checkboxItem.value === 'other' && textareaControlledFn) {
                                textareaControlledFn(!isChecked);
                            }
                        }}
                    />
                </Box>
            ))}
        </Flex>
    );
}
