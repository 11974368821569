import {
    Box,
    Button,
    Fade,
    Popover,
    PopoverAnchor,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    Portal,
    useDisclosure,
} from '@chakra-ui/react';
import { useAtomValue } from 'jotai';

import { useTooltips } from '../api/useTooltips';
import { TooltipTag } from '../model/tooltip-model';
import { userAtom } from '@/shared/store';
import SimpleModal from '@/entities/modals/simple/SimpleModal';

export default function TooltipComponent() {
    const { anchorCoords, popoverParams, currentTooltip, handleNext, handleSkip } = useTooltips();
    const user = useAtomValue(userAtom);
    const { isOpen, onOpen, onClose } = useDisclosure();

    if (user?.tooltip_info?.is_skipped) {
        return null;
    }

    if (!currentTooltip) {
        return null;
    }

    if (currentTooltip.tag === TooltipTag.Welcome) {
        return (
            <Portal>
                <Fade in>
                    <Box
                        onClick={onOpen}
                        position="fixed"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        backgroundColor="rgba(0, 0, 0, 0.3)" // Полупрозрачный фон
                        zIndex="90"
                    />
                    <Box
                        position={'fixed'}
                        top={'50%'}
                        left={'50%'}
                        transform={'translate(-50%, -50%)'}
                        zIndex={100}
                        bg={'white'}
                        color={'green.500'}
                        border={'1px'}
                        borderColor={'green.400'}
                        borderRadius="10"
                        p={'10px 12px'}
                    >
                        <Box
                            p={0}
                            mb={2}
                            fontWeight={{ base: 400, md: 500 }}
                        >
                            {currentTooltip.text}
                        </Box>
                        <Box
                            p={0}
                            display={'flex'}
                            justifyContent={'space-between'}
                        >
                            <Box
                                fontSize={14}
                                as="button"
                                fontWeight={500}
                                opacity={0.5}
                                onClick={onOpen}
                            >
                                Skip All
                            </Box>
                            <Button
                                onClick={handleNext}
                                variant={'tooltip'}
                            >
                                ОK
                            </Button>
                        </Box>
                    </Box>
                </Fade>

                <SimpleModal
                    isOpen={isOpen}
                    onClose={onClose}
                    title="Do you want to close all the tips at once?"
                    onAcceptBtnClick={() => {
                        onClose();
                        handleSkip();
                    }}
                />
            </Portal>
        );
    } else {
        // default tooltip
        return (
            <Popover
                isOpen
                closeOnBlur={false}
                autoFocus={false}
                arrowSize={20}
                preventOverflow
                flip={false}
                offset={[0, 20]}
                arrowShadowColor="green.400"
                {...popoverParams}
            >
                <PopoverAnchor>
                    <Box
                        id="anchor-tooltip"
                        zIndex={-1}
                        pos="absolute"
                        {...anchorCoords}
                    />
                </PopoverAnchor>

                <Portal>
                    <Box
                        onClick={onOpen}
                        position="fixed"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        backgroundColor="rgba(0, 0, 0, 0.3)" // Полупрозрачный фон
                        zIndex="90"
                    />

                    <PopoverContent
                        bg={'white'}
                        color={'green.500'}
                        borderRadius="10"
                        p={'10px 12px'}
                        maxW={270}
                        borderColor={'green.400'}
                    >
                        <PopoverBody
                            p={0}
                            mb={2}
                            fontWeight={{ base: 400, md: 500 }}
                            zIndex={1}
                        >
                            {currentTooltip.text}
                        </PopoverBody>
                        <PopoverFooter
                            border="none"
                            p={0}
                            display={'flex'}
                            justifyContent={'space-between'}
                            zIndex={1}
                        >
                            <Box
                                fontSize={14}
                                as="button"
                                fontWeight={500}
                                opacity={0.5}
                                onClick={onOpen}
                            >
                                Skip All
                            </Box>

                            <Button
                                onClick={handleNext}
                                variant={'tooltip'}
                            >
                                ОK
                            </Button>
                        </PopoverFooter>

                        <Box
                            pos={'absolute'}
                            top={0}
                            bottom={0}
                            left={0}
                            right={0}
                            bg={'white'}
                            zIndex={0}
                            borderRadius="10"
                        />

                        <PopoverArrow
                            zIndex={-1}
                            border={'1px solid'}
                            borderColor={'green.400'}
                        />
                    </PopoverContent>

                    <SimpleModal
                        isOpen={isOpen}
                        onClose={onClose}
                        title="Do you want to close all the tips at once?"
                        onAcceptBtnClick={() => {
                            onClose();
                            handleSkip();
                        }}
                    />
                </Portal>
            </Popover>
        );
    }
}
