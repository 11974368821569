import { createRoot } from 'react-dom/client';

import ProvidersCsr from '../../providers/providers-csr';

import { TrailerFloatPlayer } from '@/features/video-player';

// Плавающий плеер на странице курса
const trailerPlayerNode = document.getElementById('trailerPlayer');
if (trailerPlayerNode) {
    const root = createRoot(trailerPlayerNode);

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <TrailerFloatPlayer
                videoSrc={trailerPlayerNode.getAttribute('data-video-url')}
                videoPosterSrc={trailerPlayerNode.getAttribute('data-video-poster')}
            />
        </ProvidersCsr>,
    );
}
