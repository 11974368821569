import { Box, BoxProps, HStack, Text } from '@chakra-ui/react';

import { Countdown } from '@/shared/ui';

interface CoursePriceProps {
    price: number;
    discount?: number;
    isSale?: boolean;
    isCountdown?: boolean;
    styles?: BoxProps;
}
export default function CoursePrice({
    price,
    discount = 90,
    isSale = true,
    isCountdown = true,
    styles,
}: CoursePriceProps) {
    const discountPrice = Math.round(price / (1 - discount / 100));

    return (
        <Box
            position="relative"
            {...styles}
        >
            <HStack
                mb={3}
                fontSize="28px"
                fontWeight={700}
            >
                <Text as="span">US</Text>
                <Text as="span">${price}</Text>
            </HStack>

            {isSale && (
                <Box mb={1}>
                    <Text
                        as="span"
                        textDecoration="line-through"
                        textStyle="sm2"
                        color="#e1e1e1"
                    >
                        ${discountPrice}{' '}
                    </Text>

                    <Text
                        as="span"
                        ms="2"
                        fontWeight="bold"
                        textStyle="sm2"
                        color="white"
                    >
                        {discount}% discount
                    </Text>
                </Box>
            )}

            {isCountdown && (
                <Text
                    color="#f7d365"
                    fontWeight={700}
                >
                    Offer ends in:{' '}
                    <Countdown
                        isEndlessHour={true}
                        styles={{
                            fontWeight: 700,
                        }}
                    />
                </Text>
            )}
        </Box>
    );
}
