'use client';

import { useEffect, useState } from 'react';
import { useAtom, useSetAtom } from 'jotai';

import { LS_ONE_CLICK_CHECKOUT_CART } from '@/common/constants/local-storage';
import { capitalizeFirstLetter, fetchData } from '@/shared/lib';
import { CourseData } from '@/entities/common/model/courses.types';
import { URLS } from '@/common/constants/global';
import { CartTypeTariffs } from '../../one-click';
import { checkoutPricesAtom, submitCheckoutDataAtom, typeCheckoutCheckedItemsAtom } from './checkout-store';
import { APP_BRAND } from '@/common/constants/brand';
import { CourseSubscriptionPriceData } from '../../model/domestika.types';

export enum TypeOfCourse {
    Regular = 'regular',
    Optional = 'optional',
    Trial = 'trial',
}

interface CartItem {
    type: TypeOfCourse;
    title: string;
    price: number;
    courseId: number;
    imgSrc: string;

    author?: string;
    buyersPreview?: {
        buyersCount: number;
        testimonialsCount: number;
        rating: string;
    };
}

const TRIAL_ITEM = {
    type: TypeOfCourse.Trial,
    imgSrc: '/img/logos/plus-logo.png',
    title: `${capitalizeFirstLetter(APP_BRAND)} Plus`,
    price: 0,
    courseId: 0,
};

export default function useCheckoutPage() {
    const [currentCourse, setCurrentCourse] = useState<any>(null);
    const [checkoutType, setCheckoutType] = useState<CartTypeTariffs>(CartTypeTariffs.RegularPrice);
    const setTypeCheckoutCheckedItems = useSetAtom(typeCheckoutCheckedItemsAtom);
    const [optionalCourse, setOptionalCourse] = useState<any>(null);
    const setSubmitData = useSetAtom(submitCheckoutDataAtom);

    const [coursePrices, setCoursePrices] = useAtom(checkoutPricesAtom);

    const [totalCost, setTotalCost] = useState<string>('0');

    // Выбранные элементы для покупки
    const [checkedItems, setCheckedItems] = useState<CartItem[]>([]);

    const ONE_CLICK_CHECKOUT_CART =
        typeof window !== 'undefined' &&
        localStorage.getItem(LS_ONE_CLICK_CHECKOUT_CART) &&
        JSON.parse(localStorage.getItem(LS_ONE_CLICK_CHECKOUT_CART)!);

    const getCourse = async (id: number): Promise<void> => {
        const { data } = await fetchData<{ data: CourseData[] }>(URLS.getCourseById(id));

        setCurrentCourse(data[0]);
    };

    async function fetchCoursePrices() {
        const { data } = await fetchData<{ data: any }>(URLS.getDomestikaPackage(ONE_CLICK_CHECKOUT_CART.id));

        setCoursePrices(data);
    }

    useEffect(() => {
        if (ONE_CLICK_CHECKOUT_CART) {
            getCourse(ONE_CLICK_CHECKOUT_CART.id);
            fetchCoursePrices();
        }
    }, []);

    useEffect(() => {
        if (currentCourse) {
            let initialItems: CartItem[] = [
                {
                    type: TypeOfCourse.Regular,
                    title: currentCourse.title,
                    price: coursePrices?.price_base_course ?? 49,
                    imgSrc: currentCourse.thumb_big,
                    author: JSON.parse(currentCourse.author_info)
                        .map((a: any) => a.name)
                        .join(', '),
                    courseId: currentCourse.id,
                    buyersPreview: {
                        buyersCount: currentCourse.buyers_preview.buyers_count,
                        testimonialsCount: currentCourse.buyers_preview.testimonials_count,
                        rating: currentCourse.rating,
                    },
                },
            ];

            const initialOptionalCourse = currentCourse.optional_courses.map((optionalCourse: any) => ({
                type: TypeOfCourse.Optional,
                title: optionalCourse.title,
                price: 17,
                imgSrc: optionalCourse.upsell_image,
                author: JSON.parse(optionalCourse.author_info)
                    .map((a: any) => a.name)
                    .join(', '),
                courseId: optionalCourse.id,
                excerpt: optionalCourse.excerpt,
                buyersPreview: {
                    buyersCount: optionalCourse.buyers_preview.buyers_count,
                    testimonialsCount: optionalCourse.buyers_preview.testimonials_count,
                    rating: optionalCourse.rating,
                },
            }));

            if (currentCourse.optional_courses) {
                setOptionalCourse(initialOptionalCourse);
            }

            if (ONE_CLICK_CHECKOUT_CART.type === CartTypeTariffs.Trial) {
                initialItems.push(TRIAL_ITEM);

                initialItems = initialItems.map((item) => ({
                    ...item,
                    price: item.type === TypeOfCourse.Trial ? 0 : 0.99, // Делаем скидку
                }));

                setOptionalCourse(
                    initialOptionalCourse.map((item: any) => ({
                        ...item,
                        price: 7.9, // Делаем скидку
                    })),
                );
            }

            setCheckedItems(initialItems);
            setCheckoutType(ONE_CLICK_CHECKOUT_CART.type);
        }
    }, [currentCourse]);

    useEffect(() => {
        if (checkedItems.length > 0 && currentCourse) {
            const submitCourses = checkedItems
                .filter(
                    (item: any) =>
                        item.courseId && item.courseId !== currentCourse.id && item.type !== TypeOfCourse.Trial,
                )
                .map((item) => item.courseId)
                .filter((courseId): courseId is number => courseId !== undefined);

            setSubmitData({
                is_trial: checkedItems.some((item) => item.type === TypeOfCourse.Trial),
                course_id: currentCourse.id,
                additional_courses: submitCourses,
            });

            setTotalCost(checkedItems.reduce((sum, item) => sum + item.price, 0).toFixed(2));
        }
    }, [checkedItems]);

    const onDeleteItem = (courseItem: CartItem) => {
        const itemToDelete = checkedItems.find((item) => item.courseId === courseItem.courseId);

        if (!itemToDelete) {
            return;
        }

        if (courseItem.type === TypeOfCourse.Trial) {
            const restoredItems = checkedItems.map((item) => {
                return {
                    ...item,
                    price: item.type === TypeOfCourse.Optional ? 17 : 49, // Восстанавливаем цену
                };
            });

            setCheckedItems(restoredItems.filter((item) => item.type !== TypeOfCourse.Trial));
            setTypeCheckoutCheckedItems(CartTypeTariffs.RegularPrice);

            setOptionalCourse(
                optionalCourse.map((item: any) => ({
                    ...item,
                    price: 17, // Восстанавливаем цену
                })),
            );
        } else {
            const updatedItems = checkedItems.filter((item) => item.courseId !== courseItem.courseId);
            setCheckedItems(updatedItems);

            // Восстанавливаем курс в optionalCourse
            setOptionalCourse((prev: any) => [
                ...prev,
                {
                    type: TypeOfCourse.Optional,
                    title: itemToDelete.title,
                    price: itemToDelete.price,
                    imgSrc: itemToDelete.imgSrc,
                    author: itemToDelete.author,
                    courseId: itemToDelete.courseId,
                },
            ]);
        }
    };

    const onAddTrial = () => {
        if (!checkedItems.some((item) => item.type === TypeOfCourse.Trial)) {
            const newItems = [...checkedItems, TRIAL_ITEM];

            const discountedItems = newItems.map((item) => {
                return {
                    ...item,
                    price: item.type === TypeOfCourse.Trial ? 0 : item.type === TypeOfCourse.Optional ? 4 : 0.99, // Делаем скидку
                };
            });

            setCheckedItems(discountedItems);
            setTypeCheckoutCheckedItems(CartTypeTariffs.Trial);
            setOptionalCourse(
                optionalCourse.map((item: any) => ({
                    ...item,
                    price: 7.9, // Делаем скидку
                })),
            );
        }
    };

    const onAddOptionalCourse = (optionalCourseItem: CartItem) => {
        // Удаляем добавленный курс из списка optionalCourse
        const updatedOptionalCourses = optionalCourse.filter(
            (course: CartItem) => course.courseId !== optionalCourseItem.courseId,
        );

        setOptionalCourse(updatedOptionalCourses);

        // Добавляем выбранный курс в checkedItems
        const newItems = [
            ...checkedItems,
            {
                type: TypeOfCourse.Optional,
                title: optionalCourseItem.title,
                price: optionalCourseItem.price,
                imgSrc: optionalCourseItem.imgSrc,
                author: optionalCourseItem.author,
                courseId: optionalCourseItem.courseId,
            },
        ];

        setCheckedItems(newItems);
    };

    return {
        currentCourse: checkedItems[0],
        checkoutType,
        totalCost,
        checkedItems,
        optionalCourse,
        onDeleteItem,
        onAddTrial,
        onAddOptionalCourse,
    };
}
