import { useEffect, useRef } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Text,
    Box,
    ModalCloseButton,
    useMediaQuery,
    Link,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { HELLO_EMAIL } from '@/common/constants/brand';
import { UTM_SOURCES_LIST } from '@/common/constants/global';
import { CourseDataItem, Inputs, OptionalCourseDataItem } from '@/entities/modals/cart/model/types';

// Импорт компонентов для всех возможных шагов
import CartFirstStep from './component/CartFirstStep';
import CartSecondStep from './component/CartSecondStep';
import CartThirdStep from './component/CartThirdStep';
import PaymentStep from './component/PaymentStep';

import useCart from '../../model/useCart';

// Константы для идентификации шагов
export const CART_STEPS = {
    FIRST: 'first',
    UPSELLING: 'upselling',
    TRACKER: 'tracker',
    PAYMENT: 'payment',
};

// Интерфейс для определения шага корзины
export interface CartStepConfig {
    id: string;
    component: React.ComponentType<any>;
    props?: Record<string, any>;
}

// Составляем карту компонентов для каждого шага
const stepComponents: Record<string, React.ComponentType<any>> = {
    [CART_STEPS.FIRST]: CartFirstStep,
    [CART_STEPS.UPSELLING]: CartSecondStep,
    [CART_STEPS.TRACKER]: CartThirdStep,
    [CART_STEPS.PAYMENT]: PaymentStep,
};

interface CartProps {
    courseData: CourseDataItem[];
    isOpen: boolean;
    onClose: () => void;
    OptionEl?: (props: { onOptionClick: (course: any) => void; courseData: any }) => JSX.Element;
    optionsList?: OptionalCourseDataItem[];
    steps?: string[]; // ID шагов в порядке отображения
}

export default function CartModal({ courseData, isOpen, onClose, OptionEl, optionsList = [], steps }: CartProps) {
    const [isLargerThanMd] = useMediaQuery('(min-width: 768px)', {
        ssr: true,
        fallback: false,
    });
    const modal = useRef<HTMLDivElement>(null);

    const isCheckedTerms = UTM_SOURCES_LIST.some((platform: string) => window.location.search.includes(platform));

    const {
        register,
        getFieldState,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            email: (document.getElementById('realEmail') as HTMLInputElement)?.value || '',
            agree_terms: isCheckedTerms,
        },
    });

    // Определяем последовательность шагов корзины
    const getSteps = () => {
        // Если указаны шаги, используем их
        if (steps && steps.length > 0) {
            return steps;
        }

        // Иначе формируем шаги на основе данных
        const defaultSteps = [CART_STEPS.FIRST];

        // Если есть опциональные курсы, добавляем шаг апселлинга
        if (optionsList.length > 0) {
            defaultSteps.push(CART_STEPS.UPSELLING);
        }

        return defaultSteps;
    };

    // Создаем массив шагов
    const cartSteps = getSteps();

    // Используем универсальный хук для логики корзины
    const cartData = useCart({
        courseData,
        optionsList,
        getFieldState,
        steps: cartSteps,
    });

    const {
        currentStepIndex,
        currentStepId,
        goToNextStep,
        goToStepByIndex,
        goToStepById,
        courseList,
        onDeleteOptionClick,
        checkToastVisible,
        isSubmitted,
        goToPayment,
        optionListFilter,
        onOptionClick,
        setIsTrackerAdded,
        isTrackerAdded,
    } = cartData;

    // Прокрутка контента при переходе между шагами
    useEffect(() => {
        if (currentStepIndex > 0 && modal.current) {
            setTimeout(() => {
                const container = modal.current?.closest('.chakra-modal__content-container');
                if (container) {
                    container.scrollTo({ top: 0, behavior: 'smooth' });
                }
            }, 0);
        }
    }, [currentStepIndex]);

    const totalPrice = courseList.reduce((acc, item) => acc + Number(item.price), 0);

    // Функция для получения пропсов для текущего шага
    const getStepProps = (stepId: string) => {
        const baseProps = {
            ...cartData,
            register,
            control,
            handleSubmit,
            errors,
            totalPrice,
        };

        // Специфические пропсы для разных шагов
        switch (stepId) {
            case CART_STEPS.UPSELLING:
                return {
                    ...baseProps,
                    OptionEl,
                    optionListFilter,
                    onOptionClick,
                };

            default: {
                return baseProps;
            }
        }
    };

    // Получаем компонент текущего шага
    const CurrentStepComponent = stepComponents[currentStepId];

    return (
        <Modal
            scrollBehavior="outside"
            isOpen={isOpen}
            onClose={onClose}
            size={isLargerThanMd ? 'xl' : 'full'}
        >
            <ModalOverlay backgroundColor="#000" />

            <ModalContent
                my={{ md: 16 }}
                borderRadius={0}
                maxW="560px"
                ref={modal}
            >
                <ModalCloseButton
                    zIndex={20}
                    pos={{ base: 'absolute', md: 'fixed' }}
                    top="5px"
                    color="#fff"
                    sx={{
                        svg: {
                            w: 4,
                            h: 4,
                        },
                    }}
                />

                <ModalBody
                    pt={{ base: '70px', md: '48px' }}
                    px={{ base: 5, md: 10 }}
                    pb={{ base: 6, md: '80px' }}
                >
                    <Box
                        hideFrom="md"
                        position="fixed"
                        zIndex={10}
                        top={0}
                        left={0}
                        w="100%"
                        h="42px"
                        backgroundColor="black"
                    />

                    {/* Рендерим компонент текущего шага */}
                    {CurrentStepComponent && <CurrentStepComponent {...getStepProps(currentStepId)} />}

                    {currentStepId !== CART_STEPS.PAYMENT && (
                        <Box
                            mt={10}
                            fontSize="sm3"
                        >
                            <Text>
                                <Text
                                    as="span"
                                    fontWeight={500}
                                >
                                    Start practicing immediately after filling out the form and paying.
                                </Text>{' '}
                                Hurry up to buy a course at a low price while the special offer is still valid.
                            </Text>

                            <Text>
                                <Text
                                    as="span"
                                    fontWeight={500}
                                >
                                    14 days money back guarantee:
                                </Text>{' '}
                                if the course does not meet your expectations within the first fourteen days after
                                purchase, we will refund the full price no questions asked. Simply send an email to
                                support at{' '}
                                <Link
                                    href={`mailto:${HELLO_EMAIL}`}
                                    color="blue.500"
                                >
                                    {HELLO_EMAIL}
                                </Link>
                            </Text>
                        </Box>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
