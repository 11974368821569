// courseUtils.ts
import { getLocalStorageItem, setUniqueObjToArrWithReplace } from '@/shared/lib';
import { LS_ONBOARDING_DATA, LS_ONBOARDING_DATA_KEYS, SELECT_CATEGORIES } from '@/common/constants/local-storage';
import { Gender } from '@/entities/quizes/quiz/model/lists/radio-list';
import { QuizGroup, QuizGroupType } from '..';

export function getSelectedCoursesList(courses: any[]): string[] {
    return courses.reduce((acc: string[], currentCourse: any) => {
        return acc.concat(currentCourse.courseList.map((item: any) => item.value));
    }, []);
}

export function setLocalStorageCoursesList(
    arr: any[],
    selectedRadio: any,
    courseListKey?: Gender | null,
    firstCourse?: any,
) {
    const courseListKeyFormatted = courseListKey?.toLowerCase();

    // Шаг 1: Фильтрация массива категорий
    let selectedCategories = arr.reduce((acc: any, item: any) => {
        if (
            item.categoryName === selectedRadio &&
            courseListKeyFormatted &&
            courseListKeyFormatted in item.coursesList
        ) {
            return item.coursesList[courseListKeyFormatted];
        } else if (item.categoryName === selectedRadio) {
            return item.coursesList;
        }

        return acc;
    }, []);

    // Шаг 2: Обработка первого курса
    let firstCourseData = firstCourse;
    const dataFromLocalStorage = getLocalStorageItem(LS_ONBOARDING_DATA); // ls

    if (dataFromLocalStorage) {
        firstCourseData = {
            value: dataFromLocalStorage[LS_ONBOARDING_DATA_KEYS.CourseId],
            label: dataFromLocalStorage[LS_ONBOARDING_DATA_KEYS.CourseTitle],
        };
    }

    if (firstCourseData && !selectedCategories.find((item: any) => item.value === firstCourseData.value)) {
        selectedCategories = selectedCategories.filter((item: any) => item.value !== firstCourseData.value);
        selectedCategories.unshift(firstCourseData);
    }

    // Шаг 3: Создание массива значений выбранных курсов
    const selectedCourseValues = selectedCategories.map((item: any) => item.value);

    // Шаг 4: Формирование объекта для локального хранилища
    const selectedCategorieAndCourses = {
        category: selectedRadio,
        courseList: selectedCategories,
        selectedCourseValues: selectedCourseValues,
    };

    // Шаг 5: Сохранение данных в локальное хранилище
    if (typeof window !== 'undefined') {
        window.localStorage.setItem(SELECT_CATEGORIES, JSON.stringify(selectedCategorieAndCourses));
    }
}

export function getAnswersList(data: any, currentQuiz: QuizGroup): string[] {
    let answersList: string[] = [];

    if (data.formData?.radio && 'quizList' in currentQuiz) {
        answersList = [data.formData.radio];
    }

    if (data.formData?.topics?.length > 0 && 'quizList' in currentQuiz) {
        const currentAnswers = currentQuiz.quizList
            .filter((item) => data.formData.topics.includes(item.value))
            .map((item) => item.label);

        answersList = currentAnswers;
    }
    return answersList;
}

// courseUtils.ts
export function getAtomStorageCoursesList(
    currentQuiz: any,
    selectedTopics: any[],
    selectedCourses: any,
    unselectedCourses: any,
): { newSelectedCourses: any[]; newUnselectedCourses: any[] } {
    if ('quizList' in currentQuiz && 'categoryName' in currentQuiz) {
        const newSelectedCoursesData = currentQuiz.quizList.filter((item: any) => selectedTopics.includes(item.value));
        const newSelectedCourses = setUniqueObjToArrWithReplace(
            currentQuiz.categoryName,
            newSelectedCoursesData,
            selectedCourses,
        );

        const newUnselectedCoursesData = currentQuiz.quizList.filter(
            (item: any) => !selectedTopics.includes(item.value),
        );
        const newUnselectedCourses = setUniqueObjToArrWithReplace(
            currentQuiz.categoryName,
            newUnselectedCoursesData,
            unselectedCourses,
        );

        return { newSelectedCourses, newUnselectedCourses };
    }

    // Если `currentQuiz` не содержит `quizList` или `categoryName`, вернем исходные курсы
    return { newSelectedCourses: selectedCourses, newUnselectedCourses: unselectedCourses };
}

export function getFilteredByCoursesOfCategoryQuizList(quizList: QuizGroup[], selectedTopics: any[]): QuizGroup[] {
    let categoryCount = 0;

    return quizList.filter((item) => {
        if (item.type !== QuizGroupType.CoursesOfCategoryMultiple) {
            return true;
        } else if (selectedTopics.includes(item.categoryName) && categoryCount < 2) {
            categoryCount++;
            return true;
        }
        return false;
    });
}
