import { Box, BoxProps, HStack, Text } from '@chakra-ui/react';

import { Picture, IconMap, IconNames } from '@/shared/ui';

export default function AuthorPromoImg({
    imgName,
    priceValue,
    styles,
    imgStyles,
    tagStyles,
}: {
    imgName: string;
    priceValue: string;
    styles?: BoxProps;
    imgStyles?: BoxProps;
    tagStyles?: BoxProps;
}) {
    return (
        <Box
            pos="relative"
            w={{ base: '105px', lg: '230px' }}
            h={{ base: '120px', lg: '265px' }}
            {...styles}
        >
            <Picture
                src={`authors/landing/top-banner/${imgName}.png`}
                alt={'author-girl'}
                imgStyle={{
                    w: '100%',
                    h: '100%',
                    objectFit: 'cover',
                    borderRadius: '20px',
                    ...imgStyles,
                }}
            />
        </Box>
    );
}
