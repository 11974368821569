'use client';

import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { trialDayAtom } from './create-sub-store';

// Для того, чтобы при триальной подписке отправлять пиксель
export default function useTrialDay(trialDays: any) {
    const setTrialDayAtomData = useSetAtom(trialDayAtom);

    useEffect(() => {
        if (trialDays !== undefined && trialDays !== null) {
            setTrialDayAtomData(typeof trialDays === 'string' ? parseInt(trialDays) : trialDays);
        }
    }, [trialDays, setTrialDayAtomData]);

    return null;
}
