import { memo, useEffect } from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

import { validation } from '@/shared/lib';
import { useAtom } from 'jotai';
import { emailForStripeAtom } from '../../model/checkout-store';

export interface Inputs {
    email: string;
}

const CheckoutEmail = memo(function CheckoutEmail() {
    const [submitData, setEmail] = useAtom(emailForStripeAtom);

    const {
        register,
        handleSubmit,
        getValues,
        trigger,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            email: (document.getElementById('realEmail') as HTMLInputElement)?.value || '',
        },
    });

    useEffect(() => {
        const data = getValues();

        setEmail({
            email: data.email || null,
            validate: async () => await checkValidate(),
        });
    }, []);

    async function checkValidate() {
        const isValid = await trigger('email');
        return isValid;
    }

    const onBlurInput = async () => {
        if (checkValidate) {
            const data = getValues();

            setEmail({
                email: data.email,
                validate: async () => await checkValidate(),
            });
        }
    };

    return (
        <form onSubmit={handleSubmit(onBlurInput)}>
            <FormControl isInvalid={Boolean(errors.email)}>
                <FormLabel
                    mb={1}
                    fontWeight={400}
                >
                    Your Email
                </FormLabel>

                <Input
                    {...register('email', { ...validation.email })}
                    type="text"
                    placeholder="example@mail.com"
                    variant="tilda"
                    py={3}
                    mb={3}
                    lineHeight={1.2}
                    border="1px solid"
                    borderColor="gray.375"
                    onBlur={() => {
                        if (!errors.email) {
                            onBlurInput();
                        }
                    }}
                />
            </FormControl>
        </form>
    );
});

export default CheckoutEmail;
