import { ALL_COURSES_LIST_BY_ID } from '@/entities/courses/lists/ids';
import { CourseCategories } from './course-categories-list';
import { QuizList } from '../types/quiz-list-types';

export const FIRST_COURSE = {
    label: 'Vocal yoga',
    value: ALL_COURSES_LIST_BY_ID.VocalYogaPeruquois,
};

export const VOICE_MASTERY_QUIZ_LIST: QuizList[] = [
    {
        label: 'Enhancing vocal skills',
        value: ALL_COURSES_LIST_BY_ID.OpenVoiceLearnSingBeautifully,
    },
    {
        label: 'Merge breath with voice',
        value: ALL_COURSES_LIST_BY_ID.VocalYogaPeruquois,
    },
    {
        label: 'Heal through voice',
        value: ALL_COURSES_LIST_BY_ID.VoiceAlchemyHealingVoice,
    },
    {
        label: 'Express emotions',
        value: ALL_COURSES_LIST_BY_ID.MedicineSongsGeetaFeelHeal,
    },
    {
        label: 'Accessing the power within',
        value: ALL_COURSES_LIST_BY_ID.MagicVoice,
    },
    {
        label: 'Unlock the natural potential of your voice',
        value: ALL_COURSES_LIST_BY_ID.SingBetterIn21Days,
    },
    {
        label: 'Discover the shamanic power of your voice',
        value: ALL_COURSES_LIST_BY_ID.LuminaMultidimensionalSoundPractice,
    },
    {
        label: 'Open new opportunities for your voice',
        value: ALL_COURSES_LIST_BY_ID.SingWithAgaBestSingingCourse,
    },
    {
        label: 'Open your creative channel of expression through self sound healing',
        value: ALL_COURSES_LIST_BY_ID.NadaYogaSoundToHealMindBodySoul,
    },
];

export const BODY_DEVELOPMENT_QUIZ_LIST: QuizList[] = [
    {
        label: 'Breath & Voice Integration',
        value: ALL_COURSES_LIST_BY_ID.VocalYogaPeruquois,
    },
    {
        label: 'Brain & Body Coordination',
        value: ALL_COURSES_LIST_BY_ID.OuroborosBrainBody,
    },
    {
        label: 'Femininity Through Movement',
        value: ALL_COURSES_LIST_BY_ID.SensualGoddessWorkout,
    },
    {
        label: 'Sexuality & Self-Love',
        value: ALL_COURSES_LIST_BY_ID.BreathingOfSexuality,
    },
    {
        label: 'Mobility & Spine Health',
        value: ALL_COURSES_LIST_BY_ID.HealthySpine16Exercises,
    },
    {
        label: 'Increase your natural energy sources',
        value: ALL_COURSES_LIST_BY_ID.ReclaimVitalityEnergySavingQigong,
    },
    {
        label: 'Achieve a more balanced and restful state of being',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Balance your life and energy on all the levels',
        value: ALL_COURSES_LIST_BY_ID.BodyAndSoulConnectionChakras,
    },
    {
        label: 'Ignite your energy, transform your mindset, sculpt your body',
        value: ALL_COURSES_LIST_BY_ID.ExtremeTransformationChallenge,
    },
    {
        label: 'Charge yourself with positive vibes',
        value: ALL_COURSES_LIST_BY_ID.DanceMovementTherapyWithAnnaTrish,
    },
    {
        label: 'Quick route to a more supple, energetic you',
        value: ALL_COURSES_LIST_BY_ID.WarmupMobilitySpineHipShoulder,
    },
];

export const RELATIONSHIP_QUIZ_LIST: QuizList[] = [
    {
        label: 'Intimacy through Massage',
        value: ALL_COURSES_LIST_BY_ID.MassageAsDanceLove,
    },
    {
        label: 'Self-Awareness & Intimacy',
        value: ALL_COURSES_LIST_BY_ID.BreathingOfSexuality,
    },
    {
        label: 'Tantric Connections',
        value: ALL_COURSES_LIST_BY_ID.BreathingLoveTantricMagic,
    },
    {
        label: 'Romantic Growth',
        value: ALL_COURSES_LIST_BY_ID.RisingLoveHealingJourney,
    },
    {
        label: 'Dance & Self-Expression',
        value: ALL_COURSES_LIST_BY_ID.TwerkingBasicsLoveBody,
    },
    {
        label: 'Deepen the connection in your couple',
        value: ALL_COURSES_LIST_BY_ID.DiyUnforgettableNights,
    },
    {
        label: 'Revive forgotten feelings and interest',
        value: ALL_COURSES_LIST_BY_ID.WomensLingamMassageWorkshop,
    },
    {
        label: 'Elevate self-esteem and enhance intimacy with your partner',
        value: ALL_COURSES_LIST_BY_ID.PracticalOnlineCourseSacredIntimacy,
    },
    {
        label: 'Learn new things about touching a woman’s breasts',
        value: ALL_COURSES_LIST_BY_ID.BreastMassageAwakenSensitivity,
    },
    {
        label: 'Be heard by the Universe!',
        value: ALL_COURSES_LIST_BY_ID.SacredTantricManifestationRitual,
    },
];

export const DAILY_ENERGY_QUIZ_LIST: QuizList[] = [
    {
        label: 'Sexual Energy for Vitality',
        value: ALL_COURSES_LIST_BY_ID.SexualAlchemyVitalityPractices,
    },
    {
        label: 'Better Sleep for More Energy',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Rejuvenating Energy Centers',
        value: ALL_COURSES_LIST_BY_ID.HealingChakraChargeEnergyCenters,
    },
    {
        label: 'Kundalini Energy',
        value: ALL_COURSES_LIST_BY_ID.SensualAbundantUnlockKundalini,
    },
    {
        label: 'Dynamic Dance',
        value: ALL_COURSES_LIST_BY_ID.AfroJazzModernDanceSoulBody,
    },
    {
        label: 'Sharpen your mind and support cognitive growth',
        value: ALL_COURSES_LIST_BY_ID.NeuroDanceGrooveToGenius,
    },
    {
        label: 'Awaken your inner child',
        value: ALL_COURSES_LIST_BY_ID.RhythmicMoodFeelBetterInstant,
    },
    {
        label: 'Develop your creativity and unconventional thinking',
        value: ALL_COURSES_LIST_BY_ID.BeatYourBrainRhythmicNeurogymnastics,
    },
    {
        label: 'Improve focus, memory, and information assimilation',
        value: ALL_COURSES_LIST_BY_ID.NeurogeniusElevateCognitivePerformance,
    },
    {
        label: 'Foster mental clarity, emotional tranquility, and physical relaxation',
        value: ALL_COURSES_LIST_BY_ID.QigongForStressAndAnxiety,
    },
];

export const OTHER_QUIZ_LIST: QuizList[] = [
    {
        label: 'Brain & Body Coordination',
        value: ALL_COURSES_LIST_BY_ID.OuroborosBrainBody,
    },
    {
        label: 'Therapeutic Massage',
        value: ALL_COURSES_LIST_BY_ID.FeelBodyThaiMassageTechniques,
    },
    {
        label: 'Vocal Power',
        value: ALL_COURSES_LIST_BY_ID.VocalYogaPeruquois,
    },
    {
        label: 'Mindfulness & Stress',
        value: ALL_COURSES_LIST_BY_ID.BreatheBeyondStressMindfulness,
    },
    {
        label: 'Intimacy through Massage',
        value: ALL_COURSES_LIST_BY_ID.MassageAsDanceLove,
    },
    {
        label: 'Open your true feminine energy, using the sacred practices of four elements',
        value: ALL_COURSES_LIST_BY_ID.FeminineAlchemySacredPractices4Elements,
    },
    {
        label: 'Harness the potent energies of the moon',
        value: ALL_COURSES_LIST_BY_ID.MoonManifestingLunarEnergiesEft,
    },
    {
        label: 'Open true wild and divine nature',
        value: ALL_COURSES_LIST_BY_ID.DivineYouChakra,
    },
    {
        label: 'Ignite your energy, transform your mindset, sculpt your body',
        value: ALL_COURSES_LIST_BY_ID.ExtremeTransformationChallenge,
    },
];

export const ALL_COURSES_LIST = [
    {
        categoryName: CourseCategories.VoiceMastery,
        coursesList: VOICE_MASTERY_QUIZ_LIST,
    },
    {
        categoryName: CourseCategories.LoveDevelopBody,
        coursesList: BODY_DEVELOPMENT_QUIZ_LIST,
    },
    {
        categoryName: CourseCategories.StrengthenRelationships,
        coursesList: RELATIONSHIP_QUIZ_LIST,
    },
    {
        categoryName: CourseCategories.BoostDailyEnergy,
        coursesList: DAILY_ENERGY_QUIZ_LIST,
    },
    {
        categoryName: CourseCategories.SomethingElse,
        coursesList: OTHER_QUIZ_LIST,
    },
];

export const ALL_COURSES_LIST_WITH_OUTSIDE_COURSES = [
    VOICE_MASTERY_QUIZ_LIST,
    BODY_DEVELOPMENT_QUIZ_LIST,
    RELATIONSHIP_QUIZ_LIST,
    DAILY_ENERGY_QUIZ_LIST,
    OTHER_QUIZ_LIST,
    {
        label: 'Embrace the wild woman living inside',
        value: 1060,
    },
];

// -----

export const HEALTH_RELATED_QUIZ_LIST: QuizList[] = [
    {
        label: 'Enhancing mobility and spine health through exercises',
        value: ALL_COURSES_LIST_BY_ID.HealthySpine16Exercises,
    },
    {
        label: 'Achieving a healthy posture and spinal alignment',
        value: ALL_COURSES_LIST_BY_ID.RoyalPosture,
    },
    {
        label: 'Managing stress through mindfulness and breath work',
        value: ALL_COURSES_LIST_BY_ID.BreatheBeyondStressMindfulness,
    },
    {
        label: 'Reducing anxiety and stress with Qigong techniques',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Preventing workplace-related health issues with yoga',
        value: ALL_COURSES_LIST_BY_ID.YogaTherapy15min,
    },
];

export const FEMININE_POWER_QUIZ_LIST: QuizList[] = [
    {
        label: 'Developing personal and sexual empowerment',
        value: ALL_COURSES_LIST_BY_ID.SexualAlchemyVitalityPractices,
    },
    {
        label: 'Embracing femininity through holistic practices',
        value: ALL_COURSES_LIST_BY_ID.SensualGoddessWorkout,
    },
    {
        label: 'Fostering self-love and self-care',
        value: ALL_COURSES_LIST_BY_ID.BreathingLoveTantricMagic,
    },
    {
        label: 'Unlocking inner strength and feminine leadership',
        value: ALL_COURSES_LIST_BY_ID.MorningAlchemy,
    },
    {
        label: 'Exploring dance as a pathway to empowerment',
        value: ALL_COURSES_LIST_BY_ID.AfroJazzModernDanceSoulBody,
    },
    {
        label: 'Harnessing vocal power for self-expression and confidence',
        value: ALL_COURSES_LIST_BY_ID.VocalYogaPeruquois,
    },
    {
        label: 'Deepening spiritual connection and self-awareness',
        value: ALL_COURSES_LIST_BY_ID.MedicineSongsGeetaFeelHeal,
    },
    {
        label: 'Empowering through Tantric and spiritual practices',
        value: ALL_COURSES_LIST_BY_ID.BreathingLoveTantricMagic,
    },
];
