import { EventData, EventType } from '@/entities/events/event-data';
import { postJournalEventActivity, updateEventNotification } from '@/entities/socials/events/api';
import { userAtom } from '@/shared/store';
import { useBoolean, useDisclosure, useToast } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { useRef } from 'react';

export default function useEventBtnWithNotify(eventData: EventData) {
    const {
        event_link: eventLink,
        event_recording_link: eventRecordingLink,
        id: eventId,
        type: eventType,
        is_user_notification: isNotificationEnabled,
        startDate,
    } = eventData;

    const user = useAtomValue(userAtom);

    const isUpcoming = startDate!.isUpcoming;
    const eventCardLink = isUpcoming ? eventLink : eventRecordingLink;

    const isNotSub = user && !user.is_subscribe && eventType === EventType.OnlySub;
    const upcomingBtnText = isNotSub ? `Learn more` : startDate!.isToday ? `To The Event` : `Book place`;

    const notificationToast = useToast();
    const toastIdRef = useRef<ToastId | undefined>();
    const [isSubLoading, setIsSubLoading] = useBoolean(false);

    const [isNotificationEnabledState, setIsNotificationEnabledState] = useBoolean(isNotificationEnabled);

    const { isOpen: isSubModalOpen, onOpen: onSubModalOpen, onClose: onSubModalClose } = useDisclosure();

    const onEventBtnClick = async () => {
        try {
            await postJournalEventActivity(isUpcoming, eventId);
        } finally {
            if (isNotSub) {
                onSubModalOpen();
            } else {
                if (isUpcoming && !startDate!.isToday && !isNotificationEnabledState) {
                    await setNotification();
                }

                if (eventCardLink) {
                    window.open(eventCardLink, '_blank');
                }
            }
        }
    };

    const setNotification = async () => {
        setIsSubLoading.on();

        toastIdRef.current = notificationToast({
            title: `Connecting notifications...`,
            status: 'info',
        });

        try {
            await updateEventNotification(isNotificationEnabledState, eventId);

            notificationToast.update(toastIdRef.current, {
                title: !isNotificationEnabledState ? `Notification enabled` : `Notification disabled`,
                status: 'success',
                duration: 1500,
            });

            setIsNotificationEnabledState.toggle();
        } catch (error) {
            notificationToast.update(toastIdRef.current, {
                title: `Something wrong. Try again`,
                status: 'error',
                duration: 3000,
            });
        } finally {
            setIsSubLoading.off();

            await new Promise((resolve) => setTimeout(resolve, 1500));
        }
    };

    return {
        isUpcoming,
        onEventBtnClick,
        upcomingBtnText,
        isNotificationEnabledState,
        setNotification,
        isSubLoading,
        isSubModalOpen,
        onSubModalClose,
    };
}
