import { Box, BoxProps, Center, Heading, HStack } from '@chakra-ui/react';

import { useAtomValue } from 'jotai';
import { RiArrowLeftLine, RiArrowRightLine } from 'react-icons/ri';

import Slides from './Slides';
import { useEventsList } from '@/features/socials/events/get-events';
import { userAtom } from '@/shared/store';
import { LoadingContentText } from '@/shared/ui';
import { SliderNavigation } from '@/entities/lists/sliders/primary-slider';
import { SwiperSlide } from 'swiper/react';
import { EventData } from '@/entities/events/event-data';

interface SliderProps {
    title?: string;
    slideCount?: number;
    styles?: BoxProps;
    SlideEl?: (props: { eventData: EventData }) => JSX.Element;
}

export default function HeadingNavEventsSlider({
    title,
    SlideEl = ({ eventData }) => <Slides eventData={eventData} />,
    slideCount = 3,
    styles,
}: SliderProps) {
    const user = useAtomValue(userAtom);
    const { eventList, isEventsLoading } = useEventsList(Boolean(user));

    const navDisplayProp = eventList?.upcomingEvents && eventList.upcomingEvents.length > slideCount ? 'flex' : 'none';

    return (
        <Box {...styles}>
            <HStack
                justify="space-between"
                mb={{ base: 5, md: 7.5 }}
            >
                <Heading
                    size="lg3"
                    mb={0}
                >
                    {title ? title : 'Our Future Events'}
                </Heading>

                <HStack
                    hideBelow="md"
                    display={navDisplayProp}
                    gap={4}
                >
                    <Center
                        className="image-swiper-button-prev-heading"
                        w="40px"
                        h="40px"
                        border="1px solid"
                        borderColor="gray.400"
                        borderRadius="50%"
                        cursor="pointer"
                        _hover={{
                            opacity: 0.7,
                        }}
                    >
                        <RiArrowLeftLine fontSize="22px" />
                    </Center>

                    <Center
                        className="image-swiper-button-next-heading"
                        w="40px"
                        h="40px"
                        border="1px solid"
                        borderColor="gray.400"
                        borderRadius="50%"
                        cursor="pointer"
                        _hover={{
                            opacity: 0.7,
                        }}
                    >
                        <RiArrowRightLine fontSize="22px" />
                    </Center>
                </HStack>
            </HStack>

            {eventList?.upcomingEvents && eventList?.upcomingEvents.length > 0 ? (
                <SliderNavigation
                    options={{
                        navigation: {
                            nextEl: '.image-swiper-button-next-heading',
                            prevEl: '.image-swiper-button-prev-heading',
                            disabledClass: 'swiper-button-disabled',
                        },
                    }}
                    isNavShow={false}
                >
                    {eventList.upcomingEvents.map((item: EventData) => {
                        return (
                            <SwiperSlide key={item.id}>
                                <SlideEl eventData={item} />
                            </SwiperSlide>
                        );
                    })}
                </SliderNavigation>
            ) : (
                <Box
                    pos="relative"
                    h="454px"
                >
                    <LoadingContentText isLoading={isEventsLoading}>There are no events</LoadingContentText>
                </Box>
            )}
        </Box>
    );
}
