import { Box, Button, Link, Modal, ModalCloseButton, ModalContent, ModalOverlay, Text } from '@chakra-ui/react';

import { HELLO_EMAIL } from '@/common/constants/brand';
import useUnsubFlow from '../../../model/useUnsubFlow';

export default function NeedHelpScreen() {
    const { isUnsubFlowOpen, onClosePopup } = useUnsubFlow();

    return (
        <Modal
            variant="primary"
            scrollBehavior="inside"
            isOpen={isUnsubFlowOpen}
            onClose={onClosePopup}
            isCentered
        >
            <ModalOverlay />

            <ModalContent
                maxH="98vh"
                overflowY="auto"
            >
                <ModalCloseButton zIndex={10} />
                <Box
                    px={{ base: 6, md: 10 }}
                    py={10}
                >
                    <Text
                        textStyle="lg"
                        textAlign={'center'}
                    >
                        Need Help with Your Subscription?
                    </Text>

                    <Text mb={6}>
                        To cancel your subscription, please contact our support team by email at{' '}
                        <Link
                            href={`mailto:${HELLO_EMAIL}`}
                            color="blue.500"
                        >
                            {HELLO_EMAIL}
                        </Link>
                    </Text>

                    <Button
                        onClick={onClosePopup}
                        w="100%"
                    >
                        Okay
                    </Button>
                </Box>
            </ModalContent>
        </Modal>
    );
}
