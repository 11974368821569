import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';

import { APP_BRAND, HELLO_EMAIL, MONTH_PRICE } from '@/common/constants/brand';
import { capitalizeFirstLetter, getNextDateFromToday } from '@/shared/lib';

export default function CartBlockCheckMark({ BtnEl }: any) {
    return (
        <Box>
            <Flex
                gap={2}
                mb={6}
            >
                <Image
                    src="/img/icons/icons/steps.svg"
                    alt="steps"
                    w="20px"
                />

                <Box flexGrow={1}>
                    <Box
                        pb={2}
                        borderBottom="1px solid rgba(0, 0, 0, 0.1)"
                    >
                        <Flex
                            justify="space-between"
                            fontWeight={600}
                        >
                            <Box>Due today</Box>
                            <Box>$0</Box>
                        </Flex>

                        <Box fontSize="12px">7-day FREE trial</Box>
                    </Box>

                    <Flex
                        pt="4"
                        justify="space-between"
                        fontSize="14px"
                    >
                        <Box>Due on {getNextDateFromToday()}</Box>
                        <Box>${MONTH_PRICE}</Box>
                    </Flex>
                </Box>
            </Flex>

            <Text
                fontSize="12px"
                lineHeight={1.3}
                color="gray.525"
            >
                By clicking the “Try {capitalizeFirstLetter(APP_BRAND)} Membership for free” button, I agree to start my
                free trial. My card won’t be charged today. After the 7-day trial period on {getNextDateFromToday(true)}
                , ${MONTH_PRICE} will be automatically billed to my card. The subscription will renew automatically each
                month until I cancel the trial or subscription. I can cancel my trial or subscription anytime through my
                account settings or by reaching out to the support team at
                <Link href={`mailto:${HELLO_EMAIL}`}>{HELLO_EMAIL}</Link>. If I cancel before the end of a subscription
                period, I understand that {capitalizeFirstLetter(APP_BRAND)} will not provide a partial refund.
            </Text>

            {BtnEl && <BtnEl />}
        </Box>
    );
}
