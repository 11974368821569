import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from '@chakra-ui/react';

import SorryToSeeYouGoScreen from './various/SorryToSeeYouGoScreen';
import ClaimOfferScreen from './stay/ClaimOfferScreen';
import useUnsubFlow from '../../model/useUnsubFlow';
import DiscountFirstOfferScreen from './discount/DiscountFirstOfferScreen';
import DiscountSecondOfferScreen from './discount/DiscountSecondOfferScreen';

export default function AfterMailFlow() {
    const {
        currentStep,
        setCurrentStep,
        goToNextStep,
        goToPreviousStep,
        isUnsubFlowOpen,
        onClosePopup,
        goToDashboard,
    } = useUnsubFlow();

    return (
        <Modal
            variant="primary"
            scrollBehavior="inside"
            isOpen={isUnsubFlowOpen}
            onClose={onClosePopup}
            isCentered
        >
            <ModalOverlay />

            <ModalContent
                maxH="98vh"
                overflowY="auto"
            >
                <ModalCloseButton zIndex={10} />

                {currentStep === 1 && (
                    <DiscountFirstOfferScreen
                        onPrev={onClosePopup}
                        onCancelSub={goToNextStep}
                        onStayBtn={() => setCurrentStep(1.5)}
                    />
                )}

                {currentStep === 1.5 && (
                    <ClaimOfferScreen
                        onNext={goToDashboard}
                        price={49}
                    />
                )}

                {currentStep === 2 && (
                    <DiscountSecondOfferScreen
                        onPrev={goToPreviousStep}
                        onCancelSub={goToNextStep}
                        onStayBtn={() => setCurrentStep(2.5)}
                    />
                )}

                {currentStep === 2.5 && (
                    <ClaimOfferScreen
                        onNext={goToDashboard}
                        price={9}
                    />
                )}

                {currentStep === 3 && <SorryToSeeYouGoScreen onNext={goToDashboard} />}
            </ModalContent>
        </Modal>
    );
}
