import {
    Flex,
    Center,
    BoxProps,
    RangeSlider,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    RangeSliderTrack,
} from '@chakra-ui/react';
import { useMediaRemote, useMediaState } from '@vidstack/react';
import { useEffect, useState } from 'react';
import {
    RiPlayMiniLine,
    RiPauseMiniLine,
    RiRepeatLine,
    RiVolumeMuteLine,
    RiVolumeDownLine,
    RiVolumeUpLine,
} from 'react-icons/ri';

interface PlayerControlLightProps {
    playerRef: any;
    styles?: BoxProps;
}

export default function PlayerControlLight({ playerRef, styles }: PlayerControlLightProps) {
    const isPaused = useMediaState('paused', playerRef);
    const volume = useMediaState('volume', playerRef);
    const isMuted = useMediaState('muted', playerRef);

    const player = useMediaRemote(playerRef);

    const [isVolumeRangeVisible, setIsVolumeRangeVisible] = useState(false);

    useEffect(() => {
        if (isMuted) {
            player?.changeVolume(0);
        }
    }, []);

    return (
        <Flex
            pos="absolute"
            gap={2}
            justify="space-between"
            w="100%"
            maxW={{ base: 'calc(100% - 40px)', lg: '600px' }}
            left={{ base: '50%', lg: '350px' }}
            transform={{ base: 'translateX(-50%)', lg: 'translateX(0)' }}
            bottom={{ base: '20px', lg: '50px' }}
            zIndex={20}
            transition="all 0.25s ease-in-out"
            {...styles}
        >
            <Flex gap={{ base: 1, md: 2 }}>
                <Center
                    onClick={() => {
                        player?.togglePaused();
                    }}
                    cursor="pointer"
                    w={{ base: '24px', md: '48px' }}
                    h={{ base: '24px', md: '48px' }}
                    borderRadius="full"
                    backgroundColor="rgba(255, 255, 255, 0.1)"
                    backdropFilter="blur(5px)"
                    color="white"
                    fontSize={{ base: '12px', md: '26px' }}
                    _hover={{
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        color: '#d3d3d3',
                    }}
                >
                    {isPaused ? <RiPlayMiniLine /> : <RiPauseMiniLine />}
                </Center>

                <Center
                    onClick={() => {
                        player?.seek(0);
                        player?.play();
                    }}
                    cursor="pointer"
                    w={{ base: '24px', md: '48px' }}
                    h={{ base: '24px', md: '48px' }}
                    borderRadius="full"
                    backgroundColor="rgba(255, 255, 255, 0.1)"
                    backdropFilter="blur(5px)"
                    color="white"
                    fontSize={{ base: '12px', md: '24px' }}
                    _hover={{
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        color: '#d3d3d3',
                    }}
                >
                    <RiRepeatLine />
                </Center>
            </Flex>

            <Flex
                flexShrink={0}
                gap={{ base: 1, md: 2 }}
            >
                <Center
                    onClick={() => {
                        setIsVolumeRangeVisible(!isVolumeRangeVisible);
                    }}
                    cursor="pointer"
                    flexShrink={0}
                    w={{ base: '24px', md: '48px' }}
                    h={{ base: '24px', md: '48px' }}
                    borderRadius="full"
                    backgroundColor={isVolumeRangeVisible ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.1)'}
                    backdropFilter="blur(5px)"
                    color={isVolumeRangeVisible ? '#d3d3d3' : 'white'}
                    fontSize={{ base: '12px', md: '24px' }}
                    _hover={{
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        color: '#d3d3d3',
                    }}
                >
                    {isMuted || volume === 0 ? (
                        <RiVolumeMuteLine />
                    ) : volume < 0.5 ? (
                        <RiVolumeDownLine />
                    ) : (
                        <RiVolumeUpLine />
                    )}
                </Center>

                {isVolumeRangeVisible && (
                    <RangeSlider
                        w={{ base: '50px', md: '100px' }}
                        max={100}
                        defaultValue={[volume * 100]}
                        onChange={(volume) => {
                            player?.changeVolume(volume[0] / 100);
                        }}
                    >
                        <RangeSliderTrack
                            bgColor={'rgba(255, 255, 255, 0.2)'}
                            h={{ base: '3px', md: '6px' }}
                        >
                            <RangeSliderFilledTrack bgColor="#8e4aff" />
                        </RangeSliderTrack>
                        <RangeSliderThumb
                            index={0}
                            bgColor="#8e4aff"
                            w={{ base: '9px', md: '18px' }}
                            h={{ base: '9px', md: '18px' }}
                        />
                    </RangeSlider>
                )}
            </Flex>
        </Flex>
    );
}
