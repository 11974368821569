import { CourseCategories } from '../lists/course-categories-list';
import { ReactNode } from 'react';
import { EventNames } from './event-quiz-types';

export enum QuizGroupType {
    DefaultMultiple = 'defaultMultiple',
    DefaultSingle = 'defaultSingle',
    CategorySingle = 'categorySingle',
    GenderSingle = 'genderSingle',
    DefaultSingleNextPage = 'defaultSingleNextPage',
    CoursesOfCategoryMultiple = 'coursesOfCategoryMultiple',
    Picture = 'picture',
    SingleToVariants = 'singleVariants',
    Other = 'other',
}

export interface QuizList {
    name?: string;
    label: string;
    value: string;
}

interface QuizGroupBase {
    type: QuizGroupType;
    title: string;
    eventName: EventNames;
    isNextStep?: boolean;
    nextStepsCondition?: any;
    subTitle?: string;
    Component: (...args: any[]) => ReactNode | ReactNode;
}

interface QuizGroupDefault extends QuizGroupBase {
    type:
        | QuizGroupType.DefaultMultiple
        | QuizGroupType.DefaultSingle
        | QuizGroupType.CategorySingle
        | QuizGroupType.GenderSingle
        | QuizGroupType.DefaultSingleNextPage
        | QuizGroupType.SingleToVariants
        | QuizGroupType.Other;
    quizList?: QuizList[];
    categoryList?: any;
}

interface QuizGroupCategory extends QuizGroupBase {
    type: QuizGroupType.CoursesOfCategoryMultiple;
    categoryName: CourseCategories;
    quizList: QuizList[];
}

interface QuizGroupPicture extends QuizGroupBase {
    type: QuizGroupType.Picture;
    imgSrc: string;
    imgDesc: string;
}

export type QuizGroup = QuizGroupDefault | QuizGroupPicture | QuizGroupCategory;

export function isCheckboxesQuiz(quiz: QuizGroup): boolean {
    return quiz.type === QuizGroupType.DefaultMultiple || quiz.type === QuizGroupType.CoursesOfCategoryMultiple;
}
