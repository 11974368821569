import { createRoot } from 'react-dom/client';

import ProvidersCsr from '../../providers/providers-csr';

import CsrTildaCart from '@/entities/csr/csr-tilda-cart/CsrTildaCart';
import { getSearchParam } from '@/shared/lib';
import { OneClickCheckoutCart } from '@/features/checkout/one-click';
import { CountdownGridLanding } from '@/shared/ui';
import { csrStore, cartOpenStateAtom } from '@/entities/csr/store';

// Корзина на странице курса
const cartContainerNode = document.getElementById('cartContainer');

if (cartContainerNode) {
    // Управляем состоянием через глобальный стор
    csrStore.set(cartOpenStateAtom, false);

    const searchParam = getSearchParam('utm_source');
    if (searchParam) {
        localStorage.setItem('utmUser', `utm_source=${searchParam}`);
    }

    const root = createRoot(cartContainerNode);

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <CsrTildaCart
                courseId={parseInt(cartContainerNode.getAttribute('data-course-id')!)}
                isUseCardForPayment={cartContainerNode.getAttribute('data-use-card-for-payment')! === '1'}
            />
        </ProvidersCsr>,
    );

    const cartOpenNodes = document.querySelectorAll('.cart-open-el');

    if (cartOpenNodes) {
        for (const cartOpenEl of cartOpenNodes) {
            cartOpenEl.addEventListener('click', () => {
                csrStore.set(cartOpenStateAtom, true);
            });
        }
    }
}

// Промо-счетчик на странице курса
const countdownNode = document.getElementById('countdown');
if (countdownNode) {
    const root = createRoot(countdownNode);

    root.render(
        <ProvidersCsr isAlwaysRender={true}>
            <CountdownGridLanding isEndlessHour={true} />
        </ProvidersCsr>,
    );
}

// Блок с опциональной покупкой (триал или обычная цена) на странице курса
const oneClickCheckoutNodes = document.querySelectorAll('.oneClickCheckout');
if (oneClickCheckoutNodes.length > 0) {
    oneClickCheckoutNodes.forEach((node) => {
        const root = createRoot(node);
        const courseId = parseInt(node.getAttribute('data-course-id')!);

        root.render(
            <ProvidersCsr isAlwaysRender={true}>
                <OneClickCheckoutCart courseId={courseId} />
            </ProvidersCsr>,
        );
    });
}
