import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const stretchedVariant = defineStyle({
    _hover: {
        opacity: 0.7,
        textDecoration: 'none',
        color: 'green.400',
    },

    _before: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        content: '""',
    },

    _dark: {
        color: 'orange.800',
    },
});

const defaultVariant = defineStyle({
    _hover: {
        opacity: 0.7,
        textDecoration: 'none',
        color: 'green.400',
    },

    _dark: {
        color: 'orange.800',
    },
});

const outlineVariant = defineStyle({
    display: 'flex',
    gap: 2.5,
    alignItems: 'center',
    pt: { base: '9px', md: '12px' },
    pb: { base: '7px', md: '10px' },
    px: 7.5,
    border: '1px solid',
    borderColor: 'gray.675',
    borderRadius: '555px',
    fontSize: 'sm',
    fontWeight: 500,
    textTransform: 'uppercase',

    _dark: {
        color: 'orange.800',
    },
});

const navSmVariant = defineStyle({
    display: 'block',
    px: 5,
    w: '100%',
    fontSize: 'sm',
    lineHeight: '28px',

    _hover: {
        opacity: 1,
        color: 'blue.700',
        textDecor: 'none',
    },
});

const navSm2Variant = defineStyle({
    display: 'block',
    p: 5,
    borderBottom: '1px solid',
    borderBottomColor: 'gray.375',
    fontSize: 'sm2',

    _hover: {
        opacity: 1,
        color: 'blue.700',
        textDecor: 'none',
    },
});

const linkTheme = defineStyleConfig({
    variants: {
        stretched: stretchedVariant,
        outline: outlineVariant,
        'nav-sm': navSmVariant,
        'nav-sm-2': navSm2Variant,
        default: defaultVariant,
    },
});

export { linkTheme };
