import { Box, BoxProps, Text } from '@chakra-ui/react';

import { BannerCourseQuizBtn } from '@/entities/btns/banner-course-quiz';
import { PricesFreeDays } from '@/entities/promo/prices';
import { CountdownTexted, Picture, PictureType } from '@/shared/ui';
import { ActionSubType, useCreateSub } from '@/features/subscription/create-sub';

export default function WatchQuizBannerCountdown({
    timerSeconds,
    styles,
}: {
    timerSeconds: number;
    styles?: BoxProps;
}) {
    const { isSubLoading, isSubError, isSubCreate, loadingText, createSub } = useCreateSub();

    const onBtnClick = async () => {
        createSub(ActionSubType.AfterRecQuizBannerWatchCourse);
    };

    return (
        <Box
            background="url(/img/bg/pink-blue-ver.png) no-repeat 50% 50%"
            backgroundSize="cover"
            borderRadius={{ md: 'xl' }}
            pt="7.5"
            {...styles}
        >
            <CountdownTexted timerSeconds={timerSeconds} />

            <Text
                fontSize="lg"
                fontWeight={700}
                textAlign="center"
                px={4}
            >
                Start your personalized learning journey with over 200 courses and daily live events
            </Text>

            <PricesFreeDays
                styles={{
                    mb: 5,
                    color: 'gray.675',
                    sx: {
                        '.prices-free-day__throught-day': {
                            color: 'gray.600',
                        },
                    },
                }}
            />

            <BannerCourseQuizBtn
                btnText="Get started"
                onClick={onBtnClick}
                state={{
                    isLoading: isSubLoading,
                    isError: isSubError,
                    loadingText: loadingText,
                    isSubCreate: isSubCreate,
                }}
                styles={{
                    display: 'flex',
                    mx: 'auto',
                    textTransform: 'uppercase',
                    fontWeight: 700,
                    fontSize: 'sm',
                }}
            />

            <Picture
                src="promo/people/yoga-woman-stand.png"
                type={PictureType.Simple2x}
                alt="yoga-woman-stand"
                imgStyle={{
                    mt: 5,
                    transform: 'scaleX(-1)',
                }}
            />
        </Box>
    );
}
