import { useEffect, useRef, useState } from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Box, Link, Flex, HStack } from '@chakra-ui/react';
import { SwiperSlide } from 'swiper/react';

import { LoadingContentText, TabGlider } from '@/shared/ui';
import { TimeZone } from '@/entities/client/profile/time-zone';
import { useEventsList } from '@/features/socials/events/get-events';
import { SliderNavigation } from '@/entities/lists/sliders/primary-slider';
import { EventData } from '@/entities/events/event-data';
import { EventCardDate } from '@/entities/socials/events/event-card';

const EVENT_PAGE_URL = '/events';

export default function EventsTab({ styles }: { styles?: any }) {
    const { eventList, isEventsLoading, getEvents } = useEventsList();
    const isNotUpcoming = eventList?.upcomingEvents?.length === 0;
    const isPastEventExist = eventList?.pastEvents && eventList?.pastEvents?.length > 0;

    const gliderRef = useRef<HTMLButtonElement>(null);

    const [tabIndex, setTabIndex] = useState(0);
    const handleTabsChange = (index: number) => {
        setTabIndex(index);
    };

    useEffect(() => {
        if (isNotUpcoming) {
            setTabIndex(1);
        }
    }, [eventList]);

    return (
        <Tabs
            variant="spaceless"
            onChange={handleTabsChange}
            index={tabIndex}
            isLazy
            isManual
            {...styles}
        >
            <HStack
                justifyContent="space-between"
                spacing={2.5}
            >
                <TabList id="events-tabs-tooltip-anchor">
                    <Tab
                        ref={gliderRef}
                        isDisabled={isNotUpcoming}
                    >
                        Upcoming
                    </Tab>

                    <Tab>Past</Tab>

                    <TabGlider
                        gliderRef={gliderRef}
                        tabIndex={tabIndex}
                    />
                </TabList>

                <TimeZone onChangeZone={getEvents} />
            </HStack>

            <TabPanels
                minH="515px"
                mt={{ base: 3, md: 5 }}
                id="events-tooltip-anchor"
            >
                <TabPanel>
                    {eventList?.upcomingEvents ? (
                        <SliderNavigation
                            isNavShow={true}
                            styles={{ w: '100%' }}
                        >
                            {eventList.upcomingEvents.map((item: EventData) => (
                                <SwiperSlide key={item.id}>
                                    <EventCardDate
                                        eventData={item}
                                        styles={{
                                            minH: {
                                                base: '454px',
                                                md: '478px',
                                            },
                                            w: {
                                                base: '290px',
                                                md: '376px',
                                            },
                                        }}
                                    />
                                </SwiperSlide>
                            ))}
                        </SliderNavigation>
                    ) : (
                        <LoadingContentText isLoading={isEventsLoading}>
                            There are no upcoming events
                        </LoadingContentText>
                    )}
                </TabPanel>

                <TabPanel>
                    {isPastEventExist ? (
                        <Box w="100%">
                            <SliderNavigation isNavShow={true}>
                                {eventList.pastEvents!.map((item: EventData) => (
                                    <SwiperSlide key={item.id}>
                                        <EventCardDate
                                            eventData={item}
                                            styles={{
                                                minH: {
                                                    base: '454px',
                                                    md: '478px',
                                                },
                                                w: {
                                                    base: '290px',
                                                    md: '376px',
                                                },
                                            }}
                                        />
                                    </SwiperSlide>
                                ))}
                            </SliderNavigation>

                            {eventList.pastEvents!.length > 5 && (
                                <Flex
                                    w="100%"
                                    mt={4}
                                    justify="start"
                                >
                                    <Link
                                        // TODO: переделать на next
                                        href={EVENT_PAGE_URL}
                                        textStyle="see-all"
                                        color="green.400"
                                    >
                                        See more
                                    </Link>
                                </Flex>
                            )}
                        </Box>
                    ) : (
                        <LoadingContentText isLoading={isEventsLoading}>There are no past events</LoadingContentText>
                    )}
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
}
