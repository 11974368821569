import { RiLockUnlockLine, RiUserSmileLine, RiCalendarEventLine, RiUser2Line, RiPlayCircleLine } from 'react-icons/ri';
import { FeatureData } from '@/entities/lists/model/types';

export const paywallFeatureList: FeatureData[] = [
    {
        icon: () => <RiLockUnlockLine />,
        text: '<div><b>Live Zoom Sessions:</b> Daily boosts from leading experts.</div>',
    },
    {
        icon: () => <RiUserSmileLine />,
        text: "<div><b>140+ Bestselling Courses:</b> Tailored insights for your body and soul's growth.</div>",
    },
    {
        icon: () => <RiCalendarEventLine />,
        text: '<div><b>Weekly New Arrivals:</b> Always fresh, engaging content to explore.</div>',
    },
    {
        icon: () => <RiUser2Line />,
        text: '<div><b>Vibrant Community Access:</b> Engage, share, and evolve.</div>',
    },
];

export const paywallCourseFeatureList: FeatureData[] = [
    {
        icon: () => <RiLockUnlockLine />,
        text: '<div><b>Mana Movement Unlocked</b></div>',
    },
    {
        icon: () => <RiUserSmileLine />,
        text: "<div><b>140+ Bestselling Courses:</b> Tailored insights for your body and soul's growth.</div>",
    },
    {
        icon: () => <RiCalendarEventLine />,
        text: '<div><b>Weekly New Arrivals:</b> Always fresh, engaging content to explore.</div>',
    },
    {
        icon: () => <RiPlayCircleLine />,
        text: '<div><b>Live Zoom Sessions:</b> Daily boosts from leading experts.</div>',
    },
    {
        icon: () => <RiUser2Line />,
        text: '<div><b>Vibrant Community Access:</b> Engage, share, and evolve.</div>',
    },
];
