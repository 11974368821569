import { isToday, parseISO } from 'date-fns';
import { useAtom, useAtomValue } from 'jotai';

import { userAtom } from '@/shared/store';
import NeedHelpScreen from './components/various/NeedHelpScreen';
import TrialFlow from './components/TrialFlow';
import DefaultFlow from './components/DefaultFlow';
import AfterMailFlow from './components/AfterMailFlow';
import { useState } from 'react';
import SomethingWentWrongEmail from './components/error/SomethingWentWrongEmail';
import { ConfomationEvents } from '@/entities/user/user-data';
import { unsubFlowOpenStateAtom } from '../model/unsub-flow-store';

export default function UnsubscribeFlowModal() {
    const user = useAtomValue(userAtom);
    const [isSomethingWentWrongEmailScreenShowed, setSomethingWentWrongEmailScreenShowed] = useState(false);
    const [isUnsubFlowOpen, setIsUnsubFlowOpen] = useAtom(unsubFlowOpenStateAtom);
    const [isAlreadyOpened, setIsAlreadyOpened] = useState(false);

    if (!user) {
        return null;
    }

    const date = user.subscription && parseISO(user.subscription.created_at);

    const conformationEvent = user.confirmation_events?.find(
        (event) => event.event_type === ConfomationEvents.ConfirmCancelSubscription,
    );

    // Если первый день подписки, показываем попап с обращением в поддержку
    if (date && isToday(date)) {
        return <NeedHelpScreen />;
    }

    // Если пришел с почты с правильной ссылки
    if (conformationEvent && !conformationEvent.confirmation_error && conformationEvent.is_interacted === 0) {
        if (!isAlreadyOpened) {
            setIsUnsubFlowOpen(true);
            setIsAlreadyOpened(true);
        }

        return <AfterMailFlow />;
    }

    // Если пришел с почты, но ссылка неправильная
    if (
        conformationEvent?.confirmation_error &&
        conformationEvent.is_interacted === 0 &&
        !isSomethingWentWrongEmailScreenShowed
    ) {
        if (!isAlreadyOpened) {
            setIsUnsubFlowOpen(true);
            setIsAlreadyOpened(true);
        }

        return (
            <SomethingWentWrongEmail setSomethingWentWrongEmailScreenShowed={setSomethingWentWrongEmailScreenShowed} />
        );
    }

    if (user.subscription_trial_days > 0 && !user.is_add_trial_period) {
        return <TrialFlow />;
    }

    return <DefaultFlow />;
}
