export interface FetchResponse<T = string> {
    data: T;
    result_code?: string;
    result_message?: string;
}

export enum ResultCode {
    ModelNotFound = 'MNF',
    Unauthorized = 'UNA',
    SubError = 'SUB',
    CourseError = 'COURSE',
}
