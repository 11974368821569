import { atom } from 'jotai';

export enum AuthorLandingType {
    QUIZ_ONBOARDING = 'quiz_onboarding',
    ONBOARDING = 'onboarding',
    PUBLIC = 'public',
}

interface AuthorLandingProps {
    type?: AuthorLandingType;
    courseData?: {
        id: number;
        title: string;
        price: number;
        buyedCourseId?: number;
    };
    buttonUrl?: string | null;
    cbSkip?: () => void;
}

export const authorLandingAtom = atom<AuthorLandingProps | null>(null);
