import { useEffect, useState } from 'react';
import { Portal } from '@chakra-ui/react';
import { useAtom } from 'jotai';

import { CardCartCourse } from '@/entities/cards/card-cart-course';
import { fetchData, isHostInList } from '@/shared/lib';
import { cartOpenStateAtom } from '../store';
import { URLS } from '@/common/constants/global';
import { DOMAIN_NAMES } from '@/common/constants/brand';
import { CartModal } from '@/entities/modals/cart';
import { CART_STEPS } from '@/entities/modals/cart/ui/landing/CartModal';

const THREE_CART_COURSE_ID = 831;

// Настройки потоков корзины для разных доменов/курсов
const CART_FLOWS = {
    UPSELLS_TRACKER_PAYMENT: [CART_STEPS.FIRST, CART_STEPS.PAYMENT],
    UPSELLS_TRACKER: [CART_STEPS.FIRST, CART_STEPS.UPSELLING, CART_STEPS.TRACKER],
    UPSELL: [CART_STEPS.FIRST, CART_STEPS.UPSELLING],
    BASIC: [CART_STEPS.FIRST],
};

interface CsrTildaCartProps {
    courseId: number;
    isUseCardForPayment: boolean;
}

export default function CsrTildaCart({ courseId, isUseCardForPayment }: CsrTildaCartProps) {
    const [currentCourse, setCurrentCourse] = useState<any>(null);
    const [optionsCourses, setOptionsCourses] = useState<any>(null);
    const [isCartOpen, setIsCartOpen] = useAtom(cartOpenStateAtom);

    // Определяем поток шагов корзины
    const getCartFlow = () => {
        const isPaymentCart =
            isHostInList([DOMAIN_NAMES.Harmonymingle, DOMAIN_NAMES.Localhost, DOMAIN_NAMES.Mindfuleve]) &&
            isUseCardForPayment;

        if (isPaymentCart) {
            return CART_FLOWS.UPSELLS_TRACKER_PAYMENT;
        }

        const isThreeStepCart =
            isHostInList([DOMAIN_NAMES.Harmonymingle, DOMAIN_NAMES.NewMindStart, DOMAIN_NAMES.Mindfuleve]) &&
            courseId === THREE_CART_COURSE_ID;

        if (isThreeStepCart) {
            return CART_FLOWS.UPSELLS_TRACKER;
        }

        // Если есть опциональные курсы, используем поток с апселлингом
        if (optionsCourses && optionsCourses.length > 0) {
            return CART_FLOWS.UPSELL;
        }

        // По умолчанию используем базовый поток
        return CART_FLOWS.BASIC;
    };

    const fetchCourses = async () => {
        try {
            const { data } = await fetchData(URLS.getCourseById(courseId));

            setCurrentCourse(data[0]);
            setOptionsCourses(data[0].optional_courses);
        } catch (error) {
            console.error('error: ', error);
        }
    };

    useEffect(() => {
        fetchCourses();
    }, []);

    if (!currentCourse) {
        return null;
    }

    const courseData = [
        {
            id: currentCourse.id,
            title: currentCourse.title,
            price: currentCourse.course_card_price,
        },
    ];

    const optionsList =
        optionsCourses && optionsCourses.length > 0
            ? optionsCourses.map((course: any) => ({
                id: course.id,
                title: course.upsell_title,
                price: course.upsell_price,
                imgSrc: course.upsell_image,
            }))
            : [];

    return (
        <Portal>
            <CartModal
                isOpen={isCartOpen}
                onClose={() => setIsCartOpen(false)}
                courseData={courseData}
                OptionEl={({ onOptionClick, courseData }) => (
                    <CardCartCourse
                        onCourseBtnClick={onOptionClick}
                        courseData={courseData}
                    />
                )}
                optionsList={optionsList}
                steps={getCartFlow()}
            />
        </Portal>
    );
}
