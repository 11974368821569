import { Button, Text, VStack } from '@chakra-ui/react';

import { useLoadingTextWithFetch } from '@/shared/ui';

export default function CourseQuizBuyBtnPublic() {
    const { isSubmitted, loadingText, sendError, textedFetch } = useLoadingTextWithFetch(async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        window.windowPayByStripeSubscriptionNoEmail();

        await new Promise((resolve) => setTimeout(resolve, 5000));
    });

    const onClickBtn = async () => {
        await textedFetch();
    };

    return (
        <>
            <VStack w={{ base: '100%', lg: '340px' }}>
                <Button
                    w="100%"
                    maxW="360px"
                    fontSize="sm2"
                    onClick={onClickBtn}
                    isLoading={isSubmitted}
                    loadingText={loadingText}
                >
                    OPEN ACCESS NOW
                </Button>

                {sendError && (
                    <Text
                        mb={0}
                        textAlign="center"
                        color="red.500"
                    >
                        {sendError}
                    </Text>
                )}
            </VStack>
        </>
    );
}
