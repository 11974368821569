import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { RiArrowLeftLine } from 'react-icons/ri';

import { URLS } from '@/common/constants/global';
import { fetchData, METHODS } from '@/shared/lib';
import { useLoadingTextWithFetch } from '@/shared/ui';

export default function PauseOfferScreen({
    onPrev,
    onNext,
    onStayBtn,
}: {
    onPrev: () => void;
    onNext: () => void;
    onStayBtn: () => void;
}) {
    const { loadingText, sendError, isSubmitted, textedFetch } = useLoadingTextWithFetch(async () => {
        await fetchData(URLS.SUB_PAUSE, { method: METHODS.PUT });
    });

    const onPauseClick = async () => {
        await textedFetch();
        onStayBtn();
    };

    return (
        <Box
            pos="relative"
            px={{ base: 6, md: 10 }}
            py={10}
        >
            <Box
                hideFrom="md"
                as={RiArrowLeftLine}
                pos="absolute"
                top="13px"
                left="16px"
                w="20px"
                h="20px"
                onClick={onPrev}
            />

            <Text
                textStyle="lg"
                textAlign={'center'}
            >
                Need a Break? Pause Instead!
            </Text>

            <Text
                textStyle="sm"
                mb={4}
            >
                We get it — sometimes you need a little time away. Instead of unsubscribing, why not pause your
                subscription?
            </Text>

            <Text
                textStyle="md5"
                mb={0}
                fontWeight={600}
            >
                Here’s how it works:
            </Text>

            <Box
                as="ul"
                mb={4}
                pl={5}
                listStyleType={'disc'}
            >
                <li>No content will be lost. All your purchased courses and progress will stay intact.</li>
                <li>No charges during the pause. Take a break without worry.</li>
                <li>
                    Easily resume anytime. Take a break for up to one month and come back when you’re ready to continue
                    learning.
                </li>
            </Box>

            <Text
                textStyle="sm"
                mb={6}
            >
                Take control of your learning journey without losing the benefits you've gained.
            </Text>

            <Flex
                flexDir={{ base: 'column', md: 'row' }}
                flexWrap={'wrap'}
                gap={{ base: 2, md: 5 }}
            >
                <Button
                    onClick={onPauseClick}
                    loadingText={loadingText}
                    isLoading={isSubmitted}
                    w={{ md: '100%' }}
                >
                    Pause for 1 Month
                </Button>

                {sendError && (
                    <Text
                        color="red.500"
                        textAlign="center"
                        w="100%"
                        mb={0}
                    >
                        {sendError}
                    </Text>
                )}

                <Button
                    onClick={onPrev}
                    hideBelow="md"
                    w="calc(50% - 10px)"
                    bg="transparent"
                    border="1px solid"
                    borderColor="gray.500"
                    color="gray.500"
                    _hover={{
                        bg: 'transparent',
                        opacity: 0.7,
                    }}
                >
                    Back
                </Button>

                <Button
                    onClick={onNext}
                    variant="gray"
                    w={{ base: '100%', md: 'calc(50% - 10px)' }}
                >
                    Continue to Cancel
                </Button>
            </Flex>
        </Box>
    );
}
