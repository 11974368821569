import { Avatar, Box, Input, Text, useToast } from '@chakra-ui/react';
import { RiUserFill } from 'react-icons/ri';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { colors } from '@/common/theme/constant';
import { useAtomValue } from 'jotai';
import { userAtom } from '@/shared/store';
import { fetchData, METHODS } from '@/shared/lib';
import { URLS } from '@/common/constants/global';

export default function UploadAvatar() {
    const user = useAtomValue(userAtom);
    const toast = useToast();
    const hiddenInputRef = useRef<HTMLInputElement | null>(null);

    const [preview, setPreview] = useState<string | boolean>();

    const [uploadButtonLabel, setUploadButtonLabel] = useState(preview ? `Update photo` : `Upload photo`);

    const onUploadBtnClick = () => hiddenInputRef.current?.click();

    const onUploadedFile = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (!file) {
            return;
        }

        await uploadFile(file);
    };

    const uploadFile = async (file: File) => {
        setUploadButtonLabel(`Uploading...`);

        try {
            const formData = new FormData();
            formData.append('avatar', file);

            await fetchData(URLS.UPDATE_AVATAR, {
                method: METHODS.POST,
                body: formData,
            });

            setPreview(URL.createObjectURL(file));
            toast({
                duration: 5000,
                status: 'success',
                title: `Avatar uploaded successfully!`,
                position: 'top-right',
            });
        } catch (error) {
            toast({
                duration: 5000,
                status: 'error',
                title: `Upload failed. Please try again.`,
                position: 'top-right',
            });
        } finally {
            setUploadButtonLabel(preview ? `Update photo` : `Upload photo`);
        }
    };

    useEffect(() => {
        if (user) {
            setPreview(user?.avatar);
        }
    }, [user]);

    return (
        <Box>
            <Avatar
                src={preview}
                size="xl"
                w={'100px'}
                h={'100px'}
                bgColor={preview ? 'transparent' : 'gray.300'}
                mb={5}
                icon={
                    <RiUserFill
                        color={colors.gray[350]}
                        fontSize="48px"
                    />
                }
            />

            <Text
                cursor="pointer"
                textAlign={'center'}
                mb={0}
                color="blue.500"
                whiteSpace="nowrap"
                fontSize="sm3"
                onClick={onUploadBtnClick}
                _hover={{ opacity: 0.7 }}
            >
                {uploadButtonLabel}
            </Text>

            <Input
                display="none"
                type="file"
                accept={'image/*'}
                ref={hiddenInputRef}
                onChange={onUploadedFile}
            />
        </Box>
    );
}
