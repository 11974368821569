import { Box, Button, Text } from '@chakra-ui/react';

export default function ClaimOfferScreen({ onNext, price }: { onNext: () => void; price: number }) {
    return (
        <Box
            px={{ base: 6, md: 10 }}
            py={10}
        >
            <Text
                textStyle="lg"
                textAlign={'center'}
            >
                You’ve unlocked your special discount!
            </Text>

            <Text mb={4}>Welcome to unlimited learning at just ${price} per month</Text>

            <Text
                textStyle="sm"
                mb={6}
            >
                Enjoy access to all courses, resources, and features – for as long as you stay subscribed
            </Text>

            <Text
                textStyle="md5"
                mb={6}
                textAlign={'center'}
                fontWeight={600}
            >
                Let’s start your journey toward reaching your goals today!
            </Text>

            <Button
                onClick={onNext}
                w="100%"
            >
                Explore Courses Now
            </Button>
        </Box>
    );
}
