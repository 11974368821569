import { ReactNode, useState } from 'react';
import { Card, CardBody, CardFooter, Text, Image, Box, CardProps } from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { EventNotificationBtn } from '@/features/socials/events/event-btns';
import { EventData } from '@/entities/events/event-data';

interface EventCardProps {
    eventData: EventData;
    children?: ReactNode;
    styles?: CardProps;
}

export default function EventCard({ eventData, children, styles }: EventCardProps) {
    const { description, thumb: thumbSrc, title } = eventData;
    const [titleHeight, setTitleHeight] = useState<number | null>(null);
    const TITLE_CHECK_HEIGHT = 40;

    return (
        <Card
            variant="blur"
            w="100%"
            {...styles}
        >
            <Box
                pos="absolute"
                left={0}
                bottom={0}
                w="100%"
                h="100%"
                zIndex={10}
                borderRadius="inherit"
                overflow="hidden"
            >
                <Image
                    src={thumbSrc ? thumbSrc : '/img/bg/mountain-bg@2x.png'}
                    alt="event-thumb"
                    w="100%"
                    h="100%"
                    filter="blur(40px)"
                />
                <Box
                    pos="absolute"
                    top={0}
                    w="100%"
                    h="100%"
                    background="rgba(0, 0, 0, 0.35)"
                />
            </Box>

            <CardBody
                zIndex={20}
                position="relative"
                flex={0}
            >
                <Image
                    src={thumbSrc ? thumbSrc : ''}
                    alt="event-thumb"
                    display="block"
                    minH="100px"
                    minW="calc(100% + 32px)"
                    mt={-4}
                    mx={-4}
                    mb={4}
                    objectFit="cover"
                />

                <Text
                    noOfLines={2}
                    mb={0}
                    color="white"
                    fontSize="lg"
                    fontWeight={700}
                    ref={(ref) => {
                        if (ref) {
                            setTitleHeight(ref.offsetHeight);
                        }
                    }}
                >
                    {title}
                </Text>

                {description && (
                    <OverlayScrollbarsComponent>
                        <Text
                            pr={3}
                            mt={4}
                            mb={0}
                            whiteSpace="pre-line"
                            color="white"
                            maxHeight={titleHeight && titleHeight > TITLE_CHECK_HEIGHT ? '100px' : '120px'}
                        >
                            {description}
                        </Text>
                    </OverlayScrollbarsComponent>
                )}
            </CardBody>

            <CardFooter
                zIndex={20}
                position="relative"
                mt="auto"
            >
                {children ? children : <EventNotificationBtn eventData={eventData} />}
            </CardFooter>
        </Card>
    );
}
