import { AvatarGroup, Avatar, BoxProps } from '@chakra-ui/react';

interface AvatarListProps {
    count?: number;
    maxCount?: number;
    styles?: BoxProps & { size?: string };
}

export default function AvatarList({ styles, count = 4, maxCount = 4 }: AvatarListProps) {
    // Создаём массив с нужным количеством элементов
    const avatars = Array.from({ length: count }, (_, index) => index + 1);

    return (
        <AvatarGroup
            size="md"
            max={maxCount}
            {...styles}
        >
            {avatars.map((num) => (
                <Avatar
                    key={num}
                    src={`/img/success-stories/${num}.png`}
                />
            ))}
        </AvatarGroup>
    );
}
