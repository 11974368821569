import { Flex, Button, Box, Text, Img, VStack } from '@chakra-ui/react';

import { CardReviewSolid } from '@/entities/cards/cards-review';
import { HTMLRenderer } from '@/shared/ui';
import { FeatureData } from '@/entities/lists/model/types';
import { ActionSubType, useCreateSub, useTrialDay } from '@/features/subscription/create-sub';
import { MONTH_PRICE } from '@/common/constants/brand';

interface PaywallContentProps {
    subActionType: ActionSubType;
    title: string;
    featureList: FeatureData[];
    onSuccessfulSub: () => void;
}

const REVIEW_ITEMS = [
    {
        authorName: 'Ursula',
        photo: '/img/reviews/ursula.jpg',
        text: 'I have been here for 6 months now and still keep enjoying the variety of courses and topics to learn. I enjoy the daily live events, especially Daryas and Tiana Lee’s.',
        rating: 5,
        date: '12 minutes ago',
    },
];

export default function PaywallContent({ subActionType, title, featureList, onSuccessfulSub }: PaywallContentProps) {
    const { isSubLoading, isSubError, createSub, sendError } = useCreateSub(subActionType);
    useTrialDay(7);

    return (
        <Flex
            flexDir={{ base: 'column', lg: 'row' }}
            borderRadius="xl"
            backgroundColor="gray.650"
            backgroundImage={{
                base: 'url(/img/bg/course-fade-vertical.png)',
                md: 'url(/img/bg/course-fade-horizontal.png)',
            }}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
        >
            <Box
                px={{ base: 5, lg: 10 }}
                py={10}
                w={{ lg: '50%' }}
                borderRadius="xl"
                backgroundColor="white"
            >
                <Text
                    textStyle="lg3"
                    mb={5}
                >
                    {title}
                </Text>

                <Text
                    textStyle="md5"
                    fontWeight={700}
                >
                    Communicate, ignite inspiration, and cultivate limitless growth
                </Text>

                {featureList?.length > 0 && (
                    <Flex
                        flexDir="column"
                        gap={2.5}
                        mt={5}
                    >
                        {featureList.map((feature: FeatureData, index: number) => {
                            return (
                                <Flex
                                    key={index}
                                    gap={2.5}
                                    lineHeight={1.2}
                                >
                                    <Box
                                        color="green.400"
                                        fontSize="24px"
                                    >
                                        {feature.icon()}
                                    </Box>
                                    <HTMLRenderer html={feature.text} />
                                </Flex>
                            );
                        })}
                    </Flex>
                )}

                <VStack mt={6}>
                    <Button
                        w="100%"
                        fontSize={{ base: 'sm', lg: 'xl' }}
                        fontWeight={500}
                        lineHeight={1.4}
                        isLoading={isSubLoading}
                        loadingText={`Submitting`}
                        onClick={async () => {
                            try {
                                await createSub();
                                onSuccessfulSub();
                            } catch (error) {
                                console.error(error);
                            }
                        }}
                    >
                        START 7-DAY FREE TRIAL
                    </Button>

                    {isSubError && (
                        <Text
                            mb={0}
                            color="red.600"
                        >
                            {sendError}
                        </Text>
                    )}
                </VStack>
            </Box>

            <Flex
                flexDir={{ base: 'column-reverse', md: 'row-reverse', lg: 'column' }}
                align={{ md: 'flex-start' }}
                gap={6}
                px={{ base: 5, lg: 10 }}
                py={10}
                w={{ lg: '50%' }}
            >
                <Box
                    pos="relative"
                    w={{
                        md: '50%',
                        lg: '100%',
                    }}
                >
                    <CardReviewSolid {...REVIEW_ITEMS[0]} />
                    <Box
                        pos="absolute"
                        top="-13px"
                        left="7%"
                        h="13px"
                        w="86%"
                        borderTopRadius="xl"
                        boxShadow="0 -5px 20px 0 rgba(0, 0, 0, 0.08)"
                        background="#7e7e7e"
                    />
                    <Box
                        pos="absolute"
                        top="-26px"
                        left="15%"
                        h="13px"
                        w="70%"
                        borderTopRadius="xl"
                        boxShadow="box-shadow: 0 -4px 17px 0 rgba(0, 0, 0, 0.08)"
                        background="#585858"
                    />
                </Box>

                <Flex
                    gap={4}
                    align="flex-start"
                >
                    <Img
                        src="/img/helpers/aside-list-line.png"
                        alt="aside-list-line"
                        flexShrink={0}
                    />

                    <Flex
                        flexDir="column"
                        gap={6}
                        maxW="300px"
                        color="white"
                    >
                        <Box>
                            <Text
                                mb={2}
                                fontSize={{ base: 'sm2', lg: 'lg' }}
                                fontWeight={700}
                            >
                                Today - Your trial starts
                            </Text>
                            <Text
                                mb={0}
                                fontSize="sm2"
                            >
                                Start exploring amazing courses and daily live events for free
                            </Text>
                        </Box>

                        <Box>
                            <Text
                                mb={2}
                                fontSize={{ base: 'sm2', lg: 'lg' }}
                                fontWeight={700}
                            >
                                Day 5 - Email reminders
                            </Text>
                            <Text
                                mb={0}
                                fontSize="sm2"
                            >
                                We&#39;ll remind you that your free trial is ending
                            </Text>
                        </Box>

                        <Box>
                            <Text
                                mb={2}
                                fontSize={{ base: 'sm2', lg: 'lg' }}
                                fontWeight={700}
                            >
                                Day 7 - Your new subscription starts
                            </Text>
                            <Text
                                mb={0}
                                fontSize="sm2"
                            >
                                You&#39;ll keep all features unlocked during the trial at ${MONTH_PRICE}
                                /month, unless you cancel
                            </Text>
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
