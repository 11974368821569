import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button, Text } from '@chakra-ui/react';

interface SimpleModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    onAcceptBtnClick: () => void;
    acceptBtnText?: string;
    closeBtnText?: string;
}

export default function SimpleModal({
    isOpen,
    onClose,
    title,
    onAcceptBtnClick,
    acceptBtnText = 'Yes',
    closeBtnText = 'No',
}: SimpleModalProps) {
    return (
        <Modal
            variant="primary"
            scrollBehavior="inside"
            isOpen={isOpen}
            onClose={onClose}
            isCentered
        >
            <ModalOverlay />

            <ModalContent>
                <ModalBody>
                    <Text
                        textStyle="md2"
                        textAlign="center"
                    >
                        {title}
                    </Text>
                </ModalBody>

                <ModalFooter justifyContent="center">
                    <Button
                        variant="gray"
                        onClick={onClose}
                    >
                        {closeBtnText}
                    </Button>
                    <Button onClick={onAcceptBtnClick}>{acceptBtnText}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
