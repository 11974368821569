import { ALL_COURSES_LIST_BY_ID } from '@/entities/courses/lists/ids';
import { CourseCategories } from './course-categories-list';
import { QuizList } from '../types/quiz-list-types';
import { Gender } from './radio-list';

export const YOGA_LIST_FEMALE: QuizList[] = [
    {
        label: 'Heal mind, body, and soul through sound',
        value: ALL_COURSES_LIST_BY_ID.NadaYogaSoundToHealMindBodySoul,
    },
    {
        label: 'Divine chakra rituals for feminine activation',
        value: ALL_COURSES_LIST_BY_ID.DivineYouChakra,
    },
    {
        label: 'Sacred practices with the 4 elements',
        value: ALL_COURSES_LIST_BY_ID.FeminineAlchemySacredPractices4Elements,
    },
    {
        label: 'Sensual vitality practices for women',
        value: ALL_COURSES_LIST_BY_ID.SexualAlchemyVitalityPractices,
    },
    {
        label: 'Qigong for better sleep and overall wellbeing',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Healing chakra course',
        value: ALL_COURSES_LIST_BY_ID.HealingChakraChargeEnergyCenters,
    },
    {
        label: 'Unlock kundalini energy within yourself',
        value: ALL_COURSES_LIST_BY_ID.SensualAbundantUnlockKundalini,
    },
    {
        label: 'Qigong for stress and anxiety relief',
        value: ALL_COURSES_LIST_BY_ID.QigongForStressAndAnxiety,
    },
    {
        label: 'Feel better instantly through rhythmic mood marathon',
        value: ALL_COURSES_LIST_BY_ID.RhythmicMoodFeelBetterInstant,
    },
];

export const YOGA_LIST_MALE: QuizList[] = [
    {
        label: 'Heal mind, body, and soul through sound',
        value: ALL_COURSES_LIST_BY_ID.NadaYogaSoundToHealMindBodySoul,
    },
    {
        label: 'Qigong for better sleep, more energy, and overall wellbeing',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Healing chakra course',
        value: ALL_COURSES_LIST_BY_ID.HealingChakraChargeEnergyCenters,
    },
    {
        label: 'Unlock kundalini energy within yourself',
        value: ALL_COURSES_LIST_BY_ID.SensualAbundantUnlockKundalini,
    },
    {
        label: 'Qigong for stress and anxiety relief',
        value: ALL_COURSES_LIST_BY_ID.QigongForStressAndAnxiety,
    },
    {
        label: 'Beat your brain with rhythmic neurogymnastics',
        value: ALL_COURSES_LIST_BY_ID.BeatYourBrainRhythmicNeurogymnastics,
    },
    {
        label: 'Qigong for better sleep and more energy',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Feel better instantly through rhythmic mood marathon',
        value: ALL_COURSES_LIST_BY_ID.RhythmicMoodFeelBetterInstant,
    },
];

export const BREATHING_PRACTICES_LIST_FEMALE: QuizList[] = [
    {
        label: 'Navigate stress with mindful respiration',
        value: ALL_COURSES_LIST_BY_ID.BreatheBeyondStressMindfulness,
    },
    {
        label: 'Speak powerfully and persuade effectively',
        value: ALL_COURSES_LIST_BY_ID.TheArtOfSpeakingSonnectPowerfullyAndPersuadeEffectively,
    },
    {
        label: 'Divine chakra rituals for feminine activation',
        value: ALL_COURSES_LIST_BY_ID.DivineYouChakra,
    },
    {
        label: 'Sacred practices with the 4 elements',
        value: ALL_COURSES_LIST_BY_ID.FeminineAlchemySacredPractices4Elements,
    },
    {
        label: 'Elevate your cognitive performance',
        value: ALL_COURSES_LIST_BY_ID.NeurogeniusElevateCognitivePerformance,
    },
    {
        label: 'Improve brain and body through the Ouroboros system',
        value: ALL_COURSES_LIST_BY_ID.OuroborosBrainBody,
    },
];

export const BREATHING_PRACTICES_LIST_MALE: QuizList[] = [
    {
        label: 'Navigate stress with mindful respiration',
        value: ALL_COURSES_LIST_BY_ID.BreatheBeyondStressMindfulness,
    },
    {
        label: 'Speak powerfully and persuade effectively',
        value: ALL_COURSES_LIST_BY_ID.TheArtOfSpeakingSonnectPowerfullyAndPersuadeEffectively,
    },
    {
        label: 'Elevate your cognitive performance',
        value: ALL_COURSES_LIST_BY_ID.NeurogeniusElevateCognitivePerformance,
    },
    {
        label: 'Improve brain and body through the Ouroboros system',
        value: ALL_COURSES_LIST_BY_ID.OuroborosBrainBody,
    },
    {
        label: 'Qigong for better sleep, more energy, and overall wellbeing',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Qigong for stress and anxiety relief',
        value: ALL_COURSES_LIST_BY_ID.QigongForStressAndAnxiety,
    },
];

export const MEDITATION_LIST_FEMALE: QuizList[] = [
    {
        label: 'Sensual vitality practices for women',
        value: ALL_COURSES_LIST_BY_ID.SexualAlchemyVitalityPractices,
    },
    {
        label: 'Qigong for better sleep, more energy, and overall wellbeing',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Healing chakra course',
        value: ALL_COURSES_LIST_BY_ID.HealingChakraChargeEnergyCenters,
    },
    {
        label: 'Unlock kundalini energy within yourself',
        value: ALL_COURSES_LIST_BY_ID.SensualAbundantUnlockKundalini,
    },
    {
        label: 'Groove your way to genius with Neuro Dance',
        value: ALL_COURSES_LIST_BY_ID.NeuroDanceGrooveToGenius,
    },
    {
        label: 'Feel better instantly through rhythmic mood marathon',
        value: ALL_COURSES_LIST_BY_ID.RhythmicMoodFeelBetterInstant,
    },
    {
        label: 'Beat your brain with rhythmic neurogymnastics',
        value: ALL_COURSES_LIST_BY_ID.BeatYourBrainRhythmicNeurogymnastics,
    },
    {
        label: 'Elevate your cognitive performance with Neurogenius',
        value: ALL_COURSES_LIST_BY_ID.NeurogeniusElevateCognitivePerformance,
    },
    {
        label: 'Qigong for stress and anxiety relief',
        value: ALL_COURSES_LIST_BY_ID.QigongForStressAndAnxiety,
    },
    {
        label: 'Harness lunar energies with EFT tapping',
        value: ALL_COURSES_LIST_BY_ID.MoonManifestingLunarEnergiesEft,
    },
];

export const MEDITATION_LIST_MALE: QuizList[] = [
    {
        label: 'Qigong for stress and anxiety relief',
        value: ALL_COURSES_LIST_BY_ID.QigongForStressAndAnxiety,
    },
    {
        label: 'Beat your brain with rhythmic neurogymnastics',
        value: ALL_COURSES_LIST_BY_ID.BeatYourBrainRhythmicNeurogymnastics,
    },
    {
        label: 'Qigong for better sleep, more energy, and overall wellbeing',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Feel better instantly through rhythmic mood marathon',
        value: ALL_COURSES_LIST_BY_ID.RhythmicMoodFeelBetterInstant,
    },
    {
        label: 'Afro Jazz modern dance and soul body movement',
        value: ALL_COURSES_LIST_BY_ID.AfroJazzModernDanceSoulBody,
    },
    {
        label: 'Elevate your cognitive performance with Neurogenius',
        value: ALL_COURSES_LIST_BY_ID.NeurogeniusElevateCognitivePerformance,
    },
    {
        label: 'Groove your way to genius with Neuro Dance',
        value: ALL_COURSES_LIST_BY_ID.NeuroDanceGrooveToGenius,
    },
    {
        label: 'Harness lunar energies with EFT tapping',
        value: ALL_COURSES_LIST_BY_ID.MoonManifestingLunarEnergiesEft,
    },
];

export const PHYSICAL_EXERCISES_QUIZ_LIST_FEMALE: QuizList[] = [
    {
        label: 'Ouroboros: A system for improving brain and body',
        value: ALL_COURSES_LIST_BY_ID.OuroborosBrainBody,
    },
    {
        label: 'Sensual Goddess workout',
        value: ALL_COURSES_LIST_BY_ID.SensualGoddessWorkout,
    },
    {
        label: 'Healthy spine: 16 stick exercises for mobility and strength',
        value: ALL_COURSES_LIST_BY_ID.HealthySpine16Exercises,
    },
    {
        label: 'Breathing of sexuality: Fall in love with your body',
        value: ALL_COURSES_LIST_BY_ID.BreathingOfSexuality,
    },
    {
        label: 'Reclaim your vitality with energy-saving Qigong',
        value: ALL_COURSES_LIST_BY_ID.ReclaimVitalityEnergySavingQigong,
    },
    {
        label: 'Qigong for better sleep, more energy, and overall wellbeing',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Body and soul connection: Grow through your chakras',
        value: ALL_COURSES_LIST_BY_ID.BodyAndSoulConnectionChakras,
    },
    {
        label: '28 Days extreme transformation challenge',
        value: ALL_COURSES_LIST_BY_ID.ExtremeTransformationChallenge,
    },
    {
        label: 'Dance movement therapy with Anna Trish',
        value: ALL_COURSES_LIST_BY_ID.DanceMovementTherapyWithAnnaTrish,
    },
    {
        label: 'Warmup mobility: Spine, hip, and shoulder mobility for everyday',
        value: ALL_COURSES_LIST_BY_ID.WarmupMobilitySpineHipShoulder,
    },
];

export const PHYSICAL_EXERCISES_QUIZ_LIST_MALE: QuizList[] = [
    {
        label: 'Warmup mobility: Spine, hip, and shoulder mobility for everyday',
        value: ALL_COURSES_LIST_BY_ID.WarmupMobilitySpineHipShoulder,
    },
    {
        label: 'Healthy spine: 16 stick exercises for mobility and strength',
        value: ALL_COURSES_LIST_BY_ID.HealthySpine16Exercises,
    },
    {
        label: 'Ouroboros: A system for improving brain and body',
        value: ALL_COURSES_LIST_BY_ID.OuroborosBrainBody,
    },
    {
        label: '28 Days extreme transformation challenge',
        value: ALL_COURSES_LIST_BY_ID.ExtremeTransformationChallenge,
    },
    {
        label: 'Qigong for better sleep, more energy, and overall wellbeing',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Reclaim your vitality with energy-saving Qigong',
        value: ALL_COURSES_LIST_BY_ID.ReclaimVitalityEnergySavingQigong,
    },
];

export const DANCING_SINGING_QUIZ_LIST_FEMALE: QuizList[] = [
    {
        label: 'Vocal Yoga with Peruquois',
        value: ALL_COURSES_LIST_BY_ID.VocalYogaPeruquois,
    },
    {
        label: 'Sensual Goddess workout',
        value: ALL_COURSES_LIST_BY_ID.SensualGoddessWorkout,
    },
    {
        label: 'Opening the voice: Learn to sing beautifully',
        value: ALL_COURSES_LIST_BY_ID.OpenVoiceLearnSingBeautifully,
    },
    {
        label: 'Breathing of sexuality: Fall in love with your body',
        value: ALL_COURSES_LIST_BY_ID.BreathingOfSexuality,
    },
    {
        label: 'Dance movement therapy with Anna Trish',
        value: ALL_COURSES_LIST_BY_ID.DanceMovementTherapyWithAnnaTrish,
    },
    {
        label: 'Medicine songs with Geeta',
        value: ALL_COURSES_LIST_BY_ID.MedicineSongsGeetaFeelHeal,
    },
    {
        label: 'Voice Alchemy: Activate the power of your healing voice',
        value: ALL_COURSES_LIST_BY_ID.VoiceAlchemyHealingVoice,
    },
    {
        label: 'Afro Jazz modern dance and soul body movement',
        value: ALL_COURSES_LIST_BY_ID.AfroJazzModernDanceSoulBody,
    },
    {
        label: 'The Magic Voice',
        value: ALL_COURSES_LIST_BY_ID.MagicVoice,
    },
    {
        label: 'Twerking basics: Learn to shake and love your booty',
        value: ALL_COURSES_LIST_BY_ID.TwerkingBasicsLoveBody,
    },
    {
        label: 'Sing better in 21 days',
        value: ALL_COURSES_LIST_BY_ID.SingBetterIn21Days,
    },
    {
        label: 'Lumina: Multidimensional sound practice',
        value: ALL_COURSES_LIST_BY_ID.LuminaMultidimensionalSoundPractice,
    },
    {
        label: 'Sing with Aga: Your best singing course',
        value: ALL_COURSES_LIST_BY_ID.SingWithAgaBestSingingCourse,
    },
    {
        label: 'Nada Yoga: Yoga of sound to heal mind, body, and soul',
        value: ALL_COURSES_LIST_BY_ID.NadaYogaSoundToHealMindBodySoul,
    },
    {
        label: 'Neuro Dance: Groove your way to genius',
        value: ALL_COURSES_LIST_BY_ID.NeuroDanceGrooveToGenius,
    },
];

export const DANCING_SINGING_QUIZ_LIST_MALE: QuizList[] = [
    {
        label: 'Sing with Aga: Your best singing course',
        value: ALL_COURSES_LIST_BY_ID.SingWithAgaBestSingingCourse,
    },
    {
        label: 'Nada Yoga: Yoga of sound to heal mind, body, and soul',
        value: ALL_COURSES_LIST_BY_ID.NadaYogaSoundToHealMindBodySoul,
    },
    {
        label: 'Sing better in 21 days',
        value: ALL_COURSES_LIST_BY_ID.SingBetterIn21Days,
    },
    {
        label: 'Neuro Dance: Groove your way to genius',
        value: ALL_COURSES_LIST_BY_ID.NeuroDanceGrooveToGenius,
    },
    {
        label: 'Afro Jazz modern dance and soul body movement',
        value: ALL_COURSES_LIST_BY_ID.AfroJazzModernDanceSoulBody,
    },
    {
        label: 'Medicine songs with Geeta',
        value: ALL_COURSES_LIST_BY_ID.MedicineSongsGeetaFeelHeal,
    },
];

export const HEALTHY_NUTRITION_LIST_FEMALE: QuizList[] = [
    {
        label: 'The Nudie Foodie: How to eat with love',
        value: ALL_COURSES_LIST_BY_ID.TheNudieFoodieEatWithLove,
    },
    {
        label: 'The Art of Proper Food Combining for Fat Loss',
        value: ALL_COURSES_LIST_BY_ID.ProperFoodCombiningFatLoss,
    },
    {
        label: 'Nada Yoga: Yoga of sound to heal mind, body, and soul',
        value: ALL_COURSES_LIST_BY_ID.NadaYogaSoundToHealMindBodySoul,
    },
    {
        label: 'Divine You: Chakra rituals for feminine activation',
        value: ALL_COURSES_LIST_BY_ID.DivineYouChakra,
    },
    {
        label: 'Feminine Alchemy: Sacred practices with the 4 elements',
        value: ALL_COURSES_LIST_BY_ID.FeminineAlchemySacredPractices4Elements,
    },
    {
        label: 'Sexual Alchemy: Sensual vitality practices for women',
        value: ALL_COURSES_LIST_BY_ID.SexualAlchemyVitalityPractices,
    },
    {
        label: 'Qigong for better sleep, more energy, and overall wellbeing',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Healing Chakra course',
        value: ALL_COURSES_LIST_BY_ID.HealingChakraChargeEnergyCenters,
    },
    {
        label: 'Sensual Abundant You: Unlock kundalini energy within yourself',
        value: ALL_COURSES_LIST_BY_ID.SensualAbundantUnlockKundalini,
    },
    {
        label: 'Qigong for stress and anxiety relief',
        value: ALL_COURSES_LIST_BY_ID.QigongForStressAndAnxiety,
    },
    {
        label: 'Qigong for better sleep, more energy, and overall wellbeing',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Marathon of Rhythmic Mood: Feel better in an instant',
        value: ALL_COURSES_LIST_BY_ID.RhythmicMoodFeelBetterInstant,
    },
];

export const HEALTHY_NUTRITION_LIST_MALE: QuizList[] = [
    {
        label: 'The Nudie Foodie: How to eat with love',
        value: ALL_COURSES_LIST_BY_ID.TheNudieFoodieEatWithLove,
    },
    {
        label: 'The Art of Proper Food Combining for Fat Loss',
        value: ALL_COURSES_LIST_BY_ID.ProperFoodCombiningFatLoss,
    },
    {
        label: 'Nada Yoga: Yoga of sound to heal mind, body, and soul',
        value: ALL_COURSES_LIST_BY_ID.NadaYogaSoundToHealMindBodySoul,
    },
    {
        label: 'Qigong for better sleep, more energy, and overall wellbeing',
        value: ALL_COURSES_LIST_BY_ID.QigongNeuroSoundscapeBetterSleep,
    },
    {
        label: 'Healing Chakra course',
        value: ALL_COURSES_LIST_BY_ID.HealingChakraChargeEnergyCenters,
    },
    {
        label: 'Sensual Abundant You: Unlock kundalini energy within yourself',
        value: ALL_COURSES_LIST_BY_ID.SensualAbundantUnlockKundalini,
    },
    {
        label: 'Qigong for stress and anxiety relief',
        value: ALL_COURSES_LIST_BY_ID.QigongForStressAndAnxiety,
    },
    {
        label: 'Beat your brain with rhythmic neurogymnastics',
        value: ALL_COURSES_LIST_BY_ID.BeatYourBrainRhythmicNeurogymnastics,
    },
    {
        label: 'Marathon of Rhythmic Mood: Feel better in an instant',
        value: ALL_COURSES_LIST_BY_ID.RhythmicMoodFeelBetterInstant,
    },
];

export const ALL_COURSES_LIST_NEW_TEST_GENDERED = [
    {
        categoryName: CourseCategories.EnergyLevels,
        coursesList: {
            [Gender.Female]: YOGA_LIST_FEMALE,
            [Gender.Male]: YOGA_LIST_MALE,
            [Gender.Other]: YOGA_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.MentalClarity,
        coursesList: {
            [Gender.Female]: BREATHING_PRACTICES_LIST_FEMALE,
            [Gender.Male]: BREATHING_PRACTICES_LIST_MALE,
            [Gender.Other]: BREATHING_PRACTICES_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.PhysicalFitness,
        coursesList: {
            [Gender.Female]: MEDITATION_LIST_FEMALE,
            [Gender.Male]: MEDITATION_LIST_MALE,
            [Gender.Other]: MEDITATION_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.PhysicalExercises,
        coursesList: {
            [Gender.Female]: PHYSICAL_EXERCISES_QUIZ_LIST_FEMALE,
            [Gender.Male]: PHYSICAL_EXERCISES_QUIZ_LIST_MALE,
            [Gender.Other]: PHYSICAL_EXERCISES_QUIZ_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.EmotionalWellBeing,
        coursesList: {
            [Gender.Female]: DANCING_SINGING_QUIZ_LIST_FEMALE,
            [Gender.Male]: DANCING_SINGING_QUIZ_LIST_MALE,
            [Gender.Other]: DANCING_SINGING_QUIZ_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.AllOfTheAbove,
        coursesList: {
            [Gender.Female]: HEALTHY_NUTRITION_LIST_FEMALE,
            [Gender.Male]: HEALTHY_NUTRITION_LIST_MALE,
            [Gender.Other]: HEALTHY_NUTRITION_LIST_FEMALE,
        },
    },
];

export const ALL_COURSES_LIST_NEW_TEST_GENDERED_LIFESTYLE = [
    {
        categoryName: CourseCategories.EnergyLevels,
        coursesList: {
            [Gender.Female]: YOGA_LIST_FEMALE,
            [Gender.Male]: YOGA_LIST_MALE,
            [Gender.Other]: YOGA_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.MentalClarity,
        coursesList: {
            [Gender.Female]: BREATHING_PRACTICES_LIST_FEMALE,
            [Gender.Male]: BREATHING_PRACTICES_LIST_MALE,
            [Gender.Other]: BREATHING_PRACTICES_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.PhysicalFitness,
        coursesList: {
            [Gender.Female]: MEDITATION_LIST_FEMALE,
            [Gender.Male]: MEDITATION_LIST_MALE,
            [Gender.Other]: MEDITATION_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.EmotionalWellBeing,
        coursesList: {
            [Gender.Female]: PHYSICAL_EXERCISES_QUIZ_LIST_FEMALE,
            [Gender.Male]: PHYSICAL_EXERCISES_QUIZ_LIST_MALE,
            [Gender.Other]: PHYSICAL_EXERCISES_QUIZ_LIST_FEMALE,
        },
    },
    {
        categoryName: CourseCategories.AllOfTheAbove,
        coursesList: {
            [Gender.Female]: HEALTHY_NUTRITION_LIST_FEMALE,
            [Gender.Male]: HEALTHY_NUTRITION_LIST_MALE,
            [Gender.Other]: HEALTHY_NUTRITION_LIST_FEMALE,
        },
    },
];
