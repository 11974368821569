import { atom } from 'jotai';

import { EventTypes, QuizOptionComponents, QuizType } from '../types/event-quiz-types';
import { CourseData } from '@/entities/common/model/courses.types';

export enum RedirectQuizType {
    Stripe = 'stripe',
    Onboarding = 'onboarding',
    BannerDashboad = 'banner_dashboard',
    Mailchimp = 'mailchimp',
    Whatsapp = 'whatsapp',
}

interface QuizAtomProps {
    type: QuizType;
    options: {
        components?: QuizOptionComponents[];
        redirect: RedirectQuizType;
        actionSubTypeMnlt: string; // приходят со страницы
        actionSubTypeMnltYear: string | null; // приходят со страницы
        actionFirstStepSubTypeMnlt: string; // приходят со страницы
        eventType: EventTypes;
        activities?: any;
    };
}

export const quizSettingAtom = atom<QuizAtomProps | null>(null);

interface CourseOfQuizProps {
    title?: string | null;
    slug?: string | null;
    id?: string | null;
}

export const courseOfQuizAtom = atom<CourseOfQuizProps | null>(null);

interface PlanOfCourseOfQuizProps {
    price?: string | null;
    trialDays?: string | null;
}

export const planOfCourseOfQuizAtom = atom<PlanOfCourseOfQuizProps | null>(null);

export const selectedCoursesFullDataAtom = atom<CourseData[] | null>(null);
