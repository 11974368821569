import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
    my: 5,
    opacity: 1,
    borderStyle: 'dashed',
    borderColor: 'gray.425',
});

const dividerTheme = defineStyleConfig({
    variants: {
        'green-border': baseStyle,
    },
    defaultProps: {
        variant: 'green-border',
    },
});

export { dividerTheme };
