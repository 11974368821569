import { Box, BoxProps, Flex, Image, Text } from '@chakra-ui/react';

export default function JoinThePeople({ styles }: { styles?: BoxProps }) {
    return (
        <Box
            pt={{ base: 4, md: 7.5 }}
            pb={{ base: 6, md: 7.5 }}
            mx={{ base: -5, md: 0 }}
            px={{ base: 5, md: 7.5 }}
            overflow="hidden"
            bgColor="#f6f8fa"
            {...styles}
        >
            <Box
                mb={2.5}
                textAlign="center"
            >
                <Box
                    fontSize="19px"
                    fontWeight={700}
                >
                    Secure Your Discount Now
                </Box>
                <Box fontWeight={500}>No Final Decision Needed</Box>
            </Box>

            <Box
                mb={5}
                textAlign={{ md: 'center' }}
            >
                Give it a try and <b>join the 26,541 people</b> already exercising with us today. Enjoy every moment of
                your journey!
            </Box>

            <Flex
                gap={2}
                justify="center"
                mb={5}
            >
                <Image
                    src="/img/promo/people/exercise/man-yoga-1.gif"
                    alt="Join the People"
                    borderRadius="10px"
                    h="130px"
                />
                <Image
                    src="/img/promo/people/exercise/woman-stretching-1.gif"
                    alt="Join the People"
                    borderRadius="10px"
                    h="130px"
                />
                <Image
                    src="/img/promo/people/exercise/woman-stretching-2.gif"
                    alt="Join the People"
                    borderRadius="10px"
                    h="130px"
                />
                <Image
                    src="/img/promo/people/exercise/woman-yoga-1.gif"
                    alt="Join the People"
                    borderRadius="10px"
                    h="130px"
                />
                <Image
                    src="/img/promo/people/exercise/woman-yoga-2.gif"
                    alt="Join the People"
                    borderRadius="10px"
                    h="130px"
                />
            </Flex>

            <Text mb={0}>
                We support those who are ready to enjoy life, day by day. You don’t have to say “yes” today—just say
                “maybe.” Give it a try, and if you use our programs, and aren’t satisfied with the results, we’ll refund
                your money with our 30-Day Money-Back Guarantee.
            </Text>
        </Box>
    );
}
