import { Box, CheckboxProps, Flex, FormLabel, FormLabelProps, Text, useCheckbox } from '@chakra-ui/react';

import { CheckboxData } from '@/shared/models';

interface CustomCheckboxProps {
    checkboxProps: CheckboxProps;
    checkboxData?: CheckboxData;
    isInvalid?: boolean;
    styles?: FormLabelProps;
}

export default function CustomCheckboxSquare({ checkboxProps, checkboxData, isInvalid, styles }: CustomCheckboxProps) {
    const { state, getInputProps, getCheckboxProps, htmlProps } = useCheckbox(checkboxProps);

    return (
        <FormLabel
            cursor="pointer"
            m={0}
            _hover={{ opacity: 0.7 }}
            px={{ base: 5, md: 6 }}
            py={3}
            bgColor="transparent"
            border="1px solid"
            borderColor={isInvalid ? 'red.400' : 'rgba(0, 0, 0, 0.2)'}
            borderRadius="8px"
            {...styles}
            {...htmlProps}
        >
            <input
                {...getInputProps()}
                hidden
            />

            <Flex
                {...getCheckboxProps()}
                align="center"
                gap={2}
            >
                <Box
                    pos="relative"
                    flexShrink={0}
                    w={{ base: '16px', md: '20px' }}
                    h={{ base: '16px', md: '20px' }}
                    border="2px solid"
                    borderColor="green.400"
                    borderRadius="2px"
                >
                    {state.isChecked && (
                        <Box
                            pos="absolute"
                            top={{ base: '2px', md: '3px' }}
                            left={{ base: '2px', md: '3px' }}
                            w={{ base: '8px', md: '10px' }}
                            h={{ base: '8px', md: '10px' }}
                            bgColor="green.400"
                            borderRadius="2px"
                        />
                    )}
                </Box>
                <Text
                    as="span"
                    fontSize={{ base: '13px', md: '16px' }}
                    fontWeight={400}
                >
                    {checkboxData && checkboxData.label}
                </Text>
            </Flex>
        </FormLabel>
    );
}
