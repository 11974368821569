/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useState } from 'react';
import { PaymentElement, useStripe, useElements, ExpressCheckoutElement } from '@stripe/react-stripe-js';
import { Box, Button, Text } from '@chakra-ui/react';
import { RiShoppingCartLine } from 'react-icons/ri';

import { useAtomValue } from 'jotai';
import { emailForStripeAtom } from '@/features/checkout/checkout-page/model/checkout-store';

export default function StripeCard() {
    const stripe = useStripe();
    const elements = useElements();
    const email = useAtomValue(emailForStripeAtom);

    const [message, setMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!email?.email) {
            setMessage('Enter your email.');
            return;
        }

        const isValid = await email?.validate();

        if (!isValid) {
            setMessage('Enter valid email.');
            return;
        }

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        // @ts-expect-error
        if (typeof window.fbq === 'function') {
            // @ts-expect-error
            window.fbq('track', 'InitiateCheckout');
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // return_url: 'http://localhost:3000/complete',
                return_url: `${window.location.origin}/checkout-payment-success`,
                payment_method_data: {
                    billing_details: {
                        email: email.email,
                    },
                },
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message as string);
        } else {
            setMessage('An unexpected error occurred.');
        }

        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box minH="230px">
                <ExpressCheckoutElement
                    options={{ paymentMethods: { applePay: 'always' } }}
                    onConfirm={handleSubmit}
                />
                <PaymentElement id="payment-element" />
            </Box>

            <Button
                type="submit"
                loadingText="Loading"
                isLoading={isLoading || !stripe || !elements}
                display="flex"
                gap={2}
                mt={5}
                mx="auto"
            >
                <Box
                    as={RiShoppingCartLine}
                    w="18px"
                    h="18px"
                />
                Complete purchase
            </Button>

            {message && (
                <Text
                    mt={2}
                    color="red.500"
                    textAlign="center"
                >
                    {message}
                </Text>
            )}
        </form>
    );
}
