import { Box, BoxProps, Button, Flex, HStack, Img, Text } from '@chakra-ui/react';

export default function CardCartCourse({
    courseData,
    onCourseBtnClick,
    styles,
}: {
    courseData: { imgSrc: string; title: string; courseId: number; price: number };
    onCourseBtnClick: (course: any) => void;
    styles?: BoxProps;
}) {
    return (
        <Flex
            flexDir="column"
            gap={2}
            {...styles}
        >
            <Img
                src={courseData.imgSrc}
                alt="course-img"
                w="100%"
                borderRadius="xl"
            />

            <Box>
                <Text
                    mb={0}
                    fontSize={{ base: 'sm', md: 'sm2' }}
                    noOfLines={2}
                >
                    {courseData.title}
                </Text>

                <HStack fontSize={{ base: 'sm3', md: 'md' }}>
                    <Text
                        as="span"
                        fontSize={{ md: 'xl' }}
                        fontWeight={500}
                    >
                        $ {courseData.price}
                    </Text>
                    <Text
                        as="span"
                        textDecoration="line-through"
                        color="gray.500"
                    >
                        $290
                    </Text>
                </HStack>
            </Box>

            <Button
                onClick={() => onCourseBtnClick(courseData)}
                py={2}
                px={5}
                fontWeight={500}
            >
                Add to order
            </Button>
        </Flex>
    );
}
