import { Button } from '@chakra-ui/react';
import { RiArrowLeftSLine } from 'react-icons/ri';

import { capitalizeFirstLetter, fetchData, METHODS } from '@/shared/lib';
import { APP_BRAND } from '@/common/constants/brand';
import { isCheckboxesQuiz, QuizGroup, QuizGroupType } from '../types/quiz-list-types';
import { COURSE_CATEGORIES_QUIZ } from './course-categories-list';
import { COURSE_QUIZ_LIST_AGE, COURSE_QUIZ_LIST_TIME } from './radio-list';
import { EventNames } from '@/entities/quizes/quiz/model/types/event-quiz-types';
import CustomQuiz from '../../ui/components/CustomQuiz';
import PictureQuizSlide from '../../ui/components/PictureQuizSlide';
import QuizStepEmail from '../../ui/components/QuizStepEmail';
import { ALL_COURSES_LIST } from './courses-by-category-lists';
import { URLS } from '@/common/constants/global';

export const QUIZ_LANDING: QuizGroup[] = [
    {
        type: QuizGroupType.DefaultSingle,
        title: `Unlock the Superpower of Your Voice with\n300 000+ students of ${capitalizeFirstLetter(APP_BRAND)}`,
        subTitle: 'Choose your age to customize learning',
        quizList: COURSE_QUIZ_LIST_AGE,
        eventName: EventNames.Age,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            const onClickOption = async (formData: any) => {
                try {
                    await fetchData(URLS.UPDATE_PROFILE, {
                        method: METHODS.POST,
                        body: JSON.stringify({
                            age: formData.formData.radio,
                        }),
                    });
                } finally {
                    onNextBtnClick(formData);
                }
            };

            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onClickOption}
                    styles={{
                        position: 'relative',
                        maxW: 'container.sm3',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.CategorySingle,
        title: `What Brings You Here Today?`,
        quizList: COURSE_CATEGORIES_QUIZ,
        eventName: EventNames.ChooseCategory,
        categoryList: ALL_COURSES_LIST,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: 'container.sm3',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.Picture,
        isNextStep: true,
        title: 'Unlock Your Potential Today',
        imgSrc: 'promo/people/portrait-young-woman-exercising-home.png',
        imgDesc: `Did You Know? Unlocking membership, you get access not only to the Voice Program but also to 200+ best-selling courses from world-class instructors to transform your life to be healthier and happier every day!`,
        eventName: EventNames.UnlockPotential,
        Component: ({ currentQuiz, onNextBtnClick, onPrevBtnClick }) => {
            return (
                <PictureQuizSlide
                    slideData={currentQuiz}
                    NextBtnEl={() => (
                        <Button
                            width="100%"
                            height="45px"
                            onClick={onNextBtnClick}
                        >
                            Next
                        </Button>
                    )}
                    PrevBtnEl={() => (
                        <Button
                            variant="pagination"
                            h="45px"
                            onClick={onPrevBtnClick}
                        >
                            <RiArrowLeftSLine />
                        </Button>
                    )}
                    styles={{
                        position: 'relative',
                        maxW: 'container.sm3',
                        px: { base: 5, md: 15 },
                        py: { base: 7.5, md: 15 },
                        borderRadius: 'xl',
                        mx: { base: -5, md: 'auto' },
                        bg: 'gray.300',
                    }}
                />
            );
        },
    },
    {
        type: QuizGroupType.DefaultSingle,
        title: 'How much time do you want to spend learning every week?',
        quizList: COURSE_QUIZ_LIST_TIME,
        eventName: EventNames.HowMuchTime,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: 'container.sm3',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.Picture,
        isNextStep: true,
        title: "That's great, you can complete a class in two weeks!",
        imgSrc: 'promo/people/singer-lady.jpg',
        imgDesc: 'Watch 15 minute lessons at your own pace, in any order.',
        eventName: EventNames.ThatGreat,
        Component: ({ currentQuiz, onNextBtnClick, onPrevBtnClick }) => {
            return (
                <PictureQuizSlide
                    slideData={currentQuiz}
                    NextBtnEl={() => (
                        <Button
                            width="100%"
                            height="45px"
                            onClick={onNextBtnClick}
                        >
                            Next
                        </Button>
                    )}
                    PrevBtnEl={() => (
                        <Button
                            variant="pagination"
                            h="45px"
                            onClick={onPrevBtnClick}
                        >
                            <RiArrowLeftSLine />
                        </Button>
                    )}
                    styles={{
                        position: 'relative',
                        maxW: 'container.sm3',
                        px: { base: 5, md: 15 },
                        py: { base: 7.5, md: 15 },
                        borderRadius: 'xl',
                        mx: { base: -5, md: 'auto' },
                        bg: 'gray.300',
                    }}
                />
            );
        },
    },
    {
        type: QuizGroupType.Other,
        title: 'Check email',
        eventName: EventNames.CheckEmail,
        Component: () => {
            return <QuizStepEmail />;
        },
    },
];
