import { QuizType } from './types/event-quiz-types';

export const BottomTextMap: Record<QuizType, string> = {
    [QuizType.Landing]: 'Answer a Few Questions and Get Access To the Courses',
    [QuizType.Default]: 'Answer a Few Questions and Get 7 Days of Free Access',
    [QuizType.DefaultCheckEmail]: 'Answer a Few Questions to Access the Learning Platform',
};

export const PromoAlertTextMap: Record<QuizType, string> = {
    [QuizType.Landing]: '',
    [QuizType.Default]: 'Keep this page opened',
    [QuizType.DefaultCheckEmail]: 'Your order is not complete yet. Keep this page opened.',
};
