export default function Blanket(props: any) {
    return (
        <svg
            viewBox="0 0 32 32"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M27.152 6.49h-1.455v-.951c0-.504-.205-.988-.569-1.345a1.961 1.961 0 00-1.37-.557H6.303A4.903 4.903 0 002.876 5.03a4.715 4.715 0 00-1.421 3.361v15.217a4.715 4.715 0 001.421 3.36 4.903 4.903 0 003.427 1.395h20.849a3.432 3.432 0 002.398-.976 3.3 3.3 0 00.995-2.353V9.82a3.3 3.3 0 00-.995-2.353 3.432 3.432 0 00-2.398-.976zm-2.425-.951v13.579a1.945 1.945 0 00-.97-.264h-1.939V4.588h1.94c.257 0 .503.1.685.279.182.178.284.42.284.672zM17.94 18.853V4.588h2.91v14.265h-2.91zM2.424 8.393a3.772 3.772 0 011.138-2.689 3.922 3.922 0 012.741-1.115H16.97v14.266H6.303c-.754 0-1.497.174-2.17.506a4.818 4.818 0 00-1.709 1.408V8.392zm24.728 19.02H6.303c-1.029 0-2.015-.4-2.743-1.113a3.767 3.767 0 01-1.136-2.69c0-1.01.409-1.977 1.136-2.69a3.917 3.917 0 012.743-1.114h17.455c.257 0 .503.1.685.278a.942.942 0 010 1.345.98.98 0 01-.685.279H6.303c-.514 0-1.008.2-1.371.557-.364.357-.568.84-.568 1.345 0 .504.204.988.568 1.345.363.356.857.557 1.371.557h20.849a.49.49 0 00.342-.14.471.471 0 000-.672.49.49 0 00-.342-.14H6.303a.98.98 0 01-.686-.278.942.942 0 010-1.345.98.98 0 01.686-.278h20.849c.642 0 1.26.25 1.714.696.454.446.71 1.05.71 1.681 0 .63-.256 1.236-.71 1.682a2.45 2.45 0 01-1.714.696zm0-5.705h-1.715a1.86 1.86 0 00.26-.951V7.44h1.455c.642 0 1.258.251 1.713.697.454.446.71 1.05.71 1.68V22.71a3.386 3.386 0 00-1.108-.742 3.44 3.44 0 00-1.315-.261z" />
        </svg>
    );
}
