import { CheckboxData } from '@/shared/models';

export enum IssueTypes {
    VIDEO_NOT_SHOWING = 'video_not_showing',
    AUDIO_WORKS_NO_VIDEO = 'audio_no_video',
    POOR_QUALITY = 'poor_quality',
    OTHER = 'other_issue',
    TEXT_OTHER = 'text_other',
}

export const troubleCheckboxes: CheckboxData[] = [
    {
        label: 'Video Not Showing',
        value: IssueTypes.VIDEO_NOT_SHOWING,
    },
    {
        label: 'Audio Works, No Video',
        value: IssueTypes.AUDIO_WORKS_NO_VIDEO,
    },
    {
        label: 'Poor Video or Audio Quality',
        value: IssueTypes.POOR_QUALITY,
    },
];
