import { useAtomValue } from 'jotai';
import { Button, Portal, Text, useBoolean, useDisclosure, VStack } from '@chakra-ui/react';


import { EventCard, EventCardDate } from '@/entities/socials/events/event-card';
import { EventData } from '@/entities/events/event-data';
import { userAtom } from '@/shared/store';
import { ToTheEventModal } from '@/entities/modals';
import { APP_BRAND } from '@/common/constants/brand';

interface SlidesProps {
    eventData: EventData;
}

const Slides = ({ eventData }: SlidesProps) => {
    const user = useAtomValue(userAtom);
    const [isSubmitted, setIsSubmitted] = useBoolean(false);

    const { isOpen: isSubModalOpen, onOpen: onSubModalOpen, onClose: onSubModalClose } = useDisclosure();

    return (
        <EventCardDate
            eventData={eventData}
            styles={{
                minH: {
                    base: '454px',
                    md: '478px',
                },
                w: {
                    base: '290px',
                    md: '376px',
                },
            }}
        >
            <EventCard
                eventData={eventData}
                styles={{
                    h: 'auto',
                    flex: 1,
                    mt: -6,
                }}
            >
                <Button
                    variant="outline-white-rounded"
                    display="flex"
                    minW={{ base: '140px', md: '220px' }}
                    px={6}
                    m="auto"
                    onClick={onSubModalOpen}
                >
                    Learn more
                </Button>
                <Portal>
                    <ToTheEventModal
                        eventData={eventData}
                        isOpen={isSubModalOpen}
                        onClose={onSubModalClose}
                        BtnElement={() => {
                            return (
                                <VStack>
                                    <Text
                                        mb={0}
                                        color="gray.500"
                                        fontSize="sm2"
                                    >
                                        
                                            Available in{' '}
                                            <Text
                                                as="span"
                                                textTransform="capitalize"
                                            >
                                                {APP_BRAND}
                                            </Text>{' '}
                                            Membership
                                        
                                    </Text>
                                </VStack>
                            );
                        }}
                    />
                </Portal>
            </EventCard>
        </EventCardDate>
    );
};

export default Slides;
