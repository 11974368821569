import { Box, BoxProps, HStack, Text } from '@chakra-ui/react';


import { IconMap, IconNames } from '@/shared/ui';

export default function OnAirStatus({ styles }: { styles?: BoxProps }) {
    return (
        <HStack
            color="red.500"
            {...styles}
        >
            <Box
                as={IconMap[IconNames.Circle]}
                className="pulse"
                borderRadius="50%"
            />
            <Text
                as="span"
                fontSize="sm2"
                transform="translateY(1px)"
            >
                LIVE
            </Text>
        </HStack>
    );
}
