import { APP_BRAND } from '@/common/constants/brand';
import { CardReviewProps } from '@/entities/cards/cards-review';
import { capitalizeFirstLetter } from '@/shared/lib';

export const DEFAULT_REVIEW_DATA: CardReviewProps[] = [
    {
        authorName: 'Melisa',
        photo: '/img/reviews/melissa-1.png',
        rating: 5,
        text: `Now I'm like in a musical - I sing when I'm sad, happy or good. I did not expect that singing so relieves stress and helps to work through emotions. Now singing is my obligatory ritual after a working day. Helps relieve fatigue and unnecessary irritation and even burns calories)). Oh, and now I'm a karaoke star. It's so nice when friends send you to a vocal show after Adele's karaoke song.`,
    },
    {
        authorName: 'Jeep',
        photo: '/img/reviews/melissa-2.png',
        rating: 5,
        text: `Girls try it! Plastic strip, dancehall, high heels, dance therapy and much more in a subscription to all courses from  ${capitalizeFirstLetter(APP_BRAND)}. I have so much energy, confidence, femininity and sexuality. I am delighted! Dancing is the best antidepressant`,
    },
    {
        authorName: 'Cora C',
        photo: '/img/reviews/cora.png',
        rating: 5,
        text: `Orgasms, love and a happy partner are waiting for you. I spent the whole weekend with my husband in bed. We were able to get closer not only physically, but also to talk mentally. Our relationship is now like in the first month. Passion, tenderness and interest in each other. Thank God I found a  ${capitalizeFirstLetter(APP_BRAND)} subscription. Next weekend I will start a strip dance course. I will please my husband with feminine dances.`,
    },
];

export const LONG_REVIEW_DATA: CardReviewProps[] = [
    {
        authorName: 'Cora C',
        photo: '/img/reviews/cora.png',
        rating: 5,
        text: `When I first came across ${capitalizeFirstLetter(APP_BRAND)}, the variety of courses intrigued me. Despite the price, I decided to give the trial a shot. That decision has led to some of the most productive and enriching months of my life. Their unique brain exercises, yoga, and meditation sessions have not only boosted my energy levels but also improved my overall well-being. It's safe to say that a ${capitalizeFirstLetter(APP_BRAND)} subscription is one of the best investments I've made in myself.`,
    },
    {
        authorName: 'Melisa',
        photo: '/img/reviews/melissa-1.png',
        rating: 5,
        text: `Initially skeptical, I signed up for a 7-day trial with ${capitalizeFirstLetter(APP_BRAND)}. The experience has been transformative, leading me to start yoga and dance, and explore new brain exercises. After a month, my energy levels and mood have significantly improved, bringing vibrant changes to my life.`,
    },
    {
        authorName: 'Jeep',
        photo: '/img/reviews/cora.png',
        rating: 5,
        text: `My relationship was salvaged by a ${capitalizeFirstLetter(APP_BRAND)} subscription. I accidentally discovered it and was drawn to their diverse courses. After a two-month subscription filled with engaging content, my relationship woes and constant irritability have dissipated. The courses have enhanced my energy, mood and even added spark to my relationship, notably the couples massage course.`,
    },
    {
        authorName: 'Juliette',
        photo: '/img/reviews/juliette.png',
        rating: 5,
        text: 'The courses are exhilarating! Initially, the price tag seemed steep, but considering it amounts to a daily cup of coffee, the boost in energy and mood I receive is beyond comparison.',
    },
    {
        authorName: 'Antony',
        photo: '/img/reviews/antony.png',
        rating: 5,
        text: `${capitalizeFirstLetter(APP_BRAND)} is the best personal growth company out there and I've grown so much from the people I have met through their community and their courses.`,
    },
    {
        authorName: 'Rui',
        photo: '/img/reviews/rui.png',
        rating: 5,
        text: `It's incredible, but thanks to the programs presented at ${capitalizeFirstLetter(APP_BRAND)}, I have completely changed my line of work, and somehow, magically, people who share my new hobbies have begun to appear in my life. It all started with a course on brain boosting and memory improvement. I bought the course, started taking it and really saw the results: I concentrated more and remembered new information more easily. I wanted to work more on my condition and deepen my understanding of how the brain works. ${capitalizeFirstLetter(APP_BRAND)} had another course available, but it wasn't worth the extra cost, so I signed up for the full platform. This is how exercise, body therapy and work on my inner state came into my life. I am in my sixth month of learning with the platform, and I can see how far I have come in my self-improvement, how my capabilities have opened up, how my thinking and my environment have changed. I am grateful to myself and to ${capitalizeFirstLetter(APP_BRAND)} for the opportunities to improve`,
    },
    {
        authorName: 'Anna',
        photo: '/img/reviews/anna.png',
        rating: 5,
        text: `A great platform for people who want to get more for less. ${capitalizeFirstLetter(APP_BRAND)} offers limitless opportunities to learn, find a new hobby or passion, and make modern education accessible to everyone. I really like their approach, where you can learn from a variety of experts in your field for a flat fee and choose what's right for you, without the fear that the course will be useless or not up to expectations. If you don't like a program, you can easily start another one. If the study of stretching bores you, you can move on to dancing, and you will also achieve great results for your body. Thanks to this, training is neither boring nor interesting, motivation is not lost, and learning is not a chore, but rather an exciting journey towards a better version of oneself.`,
    },
    {
        authorName: 'Mia',
        photo: '/img/reviews/mia.png',
        rating: 5,
        text: `Sport has always been difficult for me, but I always knew it is important for health, body and simply to feel good every day. For a long time, I couldn't understand what I want to do and always put off sports. When I came across the ${capitalizeFirstLetter(APP_BRAND)}, I realized I did not risk anything and I could try different types of physical activity at once. So I did. I tried everything and found the perfect combination for me: Hatha yoga in the morning and dancing lessons on the weekends. Finally, sport has become a part of my life that I enjoy. And thankfully to ${capitalizeFirstLetter(APP_BRAND)} subscription I found many interesting courses for myself and now I try new ones every week! Highly recommended!`,
    },
    {
        authorName: 'Anastasia',
        photo: '/img/reviews/anastasia.png',
        rating: 5,
        text: `In the last two years, stress and anxiety did not leave me, I was very exhausted and did not know what to do with it. I signed up to ${capitalizeFirstLetter(APP_BRAND)} for Hatha yoga courses, because I knew yoga is a great solution in dealing with stress. I discovered many other wonderful courses and with opportunity to try them all, I came across the Neurohacking course from Tiana and then the Biohacking course. These courses won me over, my stress levels decreased, my interest in life and the desire to try new things and create returned to me. Every day ever since, I do neuropractices, yoga and planning to try other things with the ${capitalizeFirstLetter(APP_BRAND)}.`,
    },
];
