import { Box, Button, Text } from '@chakra-ui/react';

export default function SorryToSeeYouGoScreen({ onNext }: { onNext: () => void }) {
    return (
        <Box
            px={{ base: 6, md: 10 }}
            py={10}
        >
            <Text
                textStyle="lg"
                textAlign={'center'}
            >
                Your subscription has been canceled
            </Text>

            <Text
                textStyle="md5"
                fontWeight={700}
                mb={0}
            >
                We’re sorry to see you go!
            </Text>

            <Text
                textStyle="sm"
                mb={6}
            >
                The courses you’ve purchased will remain available in your account, so you can continue learning at your
                own pace. If you ever decide to return, we’ll be here to help you explore more courses and features.
            </Text>

            <Button
                onClick={onNext}
                w="100%"
            >
                Okay
            </Button>
        </Box>
    );
}
