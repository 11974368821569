import { StripeCard } from '@/entities/checkout/stripe-card';
import { Elements } from '@stripe/react-stripe-js';
import { Center, Spinner } from '@chakra-ui/react';

import useStripeCheckout from '../../model/useStripeCheckout';

export default function CheckouStripeElements() {
    const { stripeInstanse, clientSecret, options } = useStripeCheckout();

    return (
        <>
            {clientSecret ? (
                <Elements
                    stripe={stripeInstanse}
                    options={{ ...options, clientSecret }}
                >
                    <StripeCard />
                </Elements>
            ) : (
                <Center
                    minH="285px"
                    w="100%"
                >
                    <Spinner />
                </Center>
            )}
        </>
    );
}
