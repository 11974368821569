import { APP_BRAND } from '@/common/constants/brand';
import {
    CourseQuizBuyBtn,
    CourseQuizBuyBtnCheckboxPaywall,
    CourseQuizBuyBtnPublic,
    CourseQuizBuyBtnPublicPaywall,
    CourseQuizOnboardingBuyBtn,
    CourseQuizOnboardingBuyBtnCheckboxed,
    CourseQuizOnboardingBuyBtnYear,
} from '@/entities/btns/course-quiz-buy-btn';
import { CourseCategories } from '@/entities/quizes/quiz/model/lists/course-categories-list';
import { QuizType } from '@/entities/quizes/quiz';
import { capitalizeFirstLetter } from '@/shared/lib';
import CourseQuizOnboardingBuyBtnCheckboxedHardText from '@/entities/btns/course-quiz-buy-btn/ui/CourseQuizOnboardingBuyBtnCheckboxedHardText';
import CourseQuizOnboardingBuyBtnCheckButtonsHardText from '@/entities/btns/course-quiz-buy-btn/ui/CourseQuizOnboardingBuyBtnCheckButtonsHardText';
import CourseQuizOnboardingBuyBtnCheckboxedHardText1dollar from '@/entities/btns/course-quiz-buy-btn/ui/CourseQuizOnboardingBuyBtnCheckboxedHardText1dollar';
import CourseQuizOnboardingBuyBtnCheckButtonsHardTextT1 from '@/entities/btns/course-quiz-buy-btn/ui/CourseQuizOnboardingBuyBtnCheckButtonsHardTextT1';

export const FEATURES_LIST = [
    'All 200+ classes for your well-being goals, valued at $160 000+',
    'Daily live sessions with our best authors',
    'Bonus class guides & content',
    'Challenges to help you achieve your goals easily and consistently',
];

export const FEATURES_LIST_2 = [
    '2,600+ video sessions led by the world’s top experts (10–60 mins each)',
    '200+ diverse programs: yoga, dance, meditation, massage, brain exercises, singing, and more',
    'A total program value exceeding $160,000',
    'Daily live sessions with leading experts',
    '24/7 support & monthly new releases',
    '10 years of proven results and over 500 000 satisfied members',
];

export enum CourseQuizBuyBtnsPlan {
    YearPlan = 'year_plan',
    YearPlanDiscount = 'year_plan_discount',
    LifetimePlan = 'lifetime_plan',
    LifetimePlanDiscount = 'lifetime_plan_discount',
    ThirtyDaysForNine = '30_days_for_9',
    ThirtyDaysForNine49month = '30_days_for_9_then_49_month',
    ContinueWithTrial = 'continue_with_trial',
    ContinueWithTrialDiscount = 'continue_with_trial_discount',
}

export const CATEGORY_TEXT: any = {
    [CourseCategories.VoiceMastery]: `<b>Voice Mastery:</b> The classes in the ${capitalizeFirstLetter(APP_BRAND)} membership are designed to enhance your vocal strength, control, and clarity, helping you achieve a powerful and resonant voice. Whether you're looking to improve your singing, public speaking, or overall vocal presence, you'll develop the skills to command any audience with confidence and ease.`,
    [CourseCategories.LoveDevelopBody]: `<b>Love & Develop Body:</b> The classes in the ${capitalizeFirstLetter(APP_BRAND)} membership are designed to help you cultivate a deep connection with your body while enhancing its strength, flexibility, and overall well-being. Whether you're focused on building a positive body image, improving physical fitness, or nurturing self-love, these courses will guide you in developing a harmonious relationship with your body.`,
    [CourseCategories.StrengthenRelationships]: `<b>Strengthen Relationship:</b> The classes in the ${capitalizeFirstLetter(APP_BRAND)} membership are designed to help you build deeper connections, improve communication, and foster a stronger bond with your partner or loved ones. Whether you're looking to enhance intimacy, resolve conflicts, or nurture a more supportive relationship, these courses will provide you with the tools and insights to create lasting, meaningful relationships.`,
    [CourseCategories.BoostDailyEnergy]: `<b>Boost Daily Energy:</b> The classes in the ${capitalizeFirstLetter(APP_BRAND)} membership are designed to help you elevate your daily energy levels through mindful practices, movement, and nutrition. Whether you're looking to increase vitality, overcome fatigue, or maintain a steady flow of energy throughout your day, these courses will equip you with the strategies to feel more energized, focused, and vibrant.`,
    [CourseCategories.CognitiveSkills]: `<b>Cognitive Mastery:</b> The classes in the ${capitalizeFirstLetter(APP_BRAND)} membership are crafted to improve your focus, memory, and cognitive flexibility. Whether you're aiming to enhance your problem-solving skills, increase mental clarity, or develop better decision-making abilities, you'll gain the cognitive tools to approach any situation with confidence and precision.`,
    [CourseCategories.SomethingElse]: `<b>Achieve Your Best Self:</b> The classes in the ${capitalizeFirstLetter(APP_BRAND)} membership are tailored to support you in reaching your fullest potential, no matter your specific focus. Whether you're looking to improve your health, deepen your relationships, enhance your personal growth, or boost your overall well-being, these courses offer the tools and guidance you need to create lasting positive change in your life.`,
};

export const CourseQuizPriceTextsMap: any = {
    [QuizType.Landing]: (price: string) => '7 days for $1, then just $39/month',
    [QuizType.Default]: (days: string, price: string) => `${days} days for free, then just $${price}/month`,
    [QuizType.DefaultCheckEmail]: (days: string, price: string) => `${days} days for free, then just $${price}/month`,
    [QuizType.Longevity]: (days: string, price: string) => `${days} days for free, then just $${price}/month`,
    [QuizType.LongevityLifestyle]: (days: string, price: string) => `${days} days for free, then just $${price}/month`,
    [QuizType.DefaultCheckEmailYear]: (days: string, price: string) =>
        `${days} days for free, then just $0,52/day (one payment of $${price})`,
    [QuizType.PublicQuiz]: (days: string, price: string) => `${days} days for free, then $${price}/month`,
    [QuizType.PaywallQuiz]: (days: string, price: string) => `${days} days for free, then $${price}/month`,
    [QuizType.PaywallQuizMainPage]: (days: string, price: string) => `${days} days for free, then $${price}/month`,
    [QuizType.DefaultWithEmailCheckboxMonthly]: (days: string, price: string) =>
        `${days} days for free, then $${price}/month`,
    [QuizType.DefaultWithEmailCheckButtonsMonthly]: (days: string, price: string) =>
        `${days} days free, then just $2.99/day (billed montly at $${price})`,
    [QuizType.DefaultWithEmailCheckBoxMonthly1Trial]: (days: string, price: string) =>
        `${days} days for $1, then $${price}/month`,
    [QuizType.T1DefaultWithEmailCheckButtonsMonthly]: (days: string, price: string) =>
        `${days} days for $1, then $${price}/month`,
};

export const TEXT_FOR_FIXED_BLOCK: any = {
    [QuizType.Landing]: () => 'Start with 50% discount',
    [QuizType.Default]: (days: string) => `${days} days for free`,
    [QuizType.DefaultCheckEmail]: (days: string) => `${days} days for free`,
    [QuizType.Longevity]: (days: string) => `${days} days for free`,
    [QuizType.LongevityLifestyle]: (days: string) => `${days} days for free`,
    [QuizType.DefaultCheckEmailYear]: (days: string) => `${days} days for free`,
    [QuizType.PublicQuiz]: (days: string) => `${days} days for free`,
    [QuizType.PaywallQuiz]: (days: string) => `${days} days for free`,
    [QuizType.PaywallQuizMainPage]: (days: string) => `${days} days for free`,
    [QuizType.DefaultWithEmailCheckboxMonthly]: (days: string) => `${days} days for free`,
    [QuizType.DefaultWithEmailCheckButtonsMonthly]: (days: string) => `${days} days for free`,
    [QuizType.DefaultWithEmailCheckBoxMonthly1Trial]: (days: string) => `${days} days for $1`,
    [QuizType.T1DefaultWithEmailCheckButtonsMonthly]: (days: string) => `${days} days for $1`,
};

interface BtnElProps {
    isCheckboxed?: boolean;
    isTimed?: boolean;
    isSkipBtnShow?: boolean;
    isInsideStickyBox?: boolean;
    popupText?: string;
}

export const CourseQuizBtnElementsMap: any = {
    [QuizType.Landing]: ({ isTimed, popupText }: BtnElProps) => (
        <CourseQuizBuyBtn
            isTimed={isTimed}
            popupText={popupText}
        />
    ),
    [QuizType.Default]: ({ isCheckboxed, isSkipBtnShow }: BtnElProps) => (
        <CourseQuizOnboardingBuyBtnCheckboxed
            isCheckboxed={isCheckboxed}
            isSkipBtnShow={isSkipBtnShow}
        />
    ),
    [QuizType.DefaultCheckEmail]: ({ isCheckboxed, isSkipBtnShow }: BtnElProps) => (
        <CourseQuizOnboardingBuyBtnCheckboxed
            isCheckboxed={isCheckboxed}
            isSkipBtnShow={isSkipBtnShow}
        />
    ),
    [QuizType.Longevity]: ({ isCheckboxed, isSkipBtnShow }: BtnElProps) => (
        <CourseQuizOnboardingBuyBtnCheckboxed
            isCheckboxed={isCheckboxed}
            isSkipBtnShow={isSkipBtnShow}
        />
    ),
    [QuizType.LongevityLifestyle]: ({ isTimed, isSkipBtnShow }: BtnElProps) => (
        <CourseQuizOnboardingBuyBtn
            isTimed={isTimed}
            isSkipBtnShow={isSkipBtnShow}
        />
    ),
    [QuizType.DefaultCheckEmailYear]: ({ isTimed, isSkipBtnShow, isInsideStickyBox }: BtnElProps) => (
        <CourseQuizOnboardingBuyBtnYear
            isTimed={isTimed}
            isSkipBtnShow={isSkipBtnShow}
            isInsideStickyBox={isInsideStickyBox}
        />
    ),
    [QuizType.PublicQuiz]: () => <CourseQuizBuyBtnPublic />,
    [QuizType.PaywallQuiz]: () => <CourseQuizBuyBtnCheckboxPaywall />,
    [QuizType.PaywallQuizMainPage]: () => <CourseQuizBuyBtnPublicPaywall />,
    [QuizType.DefaultWithEmailCheckboxMonthly]: ({ isCheckboxed, isSkipBtnShow, isInsideStickyBox }: BtnElProps) => (
        <CourseQuizOnboardingBuyBtnCheckboxedHardText
            isCheckboxed={isCheckboxed}
            isSkipBtnShow={isSkipBtnShow}
            isInsideStickyBox={isInsideStickyBox}
        />
    ),
    [QuizType.DefaultWithEmailCheckButtonsMonthly]: ({ isTimed, isSkipBtnShow, isInsideStickyBox }: BtnElProps) => (
        <CourseQuizOnboardingBuyBtnCheckButtonsHardText
            isTimed={isTimed}
            isSkipBtnShow={isSkipBtnShow}
            isInsideStickyBox={isInsideStickyBox}
        />
    ),
    [QuizType.DefaultWithEmailCheckBoxMonthly1Trial]: ({
        isCheckboxed,
        isSkipBtnShow,
        isInsideStickyBox,
    }: BtnElProps) => (
        <CourseQuizOnboardingBuyBtnCheckboxedHardText1dollar
            isCheckboxed={isCheckboxed}
            isSkipBtnShow={isSkipBtnShow}
            isInsideStickyBox={isInsideStickyBox}
        />
    ),
    [QuizType.T1DefaultWithEmailCheckButtonsMonthly]: ({ isTimed, isSkipBtnShow, isInsideStickyBox }: BtnElProps) => (
        <CourseQuizOnboardingBuyBtnCheckButtonsHardTextT1
            isTimed={isTimed}
            isSkipBtnShow={isSkipBtnShow}
            isInsideStickyBox={isInsideStickyBox}
        />
    ),
};
