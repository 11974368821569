import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button, Text } from '@chakra-ui/react';

export default function CongratulationsModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
    return (
        <Modal
            variant="primary"
            scrollBehavior="inside"
            isOpen={isOpen}
            onClose={onClose}
            isCentered
        >
            <ModalOverlay />

            <ModalContent>
                <ModalBody>
                    <Text
                        textStyle="md2"
                        textAlign="center"
                    >
                        Congratulations!
                    </Text>
                </ModalBody>

                <ModalFooter justifyContent="center">
                    <Button onClick={onClose}>Go to journey!</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
