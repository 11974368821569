import { add, format } from 'date-fns';

export function getNextDateFromToday(withYear = false, formatYearString = 'MMMM dd, yyyy', days = 7) {
    const currentDate = new Date();

    const nextWeekDate = add(currentDate, { days: days });

    const formattedDate = format(nextWeekDate, withYear ? formatYearString : 'MMMM dd');

    return formattedDate;
}
