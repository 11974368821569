'use client';

import { useState, useEffect } from 'react';
import { useBoolean } from '@chakra-ui/react';

import { fetchData, METHODS } from '@/shared/lib';
import { CourseData } from '@/entities/common/model/courses.types';

export enum SearchType {
    Slug = 'slug',
    Id = 'id',
    Collection = 'collection',
    AuthorId = 'author_id',
}

const utmUser = typeof window !== 'undefined' && localStorage.getItem('utmUser');

const getCoursePath = (type: SearchType, arr: string[] | number[]) =>
    `/api/courses?${utmUser ? `${utmUser}&` : ''}expand=video_intro,price_addition_course,thumb_big,is_discount,is_purchased,course_card_discount,course_card_price,currency,is_watching,promo_blocks_resource,playlist_sections,sections&search[${type}]=${arr.join(',')}`;

export default function useCoursesListByValue(
    type: SearchType,
    arr: string[] | number[] | null,
    firstCourseId?: number,
) {
    const [coursesList, setCoursesList] = useState<CourseData[] | null>(null);
    const [isCoursesLoading, setIsCoursesLoading] = useBoolean(true);

    useEffect(() => {
        if (!coursesList) {
            getCourses();
        }
    }, []);

    async function getCourses() {
        if (!arr) {
            return null;
        }

        setIsCoursesLoading.on();

        const batchSize = 10; // Определение размера пакета
        const batches = Math.ceil(arr.length / batchSize); // Определение количества пакетов

        const allCourses: any = [];

        for (let i = 0; i < batches; i++) {
            const start = i * batchSize;
            const end = (i + 1) * batchSize;
            const batchArr = arr.slice(start, end);

            const { data } = await fetchData<any>(getCoursePath(type, batchArr), {
                method: METHODS.GET,
            });

            allCourses.push(...data);
        }

        if (firstCourseId && type === SearchType.Id) {
            moveElementToFrontByIdMutating(allCourses, firstCourseId);
        }

        setCoursesList(allCourses);

        setIsCoursesLoading.off();

        return allCourses;
    }

    return { coursesList, isCoursesLoading, getCourses };
}

function moveElementToFrontByIdMutating(array: CourseData[], id: number): void {
    // Найти индекс элемента с данным id
    const index = array.findIndex((item) => item.id === id);

    // Если элемент найден и не на первом месте
    if (index > 0) {
        // Вырезать элемент и вставить на первое место
        const [element] = array.splice(index, 1);
        array.unshift(element);
    }
}
