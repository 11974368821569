import { useEffect, useState } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { Portal, useDisclosure } from '@chakra-ui/react';

import { CongratulationsModal } from '@/entities/modals';
import { PaywallDefault } from '@/entities/promo/paywalls/paywall-default';
import { ActionSubType } from '@/features/subscription/create-sub';
import { paywallCourseFeatureList } from '@/entities/lists/model/paywall-feature-list';
import { paywallCourseName, paywallOpenStateAtom } from '../store';

export default function CsrPaywallWrapper({ actionSubType }: { actionSubType: ActionSubType }) {
    const [isPaywallOpen, setIsPaywallOpen] = useAtom(paywallOpenStateAtom);
    const courseName = useAtomValue(paywallCourseName);

    const [courseFeatureList, setCourseFeatureList] = useState(paywallCourseFeatureList);

    useEffect(() => {
        setCourseFeatureList((prevState) => {
            const updatedList = [...prevState];

            updatedList[0] = { ...updatedList[0], text: `<div><b>${courseName} Unlocked</b></div>` };
            return updatedList;
        });
    }, [courseName]);

    const { isOpen: isOpenCongrat, onOpen: onOpenCongrat } = useDisclosure();

    return (
        <Portal>
            <PaywallDefault
                subActionType={actionSubType}
                title="Unlock Unlimited Access to All Courses!"
                isOpen={isPaywallOpen}
                featureList={courseFeatureList}
                onClose={() => setIsPaywallOpen(false)}
                onCloseComplete={onOpenCongrat}
            />
            <CongratulationsModal
                isOpen={isOpenCongrat}
                onClose={() => location.reload()}
            />
        </Portal>
    );
}
