import { useEffect, useRef, useState } from 'react';
import { useDisclosure, Button, Portal, Text, VStack, Link, Box } from '@chakra-ui/react';

import { CourseCartModal } from '@/entities/modals/cart';
import { useSubFromMonolith } from '@/features/subscription/sub-from-monolith';
import { useAtomValue } from 'jotai';
import { planOfCourseOfQuizAtom, quizSettingAtom } from '@/entities/quizes/quiz/model/stores/quiz-store';
import { CurrentStepEventNames } from '@/entities/quizes/quiz';
import { userAtom } from '@/shared/store';
import { HELLO_EMAIL } from '@/common/constants/brand';
import { ActionSubType, useTrialDay } from '@/features/subscription/create-sub';
import { fetchData, getActivityByNameInArr, METHODS } from '@/shared/lib';
import { URLS } from '@/common/constants/global';
import { CourseQuizBuyBtnsPlan } from '@/entities/promo/classes-for-you/model/constants';
import TimedClassesForYouModalTimer from './modal/TimedClassesForYouModalTimer';

interface CourseQuizOnboardingBuyBtnProps {
    isTimed?: boolean;
    isInsideStickyBox?: boolean;
    isSkipBtnShow?: boolean;
}

export default function CourseQuizOnboardingBuyBtnYear({
    isTimed,
    isInsideStickyBox = false,
    isSkipBtnShow = true,
}: CourseQuizOnboardingBuyBtnProps) {
    // Если пользователь уже был на onboarding то перенаправим при скипе в dashboard
    const user = useAtomValue(userAtom);
    const isAlreadyOnboarded =
        user && user?.quiz_info.quiz_thank_page_step_history.includes(CurrentStepEventNames.QuizOnboardingPageStep);
    const skipRedirectLink = isAlreadyOnboarded ? '/client' : '/redirect-to-onboarding-page';
    // Показываем попап при первом посещении
    const isFirstTimeOnboarding = !user?.quiz_info.quiz_thank_page_step_history.includes(
        CurrentStepEventNames.QuizFinalPageStep,
    );

    const { isOpen: isOpenCartModal, onOpen: onOpenCartModal, onClose: onCloseCartModal } = useDisclosure();
    const { isOpen: isOpenSkipPopup, onOpen: onOpenSkipModal, onClose: onCloseSkipModal } = useDisclosure();

    const quizSetting = useAtomValue(quizSettingAtom);
    const {
        sendError,
        loadingText,
        isSubmitted,
        createSub: handleSignUp,
    } = useSubFromMonolith(
        getActivityByNameInArr<ActionSubType>(
            quizSetting?.options.activities,
            CourseQuizBuyBtnsPlan.ContinueWithTrial,
        )!,
    );
    const planOfCourseOfQuiz = useAtomValue(planOfCourseOfQuizAtom);
    const [isNotOpenPopup, setIsNotOpenPopup] = useState(false);
    const isNotTimedPopupRef = useRef(false);
    useTrialDay(planOfCourseOfQuiz?.trialDays);

    useEffect(() => {
        if (isNotOpenPopup) {
            isNotTimedPopupRef.current = true;
        }
    }, [isNotOpenPopup]);

    useEffect(() => {
        if (isTimed) {
            const checkPopupState = () => {
                if (!isNotTimedPopupRef.current) {
                    onOpenCartModal();
                }
            };

            const timerId = setTimeout(checkPopupState, 40000);

            return () => clearTimeout(timerId);
        }
    }, [isTimed]);

    const onLinkClick = async () => {
        async function fetchIsQuizYearSub() {
            await fetchData(URLS.QUIZ_THANK_PAGE_ADD_OPTIONAL_DETAILS, {
                method: METHODS.POST,
                body: JSON.stringify({
                    optional_details: {
                        is_quiz_year_sub: true,
                    },
                }),
            });
        }

        if (isFirstTimeOnboarding) {
            setIsNotOpenPopup(true);
            onOpenSkipModal();
            await fetchIsQuizYearSub();
        } else {
            window.location.pathname = skipRedirectLink;
        }
    };

    return (
        <>
            <VStack w={{ base: '100%', lg: '340px' }}>
                <Text
                    mb={0}
                    fontSize="14px"
                    color="gray.675"
                    textAlign="center"
                >
                    <b>{planOfCourseOfQuiz?.trialDays} days free</b>, then just $2.99/day (billed monthly at{' '}
                    <Text
                        as={'span'}
                        textDecor={'line-through'}
                    >
                        $129
                    </Text>{' '}
                    ${planOfCourseOfQuiz?.price})
                </Text>

                <Button
                    w="100%"
                    maxW="360px"
                    fontSize="sm2"
                    onClick={handleSignUp}
                    isLoading={isSubmitted}
                    loadingText={loadingText}
                >
                    Open access now
                </Button>

                {sendError && (
                    <Text
                        mb={0}
                        textAlign="center"
                        color="red.500"
                    >
                        {sendError}
                    </Text>
                )}

                {isSkipBtnShow && (
                    <Text
                        onClick={onLinkClick}
                        color="gray.500"
                        textDecor="underline"
                        cursor="pointer"
                    >
                        I’ll Pass and Lose the Savings
                    </Text>
                )}

                {!isInsideStickyBox && (
                    <Box
                        as="ul"
                        fontSize="12px"
                        color="gray.500"
                    >
                        <Box as="li">- By clicking the «Open Access Now» button, I agree to start my free trial.</Box>
                        <Box as="li">
                            - My card won’t be charged today. After the 7-day trial period, ${planOfCourseOfQuiz?.price}{' '}
                            will be automatically billed to my card.
                        </Box>
                        <Box as="li">
                            - The subscription will renew automatically each month until I cancel the trial or
                            subscription.
                        </Box>
                        <Box as="li">
                            - I can cancel my trial or subscription anytime through my account settings or by reaching
                            out to the support team at <Link href={`mailto:${HELLO_EMAIL}`}>{HELLO_EMAIL}</Link>.
                        </Box>
                    </Box>
                )}
            </VStack>

            <Portal>
                <CourseCartModal
                    isOpen={isOpenCartModal}
                    onClose={onCloseCartModal}
                >
                    <Text textStyle="md2">Let&#39;s Get Started!</Text>

                    <Text mb={7.5}>
                        Take advantage of our special offer: {planOfCourseOfQuiz?.trialDays} days free, then just $
                        {planOfCourseOfQuiz?.price && parseInt(planOfCourseOfQuiz.price, 10)}/month.
                    </Text>

                    <VStack>
                        <Button
                            w="100%"
                            maxW="360px"
                            onClick={handleSignUp}
                            isLoading={isSubmitted}
                            loadingText={loadingText}
                        >
                            OPEN ACCESS NOW
                        </Button>

                        {sendError && (
                            <Text
                                mb={0}
                                textAlign="center"
                                color="red.500"
                            >
                                {sendError}
                            </Text>
                        )}
                    </VStack>
                </CourseCartModal>

                <TimedClassesForYouModalTimer
                    isOpenSkipPopup={isOpenSkipPopup}
                    onCloseSkipModal={onCloseSkipModal}
                    isSkipBtnShow={isSkipBtnShow}
                    skipRedirectLink={skipRedirectLink}
                />
            </Portal>
        </>
    );
}
