import { QuizList } from '../types/quiz-list-types';

export enum CourseCategories {
    VoiceMastery = 'Voice Mastery',
    LoveDevelopBody = 'Love & Develop Body',
    StrengthenRelationships = 'Strengthen Relationships',
    BoostDailyEnergy = 'Boost Daily Energy',
    CognitiveSkills = 'Cognitive skills',
    SomethingElse = 'Something Else',

    Yoga = 'Yoga',
    BreathingPractices = 'Breathing practices',
    Meditation = 'Meditation',
    PhysicalExercises = 'Physical exercises',
    DancingSinging = 'Dancing and singing',
    HealthyNutrition = 'Healthy nutrition',

    EnergyLevels = 'Energy levels',
    MentalClarity = 'Mental clarity',
    PhysicalFitness = 'Physical fitness',
    EmotionalWellBeing = 'Emotional well-being',
    AllOfTheAbove = 'All of the above',
}

export const COURSE_CATEGORIES_QUIZ: QuizList[] = [
    {
        label: 'Voice Mastery',
        value: CourseCategories.VoiceMastery,
    },
    {
        label: 'Love & Develop Body',
        value: CourseCategories.LoveDevelopBody,
    },
    {
        label: 'Strengthen Relationships',
        value: CourseCategories.StrengthenRelationships,
    },
    {
        label: 'Boost Daily Energy',
        value: CourseCategories.BoostDailyEnergy,
    },
    {
        label: 'Something Else',
        value: CourseCategories.SomethingElse,
    },
];

export const COURSE_CATEGORIES_QUIZ_TRIAL: QuizList[] = [
    {
        label: 'Yoga',
        value: CourseCategories.Yoga,
    },
    {
        label: 'Breathing practices',
        value: CourseCategories.BreathingPractices,
    },
    {
        label: 'Meditation',
        value: CourseCategories.Meditation,
    },
    {
        label: 'Physical exercises',
        value: CourseCategories.PhysicalExercises,
    },
    {
        label: 'Dancing and singing',
        value: CourseCategories.DancingSinging,
    },
    {
        label: 'Healthy nutrition',
        value: CourseCategories.HealthyNutrition,
    },
];

export const COURSE_CATEGORIES_QUIZ_TRIAL_NEW: QuizList[] = [
    {
        label: CourseCategories.EnergyLevels,
        value: CourseCategories.EnergyLevels,
    },
    {
        label: CourseCategories.MentalClarity,
        value: CourseCategories.MentalClarity,
    },
    {
        label: CourseCategories.PhysicalFitness,
        value: CourseCategories.PhysicalFitness,
    },
    {
        label: CourseCategories.EmotionalWellBeing,
        value: CourseCategories.EmotionalWellBeing,
    },
    {
        label: CourseCategories.AllOfTheAbove,
        value: CourseCategories.AllOfTheAbove,
    },
];

export const COURSE_CATEGORIES_QUIZ_EXPANDED: QuizList[] = [
    {
        label: 'Voice Mastery',
        value: CourseCategories.VoiceMastery,
    },
    {
        label: 'Love & Develop Body',
        value: CourseCategories.LoveDevelopBody,
    },
    {
        label: 'Strengthen Relationships',
        value: CourseCategories.StrengthenRelationships,
    },
    {
        label: 'Boost Daily Energy',
        value: CourseCategories.BoostDailyEnergy,
    },
    {
        label: 'Cognitive skills',
        value: CourseCategories.CognitiveSkills,
    },
    {
        label: 'Something Else',
        value: CourseCategories.SomethingElse,
    },
];
