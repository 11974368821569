import { useEffect, useState } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';

import { METHODS, consoleLogger, fetchData } from '@/shared/lib';
import { userAtom } from '@/shared/store';
import { TOOLTIP_PATH_CONTEXT_MAP, TooltipTag, TOOLTIP_TAG_ELEMENT_ID_MAP } from '../model/tooltip-model';

const getAnchorCoords = (top: number, left: number, width: number, height: number) => {
    return { top: top + 'px', left: left + 'px', width: width + 'px', height: height + 'px' };
};

export const useTooltips = () => {
    const path = typeof window !== 'undefined' ? window.location.pathname : '';
    const trimmedPath = path.endsWith('/') ? path.slice(0, -1) : path;

    const user = useAtomValue(userAtom);

    const [tooltips, setTooltips] = useState<TObj[]>([]);

    const [currentStep, setCurrentStep] = useState(0);
    const [anchorCoords, setAnchorCoords] = useState<any>({ top: 0, left: 0, width: 0, height: 0 });
    const [popoverParams, setPopoverParams] = useState<any>({ placement: 'top' });
    const [currentTooltip, setCurrentTooltip] = useState<any>(null);
    const [isLarger1200, isLarger992, isLarger768, isSmaller576] = useMediaQuery([
        '(min-width: 1200px)',
        '(min-width: 992px)',
        '(min-width: 768px)',
        '(max-width: 576px)',
    ]);

    useEffect(() => {
        if (!(trimmedPath in TOOLTIP_PATH_CONTEXT_MAP)) {
            return;
        }

        if (!user) {
            return;
        }

        const apiUrl = `/api/tooltips?search[context]=${TOOLTIP_PATH_CONTEXT_MAP[trimmedPath as keyof typeof TOOLTIP_PATH_CONTEXT_MAP]}`;

        fetchData(apiUrl).then((response: any) => {
            if (!response.data) {
                return;
            }

            const tooltips =
                response.data
                    .filter((tooltip: any) => !user.tooltip_info.viewed_tooltips.includes(tooltip.id))
                    .sort((a: { sequence: number }, b: { sequence: number }) => a.sequence - b.sequence) || [];

            setTooltips(tooltips);
        });
    }, [user]);

    useEffect(() => {
        if (
            user?.tooltip_info.is_skipped === 1 ||
            tooltips.length === 0 ||
            currentStep === -1 ||
            currentStep >= tooltips.length
        ) {
            setCurrentTooltip(null);

            return;
        }

        const currentTooltipData = tooltips[currentStep];
        setCurrentTooltip(currentTooltipData);

        const tag =
            currentTooltipData.tag === TooltipTag.SupportCenter
                ? isLarger992
                    ? TooltipTag.SupportCenter
                    : TooltipTag.SupportCenterMobile
                : (currentTooltipData.tag as TooltipTag);

        if (tag === TooltipTag.Welcome) {
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });

            return;
        }

        const node = document.getElementById(TOOLTIP_TAG_ELEMENT_ID_MAP[tag]);

        if (node) {
            const { top, bottom, left, width, height } = node.getBoundingClientRect();

            if (tag !== TooltipTag.MergeAccount) {
                setAnchorCoords(getAnchorCoords(top + window.scrollY, left + window.scrollX, width, height));
            }

            if (tag === TooltipTag.ContinueCourses) {
                if (bottom >= (window.innerHeight || document.documentElement.clientHeight)) {
                    node.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
            } else if (tag === TooltipTag.CourseRecommendation) {
                node.scrollIntoView({ behavior: 'smooth', block: 'end' });
            } else if (tag === TooltipTag.Challenges) {
                if (top <= 0) {
                    node.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            } else if (tag === TooltipTag.Events) {
                node.scrollIntoView({ behavior: 'smooth', block: 'end' });
            } else if (tag === TooltipTag.TimeZone) {
                setPopoverParams({ placement: isSmaller576 ? 'bottom-end' : 'left' });
            } else if (tag === TooltipTag.MissedEvents) {
                setPopoverParams({ placement: isSmaller576 ? 'bottom-start' : 'right' });
            } else if (tag === TooltipTag.ExploreCourses) {
                setPopoverParams({ placement: 'top' });
                node.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else if (tag === TooltipTag.SupportCenter) {
                setPopoverParams({ placement: 'bottom' });
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            } else if (tag === TooltipTag.SupportCenterMobile) {
                setPopoverParams({ placement: 'bottom-end' });
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            } else if (tag === TooltipTag.ProfilePicture) {
                setPopoverParams({ placement: isLarger768 ? 'right' : 'bottom' });
                window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
            } else if (tag === TooltipTag.MergeAccount) {
                setPopoverParams({ placement: 'top' });
                if (isLarger1200) {
                    setAnchorCoords(getAnchorCoords(top, left, (width * 3) / 4, height));
                } else {
                    setAnchorCoords(getAnchorCoords(top + 65, left, width, height));
                }
                node.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        } else {
            setCurrentStep(currentStep + 1);

            consoleLogger.error(
                `Element with id ${TOOLTIP_TAG_ELEMENT_ID_MAP[currentTooltipData.tag as TooltipTag]} not found`,
            );
        }
    }, [currentStep, tooltips]);

    const handleNext = () => {
        fetchData(`/api/tooltips/user/mark-viewed/${currentTooltip.id}`, { method: METHODS.POST });

        if (currentTooltip.tag === TooltipTag.SupportCenter) {
            window.location.href = '/client/profile';

            return;
        }

        if (currentTooltip.tag === TooltipTag.MergeAccount) {
            window.location.href = '/client';

            return;
        }

        if (currentStep < tooltips.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            setCurrentStep(-1); // Завершить последовательность
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    };

    const handleSkip = async () => {
        try {
            await fetchData(`/api/tooltips/user/mark-viewed/${currentTooltip.id}`, { method: METHODS.POST });
            await fetchData('/api/tooltips/user/skip', { method: METHODS.POST });
        } finally {
            setCurrentStep(-1); // Завершить последовательность
        }
    };

    return {
        anchorCoords,
        popoverParams,
        currentTooltip,
        handleNext,
        handleSkip,
    };
};
