import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';

import PaywallContent from './PaywallContent';
import { FeatureData } from '@/entities/lists/model/types';
import { ActionSubType } from '@/features/subscription/create-sub';

interface PaywallDefaultProps {
    subActionType: ActionSubType;
    title: string;
    featureList: FeatureData[];
    isOpen: boolean;
    onClose: () => void;
    onCloseComplete: () => void;
}

export default function PaywallDefault({
    subActionType,
    title,
    featureList,
    isOpen,
    onClose,
    onCloseComplete,
}: PaywallDefaultProps) {
    return (
        <Modal
            variant="primary"
            scrollBehavior="inside"
            isOpen={isOpen}
            onClose={onClose}
            isCentered
        >
            <ModalOverlay />

            <ModalContent maxW={{ base: '360px', md: '1120px' }}>
                <ModalCloseButton
                    color={{ base: 'black', lg: 'white' }}
                    _hover={{ opacity: 0.7 }}
                />

                <ModalBody p={{ base: 0, md: 0 }}>
                    <PaywallContent
                        subActionType={subActionType}
                        title={title}
                        featureList={featureList}
                        onSuccessfulSub={() => {
                            onClose();
                            onCloseComplete();
                        }}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
