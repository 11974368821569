import { Box, Flex, RadioGroup, Text, useMediaQuery } from '@chakra-ui/react';
import { usePlaybackRateOptions } from '@vidstack/react';
import { RiSpeedLine } from 'react-icons/ri';
import { useState } from 'react';

import { CustomRadioMark } from '@/shared/ui';
import { Menu } from '@ark-ui/react/menu';

const PLAYBACK_RATES = [
    {
        label: '0.5x',
        rate: 0.5,
    },
    {
        label: '0.75x',
        rate: 0.75,
    },
    {
        label: '1.00x',
        rate: 1,
    },
    {
        label: '1.25x',
        rate: 1.25,
    },
    {
        label: '1.5x',
        rate: 1.5,
    },
];

export default function PlaybackRateSubmenu() {
    const options = usePlaybackRateOptions({
        rates: PLAYBACK_RATES,
        normalLabel: '1.00x',
    });
    const [isLargerThan1220] = useMediaQuery('(min-width: 1220px)');

    return (
        <Menu.Root
            positioning={{
                overflowPadding: 0,
                gutter: isLargerThan1220 ? 42 : 8,
                placement: isLargerThan1220 ? 'top-end' : 'bottom-end',
            }}
        >
            <Box
                as={Menu.Trigger}
                color={'gray.200'}
                _hover={{
                    color: 'gray.340',
                }}
            >
                <RiSpeedLine />
            </Box>

            <Menu.Positioner>
                <Box
                    as={Menu.Content}
                    pos={'relative'}
                    zIndex={20}
                    py={{ base: '8px', md: '20px' }}
                    px={{ base: '10px', md: '20px' }}
                    bgColor={'gray.675'}
                    borderRadius={'8px'}
                >
                    <Flex
                        align={'center'}
                        mb={{ base: 2, lg: 4 }}
                        gap={2}
                    >
                        <Text
                            as={'span'}
                            color={'white'}
                            fontSize={{ base: '14px', lg: '18px' }}
                        >
                            Playback Rate
                        </Text>
                        <Text
                            as={'span'}
                            color={'gray.430'}
                            fontSize={{ base: '10px', lg: '12px' }}
                        >
                            ·
                        </Text>
                        <Text
                            as={'span'}
                            color={'gray.430'}
                            fontSize={{ base: '10px', lg: '12px' }}
                        >
                            {PLAYBACK_RATES.find((item) => Number(options.selectedValue) === item.rate)?.label}
                        </Text>
                    </Flex>

                    <RadioGroup
                        value={options.selectedValue}
                        display={'flex'}
                        flexDir={'column'}
                        gap={{ base: 1, lg: 2 }}
                        fontSize={{ base: '12px', lg: '14px' }}
                    >
                        {options.map(({ label, value, selected, select }) => {
                            return (
                                <Menu.Item
                                    key={value}
                                    value={value}
                                >
                                    <CustomRadioMark
                                        radioData={value}
                                        label={label}
                                        isChecked={selected}
                                        onChange={() => {
                                            select();
                                        }}
                                    />
                                </Menu.Item>
                            );
                        })}
                    </RadioGroup>
                </Box>
            </Menu.Positioner>
        </Menu.Root>
    );
}
