import { useBoolean } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';

import { userAtom } from '@/shared/store';
import { ActionSubType, useOrderSub } from './useOrderSub';
import { redirectToStripeWithData } from '@/features/subscription/redirect-to-stripe';
import { dispatchBrowserEvent } from '@/shared/lib';
import { useLoadingTextWithFetch } from '@/shared/ui';

export default function useCreateSub(initialActionSubType?: ActionSubType, initialSubData?: any) {
    const [isSubCreate, setIsSubCreate] = useBoolean(false);
    const [isSubLoading, setIsSubLoading] = useBoolean(false);
    const user = useAtomValue(userAtom);
    const { orderSub } = useOrderSub();

    const fetchCreateSub = async (actionSubType?: ActionSubType, subData?: any) => {
        let isRedirect = false;
        setIsSubLoading.on();

        const currentActionSubType = actionSubType ?? initialActionSubType;
        const currentSubData = subData ?? initialSubData;

        try {
            // Если у юзера есть карта
            if (user?.is_add_payment_card) {
                await orderSub(currentActionSubType!, user.is_user_subscription);
                setIsSubCreate.on();
            } else if (currentSubData) {
                isRedirect = true;
                dispatchBrowserEvent('buy_subscription_social_user', { data_sub: currentSubData });
            } else {
                isRedirect = true;
                await redirectToStripeWithData(currentActionSubType!, currentSubData)();
            }
        } finally {
            if (!isRedirect) {
                setIsSubLoading.off();
                // Дополнительные действия, если не происходит редирект
            }
        }
    };

    const { loadingText, sendError, textedFetch } = useLoadingTextWithFetch(fetchCreateSub);

    const createSub = async (actionSubType?: ActionSubType, subData?: any) => {
        if (typeof actionSubType !== 'string') {
            await textedFetch();
        } else {
            await textedFetch(actionSubType, subData);
        }
    };

    return {
        isSubLoading,
        isSubError: !!sendError,
        isSubCreate,
        loadingText,
        sendError,
        createSub,
    };
}
