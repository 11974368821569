import { Box, Button, Text, useToast, VStack } from '@chakra-ui/react';

import { CustomCheckbox, useLoadingTextWithFetch } from '@/shared/ui';
import { Controller, useForm } from 'react-hook-form';
import { APP_BRAND } from '@/common/constants/brand';

interface Inputs {
    agree_terms: boolean;
}

export default function CourseQuizBuyBtnPublicPaywall() {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();
    const toast = useToast();

    const {
        isSubmitted,
        loadingText,
        sendError,
        textedFetch: createSub,
    } = useLoadingTextWithFetch(async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        window.windowPayByStripeSubscriptionNoEmail();

        await new Promise((resolve) => setTimeout(resolve, 5000));
    });

    const handleSignUp = async (data: Inputs) => {
        if (!data.agree_terms) {
            toast({
                duration: 5000,
                status: 'error',
                title: `Please agree the terms`,
                position: 'top-right',
            });
            return;
        }

        await createSub();
    };

    return (
        <>
            <VStack
                as="form"
                onSubmit={handleSubmit(handleSignUp)}
                align={'flex-start'}
                w={{ base: '100%', lg: '340px' }}
                px="10px"
                pb="20px"
                pt="18px"
                mb="4"
                mx={'auto'}
                border="2px solid"
                borderColor="green.400"
                borderRadius="20px"
            >
                <Controller
                    name="agree_terms"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }: TObj<any>) => {
                        return (
                            <CustomCheckbox
                                checkboxProps={{
                                    isChecked: Boolean(value),
                                    onChange: onChange,
                                    isInvalid: Boolean(errors.agree_terms),
                                }}
                                LabelEl={() => (
                                    <Box>
                                        <Text
                                            display="inline"
                                            fontSize="14px"
                                            fontWeight={400}
                                            color={errors.agree_terms ? 'red' : 'gray.675'}
                                        >
                                            I agree to the above Membership terms
                                        </Text>
                                    </Box>
                                )}
                            />
                        );
                    }}
                />

                <Button
                    type="submit"
                    w="100%"
                    maxW="360px"
                    fontSize="sm2"
                    textTransform="uppercase"
                    isLoading={isSubmitted}
                    loadingText={loadingText}
                >
                    Try {APP_BRAND} MEMBERSHIP for free
                </Button>

                {sendError && (
                    <Text
                        mb={0}
                        w="100%"
                        textAlign="center"
                        color="red.500"
                    >
                        {sendError}
                    </Text>
                )}
            </VStack>
        </>
    );
}
