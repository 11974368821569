

export const getPromoCategoriesCardList = (isLargerThanMd: boolean) => [
    {
        imgSrc: isLargerThanMd ? '/img/promo/categories/meditation.png' : '/img/promo/categories/meditation-sm.png',
        text: `Meditation`,
    },
    {
        imgSrc: isLargerThanMd ? '/img/promo/categories/breathwork.png' : '/img/promo/categories/breathwork-sm.png',
        text: `Breathwork`,
    },
    {
        imgSrc: isLargerThanMd ? '/img/promo/categories/movement.png' : '/img/promo/categories/movement-sm.png',
        text: `Movement`,
    },
    {
        imgSrc: isLargerThanMd ? '/img/promo/categories/sound.png' : '/img/promo/categories/sound-sm.png',
        text: `Sound`,
    },
];
