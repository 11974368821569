import { Box, BoxProps, Flex, Image, Text } from '@chakra-ui/react';

import { useLandingProvider } from '../../model/LandingProvider';
import { HTMLRenderer } from '@/shared/ui';

export default function WhyChoose({ styles }: { styles?: BoxProps }) {
    const { courseData } = useLandingProvider();
    const { info } = courseData;

    if (!info || !info.for || !info.for.images) {
        return null;
    }

    return (
        <Flex
            flexDir={{ base: 'column', lg: 'row' }}
            gap={{ base: '40px', lg: '50px' }}
            {...styles}
        >
            <Box
                color="white"
                maxW={{ lg: '572px' }}
            >
                <Text as="h4">{info.for.subtitle}</Text>

                <HTMLRenderer
                    className="text-wrap"
                    html={info.for.text}
                />
            </Box>

            <Box mx={{ base: 'auto', lg: 0 }}>
                <Image
                    src={info.for.images[0]}
                    alt="why-choose"
                    pos="relative"
                    zIndex={20}
                    w={{ base: '211px', xl: '351px' }}
                    ml={{ base: '37px', xl: '62px' }}
                    borderRadius={{ base: '20px', xl: '40px' }}
                />

                <Flex
                    zIndex={10}
                    pos="relative"
                    align="flex-end"
                    gap={{ base: '14px', xl: '24px' }}
                    h={{ base: '122px', xl: '210px' }}
                >
                    <Image
                        src={info.for.images[1]}
                        alt="why-choose"
                        w={{ base: '140px', xl: '234px' }}
                        borderRadius={{ base: '20px', xl: '40px' }}
                    />
                    <Image
                        src={info.for.images[2]}
                        alt="why-choose"
                        w={{ base: '166px', xl: '277px' }}
                        borderRadius={{ base: '20px', xl: '40px' }}
                    />
                </Flex>
            </Box>
        </Flex>
    );
}
