import { Button } from '@chakra-ui/react';
import { RiArrowLeftSLine } from 'react-icons/ri';

import { fetchData, METHODS } from '@/shared/lib';
import { isCheckboxesQuiz, QuizGroup, QuizGroupType } from '../types/quiz-list-types';
import { COURSE_CATEGORIES_QUIZ_EXPANDED } from './course-categories-list';
import { COURSE_QUIZ_LIST_AGE_EXPANDED, COURSE_QUIZ_LIST_GENDER, COURSE_QUIZ_LIST_TIME } from './radio-list';
import { EventNames } from '@/entities/quizes/quiz/model/types/event-quiz-types';
import { CheckEmailAndPhoneQuiz } from '@/entities/onboarding/check-email';
import CustomQuiz from '../../ui/components/CustomQuiz';
import PictureQuizSlide from '../../ui/components/PictureQuizSlide';
import { ALL_COURSES_LIST_GENDERED } from './courses-by-category-gender-lists';
import { URLS } from '@/common/constants/global';

export const QUIZ_DEFAULT_CHECK_EMAIL: QuizGroup[] = [
    {
        type: QuizGroupType.DefaultSingle,
        title: `Your order is not complete yet!`,
        subTitle: 'Please select your age to personalize learning experience',
        quizList: COURSE_QUIZ_LIST_AGE_EXPANDED,
        eventName: EventNames.Age,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            const onClickOption = async (formData: any) => {
                try {
                    await fetchData(URLS.UPDATE_PROFILE, {
                        method: METHODS.POST,
                        body: JSON.stringify({
                            age: formData.formData.radio,
                        }),
                    });
                } finally {
                    onNextBtnClick(formData);
                }
            };

            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onClickOption}
                    styles={{
                        position: 'relative',
                        maxW: 'container.sm3',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.GenderSingle,
        title: `Choose your gender to customize learning`,
        quizList: COURSE_QUIZ_LIST_GENDER,
        eventName: EventNames.Gender,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            const onClickOption = async (formData: any) => {
                try {
                    await fetchData(URLS.UPDATE_PROFILE, {
                        method: METHODS.POST,
                        body: JSON.stringify({
                            gender: formData.formData.radio === 'female' ? 0 : 1,
                        }),
                    });
                } finally {
                    onNextBtnClick(formData);
                }
            };

            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onClickOption}
                    styles={{
                        position: 'relative',
                        maxW: 'container.sm3',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.CategorySingle,
        title: `What Brings You Here Today?`,
        quizList: COURSE_CATEGORIES_QUIZ_EXPANDED,
        eventName: EventNames.ChooseCategory,
        categoryList: ALL_COURSES_LIST_GENDERED,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: 'container.sm3',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.Picture,
        isNextStep: true,
        title: 'Unlock Your Potential Today',
        imgSrc: 'promo/people/portrait-young-woman-exercising-home.png',
        imgDesc: `Did you know? Over 300,000 students are learning with us! Access 200+ Best-Selling Courses from world-class instructors in yoga, fitness, dance, singing, and more. Transform your life to be healthier and happier every day!`,
        eventName: EventNames.UnlockPotential,
        Component: ({ currentQuiz, onNextBtnClick, onPrevBtnClick }) => {
            return (
                <PictureQuizSlide
                    slideData={currentQuiz}
                    NextBtnEl={() => (
                        <Button
                            width="100%"
                            height="45px"
                            onClick={onNextBtnClick}
                        >
                            Next
                        </Button>
                    )}
                    PrevBtnEl={() => (
                        <Button
                            variant="pagination"
                            h="45px"
                            onClick={onPrevBtnClick}
                        >
                            <RiArrowLeftSLine />
                        </Button>
                    )}
                    styles={{
                        position: 'relative',
                        maxW: 'container.sm3',
                        px: { base: 5, md: 15 },
                        py: { base: 7.5, md: 15 },
                        borderRadius: 'xl',
                        mx: { base: -5, md: 'auto' },
                        bg: 'gray.300',
                    }}
                />
            );
        },
    },
    {
        type: QuizGroupType.DefaultSingle,
        title: 'How much time do you want to spend learning every week?',
        quizList: COURSE_QUIZ_LIST_TIME,
        eventName: EventNames.HowMuchTime,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: 'container.sm3',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.Picture,
        title: "That's great, you can complete a class in two weeks!",
        imgSrc: 'promo/people/singer-lady.jpg',
        imgDesc: 'Watch 15 minute lessons at your own pace, in any order.',
        eventName: EventNames.ThatGreat,
        Component: ({ currentQuiz, onNextBtnClick, onPrevBtnClick }) => {
            return (
                <PictureQuizSlide
                    slideData={currentQuiz}
                    NextBtnEl={() => (
                        <Button
                            width="100%"
                            height="45px"
                            onClick={onNextBtnClick}
                        >
                            Next
                        </Button>
                    )}
                    PrevBtnEl={() => (
                        <Button
                            variant="pagination"
                            h="45px"
                            onClick={onPrevBtnClick}
                        >
                            <RiArrowLeftSLine />
                        </Button>
                    )}
                    styles={{
                        position: 'relative',
                        maxW: 'container.sm3',
                        px: { base: 5, md: 15 },
                        py: { base: 7.5, md: 15 },
                        borderRadius: 'xl',
                        mx: { base: -5, md: 'auto' },
                        bg: 'gray.300',
                    }}
                />
            );
        },
    },
    {
        type: QuizGroupType.Other,
        title: 'Check email',
        eventName: EventNames.CheckEmail,
        Component: ({ userDataFromBlade }) => {
            return (
                <CheckEmailAndPhoneQuiz
                    styles={{
                        maxW: '380px',
                        px: 5,
                        mx: 'auto',
                        textAlign: 'center',
                        sx: {
                            form: {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            },
                        },
                    }}
                    userEmail={userDataFromBlade?.email ?? ''}
                    postFormUrl={userDataFromBlade?.urlCheckEmail ?? ''}
                    cb={() => {
                        window.location.pathname = '/redirect-to-quiz-final-page';
                    }}
                />
            );
        },
    },
];
