import { Box, BoxProps, HStack, Image, Text } from '@chakra-ui/react';


import { APP_ICON } from '@/common/constants/brand';
import { NavInfoStepper } from '@/entities/nav/stepper';

interface CourseQuizPageHeaderProps {
    activeStep: number;
    styles?: BoxProps;
}

export default function QuizPageHeaderProgress({ activeStep, styles }: CourseQuizPageHeaderProps) {
    return (
        <Box
            position="relative"
            mx="auto"
            mb={4}
            {...styles}
        >
            <HStack
                pt={4}
                mb={{ base: 4, md: 6 }}
            >
                <Image
                    src={APP_ICON ? APP_ICON.dark : ''}
                    alt="logo"
                    width={{
                        base: '80px',
                        md: '115px',
                    }}
                />
                <Text
                    pos="absolute"
                    top={{ base: '20px', md: '25px' }}
                    left={0}
                    mb={0}
                    w="100%"
                    textAlign="center"
                    fontSize={{ base: 'sm2', md: 'md' }}
                >
                    Classes For You
                </Text>
            </HStack>

            <NavInfoStepper
                activeStep={activeStep}
                styles={{
                    mx: { base: -5, md: 'auto' },
                    maxW: 'container.sm3',
                }}
            />
        </Box>
    );
}
