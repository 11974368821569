export default function LayoutBack(props: any) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M10 19H12.8H18C18.2652 19 18.5196 18.8946 18.7071 18.7071C18.8946 18.5196 19 18.2652 19 18V7.103C19 6.83778 18.8946 6.58343 18.7071 6.39589C18.5196 6.20836 18.2652 6.103 18 6.103H2C1.73478 6.103 1.48043 6.20836 1.29289 6.39589C1.10536 6.58343 1 6.83778 1 7.103V18C1 18.2652 1.10536 18.5196 1.29289 18.7071C1.48043 18.8946 1.73478 19 2 19H7.2H10ZM8.161 17H3V8.103H17V17H11.839H10H8.161ZM22 2H5V4H21V15H23V3C23 2.73478 22.8946 2.48043 22.7071 2.29289C22.5196 2.10536 22.2652 2 22 2Z"
                fill="currentColor"
            />
        </svg>
    );
}
