import { useEffect, useRef, useState } from 'react';
import {
    useDisclosure,
    Button,
    Portal,
    Text,
    VStack,
    Link,
    Box,
    Img,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    ModalCloseButton,
    ModalHeader,
    List,
    ListItem,
    ListIcon,
} from '@chakra-ui/react';

import { useSubFromMonolith } from '@/features/subscription/sub-from-monolith';
import { useAtomValue } from 'jotai';
import { CurrentStepEventNames } from '@/entities/quizes/quiz';
import { userAtom } from '@/shared/store';
import { CountdownGrid, CustomCheckbox, HTMLRenderer } from '@/shared/ui';
import { useCountdownReminigTime } from '@/features/promo/countdown';
import { Controller, useForm } from 'react-hook-form';
import { planOfCourseOfQuizAtom } from '@/entities/quizes/quiz/model/stores/quiz-store';
import { MONTH_PRICE } from '@/common/constants/brand';
import { useTrialDay } from '@/features/subscription/create-sub';

interface CourseQuizOnboardingBuyBtnProps {
    isCheckboxed?: boolean;
    isSkipBtnShow?: boolean;
}

const sentences = [
    'Engage in Weekly Live sessions, where the spiritual journey unfolds in real-time, offering fresh insights and collective energies.',
    'Connect with the universe through Yoga.',
    "Celebrate your soul's rhythm with Dance and Singing.",
    'Experience healing touch with spiritual Massage techniques.',
    'Elevate your consciousness with mindfulness and brain exercises.',
];

const TIMER_COUNTDOWN_HOURS = 12;

interface Inputs {
    agree_terms: boolean;
}

export default function CourseQuizOnboardingBuyBtnCheckboxed({
    isCheckboxed = true,
    isSkipBtnShow = true,
}: CourseQuizOnboardingBuyBtnProps) {
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            agree_terms: true,
        },
    });

    // Если пользователь уже был на onboarding то перенаправим при скипе в dashboard
    const user = useAtomValue(userAtom);
    const isAlreadyOnboarded =
        user && user?.quiz_info.quiz_thank_page_step_history.includes(CurrentStepEventNames.QuizOnboardingPageStep);
    const skipRedirectLink = isAlreadyOnboarded ? '/client' : '/redirect-to-onboarding-page';

    const { reminingTime } = useCountdownReminigTime(TIMER_COUNTDOWN_HOURS);

    const { isOpen: isOpenSkipPopup, onOpen: onOpenSkipModal, onClose: onCloseSkipModal } = useDisclosure();

    const { sendError, loadingText, isSubmitted, createSub: handleSignUp } = useSubFromMonolith();
    const planOfCourseOfQuiz = useAtomValue(planOfCourseOfQuizAtom);
    useTrialDay(planOfCourseOfQuiz?.trialDays);

    // Наблюдаем за состоянием чекбокса
    const exampleCheckboxValue = watch('agree_terms', false);
    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        if (!exampleCheckboxValue && isCheckboxed) {
            // Вызываем функцию, если чекбокс не отмечен
            onOpenSkipModal();
        }
    }, [exampleCheckboxValue, isCheckboxed]);

    const onLinkClick = () => {
        window.location.pathname = skipRedirectLink;
    };

    return (
        <>
            <VStack w={{ base: '100%', lg: '340px' }}>
                {isCheckboxed ? (
                    <Controller
                        name="agree_terms"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }: TObj<any>) => {
                            return (
                                <CustomCheckbox
                                    checkboxProps={{
                                        isChecked: Boolean(value),
                                        onChange: onChange,
                                        isInvalid: Boolean(errors.agree_terms),
                                    }}
                                    LabelEl={() => (
                                        <Box>
                                            <Text
                                                display="inline"
                                                fontWeight={700}
                                                color={errors.agree_terms ? 'red' : 'gray.675'}
                                            >
                                                Get {planOfCourseOfQuiz?.trialDays} days of free access to 200+
                                                BestSellers & Daily Live Sessions
                                            </Text>

                                            {/* <Text
                                                mt={4}
                                                mb={4}
                                                fontWeight={400}
                                            >
                                                Best classes of Yoga, Dance, Singing, Massage, and Brain Exercises. Try{' '}
                                                {planOfCourseOfQuiz?.trialDays} days for free, then it’s $
                                                {planOfCourseOfQuiz?.price && parseInt(planOfCourseOfQuiz.price, 10)}
                                                /month. Cancel anytime
                                            </Text> */}
                                        </Box>
                                    )}
                                />
                            );
                        }}
                    />
                ) : null}

                <Button
                    w="100%"
                    maxW="360px"
                    fontSize="sm2"
                    onClick={
                        exampleCheckboxValue
                            ? handleSignUp
                            : () => (window.location.pathname = '/redirect-to-onboarding-page')
                    }
                    isLoading={isSubmitted}
                    loadingText={loadingText}
                >
                    Continue to the course
                </Button>

                <Text
                    mb={0}
                    fontSize={{ base: 'sm', md: 'sm3' }}
                    textAlign="center"
                >
                    {planOfCourseOfQuiz?.trialDays} days for free, then $
                    {planOfCourseOfQuiz?.price && parseInt(planOfCourseOfQuiz.price, 10)}/month. Cancel anytime
                </Text>

                {sendError && (
                    <Text
                        mb={0}
                        textAlign="center"
                        color="red.500"
                    >
                        {sendError}
                    </Text>
                )}
            </VStack>

            <Portal>
                <Modal
                    variant="top-block"
                    scrollBehavior="inside"
                    isOpen={isOpenSkipPopup}
                    onClose={onCloseSkipModal}
                    isCentered
                >
                    <ModalOverlay />

                    <ModalContent>
                        <ModalCloseButton />

                        <ModalHeader
                            background="url(/img/bg/blur-pink-blue-hor.png) no-repeat 50% 50%"
                            backgroundSize="cover"
                            color="white"
                        >
                            <CountdownGrid
                                timerSeconds={reminingTime}
                                styles={{ justifyContent: 'center' }}
                            />

                            <Text
                                textStyle="lg"
                                mt={5}
                                mb={2.5}
                            >
                                Think that 7 days is not enough?
                            </Text>

                            <Text
                                textStyle="md6"
                                fontWeight={700}
                                mb={0}
                            >
                                Try this One Time Offer with{' '}
                                <Text
                                    as="span"
                                    pos="relative"
                                    mb={0}
                                    display={{ base: 'inline-block', md: 'inline' }}
                                >
                                    30-Day Free Trial
                                    <Img
                                        src="/img/elements/ranbow-line.svg"
                                        alt="ranbow-line"
                                        pos="absolute"
                                        top={{ base: '18px', md: '20px' }}
                                        left={0}
                                        w="100%"
                                        h="100%"
                                    />
                                </Text>
                            </Text>
                        </ModalHeader>

                        <ModalBody>
                            <Box>
                                <Text>
                                    Unlock a{' '}
                                    <Text
                                        as="span"
                                        fontWeight={700}
                                    >
                                        30-day free trial
                                    </Text>{' '}
                                    with access to 200+ recorded and live programs. After, it&#39;s{' '}
                                    <Text
                                        as="span"
                                        fontWeight={700}
                                    >
                                        only ${MONTH_PRICE}
                                        /month
                                    </Text>
                                    . Enjoy a complete self-recovery retreat from the comfort of your own home, no
                                    matter where you are:
                                </Text>

                                <List
                                    display="flex"
                                    flexDir="column"
                                    gap={{ base: 2.5, md: 3 }}
                                >
                                    {sentences.map((text, index) => {
                                        return (
                                            <ListItem
                                                display="flex"
                                                flexDir="row"
                                                alignItems="baseline"
                                                gap={2.5}
                                                key={index}
                                            >
                                                <ListIcon
                                                    as={Img}
                                                    src="/img/icons/common/arrow-mark.svg"
                                                    alt="arrow-mark"
                                                />
                                                <HTMLRenderer text={text} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                w={{ md: '100%' }}
                                onClick={handleSignUp}
                                isLoading={isSubmitted}
                                loadingText={loadingText}
                            >
                                OPEN ACCESS NOW
                            </Button>

                            {sendError && (
                                <Text
                                    mb={0}
                                    textAlign="center"
                                    color="red.500"
                                >
                                    {sendError}
                                </Text>
                            )}

                            {isSkipBtnShow && (
                                <Link
                                    href={skipRedirectLink}
                                    textAlign="center"
                                    color="gray.525"
                                    textDecoration="underline"
                                >
                                    Skip this one time offer
                                </Link>
                            )}
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Portal>
        </>
    );
}
