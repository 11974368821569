import { Box, BoxProps, Button, Portal, Text, useBoolean, useDisclosure, VStack } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';

import { useEventsList } from '@/features/socials/events/get-events';
import { userAtom } from '@/shared/store';
import { LoadingContentText } from '@/shared/ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EventData } from '@/entities/events/event-data';
import { EventCardBlue } from '@/entities/socials/events/event-card';
import { APP_BRAND } from '@/common/constants/brand';
import { ToTheEventModal } from '@/entities/modals';

interface SliderProps {
    styles?: BoxProps;
}

export default function HeadingNavEventsSliderSimpleBlue({ styles }: SliderProps) {
    const user = useAtomValue(userAtom);
    const { eventList, isEventsLoading } = useEventsList(Boolean(user));

    const [isSubmitted, setIsSubmitted] = useBoolean(false);

    const { isOpen: isSubModalOpen, onOpen: onSubModalOpen, onClose: onSubModalClose } = useDisclosure();

    return (
        <Box {...styles}>
            {eventList?.upcomingEvents && eventList?.upcomingEvents.length > 0 ? (
                <Swiper
                    slidesPerView="auto"
                    spaceBetween="16"
                    style={{
                        paddingLeft: '20px',
                        paddingRight: '20px',
                    }}
                >
                    {eventList.upcomingEvents.map((item: EventData, index: number) => (
                        <SwiperSlide
                            key={item.id}
                            style={{
                                width: 'auto',
                                height: 'auto',
                            }}
                        >
                            <EventCardBlue
                                eventData={item}
                                styles={{ w: { base: '285px', md: '376px' } }}
                            >
                                {user ? null : (
                                    <>
                                        <Button
                                            variant="white-blue-outline"
                                            display="flex"
                                            minW={{ base: '140px', md: '220px' }}
                                            px={6}
                                            m="auto"
                                            onClick={onSubModalOpen}
                                        >
                                            Learn more
                                        </Button>
                                        <Portal>
                                            <ToTheEventModal
                                                eventData={item}
                                                isOpen={isSubModalOpen}
                                                onClose={onSubModalClose}
                                                BtnElement={() => {
                                                    return (
                                                        <VStack>
                                                            <Button
                                                                variant="white-blue-outline"
                                                                w="auto !important"
                                                                minW={{ base: '222px', md: '322px' }}
                                                                px={{ md: '80px' }}
                                                                isLoading={isSubmitted}
                                                                loadingText={`Submitting`}
                                                                onClick={() => {
                                                                    setIsSubmitted.on();
                                                                    // ! привязка к монолиту
                                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                                    // @ts-expect-error
                                                                    window.windowPayByStripeSubscriptionNoEmail();
                                                                }}
                                                            >
                                                                BOOK YOUR SPOT FOR EVENT
                                                            </Button>

                                                            <Text
                                                                mb={0}
                                                                color="gray.500"
                                                                fontSize="sm2"
                                                            >
                                                                Available in{' '}
                                                                <Text
                                                                    as="span"
                                                                    textTransform="capitalize"
                                                                >
                                                                    {APP_BRAND}
                                                                </Text>{' '}
                                                                Membership
                                                            </Text>
                                                        </VStack>
                                                    );
                                                }}
                                            />
                                        </Portal>
                                    </>
                                )}
                            </EventCardBlue>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <Box
                    pos="relative"
                    h="230px"
                >
                    <LoadingContentText isLoading={isEventsLoading}>There are no events</LoadingContentText>
                </Box>
            )}
        </Box>
    );
}
