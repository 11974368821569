import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    Text,
    ModalOverlay,
    Flex,
    ModalCloseButton,
    VStack,
} from '@chakra-ui/react';

import { Controller, useForm } from 'react-hook-form';

import { CheckboxGroup, CustomCheckbox, CustomCheckboxTextarea, useLoadingTextWithFetch } from '@/shared/ui';
import { IssueTypes, troubleCheckboxes } from './data';
import { consoleLogger, fetchData } from '@/shared/lib';
import { URLS } from '@/common/constants/global';

type InputsData = {
    troubles: IssueTypes[];
    [IssueTypes.OTHER]: boolean;
    [IssueTypes.TEXT_OTHER]: string;
};

export default function VideoTroubleModal({
    courseId,
    isOpen,
    onClose,
}: {
    courseId: number;
    isOpen: boolean;
    onClose: () => void;
}) {
    const {
        control,
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<InputsData>();

    const { textedFetch, isSubmitted, loadingText, sendError, isSended } = useLoadingTextWithFetch(
        async (issuesObject) => {
            await fetchData(URLS.getVideoTrouble(courseId), {
                method: 'POST',
                body: JSON.stringify(issuesObject),
            });
        },
    );

    const hasErrors = Object.keys(errors).length > 0;

    async function onSubmit(data: InputsData) {
        const issuesObject = {
            [IssueTypes.VIDEO_NOT_SHOWING]: data.troubles
                ? data.troubles.includes(IssueTypes.VIDEO_NOT_SHOWING)
                : false,
            [IssueTypes.AUDIO_WORKS_NO_VIDEO]: data.troubles
                ? data.troubles.includes(IssueTypes.AUDIO_WORKS_NO_VIDEO)
                : false,
            [IssueTypes.POOR_QUALITY]: data.troubles ? data.troubles.includes(IssueTypes.POOR_QUALITY) : false,
            [IssueTypes.OTHER]: data[IssueTypes.OTHER] || false,
            [IssueTypes.TEXT_OTHER]: data.text_other,
        };

        await textedFetch(issuesObject);
        consoleLogger.log('data: ', data);
    }

    function onCloseModal() {
        onClose();

        reset({
            troubles: [],
            [IssueTypes.OTHER]: false,
            [IssueTypes.TEXT_OTHER]: '',
        });
    }

    return (
        <Modal
            variant="primary"
            scrollBehavior="inside"
            isOpen={isOpen}
            onClose={onCloseModal}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalCloseButton />

                <ModalBody>
                    <Text textStyle="md2">
                        Something wrong?
                    </Text>

                    <Flex
                        as="form"
                        id="issue-form"
                        flexDir="column"
                        gap={5}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <CheckboxGroup
                            control={control}
                            name="troubles"
                            checkboxList={troubleCheckboxes}
                            rules={{ required: true }}
                            CheckboxElement={({ checkboxData, ...restProps }) => (
                                <CustomCheckbox
                                    checkboxData={checkboxData}
                                    checkboxProps={restProps}
                                />
                            )}
                            styles={{
                                flexDir: 'column',
                                gap: 5,
                            }}
                        />
                        <Controller
                            name={IssueTypes.OTHER}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <CustomCheckboxTextarea
                                    text="Other Issue?"
                                    isChecked={value}
                                    onCheckboxChange={(isChecked) => {
                                        onChange(isChecked);

                                        if (!isChecked) {
                                            setValue(IssueTypes.TEXT_OTHER, '');
                                        }
                                    }}
                                    register={register(IssueTypes.TEXT_OTHER)}
                                    isInvalid={!!errors[IssueTypes.TEXT_OTHER]}
                                />
                            )}
                        />
                    </Flex>
                </ModalBody>

                <ModalFooter mt={{ base: 5, md: 10 }}>
                    {isSended ? (
                        <Text
                            mb={0}
                            fontWeight={700}
                        >
                            Thank you!
                        </Text>
                    ) : (
                        <VStack>
                            <Button
                                type="submit"
                                form="issue-form"
                                isLoading={isSubmitted}
                                isDisabled={hasErrors}
                                loadingText={loadingText}
                                w="100% !important"
                            >
                                Submit
                            </Button>

                            {sendError && (
                                <Text
                                    mb={0}
                                    color="red.500"
                                >
                                    {sendError}
                                </Text>
                            )}

                            {hasErrors && (
                                <Text
                                    mb={0}
                                    color="red.500"
                                >
                                    Choose option
                                </Text>
                            )}
                        </VStack>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
