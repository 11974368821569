import { Box, Button, Text } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';

import { userAtom } from '@/shared/store';
import { useEffect } from 'react';
import { useLoadingTextWithFetch } from '@/shared/ui';
import { fetchData, METHODS } from '@/shared/lib';
import { URLS } from '@/common/constants/global';

export default function CheckYourInboxScreen({ onBtnClick }: { onBtnClick: () => void }) {
    const user = useAtomValue(userAtom);
    const { loadingText, sendError, isSubmitted, textedFetch } = useLoadingTextWithFetch(async () => {
        await fetchData(URLS.UNSUB_POST_EMAIL, { method: METHODS.POST });
    });

    useEffect(() => {
        textedFetch();
    }, []);

    return (
        <Box
            px={{ base: 6, md: 10 }}
            py={10}
        >
            <Text
                textStyle="lg"
                textAlign={'center'}
            >
                Please Check Your Inbox
            </Text>

            <Text mb={4}>
                To complete the cancellation, please confirm it using the email we sent to {user?.email}. If you don’t
                see the email within a few minutes, check your spam or junk folder.
            </Text>

            <Button
                onClick={onBtnClick}
                loadingText={loadingText}
                isLoading={isSubmitted}
                w="100%"
            >
                Okay
            </Button>

            {sendError && (
                <Text
                    color="red.500"
                    textAlign="center"
                    w="100%"
                    mb={0}
                >
                    {sendError}
                </Text>
            )}
        </Box>
    );
}
