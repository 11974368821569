import { AccordionItem, AccordionButton, AccordionPanel, Text, Center, Box } from '@chakra-ui/react';

import { HTMLRenderer, IconMap, IconNames } from '@/shared/ui';

export default function TildaFaqItem({ title, description }: { title: string; description: string }) {
    return (
        <AccordionItem>
            {({ isExpanded }) => (
                <>
                    <AccordionButton pos="relative">
                        <Text
                            as="span"
                            flex="1"
                            textAlign="left"
                            fontWeight={500}
                        >
                            {title}
                        </Text>

                        <Box
                            as={IconMap[IconNames.PlusTiny]}
                            color="white"
                            w="22px"
                            transition="all .25s ease-in-out"
                            transformOrigin={isExpanded ? 'center center' : ''}
                            transform={isExpanded ? 'rotate(-45deg)' : ''}
                        />
                    </AccordionButton>

                    <AccordionPanel>
                        <HTMLRenderer
                            className="text-wrap"
                            html={description}
                        />
                    </AccordionPanel>
                </>
            )}
        </AccordionItem>
    );
}
