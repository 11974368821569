import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { inputAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const defaultVariant = definePartsStyle({
    field: {
        width: '100%',
        height: 'auto',
        py: { base: 4.25, lg: 4 },
        px: 5,
        border: '1px solid',
        borderRadius: 'xl',
        backgroundColor: 'white',
        fontSize: { base: 'sm', lg: 'md' },
        lineHeight: { base: '20px', lg: '24px' },

        _invalid: {
            borderColor: 'red.500',
        },
    },
});

const greenBorderVariant = definePartsStyle({
    field: {
        height: 'auto',
        py: 2,
        px: 5,
        border: '2px solid',
        borderColor: 'green.400',
        borderRadius: 'xl',
        fontSize: { base: 'sm', lg: 'md' },
        lineHeight: { base: '20px', lg: '24px' },

        _invalid: {
            borderColor: 'red.500',
        },
    },
});

const grayBorderVariant = definePartsStyle({
    field: {
        height: 'auto',
        py: { base: 4.25, lg: 4 },
        px: 5,
        p: '19px 30px',
        fontSize: 'sm2',
        borderRadius: 'xl',
        border: '1px solid',
        borderColor: 'gray.375',
        lineHeight: { base: '20px', lg: '24px' },

        _invalid: {
            borderColor: 'red.500',
        },
    },
});

const tildaVariant = definePartsStyle({
    field: {
        height: 'auto',
        py: { base: 4.25, lg: 4 },
        px: 5,
        border: '1px solid',
        borderRadius: 0,
        borderColor: '#000',
        fontSize: { base: 'md' },

        _invalid: {
            borderColor: 'red.500',
        },
    },
});

const commentVariant = definePartsStyle({
    field: {
        height: 'auto',
        p: 3,
        color: 'gray.340',
        border: '1px solid',
        borderColor: 'gray.430',
        fontSize: { base: 'sm', lg: 'md' },
        lineHeight: { base: '20px', lg: '24px' },

        _invalid: {
            borderColor: 'red.500',
        },
    },
});

const selectTheme = defineMultiStyleConfig({
    variants: {
        default: defaultVariant,
        'green-border': greenBorderVariant,
        'gray-border': grayBorderVariant,
        tilda: tildaVariant,
        comment: commentVariant,
    },
    defaultProps: {},
});

export { selectTheme };
