import { Box, Flex, Img, Text } from '@chakra-ui/react';

import { CardReviewProps } from '../model/types';

export default function CardReviewSolid({ photo, authorName, text, rating, styles }: CardReviewProps) {
    return (
        <Flex
            flexDir="column"
            gap={4}
            p="5"
            border="1px solid"
            borderColor="gray.375"
            borderRadius="lg"
            backgroundColor="white"
            {...styles}
        >
            <Flex gap={1}>
                {[...Array(rating)].map((_, index) => (
                    <Img
                        key={index}
                        src="/img/icons/star.svg"
                        alt="star"
                        w="18px"
                        gap="2"
                    />
                ))}
            </Flex>

            <Text
                mb={0}
                fontSize="sm2"
            >
                {text}
            </Text>

            <Flex
                gap={4}
                align="center"
            >
                {photo ? (
                    <Img
                        src={photo}
                        alt="photo"
                        w="48px"
                        h="48px"
                        borderRadius="full"
                        flexShrink="0"
                    />
                ) : (
                    <Box
                        w="48px"
                        h="48px"
                        borderRadius="full"
                        flexShrink="0"
                        backgroundColor="rgba(115,195,113,.3)"
                    />
                )}

                <Flex
                    flexDir="column"
                    gap={1}
                >
                    <Text
                        mb={0}
                        fontWeight="700"
                    >
                        {authorName}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
}
