import { VideoDataResponse } from '@/features/video-player';

export interface Author {
    name: string;
    about: string;
    photo: string;
}

export interface CourseMaterialsData {
    url: string;
    title: string;
}

export interface Testimonial {
    id: number;
    course_id: number;
    user_id: number;
    rating: number;
    status: number;
    name: string;
    text: string;
    avatar_url: string;
    media: Media[];
    created_at: string;
    updated_at: string;
}

interface Media {
    id: number;
    model_type: string;
    model_id: number;
    uuid: string;
    collection_name: string;
    name: string;
    file_name: string;
    mime_type: string;
    disk: string;
    conversions_disk: string;
    size: number;
    manipulations: any[];
    custom_properties: any;
    generated_conversions: {
        avatar: boolean;
        screenshot: boolean;
    };
    responsive_images: any[];
    order_column: number;
    original_url: string;
    preview_url: string;
}

interface Video {
    id: number;
    model_type: string;
    model_id: number;
    duration: number;
    cdn_player: string;
    screenshot: any; // Замените тип any на конкретный тип, если он известен
    type: string;
    task_status: number;
    use_transcoded_path: number;
    created_at: string;
}

interface Lesson {
    id: number;
    title: string;
    course_id: number;
    section_id: number;
    type: string;
    description: string;
    admin_description: string | null; // Может быть null, если не заполнено
    is_hidden: number;
    video: Video;
    preview: any; // Замените тип any на конкретный тип, если он известен
    media: any[]; // Замените тип any на конкретный тип, если он известен
}

interface Section {
    id: number;
    course_id: number;
    title: string;
    created_at: string;
    updated_at: string;
    lessons: Lesson[];
}

export interface CourseData {
    id: number;
    status: number;
    uploading: number;
    title: string;
    slug: string;
    author_id: number;
    author_info: string;
    price: number;
    duration: string;
    excerpt: string;
    description: string;
    admin_description: string;
    lang: CourseLang;
    rating: string;
    payout_percent: any;
    vimeo_folder: any;
    cdn_source: number;
    fb_pixel: string;
    fb_token: string;
    created_at: string;
    updated_at: string;

    playlist_sections?: {
        playlist: VideoDataResponse[];
        materials: CourseMaterialsData[];
    };
    buyers_preview?: {
        buyers_count: number;
        testimonials_count: number;
        amount_str: string;
    };
    authors?: Author[];
    video_intro?: string;
    thumb_big?: string;
    sections?: Section[];
    fb_pixels?: string[];
    testimonials?: Testimonial[];
    payment_gateway?: string;
    edc_discount_code?: any;
    public_key?: string;
    doc_links?: {
        terms_link: string;
        privacy_link: string;
        refund_link: string;
    };
    info?: {
        learn_to: {
            text: string;
            type: string;
            subtitle: string;
            list_title: string;
            additional_list: Record<string, string>;
        };
        descr: {
            text: string;
            type: string;
            subtitle: string;
            images: string[];
        };
        for: {
            text: string;
            type: string;
            subtitle: string;
            images: string[];
            text_after_list: string;
            list_title: string;
            additional_list: Record<string, string>;
        };
        include: {
            list: {
                icon: string;
                text: string;
            }[];
            type: string;
            subtitle: string;
        };
    };

    is_purchased: boolean;
    is_watching: boolean;
    is_discount: boolean;
    course_card_discount: number;
    course_card_price: number;
    promo_blocks_resource: PromoBlock[];
    collections: Collection[];
    rating_user: number | null;
}

export interface LandingData {
    result_code: string;
    result_message: string;
    data: CourseData;
}

export interface CourseInfo {
    title: string;
    rating: string;
    buyersCount: number;
    reviewCount: number;
    description: string;
    authors: Author[];
    updateDate: string;
    videoCount: number;
}

export enum PlacementPromoBlock {
    Dashboard = 'dashboard',
    Player = 'player',
    ThankYouPage = 'thank_you_page',
}

interface Product {
    id: number;
    thumb: string;
    title: string;
    slug: string;
    excerpt: string;
}

export interface PromoBlock {
    id: number;
    title: string;
    description: string;
    button_text: string;
    placement: PlacementPromoBlock;
    price: string;
    product: Product;
    button_background_color: string;
    button_text_color: string;
    background_image_url: {
        desktop: string;
        mobile: string;
    };
}

export enum CourseLang {
    Ru = 'ru',
    En = 'en',
}

interface Pivot {
    course_id: number;
    collection_id: number;
}

interface Collection {
    id: number;
    title: string;
    title_2: string;
    title_3: string;
    hide_from: string; // JSON-строка, возможно, массив строк (лучше парсить при использовании)
    order: number;
    created_at: string;
    updated_at: string;
    pivot: Pivot;
}
