import { useState } from 'react';
import { Box, Button, Collapse, Flex, Text, Textarea } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { RiArrowLeftLine } from 'react-icons/ri';

import { CheckboxGroupTextaread, CustomCheckboxSquare, useLoadingTextWithFetch } from '@/shared/ui';
import { fetchData, METHODS } from '@/shared/lib';
import { URLS } from '@/common/constants/global';

const CHECKBOX_LIST = [
    { value: 'Too expensive', label: 'Too expensive' },
    { value: 'Switching to another platform or service', label: 'Switching to another platform or service' },
    { value: 'Didn’t find the courses/content I wanted', label: 'Didn’t find the courses/content I wanted' },
    { value: 'Confusing or difficult to use', label: 'Confusing or difficult to use' },
    { value: 'other', label: 'Other (please specify)' },
];
const FORM_ID = 'cancel-reason-form';

type Inputs = {
    cancel_reason: string[];
    other: string;
};

export default function ReasonCancelScreen({
    onPrev,
    onNext,
    onStayBtn,
}: {
    onPrev: () => void;
    onNext: () => void;
    onStayBtn: () => void;
}) {
    const {
        register,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();

    const [isTextareaVisible, setTextareaVisible] = useState(false);
    const { loadingText, sendError, isSubmitted, textedFetch } = useLoadingTextWithFetch(async (data) => {
        await fetchData(URLS.SUB_CANCEL_REASON, {
            method: METHODS.POST,
            body: JSON.stringify({ reason: data }),
        });
    });

    async function onSubmit(data: Inputs) {
        const result = data.cancel_reason
            .map((reason: string) => (reason === 'other' && data.other ? data.other : reason)) // Заменяем "other" на текст из data.other
            .join(', ');

        await textedFetch(result);
        onNext();
    }

    return (
        <Box
            pos="relative"
            px={{ base: 6, md: 10 }}
            py={10}
        >
            <Box
                hideFrom="md"
                as={RiArrowLeftLine}
                pos="absolute"
                top="13px"
                left="16px"
                w="20px"
                h="20px"
                onClick={onPrev}
            />

            <Text
                textStyle="lg"
                textAlign={'center'}
            >
                We’d love for you to stay with us!
            </Text>

            <Text
                textStyle="sm"
                mb={6}
            >
                Before you unsubscribe, we’d love to know why. Your feedback helps us improve and create better
                experiences for all our users.
            </Text>

            <Text
                textStyle="md5"
                fontWeight={700}
            >
                What’s the main reason for canceling your subscription?
            </Text>

            <Text
                textStyle="sm"
                mb={4}
                color="gray.500"
            >
                Select all that apply
            </Text>

            <Box
                as="form"
                id={FORM_ID}
                onSubmit={handleSubmit(onSubmit)}
                mb={6}
            >
                <CheckboxGroupTextaread
                    name="cancel_reason"
                    control={control}
                    checkboxList={CHECKBOX_LIST}
                    rules={{
                        required: 'Please select at least one option.',
                    }}
                    styles={{
                        flexDir: 'column',
                        gap: '8px',
                    }}
                    textareaControlledFn={(isChecked) => setTextareaVisible(isChecked)}
                    CheckboxElement={(props) => {
                        const { checkboxData, ...restProps } = props;

                        return (
                            <CustomCheckboxSquare
                                checkboxData={checkboxData}
                                checkboxProps={restProps}
                            />
                        );
                    }}
                />

                <Collapse
                    in={isTextareaVisible}
                    animateOpacity
                >
                    <Textarea
                        mt={2}
                        variant="simple"
                        border="1px solid rgba(0, 0, 0, 0.2)"
                        borderRadius="8px"
                        placeholder="Enter your issue"
                        {...register('other')}
                    />
                </Collapse>

                {errors.cancel_reason && (
                    <Text
                        mt={2}
                        color="red.500"
                        textAlign="center"
                    >
                        {errors.cancel_reason.message}
                    </Text>
                )}
            </Box>

            <Flex
                flexDir={{ base: 'column', md: 'row' }}
                flexWrap={'wrap'}
                gap={{ base: 2, md: 5 }}
            >
                <Button
                    onClick={onStayBtn}
                    w={{ md: '100%' }}
                >
                    Keep Subscription
                </Button>

                <Button
                    onClick={onPrev}
                    hideBelow="md"
                    w="calc(50% - 10px)"
                    bg="transparent"
                    border="1px solid"
                    borderColor="gray.500"
                    color="gray.500"
                    _hover={{
                        bg: 'transparent',
                        opacity: 0.7,
                    }}
                >
                    Back
                </Button>

                <Button
                    type="submit"
                    form={FORM_ID}
                    loadingText={loadingText}
                    isLoading={isSubmitted}
                    variant="gray"
                    w={{ base: '100%', md: 'calc(50% - 10px)' }}
                >
                    Continue to Cancel
                </Button>

                {sendError && (
                    <Text
                        color="red.500"
                        textAlign="center"
                        w="100%"
                        mb={0}
                    >
                        {sendError}
                    </Text>
                )}
            </Flex>
        </Box>
    );
}
