import { Box, BoxProps, Image, Text } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';

import { useEventsList } from '@/features/socials/events/get-events';
import { userAtom } from '@/shared/store';
import { LoadingContentText } from '@/shared/ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EventData } from '@/entities/events/event-data';

interface SliderProps {
    styles?: BoxProps;
}

export default function HeadingNavEventsSliderSimple({ styles }: SliderProps) {
    const user = useAtomValue(userAtom);
    const { eventList, isEventsLoading } = useEventsList(Boolean(user));

    return (
        <Box {...styles}>
            {eventList?.upcomingEvents && eventList?.upcomingEvents.length > 0 ? (
                <Swiper
                    slidesPerView="auto"
                    spaceBetween="8"
                    breakpoints={{
                        768: {
                            spaceBetween: 16,
                        },
                    }}
                    slidesOffsetBefore={-50}
                >
                    {eventList.upcomingEvents.map((item: EventData, index: number) => (
                        <SwiperSlide
                            key={item.id}
                            style={{
                                width: 'auto',
                                height: 'auto',
                            }}
                        >
                            <Box
                                pos="relative"
                                h={{ base: '160px', md: '200px' }}
                                w={{ base: '300px', md: '376px' }}
                                bgColor="rgba(17, 17, 17, 0.15)"
                                borderRadius={{ base: '13px', md: '16px' }}
                            >
                                <Image
                                    src={item.thumb}
                                    alt={item.title}
                                    borderRadius="inherit"
                                    objectFit="cover"
                                />
                                <Text
                                    pos="absolute"
                                    left={{ base: '16px', md: '20px' }}
                                    bottom={{ base: '18px', md: '20px' }}
                                    maxW="230px"
                                    mb={0}
                                    color="white"
                                    fontSize={{ base: '14px', md: '18px' }}
                                    fontWeight={600}
                                >
                                    {item.title}
                                </Text>
                            </Box>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                <Box
                    pos="relative"
                    h="230px"
                >
                    <LoadingContentText isLoading={isEventsLoading}>There are no events</LoadingContentText>
                </Box>
            )}
        </Box>
    );
}
