import { BoxProps, HStack, Tag } from '@chakra-ui/react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';

const tagsArray = ['Top-tier authors', '4.9 ranked', '50k+ reviews'];
export const tagsArray2 = ['Worlds-Best Authors', '4.9 ranked', '50k+ reviews'];

export default function TagsElements({ tags = tagsArray, styles }: { tags?: string[]; styles?: BoxProps }) {
    return (
        <OverlayScrollbarsComponent options={{ scrollbars: { visibility: 'hidden' } }}>
            <HStack {...styles}>
                {tags.map((tag, index) => (
                    <Tag
                        key={index}
                        variant="outline"
                        flexShrink={0}
                        px={3}
                        borderRadius="xl"
                        color="gray.675"
                    >
                        {tag}
                    </Tag>
                ))}
            </HStack>
        </OverlayScrollbarsComponent>
    );
}
