import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';
import { RiShieldCheckLine, RiStarSFill } from 'react-icons/ri';

import { HELLO_EMAIL } from '@/common/constants/brand';

const REVIEW_LIST = [
    {
        imgName: 'glenna',
        name: 'Glenna Gerstenkorn',
        text: `I wasn’t sure at first, but giving this a try turned out to be one of the best decisions. It opened the door to new practices I never thought I’d enjoy — now I feel more in tune with myself than ever before.`,
    },
    {
        imgName: 'qing',
        name: 'Qing Liu',
        text: `Every lesson feels like a breath of fresh air. The variety keeps me inspired, and I actually look forward to making time for myself again.`,
    },
    {
        imgName: 'carolyn',
        name: 'Carolyn Dwyer',
        text: `I love how the platform brings everything together in one space — practices, guidance, and a supportive team that’s always there when needed. It really makes the journey feel effortless and inspiring.`,
    },
];

export default function PaymentStepFooter() {
    return (
        <>
            <Box
                p={6}
                borderRadius={'8px'}
                mb={6}
                boxShadow={'0px 0px 20px 0px rgba(128, 128, 128, 0.2)'}
                fontSize={'14px'}
            >
                <Text mb={4}>Payments are processed via Authorize.net.</Text>

                <Text>
                    <b>Start practicing immediately after filling out the form and paying.</b> Hurry up to buy a course
                    at a low price while the special offer is still valid.
                </Text>

                <Text mb={4}>
                    <b>14 days money back guarantee:</b> if the course does not meet your expectations within the first
                    fourteen days after purchase, we will refund the full price no questions asked. Simply send an email
                    to support at{' '}
                    <Link
                        href={`mailto:${HELLO_EMAIL}`}
                        color={'green.400'}
                    >
                        {HELLO_EMAIL}
                    </Link>
                    .
                </Text>

                <Flex gap={1}>
                    <Box
                        as={RiShieldCheckLine}
                        color={'green.400'}
                        w={6}
                        h={6}
                    />
                    <Text>
                        <b>100% secure payment</b> powered by <b>Authorize.net</b>
                    </Text>
                </Flex>
            </Box>

            <Box
                p={5}
                borderRadius={'8px'}
                mb={6}
                boxShadow={'0px 0px 20px 0px rgba(128, 128, 128, 0.2)'}
                fontSize={'14px'}
                textAlign={'center'}
            >
                <Image
                    src="/img/icons/img/trustpilot-stared.svg"
                    alt="trustpilot-stared"
                    mb={2}
                    mx={'auto'}
                    h={'70px'}
                />
                <Flex
                    gap={2}
                    justify={'center'}
                >
                    <Text as={'b'}>Trust score 4.1</Text> | <Text as={'b'}>34,275 reviews</Text>
                </Flex>
            </Box>

            <Box>
                <Text
                    mb={4}
                    fontSize={'20px'}
                    fontWeight={600}
                >
                    Users talk about their progress
                </Text>

                <Flex
                    flexDir={'column'}
                    gap={2}
                >
                    {REVIEW_LIST.map((review) => (
                        <Flex
                            key={review.name}
                            gap={2}
                            p={6}
                            bgColor={'gray.370'}
                            borderRadius={'8px'}
                            border={'1px solid'}
                            borderColor={'gray.415'}
                        >
                            <Image
                                src={`/img/reviews/${review.imgName}.png`}
                                alt="course-grid"
                                w={'60px'}
                                h={'60px'}
                            />

                            <Box>
                                <Flex
                                    gap={'2px'}
                                    mb={1}
                                >
                                    {Array.from({ length: 5 }).map((_, index) => (
                                        <Box
                                            key={index}
                                            as={RiStarSFill}
                                            w={'16px'}
                                            h={'16px'}
                                            color={'purple.450'}
                                        />
                                    ))}
                                </Flex>

                                <Text
                                    mb={1}
                                    fontSize={'18px'}
                                    fontWeight={600}
                                >
                                    {review.name}
                                </Text>

                                <Text
                                    fontSize={'14px'}
                                    lineHeight={1.3}
                                >
                                    {review.text}
                                </Text>
                            </Box>
                        </Flex>
                    ))}
                </Flex>
            </Box>
        </>
    );
}
