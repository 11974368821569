import { useState, useEffect } from 'react';
import { Button } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';

import { userAtom } from '@/shared/store';
import { METHODS, fetchData } from '@/shared/lib';
import { URLS } from '@/common/constants/global';

export default function CabinetUserNameData() {
    const user = useAtomValue(userAtom);

    const [firstName, setFirstName] = useState(user?.first_name);
    const [lastName, setLastName] = useState(user?.last_name);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFirstName(user?.first_name);
        setLastName(user?.last_name);
    }, [user]);

    const hasChanges = firstName !== user?.first_name || lastName !== user?.last_name;

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
        setUpdateSuccess(false);
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
        setUpdateSuccess(false);
    };

    const submitUpdateUserData = async () => {
        if (!hasChanges) {
            return;
        }

        setLoading(true);

        const responseData = await fetchData(URLS.USERS_DATA, {
            method: METHODS.PUT,
            body: JSON.stringify({
                first_name: firstName,
                last_name: lastName,
            }),
        });

        if (responseData) {
            setUpdateSuccess(true);
            setLoading(false);
        }
    };

    return (
        <>
            <div
                className="user-info__field-wrap row"
                style={{ position: 'relative' }}
            >
                <span className="user-info__field-title col-12 col-xl-2">Name</span>
                <div className="hstack gap-2 col-12 col-xl-10">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        value={firstName}
                        onChange={handleFirstNameChange}
                    />
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={handleLastNameChange}
                    />
                </div>
                <p className="user-info__field-description col-12 col-xl-10 offset-xl-2">
                    Visible when you post comments on courses
                </p>
                {hasChanges && !updateSuccess && (
                    <Button
                        isLoading={!updateSuccess && loading}
                        onClick={submitUpdateUserData}
                        colorScheme="blue"
                        position="absolute"
                        right={0}
                        bottom={-5}
                        width="auto"
                    >
                        Save
                    </Button>
                )}
            </div>
            <div className="user-info__field-wrap row">
                <span className="user-info__field-title col-12 col-xl-2">Email</span>
                <div className="col-12 col-xl-10">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="email"
                        value={user?.email}
                        readOnly
                    />
                </div>
                <p className="user-info__field-description col-12 col-xl-10 offset-xl-2">
                    Used for signing into your account
                </p>
            </div>
        </>
    );
}
