import { Box, Center, Flex, HStack, VStack } from '@chakra-ui/react';
import { Picture, PictureType } from '@/shared/ui';
import { SpecialOfferForm } from '@/features/form/special-offer';
import { useMemo } from 'react';

const getImgsList = () => {
    const list: string[] = [];
    for (let i = 1; i < 12; i++) {
        list.push(`authors/ticker/ticker-${i}.jpg`);
    }
    return list;
};

const TICKER_IMGS_LIST = getImgsList();

const TickerImgElement = ({ item }: { item: string }) => {
    return (
        <Box
            key={item}
            w={{ base: '102px', md: '160px' }}
            h={{ base: '154px', md: '240px' }}
            borderRadius="xl"
            overflow="hidden"
            backgroundColor="blue"
        >
            <Picture
                src={item}
                alt="author"
                type={PictureType.Simple}
            />
        </Box>
    );
};

export default function PaywallSpecialOffer() {
    const tickerImgList = useMemo(() => TICKER_IMGS_LIST, []);

    return (
        <Flex
            flexDir={{ base: 'column', lg: 'row' }}
            maxH="700px"
            borderRadius="xl"
            backgroundColor="gray.650"
            backgroundImage={'url(/img/bg/pink-point.png)'}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
        >
            <Box w={{ lg: '50%' }}>
                <HStack
                    pos="relative"
                    gap={{ base: 2, md: 2.5 }}
                    justify="center"
                    maxH={{ base: '200px', md: '100%' }}
                    overflow="hidden"
                >
                    <VStack
                        gap={{ md: 2.5 }}
                        mt="120px"
                    >
                        {tickerImgList.slice(0, 3).map((item) => (
                            <TickerImgElement
                                key={item}
                                item={item}
                            />
                        ))}
                    </VStack>

                    <VStack
                        gap={{ md: 2.5 }}
                        mt="60px"
                    >
                        {tickerImgList.slice(3, 6).map((item) => (
                            <TickerImgElement
                                key={item}
                                item={item}
                            />
                        ))}
                    </VStack>

                    <VStack gap={{ md: 2.5 }}>
                        {tickerImgList.slice(6, 9).map((item) => (
                            <TickerImgElement
                                key={item}
                                item={item}
                            />
                        ))}
                    </VStack>
                </HStack>
            </Box>

            <SpecialOfferForm
                styles={{
                    as: Center,
                    flexDir: 'column',
                    alignItems: 'flex-start',
                    w: { lg: '50%' },
                    px: { base: 5, lg: '60px' },
                    py: 10,
                    borderRadius: 'xl',
                    backgroundImage: 'url(/img/bg/white-elipse.png)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%',
                }}
            />
        </Flex>
    );
}
