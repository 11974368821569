import { Box, BoxProps, HStack, Text } from '@chakra-ui/react';

import { Picture, IconMap, IconNames } from '@/shared/ui';

export default function AuthorPromoImgRounded({
    imgSrc,
    priceValue,
    styles,
    imgStyles,
    tagStyles,
}: {
    imgSrc: string;
    priceValue: string;
    styles?: BoxProps;
    imgStyles?: BoxProps;
    tagStyles?: BoxProps;
}) {
    return (
        <Box
            pos="relative"
            w={100}
            h={100}
            {...styles}
        >
            <Picture
                src={imgSrc}
                alt={'author-girl'}
                imgStyle={{
                    w: '100%',
                    h: '100%',
                    objectFit: 'cover',
                    borderRadius: 'full',
                    ...imgStyles,
                }}
            />

            {window.location.pathname === '/' && (
                <HStack
                    pos="absolute"
                    zIndex={30}
                    w={120}
                    py={{ base: '2px', lg: '8px' }}
                    px={{ base: '6px', lg: '8px' }}
                    borderRadius="24px"
                    backgroundColor="white"
                    boxShadow="0 0 20px 0 rgba(0, 0, 0, 0.1)"
                    {...tagStyles}
                >
                    <Box
                        as={IconMap[IconNames.LineChartUp]}
                        w="17px"
                        h="13px"
                        color="green.400"
                        flexShrink={0}
                    />

                    <Text
                        as="span"
                        fontSize={{ base: '9px', lg: 'md' }}
                        lineHeight={{ base: '9px', lg: '16px' }}
                        textAlign="left"
                    >
                        ${priceValue}
                        <Box
                            as="br"
                            hideFrom="lg"
                        />{' '}
                        per month
                    </Text>
                </HStack>
            )}
        </Box>
    );
}
