import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const defaultVariant = definePartsStyle({
    container: {
        display: 'flex',
        flexDir: 'column',
        gap: '14px',
        m: '0 !important',
    },
    item: {
        display: 'flex',
        gap: 4,
        listStyleType: 'none',
    },
});

const listTheme = defineMultiStyleConfig({
    variants: {
        default: defaultVariant,
    },
});

export { listTheme };
