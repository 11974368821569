import { BoxProps, Button, Flex, Text, VStack } from '@chakra-ui/react';

import { useAtomValue } from 'jotai';

import { userAtom } from '@/shared/store';
import { CongratulationsModal } from '@/entities/modals';
import { ActionSubType, useCreateSub } from '@/features/subscription/create-sub';
import { SubPeriod } from '@/entities/user/user-data';

const SAVE_PRICE = 569;
const MONTH_PRICE = 21;
const YEAR_PRICE = 259;

export default function CabinetUserBannerYear({ styles }: { styles?: BoxProps }) {
    const { isSubLoading, isSubError, isSubCreate, createSub, sendError } = useCreateSub(
        ActionSubType.CABINET_USER_BANNER_UPGRADE_YEAR,
    );
    const user = useAtomValue(userAtom);

    if (!user?.is_subscribe || (user?.is_subscribe && user?.subscription_plan?.period === SubPeriod.Year)) {
        return null;
    }

    return (
        <Flex
            flexDir={{ base: 'column', lg: 'row' }}
            align="center"
            justify="space-between"
            gap={5}
            py={{ base: 7.5, lg: 4 }}
            px={{ base: 5, lg: 10 }}
            pr={{ xl: 15 }}
            color="white"
            backgroundImage={{
                base: '/img/bg/blue-pink-to-right.png',
                lg: '/img/bg/blue-pink-to-right-wide.png',
            }}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundPosition="50%"
            borderRadius="lg"
            {...styles}
        >
            <Text
                as="span"
                flexShrink={0}
                py={2.5}
                px={5}
                fontSize="lg"
                fontWeight={700}
                textTransform="uppercase"
                bgImg="/img/bg/ranbow-border.png"
                backgroundRepeat="no-repeat"
                backgroundSize="contain"
            >
                Save ${SAVE_PRICE}
            </Text>

            <VStack
                maxW={{ base: '280px', lg: '260px' }}
                w="100%"
            >
                <Button
                    variant="white"
                    px={0}
                    w="100%"
                    fontSize="sm"
                    fontWeight={500}
                    isLoading={isSubLoading}
                    loadingText={`Submitting`}
                    onClick={createSub}
                >
                    UPGRADE TO YEARLY PLAN
                </Button>

                {isSubError && (
                    <Text
                        mb={0}
                        color="red.500"
                    >
                        {sendError}
                    </Text>
                )}
            </VStack>

            <VStack
                spacing="4px"
                align={{ base: 'center', lg: 'flex-start' }}
                flexShrink={0}
            >
                <Text
                    mb={0}
                    fontWeight={700}
                >
                    just ${MONTH_PRICE} /month
                </Text>

                <Text
                    fontSize="sm2"
                    mb={0}
                >
                    bill yearly ${YEAR_PRICE}
                </Text>
            </VStack>

            <CongratulationsModal
                isOpen={isSubCreate}
                onClose={() => location.reload()}
            />
        </Flex>
    );
}
