'use client';

import { useRef } from 'react';
import { Box, Link, Text } from '@chakra-ui/react';

import { APP_BRAND } from '@/common/constants/brand';
import CheckoutCartDesc from './CheckoutCartDesc';
import CheckoutEmail from './CheckoutEmail';
import CheckoutFloatBtn from './CheckoutFloatBtn';
import CheckoutPaymentDesc from './CheckoutPaymentDesc';
import CheckouStripeElements from './CheckouStripeElements';
import { typeCheckoutCheckedItemsAtom } from '../../model/checkout-store';
import { useAtomValue } from 'jotai';
import { TypeOfCourse } from '../../model/useCheckoutPage';

export default function CheckoutStripe() {
    const typeCheckoutCheckedItems = useAtomValue(typeCheckoutCheckedItemsAtom);

    const btnObservedBlock = useRef<HTMLDivElement | null>(null);

    return (
        <Box w={{ base: '100%', lg: '50%' }}>
            <Box
                p={4}
                bgColor="white"
                ref={btnObservedBlock}
            >
                <Text fontSize="28px">Confirm your purchase</Text>

                <CheckoutEmail />

                <CheckouStripeElements />

                <Text
                    mt={3}
                    mb={0}
                    fontSize="13px"
                    color="gray.500"
                >
                    By clicking &#34;Complete purchase&#34; I am confirming I am 16 or older and I accept the{' '}
                    <Link
                        href={`https://account.${APP_BRAND}.com/terms-of-service`}
                        textDecoration="underline"
                    >
                        Terms of Use
                    </Link>{' '}
                    ,{' '}
                    <Link
                        href={`https://account.${APP_BRAND}.com/privacy-policy`}
                        textDecoration="underline"
                    >
                        Privacy Policy
                    </Link>{' '}
                    and agree to receive news and promotions.
                </Text>

                <CheckoutPaymentDesc />

                <CheckoutCartDesc styles={{ mt: 5, hideFrom: 'lg' }} />

                {typeCheckoutCheckedItems === TypeOfCourse.Trial && (
                    <CheckoutFloatBtn btnObservedBlock={btnObservedBlock} />
                )}
            </Box>
        </Box>
    );
}
