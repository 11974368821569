import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
    control: {
        width: '18px',
        height: '18px',
        m: '3px',
        borderColor: 'green.400',

        _checked: {
            width: '24px',
            height: '24px',
            m: 0,
            backgroundColor: 'transparent',
            border: 'none',

            _hover: {
                background: 'transparent',
            },
        },
    },
    container: {
        display: 'flex',
        alignItems: 'flex-start',

        _hover: {
            '.chakra-checkbox__control': {
                opacity: '0.7',
            },
        },
    },
    label: {
        ms: '2.5',
    },
    icon: {},
});

const checkboxTheme = defineMultiStyleConfig({ baseStyle });

export { checkboxTheme };
