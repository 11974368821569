import { Button, Box } from '@chakra-ui/react';
import { RiShoppingCartLine } from 'react-icons/ri';

import { useVisibleEl } from '@/shared/lib';

export default function CheckoutFloatBtn({ btnObservedBlock }: any) {
    const { isBlockVisible: isTargetBlockVisible } = useVisibleEl(btnObservedBlock);

    const scrollToBlock = () => {
        if (btnObservedBlock && btnObservedBlock.current) {
            btnObservedBlock.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <Button
            onClick={scrollToBlock}
            hideFrom="md"
            opacity={isTargetBlockVisible ? '0' : '1'}
            visibility={isTargetBlockVisible ? 'hidden' : 'visible'}
            pos="fixed"
            left="50%"
            transform="translateX(-50%)"
            bottom={3}
            display="flex"
            gap={2}
            mx="auto"
            zIndex={50}
            w="calc(100% - 40px)"
        >
            <Box
                as={RiShoppingCartLine}
                w="18px"
                h="18px"
            />
            Complete purchase
        </Button>
    );
}
