import { useState } from 'react';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';

import { HTMLRenderer } from '@/shared/ui';

export default function CartThirdStep({
    handleSubmit,
    goToNextStep,
    isSubmitted,
    totalPrice,
    setIsTrackerAdded,
    isTrackerAdded,
}: any) {
    const [totalPriceWithEvent, setTotalPriceWithEvent] = useState(totalPrice);

    const BtnEl = ({ styles }: any) => {
        return (
            <Flex
                gap={2.5}
                {...styles}
            >
                <Flex
                    gap={1}
                    align="baseline"
                >
                    <Text
                        as="span"
                        fontSize="20px"
                        fontWeight={500}
                    >
                        $29
                    </Text>
                    <Text
                        as="span"
                        textDecor="line-through"
                        color="gray.500"
                    >
                        $59
                    </Text>
                </Flex>

                <Button
                    px={5}
                    py={2}
                    onClick={() => {
                        if (!isTrackerAdded) {
                            setTotalPriceWithEvent(totalPriceWithEvent + 29);
                            setIsTrackerAdded.on();
                        } else {
                            setTotalPriceWithEvent(totalPriceWithEvent - 29);
                            setIsTrackerAdded.off();
                        }
                    }}
                >
                    {isTrackerAdded ? 'Remove' : 'Add'}
                </Button>
            </Flex>
        );
    };

    return (
        <Box
            as="form"
            onSubmit={handleSubmit(goToNextStep)}
        >
            <Text
                mb={{ base: 2.5, md: 5 }}
                fontSize={{ base: '22px', md: '26px' }}
                lineHeight={1.4}
                fontWeight={600}
            >
                Add the Personal Tracker to Support Your Growth!
            </Text>

            <Box
                p="12px"
                border="1px solid"
                borderRadius="10px"
                css={{
                    borderImage:
                        'linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%)',
                    borderImageSlice: 1,
                }}
            >
                <Flex
                    alignItems="flex-start"
                    gap={{ base: 2.5, md: 5 }}
                    mb={{ base: 3, md: 4 }}
                >
                    <Image
                        src="/img/promo/upsells/tracker.png"
                        alt="event"
                        w={{ base: '94px', md: '142px' }}
                        borderRadius="8px"
                    />

                    <Box>
                        <Text
                            mb={0}
                            fontSize={{ base: '12px', md: '14px' }}
                            fontWeight={700}
                            letterSpacing="-0.25px"
                            css={{
                                textWrap: 'pretty',
                            }}
                        >
                            The Personal Tracker Journal helps you set goals, track habits, and stay aligned with your
                            growth.
                        </Text>

                        <BtnEl
                            styles={{
                                mt: { base: 4, md: 2 },
                            }}
                        />
                    </Box>
                </Flex>

                <Box fontSize={{ base: '12px', md: '14px' }}>
                    {[
                        'Set and Track Goals: Break down objectives into clear, actionable steps that are easy to follow.',
                        'Daily Wellness Check: Keep track of your daily routines, moods, and reflections to support a balanced lifestyle.',
                        'Gratitude Journal: Regularly note down achievements and positive moments to maintain a constructive mindset.',
                    ].map((item, index) => {
                        return (
                            <Flex
                                key={index}
                                align="baseline"
                                gap={2}
                            >
                                <svg
                                    viewBox="0 0 8 8"
                                    width="8px"
                                    height="8px"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ flexShrink: 0 }}
                                >
                                    <circle
                                        cx="4"
                                        cy="4"
                                        r="4"
                                        fill="#73C371"
                                    />
                                </svg>

                                <HTMLRenderer
                                    html={item}
                                    styles={{ sx: { p: { mb: 0 } } }}
                                />
                            </Flex>
                        );
                    })}
                </Box>
            </Box>

            <Box
                mt={8}
                mb={4}
                fontWeight={600}
                textAlign="right"
            >
                <Text as="span">$ {Number(totalPriceWithEvent).toFixed(2)}</Text>
            </Box>

            <Button
                type="submit"
                p={5}
                mt={2}
                w="100%"
                fontSize="sm3"
                fontWeight={500}
                isLoading={isSubmitted}
                loadingText="Submitting..."
            >
                PROCEED TO CHECKOUT
            </Button>
        </Box>
    );
}
