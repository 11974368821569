export function isElementPartiallyVisible(element: any, visibilityThreshold: number = 1) {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    const elementHeight = rect.height;

    // Количество пикселей, которое должно быть видно
    const visiblePart = elementHeight * visibilityThreshold;

    // Фактически видимая часть элемента
    const visibleHeight = Math.max(0, Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0));

    // Для полной видимости используется >=, для частичной > (если threshold изменен)
    if (visibilityThreshold === 1) {
        return visibleHeight >= visiblePart;
    }
    return visibleHeight > visiblePart;
}
