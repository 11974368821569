import getDataFromStripeByActionSubType from './getDataFromStripeByActionSubType';
import { ActionSubType } from '@/features/subscription/create-sub';
import { dispatchBrowserEvent } from '@/shared/lib';

export default function redirectToStripeWithData(actionSubType: ActionSubType, subData?: any) {
    async function redirect() {
        let data = null;

        if (!subData) {
            const result = await getDataFromStripeByActionSubType(actionSubType);
            data = result.data;
        }

        if (data || subData) {
            dispatchBrowserEvent('buy_subscription_social_user', {
                data_sub: data || subData,
            });
        } else {
            throw new Error('Redirect to stripe failed');
        }
    }

    return redirect;
}
