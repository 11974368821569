import { URLS } from '@/common/constants/global';
import { CheckEmailQuiz } from '@/entities/onboarding/check-email';
import { CheckPhoneQuiz } from '@/entities/onboarding/check-phone';
import { capitalizeFirstLetter, fetchData, METHODS } from '@/shared/lib';
import CustomQuiz from '../../ui/components/CustomQuiz';
import CustomQuizBtned from '../../ui/components/CustomQuizBtned';
import { EventNames } from '../types/event-quiz-types';
import { QuizGroup, QuizGroupType, isCheckboxesQuiz } from '../types/quiz-list-types';
import { COURSE_CATEGORIES_QUIZ_TRIAL_NEW } from './course-categories-list';
import { ALL_COURSES_LIST_NEW_TEST_GENDERED } from './courses-by-category-gender-lists-test-quiz';
import { Gender, COURSE_QUIZ_LIST_AGE_SHORT } from './radio-list';
import { APP_BRAND } from '@/common/constants/brand';
import PictureQuizSlide from '../../ui/components/PictureQuizSlide';
import { Button } from '@chakra-ui/react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import CustomQuizBtned2 from '../../ui/components/CustomQuizBtned2';
import ClassesForYouOnboardingList from '@/entities/promo/classes-for-you/ui/ClassesForYouOnboardingList';

export const QUIZ_LONGEVITY: QuizGroup[] = [
    {
        type: QuizGroupType.GenderSingle,
        title: `Thank you for purchasing the course!`,
        subTitle: 'Let’s personalize your experience. Please select your gender.',
        quizList: [
            {
                name: 'your_gender',
                label: 'Female',
                value: Gender.Female,
            },
            {
                name: 'your_gender',
                label: 'Male',
                value: Gender.Male,
            },
        ],
        eventName: EventNames.Gender,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            const onClickOption = async (formData: any) => {
                try {
                    await fetchData(URLS.UPDATE_PROFILE, {
                        method: METHODS.POST,
                        body: JSON.stringify({
                            gender: formData.formData.radio === 'female' ? 0 : 1,
                        }),
                    });
                } finally {
                    onNextBtnClick(formData);
                }
            };

            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onClickOption}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.Other,
        title: 'Please confirm your email to ensure you receive important updates and access details.',
        eventName: EventNames.CheckEmail,
        Component: ({ userDataFromBlade, onNextBtnClick }) => {
            return (
                <CheckEmailQuiz
                    styles={{
                        maxW: '500px',
                        mx: 'auto',
                        textAlign: 'center',
                        pt: { base: 7.5, md: 12 },
                        sx: {
                            form: {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                px: { md: 12 },
                            },
                        },
                    }}
                    title="Please confirm your email to ensure you receive important updates and access details."
                    userEmail={userDataFromBlade?.email ?? ''}
                    postFormUrl={userDataFromBlade?.urlCheckEmail ?? ''}
                    cb={onNextBtnClick}
                />
            );
        },
    },
    {
        type: QuizGroupType.Other,
        title: 'Please provide your phone number to securely restore access via WhatsApp or SMS if needed.',
        eventName: EventNames.AddPhone,
        Component: ({ onNextBtnClick }) => {
            return (
                <CheckPhoneQuiz
                    styles={{
                        maxW: '500px',
                        mx: 'auto',
                        textAlign: 'center',
                        pt: { base: 7.5, md: 12 },
                        sx: {
                            form: {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            },
                        },
                    }}
                    cb={onNextBtnClick}
                />
            );
        },
    },
    {
        type: QuizGroupType.DefaultSingle,
        title: `Please Share Your Age for Customized Program Suggestions`,
        subTitle: 'Receive recommendations that best match your needs',
        quizList: COURSE_QUIZ_LIST_AGE_SHORT,
        eventName: EventNames.Age,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            const onClickOption = async (formData: any) => {
                try {
                    await fetchData(URLS.UPDATE_PROFILE, {
                        method: METHODS.POST,
                        body: JSON.stringify({
                            age: formData.formData.radio,
                        }),
                    });
                } finally {
                    onNextBtnClick(formData);
                }
            };

            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onClickOption}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        type: QuizGroupType.DefaultSingle,
        title: `How Often Do You Engage in Physical Activities?`,
        subTitle: 'Let us know to recommend wellness programs tailored for health and longevity',
        quizList: [
            {
                name: 'how_often',
                label: 'Daily',
                value: 'Daily',
            },
            {
                name: 'how_often',
                label: 'A few times a week',
                value: 'A few times a week',
            },
            {
                name: 'how_often',
                label: 'Rarely',
                value: 'Rarely',
            },
        ],
        eventName: EventNames.HowOftenDoYouEngage,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        // 6
        type: QuizGroupType.DefaultSingle,
        title: `Did You Know Physical Activities Boost Well-being?`,
        subTitle: 'Regular fitness, yoga, singing, dance, and meditation can reduce stress and enhance health',
        quizList: [
            {
                name: 'extend_life',
                label: 'Yes, I knew',
                value: 'Yes, I knew',
            },
            {
                name: 'extend_life',
                label: "No, that's new to me",
                value: "No, that's new to me",
            },
        ],
        eventName: EventNames.PhysicalActivitiesReduceStress,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        // 7
        type: QuizGroupType.DefaultSingle,
        title: `Did you know that proper sleep, healthy nutrition, and regular physical activity are scientifically proven to extend life?`,
        subTitle:
            'Recent studies on physical activity and longevity, including those from the Journal of the American Medical Association and Oxford University Press, predict that humans could live up to 120-130 years with the right practices.',
        quizList: [
            {
                name: '130_years_life',
                label: 'Yes, I’ve heard about this',
                value: 'Yes, I’ve heard about this',
            },
            {
                name: '130_years_life',
                label: 'No, this is new to me',
                value: 'No, this is new to me',
            },
        ],
        eventName: EventNames.LongYearsLife,
        nextStepsCondition: {
            'No, this is new to me': 0,
            'Yes, I’ve heard about this': 1,
        },
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        // 7a
        type: QuizGroupType.DefaultSingle,
        title: `Great, we’re happy to share this exciting news!`,
        subTitle:
            'Our company has gathered programs that align with the latest trends in longevity to help you live a longer, healthier life.',
        quizList: [
            {
                name: 'tell_me_more',
                label: 'Tell me more',
                value: 'Tell me more',
            },
        ],
        eventName: EventNames.OurCompanyHasPrograms,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    {
        // 8
        type: QuizGroupType.CategorySingle,
        title: `What Areas of Your Life Do You Want to Enhance?`,
        subTitle: 'Select the aspect you’d like to focus on for personal growth',
        quizList: COURSE_CATEGORIES_QUIZ_TRIAL_NEW,
        eventName: EventNames.ChooseCategory,
        categoryList: ALL_COURSES_LIST_NEW_TEST_GENDERED,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuiz
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    quizNumber={currentQuizStepNumber}
                    quizList={'quizList' in currentQuiz ? currentQuiz.quizList : []}
                    isCheckboxes={isCheckboxesQuiz(currentQuiz)}
                    isSubmitOnSelect={true}
                    onNextBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                    ErrorEl={() => null}
                    BottomEl={({ errors, emptyValue }) => null}
                />
            );
        },
    },
    // 9
    {
        type: QuizGroupType.Picture,
        isNextStep: true,
        title: 'Unlock Your Potential Today',
        imgSrc: 'promo/people/portrait-young-woman-exercising-home.png',
        imgDesc: `Did you know? At ${capitalizeFirstLetter(APP_BRAND)}+, we offer over 250 programs designed to enhance your well-being and support longevity, all led by top instructors. Engage in daily live events and challenges designed to keep you motivated, and connected with others on a similar journey to a healthier life.`,
        eventName: EventNames.UnlockPotential,
        Component: ({ currentQuiz, onNextBtnClick, onPrevBtnClick }) => {
            return (
                <PictureQuizSlide
                    slideData={currentQuiz}
                    NextBtnEl={() => (
                        <Button
                            width="100%"
                            height="45px"
                            onClick={onNextBtnClick}
                        >
                            Next
                        </Button>
                    )}
                    PrevBtnEl={() => (
                        <Button
                            variant="pagination"
                            h="45px"
                            onClick={onPrevBtnClick}
                        >
                            <RiArrowLeftSLine />
                        </Button>
                    )}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        px: { base: 5, md: 15 },
                        py: { base: 7.5, md: 15 },
                        borderRadius: 'xl',
                        mx: { base: -5, md: 'auto' },
                        bg: 'gray.300',
                    }}
                />
            );
        },
    },
    // 10
    {
        type: QuizGroupType.Picture,
        isNextStep: true,
        title: 'Get Longevity Expert Advice',
        imgSrc: 'promo/people/zoom-girl.png',
        imgDesc: `Our platform provides a personal health and longevity tracker to keep you focused on wellness and fitness goals. Stay consistent and motivated with regular check-ins! Schedule a call with our health coach and get a personalized roadmap to enhance your journey toward lasting health and vitality.`,
        eventName: EventNames.GetLongevityExpertAdvice,
        Component: ({ currentQuiz, onNextBtnClick, onPrevBtnClick }) => {
            return (
                <PictureQuizSlide
                    slideData={currentQuiz}
                    NextBtnEl={() => (
                        <Button
                            width="100%"
                            height="45px"
                            onClick={onNextBtnClick}
                        >
                            Okay
                        </Button>
                    )}
                    PrevBtnEl={() => (
                        <Button
                            variant="pagination"
                            h="45px"
                            onClick={onPrevBtnClick}
                        >
                            <RiArrowLeftSLine />
                        </Button>
                    )}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        px: { base: 5, md: 15 },
                        py: { base: 7.5, md: 15 },
                        borderRadius: 'xl',
                        mx: { base: -5, md: 'auto' },
                        bg: 'gray.300',
                    }}
                />
            );
        },
    },
    {
        // 11
        type: QuizGroupType.Other,
        title: `Subscribe to ${capitalizeFirstLetter(APP_BRAND)}+ and get your first 7 days for free!`,
        subTitle: `At ${capitalizeFirstLetter(APP_BRAND)}+, you’ll have access to over 250 programs, including:`,
        eventName: EventNames.TrialSub,
        Component: ({ currentQuiz, onNextBtnClick }) => {
            return (
                <CustomQuizBtned
                    title={currentQuiz.title}
                    subTitle={currentQuiz.subTitle}
                    onSkipBtnClick={onNextBtnClick}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        pt: { base: 7.5, md: 12 },
                        mx: { base: 'auto' },
                    }}
                />
            );
        },
    },
    // 12
    {
        type: QuizGroupType.Picture,
        title: `Your Personalized Path to Success`,
        imgDesc:
            'Our platform, trained with insights from other students, has gathered tailored recommendations to make your journey toward your goals as effective as possible.',
        eventName: EventNames.YourPersonalizedPathToSuccess,
        imgSrc: 'promo/people/group-girls.png',
        Component: ({ onPrevBtnClick, currentQuiz, onNextBtnClick }) => {
            return (
                <PictureQuizSlide
                    slideData={currentQuiz}
                    NextBtnEl={() => (
                        <Button
                            width="100%"
                            height="45px"
                            onClick={() => (window.location.pathname = '/redirect-to-quiz-final-page')}
                        >
                            Okay
                        </Button>
                    )}
                    PrevBtnEl={() => (
                        <Button
                            variant="pagination"
                            h="45px"
                            onClick={onPrevBtnClick}
                        >
                            <RiArrowLeftSLine />
                        </Button>
                    )}
                    styles={{
                        position: 'relative',
                        maxW: '500px',
                        px: { base: 5, md: 15 },
                        py: { base: 7.5, md: 15 },
                        borderRadius: 'xl',
                        mx: { base: -5, md: 'auto' },
                        bg: 'gray.300',
                    }}
                />
            );
        },
    },
    // 13
    {
        type: QuizGroupType.Other,
        title: `Great! We have such a tracker.`,
        eventName: EventNames.WeHaveTracker,
        Component: ({ currentQuizStepNumber, currentQuiz, onNextBtnClick }) => {
            return (
                <ClassesForYouOnboardingList
                    styles={{
                        pt: { base: 7.5, md: 12 },
                    }}
                />
            );
        },
    },
];
