import { Box, HStack, Image, Text } from '@chakra-ui/react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { TypeOfCourse } from '../../model/useCheckoutPage';
import { capitalizeFirstLetter } from '@/shared/lib';
import { APP_BRAND } from '@/common/constants/brand';
import { CartTypeTariffs } from '@/features/checkout/one-click';
import { checkoutPricesAtom, typeCheckoutCheckedItemsAtom } from '../../model/checkout-store';
import { useAtomValue } from 'jotai';

export default function CheckoutCourseRegular({
    course,
    isNotDeleted = false,
    onDelete,
}: {
    course: any;
    isNotDeleted?: boolean;
    onDelete: (course: any) => void;
}) {
    const { imgSrc, title, author, price, type } = course;
    const typeCheckoutCheckedItems = useAtomValue(typeCheckoutCheckedItemsAtom);

    const coursePrices = useAtomValue(checkoutPricesAtom);
    const yearPrice = coursePrices ? Number(coursePrices.subscription_price) : '';
    const discountPercentMainCourse =
        coursePrices &&
        Math.round(
            ((coursePrices.price_base_course - coursePrices.price_course_by_subscription) /
                coursePrices.price_base_course) *
                100,
        );

    const discountPercent = type === TypeOfCourse.Optional ? '76' : discountPercentMainCourse;
    const discountPrice = type === TypeOfCourse.Optional ? 17 : coursePrices?.price_base_course;

    return (
        <Box mt={5}>
            <HStack
                gap={3}
                align="flex-start"
            >
                <Image
                    src={imgSrc}
                    alt={title}
                    flexShrink={0}
                    w="60px"
                    h="40px"
                    objectFit="contain"
                    borderRadius="lg"
                />

                <Box>
                    <Text
                        mb={0}
                        fontSize="15px"
                    >
                        {title}
                    </Text>

                    {author && (
                        <Text
                            mb={0}
                            fontSize="13px"
                        >
                            A course by {author}
                        </Text>
                    )}

                    {((typeCheckoutCheckedItems === CartTypeTariffs.Trial && type === TypeOfCourse.Optional) ||
                        (typeCheckoutCheckedItems === CartTypeTariffs.Trial && type === TypeOfCourse.Regular)) && (
                        <Text
                            mb={0}
                            fontSize="13px"
                            color="red.500"
                        >
                            {discountPercent}% Disc.{' '}
                            <Text
                                as="span"
                                textDecor="line-through"
                            >
                                ${discountPrice} USD
                            </Text>
                        </Text>
                    )}

                    {type === TypeOfCourse.Trial && (
                        <Text
                            fontSize="13px"
                            mb={0}
                        >
                            Enjoy the benefits of the {capitalizeFirstLetter(APP_BRAND)} Plus Subscription program with{' '}
                            <b>free access to 200+ courses</b>. Cancel anytime. When the 7-day free trial ends, we will
                            automatically charge you for the annual plan (${yearPrice} USD). Cancel anytime during 7-day
                            trial period to avoid charges.
                        </Text>
                    )}
                </Box>

                {!isNotDeleted && (
                    <Box
                        onClick={() => onDelete(course)}
                        as={RiDeleteBinLine}
                        cursor="pointer"
                        w="15px"
                        h="15px"
                        ml="auto"
                        flexShrink={0}
                    />
                )}
            </HStack>

            <Text
                as="span"
                display="block"
                textAlign="right"
            >
                ${price}
            </Text>
        </Box>
    );
}
