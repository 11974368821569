'use client';

import { Box, BoxProps, Flex, HStack, Text, VStack } from '@chakra-ui/react';

import { useCountdown } from '@/shared/ui';
import { RiCloseFill } from 'react-icons/ri';
import { useState } from 'react';

export default function CountdownGridLanding({
    timerSeconds,
    isEndlessHour,
    styles,
}: {
    timerSeconds?: number;
    isEndlessHour?: boolean;
    styles?: BoxProps;
}) {
    const { hours, minutes, seconds } = useCountdown(timerSeconds, isEndlessHour);
    const [isOpen, setIsOpen] = useState(true);

    if (!isOpen) {
        return null;
    }

    return (
        <Box
            py="12px"
            px="16px"
            bgColor="red.425"
            pos="relative"
            pr={{ base: 5, md: 8 }}
        >
            <Flex
                color="white"
                justify="space-between"
                align={{ md: 'center' }}
            >
                <Text
                    mt={{ base: 2, md: 0 }}
                    mb={0}
                    fontSize={{ base: '15px', md: '18px' }}
                >
                    <Text
                        as="span"
                        fontWeight={500}
                    >
                        Course from
                    </Text>{' '}
                    $0.99 USD
                </Text>

                <HStack {...styles}>
                    {[
                        {
                            value: hours,
                            title: 'hours',
                        },
                        {
                            value: minutes,
                            title: 'minutes',
                        },
                        {
                            value: seconds,
                            title: 'seconds',
                        },
                    ].map(({ value, title }, index) => (
                        <VStack
                            key={title}
                            gap={0}
                            textAlign="center"
                        >
                            <Text
                                as="span"
                                fontSize={{ base: '15px', md: '30px' }}
                                fontWeight={500}
                                lineHeight={1.2}
                            >
                                {value}
                            </Text>

                            <Text
                                as="span"
                                fontSize="11px"
                                color="white"
                            >
                                {title}
                            </Text>
                        </VStack>
                    ))}
                </HStack>
            </Flex>

            <Box
                as={RiCloseFill}
                pos="absolute"
                top="5px"
                right="5px"
                w="20px"
                h="20px"
                color="white"
                opacity={0.7}
                onClick={() => setIsOpen(!isOpen)}
            />
        </Box>
    );
}
