import { Box, BoxProps } from '@chakra-ui/react';


interface PromoAlertProps {
    text?: string;
    styles?: BoxProps;
}

export default function PromoAlert({ text = 'Keep this page opened', styles }: PromoAlertProps) {
    return (
        <Box
            layerStyle="alert"
            mb={{ base: 5, md: 6 }}
            mx={{ base: -5, md: 0 }}
            {...styles}
        >
            {text}
        </Box>
    );
}
