import { ReactNode } from 'react';
import { Card, CardBody, CardFooter, Text, Image, Box, CardProps, Flex } from '@chakra-ui/react';

import { EventData } from '@/entities/events/event-data';

interface EventCardProps {
    eventData: EventData;
    children?: ReactNode;
    styles?: CardProps;
}

export default function EventCardBlue({ eventData, children, styles }: EventCardProps) {
    const { thumb: thumbSrc, title, startDate } = eventData;

    return (
        <Card
            w="100%"
            bgColor={'#fff'}
            borderRadius={{ base: '30px', md: '16px' }}
            overflow={'hidden'}
            fontFamily={'Jeko, serif'}
            boxShadow={'none'}
            {...styles}
        >
            <CardBody
                zIndex={20}
                position="relative"
                flex={0}
                p={4}
            >
                <Image
                    src={thumbSrc ? thumbSrc : ''}
                    alt="event-thumb"
                    display="block"
                    minH="100px"
                    minW="calc(100% + 32px)"
                    mt={-4}
                    mx={-4}
                    mb={4}
                    objectFit="cover"
                />

                <Text
                    noOfLines={2}
                    mb={2}
                    fontSize={{ base: '18px', md: '20px' }}
                    h={'52px'}
                    lineHeight={1.3}
                    fontWeight={800}
                    color={' #525dd0'}
                >
                    {title}
                </Text>

                <Flex
                    justify={'space-between'}
                    gap={2}
                >
                    <Box w={{ base: '125px', md: 'auto' }}>
                        <Text
                            mb={1}
                            fontSize={{ base: '14px', md: '16px' }}
                            fontWeight={500}
                            color={'#b0b0b0'}
                        >
                            Date and time
                        </Text>

                        <Box
                            fontSize={{ base: '12px', md: '13px' }}
                            fontWeight={800}
                            color={'#525dd0'}
                        >
                            {startDate?.day}
                        </Box>
                        <Box
                            fontSize={{ base: '12px', md: '13px' }}
                            fontWeight={800}
                            color={'#525dd0'}
                        >
                            {startDate?.time}
                        </Box>
                    </Box>

                    <Box w={{ base: '125px', md: 'auto' }}>
                        <Text
                            mb={1}
                            fontSize={{ base: '14px', md: '16px' }}
                            fontWeight={500}
                            color={'#b0b0b0'}
                        >
                            Necessary equipment
                        </Text>

                        <Box
                            fontSize={{ base: '12px', md: '13px' }}
                            fontWeight={800}
                            color={'#525dd0'}
                        >
                            Yoga mat
                        </Box>
                        <Box
                            fontSize={{ base: '12px', md: '13px' }}
                            fontWeight={800}
                            color={'#525dd0'}
                        >
                            Blanket
                        </Box>
                    </Box>
                </Flex>
            </CardBody>

            {children && (
                <CardFooter
                    zIndex={20}
                    position="relative"
                    mt="auto"
                    p={4}
                >
                    {children}
                </CardFooter>
            )}
        </Card>
    );
}
