'use client';

import { Box, BoxProps } from '@chakra-ui/react';
import { useState } from 'react';

import { Navigation } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { CardReviewBlack, CardReviewProps } from '@/entities/cards/cards-review';
import { NavBtnBlack, NavBtnType } from '@/entities/btns/nav-btn';

interface ReviewSliderAvatarNavProps {
    reviewList: CardReviewProps[];
    styles?: BoxProps;
}

export default function ReviewSliderBlack({ reviewList, styles }: ReviewSliderAvatarNavProps) {
    const [swiperState, setSwiperState] = useState<SwiperClass>();

    const handleNext = () => {
        swiperState?.slideNext();
    };
    const handlePrevious = () => {
        swiperState?.slidePrev();
    };

    return (
        <Box
            layerStyle="swiper-tilda"
            position="relative"
            {...styles}
        >
            <Swiper
                modules={[Navigation]}
                slidesPerView="auto"
                spaceBetween="20"
                loop={true}
                breakpoints={{
                    1024: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    0: {
                        slidesPerView: 1,
                    },
                }}
                onSwiper={(swiper: SwiperClass) => setSwiperState(swiper)}
            >
                {reviewList.map((review: CardReviewProps, index: number) => (
                    <SwiperSlide
                        key={index}
                        style={{ height: 'auto' }}
                    >
                        <CardReviewBlack
                            {...review}
                            styles={{
                                px: {
                                    base: 5,
                                    md: 15,
                                },
                            }}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

            <Box>
                <NavBtnBlack
                    onClick={handlePrevious}
                    type={NavBtnType.Previous}
                    styles={{
                        position: 'absolute',
                        top: 'calc(50% - 15px)',
                        left: '-20px',
                        background: '#464646',
                        boxShadow: 'none',
                        color: '#fff',
                        w: '32px',
                        h: '32px',
                        sx: {
                            div: {
                                w: '7px',
                                h: '15px',
                            },
                        },
                    }}
                />

                <NavBtnBlack
                    onClick={handleNext}
                    type={NavBtnType.Next}
                    styles={{
                        position: 'absolute',
                        top: 'calc(50% - 15px)',
                        right: '-20px',
                        background: '#464646',
                        boxShadow: 'none',
                        color: '#fff',
                        w: '32px',
                        h: '32px',
                        sx: {
                            div: {
                                w: '7px',
                                h: '15px',
                            },
                        },
                    }}
                />
            </Box>
        </Box>
    );
}
